function convertList(list: any): List {
    let listObject: List = {
      listId: list.listId,
      listType: list.listType,
      programItemIndex: list.programItemIndex,
      event: list.event,
      sid: list.sid,
      pool: list.pool,
    };
    if (list.listType === "resultList" || list.listType === "resultListJunior") {
      (listObject as ResultList).results = list.results as ResultListItem[];
      (listObject as ResultList).round = list.round;
    } else if (list.listType === "startList") {
      (listObject as StartList).starts = list.starts as StartListItem[];
      (listObject as StartList).heats = (list as any).heats;
      (listObject as StartList).heatArray = convertHeatArray(list.starts);
    } else if (list.listType === "entryList" || list.listType === "entryListJunior") {
      (listObject as EntryList).entries = list.entries as EntryListItem[];
      (listObject as EntryList).type = list.type;
    }
    return listObject;
  }

  function convertHeatArray(starts: StartListItem[]): Heat[] {
    let heatArray: Heat[] = [];
    let heatMap: { [key: string]: Heat } = {};
    for (let start of starts) {
      if (start.heat) {
        if (!heatMap[start.heat]) {
          heatMap[start.heat] = {
            heatId: start.heatId,
            heatName: start.heatName,
            heatNumber: parseInt(start.heat),
            starts: [start],
            status: undefined,
          };
        } else {
          heatMap[start.heat].starts.push(start);
        }
      }
    }
    for (let heatId in heatMap) {
      heatArray.push(heatMap[heatId]);
    }
    // sort heats by heatNumber
    heatArray.sort((a, b) => a.heatNumber - b.heatNumber);
    return heatArray;
  }

  export function convertLiveTimingLists(lists: any): Lists | undefined {
    let listsObject: Lists = {};
    for (const [listId, list] of Object.entries(lists)) {
      listsObject[listId] = convertList(list);
    }

    // check if there are both resultList and resultListJunior for the same programItemIndex, and if so, append the junior results to the regular results while setting the attribute "series" to "junior"
    for (const [listId, list] of Object.entries(listsObject)) {
      if (list.listType === "resultList") {
        let resultList = list as ResultList;
        // find 3rd underscore from the beginning of listId, and remove everything after that
        let juniorId = listId.substring(0, listId.indexOf("_", listId.indexOf("_", listId.indexOf("_") + 1) + 1)) + "_J_resultListJunior";
        if (listsObject[juniorId]) {
          let resultListJunior = listsObject[juniorId] as ResultList;
          if (resultListJunior.results) {
            for (let r = 0; r < resultListJunior.results.length; r++) {
              resultListJunior.results[r].series = "Nuoret";
            }
            resultList.results = resultList?.results?.concat(resultListJunior.results);
            listsObject[listId] = resultList;
            // delete the junior list
            delete listsObject[juniorId];
          }
        }
      }
    }

    // check if there are both entryList and entryListJunior for the same programItemIndex, and if so, append the junior entries to the regular entries while setting the attribute "series" to "Nuoret"
    for (const [listId, list] of Object.entries(listsObject)) {
      if (list.listType === "entryList") {
        let entryList = list as EntryList;
        // find 3rd underscore from the beginning of listId, and remove everything after that
        let juniorId = listId.substring(0, listId.indexOf("_", listId.indexOf("_", listId.indexOf("_") + 1) + 1)) + "_STJ_entryListJunior";
        if (listsObject[juniorId]) {
          let entryListJunior = listsObject[juniorId] as EntryList;
          if (entryListJunior.entries) {
            for (let e = 0; e < entryListJunior.entries.length; e++) {
              entryListJunior.entries[e].series = "Nuoret";
            }
            entryList.entries = entryList?.entries?.concat(entryListJunior.entries);
            listsObject[listId] = entryList;
            // delete the junior list
            delete listsObject[juniorId];
          }
        }
      }
    }

    // check if there are only junior results but no regular results, and if so, rename the listType to resultlist while setting the attribute "series" to "Nuoret"
    for (const [listId, list] of Object.entries(listsObject)) {
      if (list.listType === "resultListJunior") {
        let resultListJunior = list as ResultList;
        // find 3rd underscore from the beginning of listId, and remove everything after that
        let regularId =
          listId.substring(0, listId.indexOf("_", listId.indexOf("_", listId.indexOf("_") + 1) + 1)) + "_" + (list as ResultList).round + "_resultList";
        if (!listsObject[regularId]) {
          resultListJunior.listType = "resultList";
          if (resultListJunior.results) {
            for (let r = 0; r < resultListJunior.results.length; r++) {
              resultListJunior.results[r].series = "Nuoret";
            }
            listsObject[listId] = resultListJunior;
          }
        }
      }
    }

    // check if there are only junior entries but no regular entries, and if so, rename the listType to entryList while setting the attribute "series" to "Nuoret"
    for (const [listId, list] of Object.entries(listsObject)) {
      if (list.listType === "entryListJunior") {
        let entryListJunior = list as EntryList;
        // find 3rd underscore from the beginning of listId, and remove everything after that
        let regularId = listId.substring(0, listId.indexOf("_", listId.indexOf("_", listId.indexOf("_") + 1) + 1)) + "_" + "_entryList";
        if (!listsObject[regularId]) {
          entryListJunior.listType = "entryList";
          if (entryListJunior.entries) {
            for (let e = 0; e < entryListJunior.entries.length; e++) {
              entryListJunior.entries[e].series = "Nuoret";
            }
            listsObject[listId] = entryListJunior;
          }
        }
      }
    }

    // for each resultList, convert the results to summaries, where the name of each summary matches the series of the result
    for (const [listId, list] of Object.entries(listsObject)) {
      if (list.listType === "resultList") {
        let resultList = list as ResultList;
        if (resultList.results) {
          let summaries: SummariesObject = {};
          for (let result of resultList.results) {
            let seriesName = (result.series ? result.series : "Total");
            if (!summaries[seriesName]) {
              summaries[seriesName] = [];
            }
            delete result.series;
            summaries[seriesName].push(result);
          }
          resultList.summaries = summaries;
          listsObject[listId] = resultList;

          // delete the converted results array
          //delete resultList.results;
        }
      }
    }

    // for each entryList, convert the entries to entryRanks, where the name of each entryRank matches the series of the entry
    for (const [listId, list] of Object.entries(listsObject)) {
      if (list.listType === "entryList") {
        let entryList = list as EntryList;
        if (entryList.entries) {
          let entryRanks: EntryRanksObject = {};
          for (let entry of entryList.entries) {
            let seriesName = (entry.series ? entry.series : "Total");
            if (!entryRanks[seriesName]) {
              entryRanks[seriesName] = [];
            }
            delete entry.series;
            entryRanks[seriesName].push(entry);
          }
          entryList.entryRanks = entryRanks;
          listsObject[listId] = entryList;

          // delete the converted entries array
          //delete entryList.entries;
        }
      }
    }

    return listsObject;
  }