import { getListIds } from "../Helpers";
import moment from "moment";
import { gql } from "../../src/__generated__/gql";

export const EVENT_QUERY = gql(/* GraphQL */ `
  query Events($cid: uuid!, $event: Int!) {
    events(where: { competition_id: { _eq: $cid }, number: { _eq: $event } }) {
      ...EventData
      entry_list_types(order_by: { order: asc }) {
        ...EntryListTypeData
        entry_ranks {
          ...EntryRankData
          entry {
            ...EntryData
            competitor {
              ...CompetitorData
              club {
                ...ClubNames
              }
              relay_competitors(order_by: { order: asc }) {
                competition_id
                competitor_oid
                created_at
                entry_oid
                id
                lane_oid
                oid
                order
                updated_at
              }
            }
          }
        }
      }
      rounds(order_by: { sort_order: asc }) {
        ...RoundData
        summary_types(order_by: { order: asc }) {
          ...SummaryTypeData
          ranks(order_by: { rank: asc }) {
            ...RankData
            lane {
              ...LaneData
              competitor {
                ...CompetitorData
                club {
                  ...ClubNames
                }
                relay_competitors(order_by: { order: asc }) {
                  competition_id
                  competitor_oid
                  created_at
                  entry_oid
                  id
                  lane_oid
                  oid
                  order
                  updated_at
                }
              }
              heat {
                ...HeatData
              }
            }
          }
        }
        heats(order_by: { number: asc }) {
          ...HeatData
          time_program_entry_oid
          lanes(order_by: { number: asc }) {
            ...LaneData
            sub_results {
              done_at
              result_value_text
            }
            competitor {
              ...CompetitorData
              club {
                ...ClubNames
              }
              relay_competitors(order_by: { order: asc }) {
                competition_id
                competitor_oid
                created_at
                entry_oid
                id
                lane_oid
                oid
                order
                updated_at
              }
            }
          }
        }
      }
    }
  }
`);

export function getStartList(swimifyEvent: any, programItem: ProgramItem) {
  let round_oid = programItem?.roundOid;
  let tpid = programItem?.tpid;
  let roundData = swimifyEvent?.rounds.find((round: any) => round.oid === round_oid);
  //let heatsData = roundData?.heats.filter((heat: any) => heat.time_program_entry_oid === tpid);
  const startList: StartList = {
    listType: "startList",
    listId: getListIds(programItem).startListId,
    sid: programItem.sid,
    event: programItem.event,
    programItemIndex: programItem.programItemIndex,
    heats: roundData?.heats.length, //TODO,
    starts: getStarts(swimifyEvent, programItem, undefined),
    heatArray: getHeats(swimifyEvent, programItem),
  };
  return startList;
}

function getHeats(swimifyEvent: any, programItem: ProgramItem): Heat[] {
  let heatArray: Heat[] = [];
  let round_oid = programItem?.roundOid;
  let tpid = programItem?.tpid;
  let roundData = swimifyEvent?.rounds.find((round: any) => round.oid === round_oid);
  let heatsData = roundData?.heats.filter((heat: any) => heat.time_program_entry_oid === tpid);
  heatsData.forEach((heat: any) => {
    let heatData: Heat = {
      heatId: heat.id,
      heatName: heat.name,
      heatNumber: heat.number,
      starts: getStarts(swimifyEvent, programItem, heat.id),
      startTime: heat.start_time,
      estimatedStartTime: heat.estimated_start_time,
      status: heat.status,
      isLive: false,
    };
    heatArray.push(heatData);
  });
  return heatArray;
}

function convertSplits(sub_results: any): Splits | undefined {
  let splits: Splits = {};
  if(sub_results) {
    for (let i = 0; i < sub_results.length; i++) {
      splits[sub_results[i].done_at + "m"] = sub_results[i].result_value_text;
    }
  return splits;
  }
}

function getStarts(swimifyEvent: any, programItem: ProgramItem, heatId: number | undefined) {
  let starts: StartListItem[] = [];
  let round_oid = programItem?.roundOid;
  let tpid = programItem.tpid;
  let roundData = swimifyEvent?.rounds.find((round: any) => round.oid === round_oid);
  let heatsData = roundData?.heats.filter((heat: any) => heat.time_program_entry_oid === tpid);
  heatsData.forEach((heat: any) => {
    if (heatId && heatId !== heat.id) {
      // do nothing if heatId is defined and not equal to heat.id
    } else {
      heat.lanes.forEach((lane: any) => {
        if (lane.competitor) {
          let swimmer = lane.competitor;
          let start: StartListItem = {
            name: swimmer.full_name,
            lastName: swimmer.last_name,
            firstNames: swimmer.first_name,
            event: programItem.event,
            club: swimmer.club?.name,
            year: swimmer.birthday.substring(0, 4),
            listId: getListIds(programItem).startListId,
            listType: "startList",
            listItemType: "start",
            programItemIndex: programItem.programItemIndex,
            sid: programItem.sid,
            id: swimmer.full_name + " " + swimmer.birthday.substring(0, 4),
            heat: heat.number,
            heatName: heat.name,
            track: lane.number,
            startlistindex: 1000 * heat.number + lane.number,
            heat_rank: lane.heat_rank,
            heatId: heat.id,
            heatTimeText: moment(heat.estimated_start_time).format("H.mm"),
            resultTime: lane.result_text,
            entryTime: lane.entry_time_text,
            nationality: swimmer.country_code,
            dsq: lane.dsq,
            dnf: lane.dnf,
            dns: lane.dns,
            dq_code: lane.dq_code,
            dq_description: lane.dq_description,
            swimmed_distance: lane.swimmed_distance,
            splits: convertSplits(lane.sub_results),
            isLive: false,
            heatStatus: heat.status,
            additionalInfo:
              "" +
              (lane.qualification ? lane.qualification : "") +
              (lane.dnf ? "DNF " : "") +
              (lane.dns ? "DNS " : "") +
              (lane.dsq ? "DSQ " : ""),
          };
          if (start.heat_rank === 0) {
            start.heat_rank = undefined;
          }
          starts.push(start);
        }
      });
    }
  });
  return starts;
}

export function getResultList(swimifyEvent: any, programItem: ProgramItem) {
  let round_oid = programItem?.roundOid;
  const resultList: ResultList = {
    listType: "resultList",
    listId: getListIds(programItem).resultListId,
    sid: programItem.sid,
    event: programItem.event,
    programItemIndex: programItem.programItemIndex,
    summaries: getSummaries(swimifyEvent, programItem),
    round: "" + round_oid,
  };
  return resultList;
}

function sortRanks(a: any, b: any) {
  let sortKeyA = a.rank;
  let sortKeyB = b.rank;
  if (a.rank === 0) {
    sortKeyA = -1000;
  }
  if (b.rank === 0) {
    sortKeyB = 1000;
  }
  if (sortKeyA < sortKeyB) {
    return -1;
  }
  if (sortKeyA > sortKeyB) {
    return 1;
  }
  return 0;
}

function getSummaries(swimifyEvent: any, programItem: ProgramItem) {
    let round_oid = programItem?.roundOid;
    let roundData = swimifyEvent?.rounds.find((round: any) => round.oid === round_oid);
    let summaries: SummariesObject = {};
    roundData &&
        roundData.summary_types &&
        roundData.summary_types.forEach((summaryType: any) => {
            const results = getResults(summaryType, programItem);
            if(results && results.length > 0) {
                summaries[summaryType.name] = results;
            }
        });
    // make "Total" the last name in the object
    let total = summaries["Total"];
    delete summaries["Total"];
    summaries["Total"] = total;

    // if there are two summaries with the same ranks_count and the other one has an empty name, remove the one with empty name
    let keys = Object.keys(summaries);
    keys.forEach((key) => {
        if (key === "") {
            // if there is another summary with name "Total" and same amount of ranks, remove this one
            let total = summaries["Total"];
            if (total && total.length === summaries[key].length) {
                delete summaries[key];
            }
        }
    });
    
    return summaries;
}

function getResults(summaryType: any, programItem: ProgramItem) {
  let results: ResultListItem[] = [];
      let ranksForSort = [...summaryType.ranks];
      ranksForSort &&
        ranksForSort.sort(sortRanks).forEach((rank: any) => {
          if (rank?.lane?.competitor) {
            let swimmer = rank.lane.competitor;
            let result: ResultListItem = {
              name: swimmer?.full_name,
              lastName: swimmer?.last_name,
              firstNames: swimmer?.first_name,
              event: programItem.event,
              club: swimmer?.club?.name,
              year: swimmer?.birthday?.substring(0, 4),
              listId: getListIds(programItem).resultListId,
              listType: "resultList",
              listItemType: "result",
              programItemIndex: programItem?.programItemIndex,
              sid: programItem.sid,
              id: swimmer?.full_name + " " + swimmer?.birthday?.substring(0, 4),
              time: rank.lane.result_text,
              entryTime: rank.lane.entry_time_text,
              position: "" + rank.rank,
              nationality: swimmer?.country_code,
              dq_code: rank.lane.dq_code,
              dq_description: rank.lane.dq_description,
              additionalInfo:
                "" +
                (rank.lane.qualification ? rank.lane.qualification : "") +
                (rank.lane.dnf ? "DNF " : "") +
                (rank.lane.dns ? "DNS " : "") +
                (rank.lane.dsq ? "DSQ " : ""),
            };
            if (result.position === "0") {
              result.position = "-";
            }
            results.push(result);
            
          }
        });
  return results;
}

export function getEntryList(swimifyEvent: any, programItem: ProgramItem) {
  const entryList: EntryList = {
    listType: "entryList",
    type: "entry",
    sid: programItem.sid,
    listId: getListIds(programItem).entryListId,
    event: programItem.event,
    programItemIndex: programItem.programItemIndex,
    //entries: getEntries(swimifyEvent, programItem),
    entryRanks: getEntryRanks(swimifyEvent, programItem),
  };
  return entryList;
}

function getEntryRanks(swimifyEvent: any, programItem: ProgramItem) {
    let entryRanks : EntryRanksObject = {};
    if (swimifyEvent?.entry_list_types) {
        swimifyEvent.entry_list_types.forEach((entryListType: any) => {
            let entryRanksName = entryListType.name;
            if(entryRanksName === "") {
              entryRanksName = "Total";
            }
            entryRanks[entryRanksName] = getEntries(entryListType, programItem);
        });
    }
    return entryRanks;
}

function getEntries(entryListType: any, programItem: ProgramItem) : EntryListItem[] {
  let entries: EntryListItem[] = [];
      entryListType.entry_ranks.forEach((entryRank: any) => {
        if (entryRank.entry?.competitor) {
          let swimmer = entryRank.entry.competitor;
          let entry: EntryListItem = {
            name: swimmer.full_name,
            lastName: swimmer.last_name,
            firstNames: swimmer.first_name,
            event: programItem.event,
            club: swimmer.club?.name,
            year: swimmer.birthday.substring(0, 4),
            listId: getListIds(programItem).entryListId,
            listType: "entryList",
            listItemType: "entry",
            programItemIndex: programItem.programItemIndex,
            sid: programItem.sid,
            id: swimmer.full_name + " " + swimmer.birthday.substring(0, 4),
            entryTime: entryRank.entry?.entry_time_text,
            nationality: swimmer.country_code,
            ranking: entryRank.rank,
          };
          entries.push(entry);
        }
      });
  return entries;
}
