import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import { addFavoriteClub, isFavoriteClub, removeFavoriteClub } from "../../User/FavoriteHelpers";
import getSwimifyClubs from "../GetClubs";
import { FlagIcon } from "../../Helpers";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemButton from "@mui/material/ListItemButton";

interface SwimmersClubsProps {
  clubs: Clubs | undefined;
  cid: string;
  userSettings: UserSettings | undefined;
  clubNamePairs: ClubNamePairs | undefined;
  setAccountDialog: (accountDialogOpen: boolean) => void;
}

export function SwimmersClubs(props: SwimmersClubsProps) {
  const navigate = useNavigate();
  const favoriteClubs = props.userSettings && props.userSettings.favoriteClubs;
  const anonGuestModeStarted = props.userSettings && props.userSettings.anonGuestModeStarted;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  function sortClubsAlpha(clubA: Club, clubB: Club) {
    return clubB.name.trim().localeCompare(clubA.name.trim()) > 0 ? -1 : 1;
  }

  function clubClicked(clubName: string) {
    navigate("../../club/" + encodeURIComponent(clubName));
  }

  const skipClubs = props.cid.length === 4 || props.userSettings === undefined;
  const {
    swimifyClubs: swimifyClubs,
    swimifyClubsLoading: swimifyClubsLoading,
    swimifyClubsLoadingError: swimifyClubsLoadingError,
  } = getSwimifyClubs(props.cid, skipClubs);
  const clubs = props.cid.length === 4 ? props.clubs : swimifyClubs;

  interface ClubsListType {
    [key: string]: Club;
  }

  let clubsList: ClubsListType = {};
  if (clubs !== undefined) {
    // loop through clubs object
    for (const c of Object.keys(clubs)) {
      // jos seura on parillinen ja löytyy parillisten seurojen listasta
      //console.log("comparing", c.name, props.clubNamePairs, props.clubNamePairs && props.clubNamePairs.hasOwnProperty(c.name))
      if (props.clubNamePairs && props.clubNamePairs.hasOwnProperty(c)) {
        // parillinen seura
        let pairClubName = props.clubNamePairs[c];
        // jos parillinen seura on pidempi kuin alkuperäinen seura, käytetään sitä
        if (c.length > pairClubName.length) {
          clubsList[c] = { name: c, shortName: pairClubName };
          if (clubs[c].competitor_count) {
            clubsList[c].competitor_count = clubs[c].competitor_count;
          }
          if (clubs[c].country_code) {
            clubsList[c].country_code = clubs[c].country_code;
          }
          // jos alkuperäinen seura on pidempi kuin parillinen seura, käytetään sitä
        } else {
          clubsList[pairClubName] = { name: pairClubName, shortName: c };
          if (clubs[pairClubName].competitor_count) {
            clubsList[pairClubName].competitor_count = clubs[pairClubName].competitor_count;
          }
          if (clubs[pairClubName].country_code) {
            clubsList[pairClubName].country_code = clubs[pairClubName].country_code;
          }
        }
      } else {
        // pariton seura, lisätään listaan
        clubsList[c] = { name: c };
        if (clubs[c].competitor_count) {
          clubsList[c].competitor_count = clubs[c].competitor_count;
        }
        if (clubs[c].country_code) {
          clubsList[c].country_code = clubs[c].country_code;
        }
      }
    }
  }

  return (
    <div>
      <div style={{ height: "4px", width: "100%", clear: "both", marginBottom: 0 }}>
        {(clubsList === undefined || swimifyClubsLoading === undefined) && <LinearProgress />}
      </div>

      {clubsList !== undefined && Object.keys(clubsList).length > 0 && (
        <div>
          <List style={{ maxHeight: "100%", overflow: "auto" }}>
            {Object.values(clubsList)
              .sort(sortClubsAlpha)
              .map((club: Club) => (
                <ListItem
                  key={"clubs_" + club.name}
                  disablePadding
                  secondaryAction={
                    <>
                      {!isFavoriteClub(club.name, club.shortName, favoriteClubs) ? (
                        <IconButton
                          edge="end"
                          onClick={() =>
                            addFavoriteClub(club.name, club.shortName, favoriteClubs, props.setAccountDialog, anonGuestModeStarted, enqueueSnackbar)
                          }
                        >
                          <FavoriteBorderIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          edge="end"
                          onClick={() =>
                            removeFavoriteClub(club.name, club.shortName, favoriteClubs, props.setAccountDialog, anonGuestModeStarted, enqueueSnackbar)
                          }
                        >
                          <FavoriteIcon style={{ color: theme.palette.secondary.main }} />
                        </IconButton>
                      )}
                      <IconButton edge="end" onClick={() => clubClicked(club.name)} tabIndex={-1}>
                        <ArrowRightIcon />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemButton component={Link} to={"../../club/" + encodeURIComponent(club.name)} aria-label={club.name}>
                    <ListItemText
                      sx={{ fontSize: "8pt" }}
                      primary={
                        <span>
                          {club.name}
                          {club.country_code && (
                            <span style={{ marginLeft: "0.5rem" }}>
                              <FlagIcon size="large" nationality={club.country_code} />
                            </span>
                          )}
                        </span>
                      }
                      secondary={
                        <span>
                          {club.competitor_count ? club.competitor_count + " uimari" : ""}
                          {club.competitor_count && club.competitor_count > 1 ? "a" : ""}
                        </span>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </div>
      )}

      {clubsList !== undefined && Object.keys(clubsList).length === 0 && (
        <Typography variant="body2" style={{ margin: "12px" }}>
          Kilpailun listoissa ei ole vielä uimareita.
        </Typography>
      )}
    </div>
  );
}
