import { useQuery, gql } from "@apollo/client";

const COMPETITIONDATA_QUERY = gql`
  query CompetitionsData($cid: uuid!) @cached(ttl: 60) {
    competitions_by_pk(id: $cid) {
      endDate
      id
      locked
      manual_live_sort_order
      name
      nation_code
      organizer
      city
      pool_name
      pool_type
      seo_text
      show_age
      startDate
      superlive
      updated_at
      events(order_by: { number: asc }) {
        athlete_count
        competition_id
        created_at
        distance
        event_competition_level
        event_type
        gender_number
        hidden
        id
        is_masters_event
        is_para_event
        is_relay
        max_age
        min_age
        name
        number
        oid
        sponsor_oid
        updated_at
        use_pre_masters
        agegroups {
          competition_id
          created_at
          event_oid
          from_age
          id
          name
          number
          oid
          to_age
          updated_at
        }
      }
      competition_sessions(order_by: { number: asc }) {
        competition_id
        created_at
        day
        id
        name
        number
        oid
        start_time
        superlive_seo_link
        updated_at
        time_program_entries(order_by: { sort_order: asc }) {
          competition_id
          competition_session_oid
          created_at
          id
          name
          oid
          round_oid
          sort_order
          start_time
          stop_time
          type
          updated_at
          round {
            competition_id
            created_at
            event_oid
            id
            name
            official_at
            oid
            round_type
            sort_order
            status
            updated_at
          }
        }
      }
      file_archives {
        category_type
        competition_id
        competition_session_oid
        created_at
        description
        id
        link
        name
        order
        round_oid
        updated_at
        visible
      }
      competitors {
        age
        birthday
        club_oid
        code
        competition_id
        country_code
        created_at
        first_name
        full_name
        full_name_reversed
        gender_number
        id
        is_para
        is_relay
        last_name
        oid
        para_class_breast
        para_class_free
        para_class_medley
        updated_at
      }
      clubs {
        city
        code
        competition_id
        country_code
        country_name
        id
        name
        oid
        region_name
        short_name
      }
    }
  }
`;

export function getSwimifyCompetitionData(cid: string | undefined, skip : boolean) {
    const {
        data: swimifyCompetitionData,
        loading: competitionDataLoading,
        error: competitionDataError,
      } = useQuery(COMPETITIONDATA_QUERY, {
            variables: { cid },
            pollInterval: 1 * 60 * 1000, // every 1 minute
            skip: skip
          });
    return { data: swimifyCompetitionData, loading: competitionDataLoading, error: competitionDataError };
}

export default getSwimifyCompetitionData;