import Avatar from "@mui/material/Avatar";
import useTheme from "@mui/material/styles/useTheme";
import CoffeeIcon from "@mui/icons-material/Coffee";
import { EmojiEvents } from "@mui/icons-material";

interface ProgramItemAvatarProps {
  programItem: ProgramItem;
  isLive: boolean;
}

export const ProgramItemAvatar: React.FC<ProgramItemAvatarProps> = (props: ProgramItemAvatarProps): JSX.Element => {
  const theme = useTheme();
  const programItem = props.programItem;
  const isLive = props.isLive;

  let avatarColor = theme.palette.grey[500]; // default is gray
  if (programItem.status === 5) {
    avatarColor = "#53aa8a"; // done is green
  }
  if (isLive) {
    avatarColor = theme.palette.error.main; // live is red
  }
  let avatar = (
    <Avatar variant="rounded" style={{ backgroundColor: avatarColor }}>
      <div style={{ display: "table", marginTop: "auto", marginBottom: "auto" }}>
        <div style={{ textAlign: "center", fontSize: "0.55rem", marginLeft: "auto", marginRight: "auto", marginTop: "0.1rem", marginBottom: "0.15rem" }}>
          LAJI
        </div>
        <div style={{ display: "table", marginLeft: "auto", marginRight: "auto" }}>{programItem.event}</div>
      </div>
    </Avatar>
  );

  if (programItem.event === "0") {
    avatar = <Avatar style={{ backgroundColor: theme.palette.background.default }} />;
  }

  if (programItem.programItemName && (programItem.programItemName.includes("Tauko") || programItem.programItemName.includes("Break"))) {
    avatar = (
      <Avatar variant="rounded" style={{ backgroundColor: avatarColor }}>
        <CoffeeIcon />
      </Avatar>
    );
  }
  if (
    programItem.event === "0" &&
    programItem.programItemName &&
    (programItem.programItemName.includes("Palkinto") || programItem.programItemName.includes("Prize"))
  ) {
    avatar = (
      <Avatar variant="rounded" style={{ backgroundColor: "#d6bb28" }}>
        <EmojiEvents />
      </Avatar>
    );
  }

  return avatar;
};
