import { useQuery } from "@apollo/client";
import { getListIds, getStartOrLiveStart } from "../Helpers";
import { gql } from "../../src/__generated__/gql";

/*import { CompetitorDataFragment } from "./GetCompetitors";
import { EntryFragment } from "./GetProgramItemData";
import { EntryRankFragment } from "./GetProgramItemData";
import { EntryListTypeFragment } from "./GetProgramItemData";
import { EventFragment } from "./GetProgramItemData";
import { RoundFragment } from "./GetProgramItemData";
import { SummaryTypeFragment } from "./GetProgramItemData";
import { RankFragment } from "./GetProgramItemData";
import { LaneFragment } from "./GetProgramItemData";
import { HeatFragment } from "./GetProgramItemData"; */
import moment from "moment";

const SWIMMERDATA_QUERY = gql(/* GraphQL */ `
  query CompetitorDetails($cid: uuid!, $full_name: String!, $age: Int!) @cached(ttl: 30) {
    competitor(
      where: {
        _and: [
          { competition_id: { _eq: $cid } }
          {
            _or: [
              { entries: { relay_competitors: { competitor: { full_name: { _eq: $full_name }, age: { _eq: $age } } } } }
              { full_name: { _eq: $full_name }, age: { _eq: $age } }
            ]
          }
        ]
      }
    ) {
      ...CompetitorData
      club {
        ...ClubNames
      }
      entries {
        ...EntryData
        entry_ranks(order_by: { rank: asc }) {
          ...EntryRankData
          entry_list_type {
            ...EntryListTypeData
          }
        }
        event {
          ...EventData
        }
        relay_competitors(order_by: { order: asc }) {
          competition_id
          competitor_oid
          created_at
          entry_oid
          id
          lane_oid
          oid
          order
          updated_at
          competitor {
            id
            oid
            full_name
            first_name
            last_name
            birthday
            age
          }
        }
      }
      lanes(order_by: { heat: { number: asc } }) {
        result_text
        fina_points
        wps_points
        ...LaneData
        sub_results {
          done_at
          id
          oid
          order
          relay_competitor_order
          result_value_text
          split_diff_text
          take_over
        }
        heat {
          ...HeatData
          time_program_entry_oid
          round {
            ...RoundData
            event {
              ...EventData
            }
          }
        }
        ranks {
          ...RankData
          summary_type {
            ...SummaryTypeData
            round {
              ...RoundData
            }
          }
        }
      }
    }
  }
`);

export function getSwimifySwimmer(liveHeatData : any, cid: string, swimmerId: string, competitionData: CompetitionData | undefined, skip: boolean, filterEvent : string | undefined) {
  const full_name = swimmerId.substring(0, swimmerId.lastIndexOf(" "));
  const year = swimmerId.substring(swimmerId.lastIndexOf(" ") + 1);
  const age = competitionData !== undefined ? 
  year.startsWith("0") ? parseInt(year) : parseInt(competitionData?.startDate?.substring(0, 4)) - parseInt(year) : -1;

  const {
    data: swimifySwimmers,
    loading: swimifySwimmerLoading,
    error: swimifySwimmerError,
  } = useQuery(SWIMMERDATA_QUERY, {
    variables: { cid, full_name, age },
    pollInterval: 1 * 60 * 1000, // every 1 minute
    skip: skip || competitionData === undefined,
  });

  if (swimifySwimmers === undefined || swimifySwimmers.competitor === undefined) {
    return { swimifySwimmer: undefined, swimifySwimmerLoading: undefined, swimifySwimmerError: undefined };
  }

  // TODO what if multiple swimmers with same name and age?
  const firstSwimmer = swimifySwimmers.competitor[0];

  const swimmer: Swimmer = {
    id: swimmerId,
    // @ts-ignore
    name: firstSwimmer?.full_name,
    // @ts-ignore
    firstNames: firstSwimmer?.first_name,
    // @ts-ignore
    lastName: firstSwimmer?.last_name,
    // @ts-ignore
    club: firstSwimmer?.club?.name,
    // @ts-ignore
    nationality: firstSwimmer?.country_code,
    // @ts-ignore
    year: firstSwimmer?.birthday?.substring(0, 4),
  };

  // How about combining them?
  let swimmerSessionsArray: SwimmerSessions[] = [];
  for (var i = 0; i < swimifySwimmers.competitor.length; i++) {
    let sessions = getSwimmerSessions(liveHeatData, swimmer, swimifySwimmers.competitor[i], competitionData as CompetitionData);
    if (sessions) {
      swimmerSessionsArray.push(sessions);
    }
  }
  let swimmerSessions: SwimmerSessions = {};
  for (var i = 0; i < swimmerSessionsArray.length; i++) {
    for (const [sid, session] of Object.entries(swimmerSessionsArray[i])) {
      if (swimmerSessions[sid] === undefined) {
        swimmerSessions[sid] = session;
      } else {
        for (const [programItemIndex, programItem] of Object.entries(session.swimmerProgramItems)) {
          if (swimmerSessions[sid]?.swimmerProgramItems[programItemIndex] === undefined) {
            swimmerSessions[sid].swimmerProgramItems[programItemIndex] = programItem;
          }
        }
      }
    }
  }

  if (swimmer && competitionData && swimmerSessions) {
    swimmer.swimmerSessions = swimmerSessions;
  }

  return { swimifySwimmer: swimmer, swimifySwimmerLoading: swimifySwimmerLoading, swimifySwimmerError: swimifySwimmerError };
}

function getSwimmerSessions(liveHeatData : any, swimmer: Swimmer, swimifySwimmer: any, competitionData: CompetitionData | undefined): SwimmerSessions | undefined {
  if (competitionData === undefined) return undefined;
  if (competitionData.sessions === undefined) return undefined;

  let swimmerSessions: SwimmerSessions = {};

  for (const [sid, session] of Object.entries(competitionData.sessions)) {
    let swimmerProgramItems: SwimmerProgramItems | undefined = getSwimmerProgramItems(liveHeatData, swimmer, swimifySwimmer, session);
    if (swimmerProgramItems !== undefined && Object.keys(swimmerProgramItems).length > 0) {
      let swimmerSession: SwimmerSession = {
        sid: sid,
        swimmerProgramItems: swimmerProgramItems,
      };
      swimmerSessions[session.sid] = swimmerSession;
    }
  }
  return swimmerSessions;
}

function getSwimmerProgramItems(liveHeatData : any, swimmer: Swimmer, swimifySwimmer: any, session: Session): SwimmerProgramItems | undefined {
  if (session.programItems === undefined) return undefined;
  let swimmerProgramItems: any = {};

  for (const [programItemIndex, programItem] of Object.entries(session.programItems)) {
    let swimmerProgramItem: any = undefined;

    // get entries
    let entryData = swimifySwimmer?.entries?.find((entry: any) => entry.event.number + "" === programItem.event);
    if (entryData) {
      let spi: SwimmerProgramItem = {
        programItemIndex: parseInt(programItemIndex),
        sid: session.sid,
        competitorType: "competitor",
      };
      const entry = getEntry(swimmer, entryData, programItem);
      if (entry) {
        spi.entry = entry;
        swimmerProgramItem = spi as SwimmerProgramItem;
      }
    }

    // get starts
    let startData = swimifySwimmer?.lanes?.find(
      (lane: any) => lane.heat.round?.event?.number + "" === programItem.event && lane.heat.round?.oid + "" === programItem.roundOid + "" && lane.heat.time_program_entry_oid + "" === programItem.tpid + ""
    );
    if (startData) {
      let spi: SwimmerProgramItem = {
        programItemIndex: parseInt(programItemIndex),
        sid: session.sid,
        competitorType: "competitor",
      };
      const start = 
        getStartOrLiveStart(
          getStart(swimmer, startData, programItem)
        ,
          liveHeatData
        );
      if (start) {
        spi.start = start;
        if (swimmerProgramItem) {
          swimmerProgramItem.start = start;
        } else {
          swimmerProgramItem = spi as SwimmerProgramItem;
        }
      }
    }

    // get results
    let laneData = swimifySwimmer?.lanes?.find(
      (lane: any) => lane.heat.round?.event?.number + "" === programItem.event && lane.heat.round?.oid + "" === programItem.roundOid + ""
    );
    if (laneData) {
        const round = laneData.heat?.round;
        if(round && round.status === 5) {
        let spi: SwimmerProgramItem = {
          programItemIndex: parseInt(programItemIndex),
          sid: session.sid,
          competitorType: "competitor",
        };
        const result = getResult(swimmer, laneData, programItem);
        if(result) {
            spi.result = result;
            if (swimmerProgramItem) {
                swimmerProgramItem.result = result;
            } else {
                swimmerProgramItem = spi as SwimmerProgramItem;
            }
        }
      }
    }    

    // finally, add the swimmerProgramItem to the swimmerProgramItems
    if (swimmerProgramItem && Object.keys(swimmerProgramItem).length > 0) {
      swimmerProgramItems[programItemIndex] = swimmerProgramItem;
    }
  }
  return swimmerProgramItems;
}

function getEntry(swimmer: Swimmer, entryData: any, programItem: ProgramItem): EntryListItem | undefined {
  let rankings: RankingsObject = {};
  entryData?.entry_ranks?.map((entry_rank: any) => {
    rankings[entry_rank.entry_list_type.name] = entry_rank.rank;
  });

  const entry: EntryListItem = {
    id: swimmer.id,
    name: swimmer.name,
    lastName: swimmer.lastName,
    firstNames: swimmer.firstNames,
    event: programItem.event,
    club: swimmer.club ? swimmer.club : "",
    year: swimmer.year,
    listId: getListIds(programItem).entryListId,
    listType: "entryList",
    listItemType: "entry",
    programItemIndex: programItem.programItemIndex,
    sid: programItem.sid,
    rankings: rankings,
    ranking: Object.keys(rankings).length === 1 ? rankings[Object.keys(rankings)[0]] : undefined,
    entryTime: entryData.entry_time_text,
    athletes: getAthletes(entryData.relay_competitors),
  };
  return entry;
}

function getAthletes(relay_competitors: any): Athlete[] | undefined {
  if (relay_competitors === undefined) return undefined;
  let athleteArray: Athlete[] = [];
  for (let i = 0; i < relay_competitors.length; i++) {
    let c = relay_competitors[i].competitor;
    if(c && c !== null) {
    let athlete: Athlete = {
      id: c.full_name + " " + c.birthday.substring(0, 4),
      name: c.full_name,
      lastName: c.last_name,
      firstNames: c.first_name,
      year: c.birthday.substring(0, 4),
    };
    athleteArray.push(athlete);
    }
  }
  return athleteArray;
}

function getStart(swimmer: Swimmer, startData: any, programItem: ProgramItem): StartListItem | undefined {
  const start: StartListItem = {
    heat: startData.heat.number,
    id: swimmer.id,
    name: swimmer.name,
    lastName: swimmer.lastName,
    firstNames: swimmer.firstNames,
    event: programItem.event,
    club: swimmer.club ? swimmer.club : "",
    year: swimmer.year,
    listId: getListIds(programItem).startListId,
    listType: "startList",
    listItemType: "start",
    programItemIndex: programItem.programItemIndex,
    sid: programItem.sid,
    track: startData.number,
    reactionTime: startData.reaction_time,
    resultTime: (startData.heat.status > 1 ? startData.result_text : undefined),
    heatTimeText: moment(startData.heat.estimated_start_time).format("H.mm"),
    heatName: startData.heat.name,
    startlistindex: 1000 * startData.heat.number + startData.number,
    heat_rank: (startData.heat.status > 1 ? startData.heat_rank : undefined),
    heatId: startData.heat.id,
    swimmed_distance: startData.swimmed_distance,
    splits: convertSplits(startData.sub_results),
    fina_points: startData.fina_points,
    wps_points: startData.wps_points,
    dns: startData.dns,
    dsq: startData.dsq,
    dnf: startData.dnf,
    dq_code: startData.dq_code,
    dq_description: startData.dq_description,
    qualification: startData.qualification, 
    isLive: false,
    heatStatus: startData.heat.status,
    additionalInfo:
    "" +
    (startData.additionalInfo ? startData.additionalInfo : "")
  };
  return start;
}

function convertSplits(sub_results: any): Splits {
  let splits: Splits = {};
  for (let i = 0; i < sub_results.length; i++) {
    splits[sub_results[i].done_at + "m"] = sub_results[i].result_value_text;
  }
  return splits;
}

function getResult(swimmer: Swimmer, laneData : any, programItem: ProgramItem): ResultListItem | undefined {
    let rankings: RankingsObject = {};
    laneData?.ranks?.map((rank: any) => {
        if(rank.summary_type && rank.summary_type.name) {
           let rankString = rank.rank === 0 ? "-" : rank.rank;
            rankings[rank.summary_type.name] = rankString;
        }
    });
    // make sure that the parameter "Total" is the last one in object rankings
    let total = rankings["Total"];
    delete rankings["Total"];
    if(total) {
        rankings["Total"] = total;
    }

  const result: ResultListItem = {
    id: swimmer.id,
    name: swimmer.name,
    lastName: swimmer.lastName,
    firstNames: swimmer.firstNames,
    event: programItem.event,
    club: swimmer.club ? swimmer.club : "",
    year: swimmer.year,
    listId: getListIds(programItem).resultListId,
    listType: "resultList",
    listItemType: "result",
    programItemIndex: programItem.programItemIndex,
    sid: programItem.sid,
    time: laneData.result_text,
    rankings: rankings,
    position: Object.keys(rankings).length === 1 ? rankings[Object.keys(rankings)[0]] : "",
    series: Object.keys(rankings).length === 1 ? Object.keys(rankings)[0] : undefined,
    splits: convertSplits(laneData.sub_results),
    FINA: laneData.fina_points,
    wps_points: laneData.wps_points,
    dns: laneData.dns,
    dsq: laneData.dsq,
    dnf: laneData.dnf,
    dq_code: laneData.dq_code,
    dq_description: laneData.dq_description,
    qualification: laneData.qualification, 
    additionalInfo: laneData.additionalInfo,
  };
  if (result.position === "0") {
    result.position = "-";
  }
  if(laneData.heat?.status && laneData.heat?.status === 3) {
    return result;
  }  
}
