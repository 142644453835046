import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Pagination from "@mui/material/Pagination";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getEnvPrefix } from "./Helpers";
import UinnitAppBar from "./UinnitAppBar";
import UinnitAppTabs from "./UinnitAppTabs";
import { gql, useApolloClient } from "@apollo/client";

const COMPETITIONS_QUERY = gql`
query Competitions($startDate: date!, $endDate: date!) {
  competitions(
    limit: 100
    order_by: {startDate: desc}
      where: {
          nation_code: { _eq: "FIN" }
          startDate: { _gte: $startDate, _lt: $endDate }
      }
  ) {
      city
      created_at
      endDate
      id
      name
      nation_code
      organizer
      pool_name
      pool_type
      seo_text
      startDate
      superlive
      updated_at
  }
}
`;

const CompetitionSelectorPast = () => {

  const [pastLiveTimingCompetitions, setPastLiveTimingCompetitions] = useState<Array<CompetitionData>>([]);
  const [pastLoadingInProgress, setPastLoadingInProgress] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pastPage, setPastPage] = useState<number>(1);
  const navigate = useNavigate();

  const client = useApolloClient();
  const [pastCompetitionsData, setPastCompetitionsData] = useState<any[]>([]);

  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const currentMonth = new Date().getMonth() + 1; // get current month (1-12)
        const queries = [];

        // TODO TÄÄ HAJOAA KUN VUOSI 2024 PÄÄTTYY

        for (let month = 1; month <= currentMonth; month++) {
          const startDate = new Date(2024, month - 1, 1).toISOString(); // first day of the month
          const endDate = new Date(2024, month, 0).toISOString(); // last day of the month

          queries.push(client.query({ query: COMPETITIONS_QUERY,
            variables: { startDate, endDate },
            fetchPolicy: "network-only",
          }));
        }

        const results = await Promise.all(queries);
        const combinedData = results.map(result => result.data);
        const flattenedData = combinedData.flatMap(data => data.competitions);
        //setData(combinedData);
        let converted = convertCompetitions(flattenedData);

        // remove competitions that are in the future
        let now = new Date().toISOString();
        converted = converted && converted.filter((competition : CompetitionData) => competition.endDate <= now);

        if(converted) {
          setPastCompetitionsData(converted);
          setPastLoadingInProgress(false);  
        }

      } catch (error) {
        console.error('Error fetching past competitions:', error);
      }
    };

    fetchQueries();
  }, [client]);

  /*
  const { data: pastCompetitionsData, loading, error } = useQuery(COMPETITIONS_QUERY, {
    pollInterval: 1 * 60 * 1000, // every 1 minute
    //fetchPolicy: "network-only",
    variables: { startDate: startDateString, endDate: endDateString },
  }); */

  //let swimifyPastCompetitions = convertCompetitions(pastCompetitionsData?.competitions);

  let swimifyPastCompetitions = pastCompetitionsData;

  useEffect(() => {
    if (pastLiveTimingCompetitions.length === 0) {
      return getPastLiveTimingCompetitions();
    }
  }, []);

  function getPastLiveTimingCompetitions() {
    setPastLoadingInProgress(true);
    var lastDay = "2024-01-01";
    const competitionsListDocRef = doc(getFirestore(), getEnvPrefix() + "LT-competitionsList", "competitionsListDoc");
    const unsub = onSnapshot(competitionsListDocRef, (doc) => {
      const dd = doc.data();
      if (dd) {
        console.debug("Past competitions list updated from firestore (" + dd.competitions.length + ")");
        const competitions = dd.competitions
          .filter((competition: CompetitionData) => (competition.showCompetition = true))
          .filter((competition: CompetitionData) => competition.endDate < lastDay);
        //add " (LT)" to the end of all competitionNames
        //competitions.forEach((competition: CompetitionData) => {
        //  competition.competitionName = competition.competitionName + "";
        //});
        setPastLiveTimingCompetitions(competitions);
        setPastLoadingInProgress(false);
      }
    });
    return unsub;
  }

  const searchTermChanged = (event: any) => {
    setPastPage(1);
    setSearchTerm(event.target.value);
  };

  const clearSearch = (event: any) => {
    console.debug("Clear search clicked");
    setSearchTerm("");
  };

  function filterCompetitions(competition: CompetitionData, index: number, competitions: any) {
    if (searchTerm === undefined || searchTerm === null || searchTerm === "" || searchTerm.trim() === "") {
      return true;
    } else {
      return (
        competition.competitionName.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0 ||
        (competition.competitionLocation && competition.competitionLocation.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0)
      );
    }
  }

  let allPastCompetitions : CompetitionData[] = [];
  if(swimifyPastCompetitions) {
    let sortedSwimifyPastCompetitions = swimifyPastCompetitions.sort((a, b) => {
      if(a.endDate === undefined || b.endDate === undefined) return 0;
      if(a.endDate < b.endDate) {
        return 1;
      } else {
        return -1;
      }
    });
    allPastCompetitions = sortedSwimifyPastCompetitions;
  }
  if(pastLiveTimingCompetitions) {
    allPastCompetitions = allPastCompetitions.concat(pastLiveTimingCompetitions);
  }
  const filteredPastCompetitions = allPastCompetitions.filter(filterCompetitions);

  const PER_PAGE = 50;
  const pastStart = pastPage * PER_PAGE - PER_PAGE;
  const pastEnd = pastStart + PER_PAGE - 1;
  let filteredPagedPastCompetitions = filteredPastCompetitions.slice(pastStart, pastEnd);

  const handlePastPageChange = (e: any, p: number) => {
    setPastPage(p);
  };

  return (
    <>
    {filteredPastCompetitions !== undefined && (
      <div>
        <div style={{ height: "4px", width: "100%", clear: "both", marginBottom: 0 }}>{pastLoadingInProgress && <LinearProgress />}</div>

        <div style={{ margin: "12px" }}>
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel htmlFor="outlined-searchterm">Etsi hakusanalla</InputLabel>
            <OutlinedInput
              id="outlined-searchterm"
              type={"text"}
              fullWidth
              value={searchTerm}
              onChange={searchTermChanged}
              label={"Etsi hakusanalla"}
              endAdornment={
                <InputAdornment position="end">
                  {searchTerm !== undefined && searchTerm.length > 0 ? (
                    <IconButton onClick={clearSearch}>
                      <CancelIcon />
                    </IconButton>
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

        <List dense sx={{marginTop: 0, paddingTop: 0}}>
          {filteredPagedPastCompetitions.map((competition: any) => (
            <ListItem button key={"competition_" + competition.cid} onClick={() => navigate("/competition/" + (competition.navigationCID ? competition.navigationCID : competition.cid) + "/session/")}>
              <ListItemText
                primary={competition.competitionName}
                secondary={
                  (competition.startDate === competition.endDate ? competition.startDate : competition.startDate + " - " + competition.endDate) +
                  " " +
                  competition.competitionLocation
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" component={Link} to={"/competition/" + (competition.navigationCID ? competition.navigationCID : competition.cid) + "/session/"}>
                  <ArrowForwardIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <div>
          <Pagination onChange={handlePastPageChange} page={pastPage} count={Math.ceil(filteredPastCompetitions.length / PER_PAGE)} color="primary" />
        </div>
      </div>
    )}
    </>
  );

}



interface CompetitionSelectorProps {
  futureCompetitions: CompetitionData[];
}

function convertCompetitions(competitions : any) {
  if(competitions === undefined) return;
  let convertedCompetitions : CompetitionData[] = [];
  competitions.forEach((competition : any) => {
    convertedCompetitions.push({
      cid: competition.id,
      navigationCID: competition.seo_text,
      competitionName: competition.name,
      startDate: competition.startDate,
      endDate: competition.endDate,
      competitionDates: competition.competitionDates,
      competitionLocation: competition.city,
      organizer: competition.organizer,
      showCompetition: true,
      pool: competition.pool,
    });
  });
  return convertedCompetitions;
}

const CompetitionSelector = (props: CompetitionSelectorProps) => {
  const [tab, setTab] = useState("future");
  const [futurePage, setFuturePage] = useState<number>(1);
  const theme = useTheme();
  const navigate = useNavigate();


  const tabs = [
    { key: "future", label: "Tulevat" },
    { key: "past", label: "Päättyneet" },
  ];

  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
    window.scrollTo(0, 0);
  };



  const PER_PAGE = 50;
  const futureStart = futurePage * PER_PAGE - PER_PAGE;
  const futureEnd = futureStart + PER_PAGE - 1;
  let pagedFutureCompetitions = props.futureCompetitions ? props.futureCompetitions.slice(futureStart, futureEnd) : undefined;



  const handleFuturePageChange = (e: any, p: number) => {
    setFuturePage(p);
  };

  let tabsElement = 
  <UinnitAppTabs
  value={tab}
  variant="fullWidth"
  onChange={handleChange}
>
  {tabs.map((tab) => (
    <Tab key={tab.key} value={tab.key} label={tab.label} />
  ))}
</UinnitAppTabs>;

  return (
    <div>
      <UinnitAppBar navigationCID={undefined} currentHeats={undefined} includeBackButton={true} title="Valitse kilpailu" tabs={tabsElement} denseTabs={true}/>

      <div style={{ backgroundColor: theme.palette.background.default, height: "auto", minHeight: "100vh" }}>
        <div>
          {tab === "future" && pagedFutureCompetitions && (
            <div>
              <List dense>
                {pagedFutureCompetitions && pagedFutureCompetitions.map((competition: any) => (
                  <ListItem button key={"futurecompetition_" + competition.cid} onClick={() => navigate("/competition/" + (competition.navigationCID ? competition.navigationCID : competition.cid) + "/session/")}>
                    <ListItemText
                      primary={competition.competitionName}
                      secondary={
                        (competition.startDate === competition.endDate ? competition.startDate : competition.startDate + " - " + competition.endDate) +
                        " " +
                        competition.competitionLocation
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" component={Link} to={"/competition/" + (competition.navigationCID ? competition.navigationCID : competition.cid) + "/session/"}>
                        <ArrowForwardIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {props.futureCompetitions && <Pagination onChange={handleFuturePageChange} page={futurePage} count={Math.ceil(props.futureCompetitions.length / PER_PAGE)} color="primary" />}
            </div>
          )}
          {tab === "past" && <CompetitionSelectorPast 

          />} 
        </div>
      </div>
    </div>
  );
};

export default CompetitionSelector;
