import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Card, CardActionArea, CardContent, Divider, IconButton } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import SwimmerProgramItemContent from "./SwimmerProgramItemContent";
import { ProgramItemAvatar } from "../ProgramItemAvatar";

interface SwimmerProgramItemProps {
  cid: string;
  competitionData: CompetitionData | undefined;
  swimmerProgramItem: SwimmerProgramItem;
  sid: string;
  swimmer: Swimmer;
  currentHeats: CurrentHeat[];
  dense: boolean;
  detailed: boolean;
  favoriteSwimmers: FavoriteSwimmers | undefined;
}

const SwimmerProgramItem = (props: SwimmerProgramItemProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const competitionData = props.competitionData;
  const swimmerProgramItem = props.swimmerProgramItem;
  const sid = props.sid;
  const swimmer = props.swimmer;
  const programItemIndex = props.swimmerProgramItem.programItemIndex;

  if (competitionData === undefined) return <></>;
  const session = competitionData.sessions && competitionData.sessions[sid];
  if (session === undefined) return <></>;
  const programItem = session.programItems && session.programItems[swimmerProgramItem.programItemIndex];
  if (programItem === undefined) return <></>;

  let isLive = false;
  for (let i = 0; i < props.currentHeats.length; i++) {
    if (props.currentHeats[i].programItemIndex === programItemIndex) {
      if (props.currentHeats[i].sid === sid) {
        isLive = true;
      }
    }
  }

  function openSwimmerEvent(swimmerid : string, event: string) {
    navigate("../swimmer/" + swimmerid + "/event/" + event);
  }

  function openEvent(event : string) {
    navigate("../event/" + event);
  }

  function itemClicked(swimmerid : string, event : string) {
    if(props.detailed) {
      return openEvent(event);
    } else {
      return openSwimmerEvent(swimmerid, event);
    }
  }

  return (
    <div key={"pi" + programItemIndex}>
      <Card elevation={0} key={sid + "" + programItemIndex} square>
        <CardActionArea
          onClick={() => itemClicked(swimmer.id, programItem.event)}
          >
          <CardHeader
            sx={{
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            }}
            avatar={<ProgramItemAvatar programItem={programItem} isLive={isLive} />}
            title={
              <>
                <Typography variant="subtitle2">{programItem.programItemName}</Typography>
                <div>
                  {programItem.startTimeText && programItem.startTimeText.length > 0 && (
                    <Typography variant="body2">
                      <span style={{ color: theme.palette.text.secondary }}>klo</span> {programItem.startTimeText}
                    </Typography>
                  )}
                </div>
              </>
            }
            action={
            <>
            <IconButton tabIndex={-1} component="span">
            <ArrowRightIcon sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
            </>}
          />

          <CardContent>
            <>
              <div style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginBottom: theme.spacing(1) }}>
                <SwimmerProgramItemContent
                  cid={props.cid}
                  competitionData={props.competitionData}
                  swimmerProgramItem={swimmerProgramItem}
                  sid={sid}
                  swimmer={swimmer}
                  includeName={false}
                  showSplits={props.detailed ? true : false}
                  showAthletes={props.detailed ? true : false}
                  dense={props.dense}
                  favoriteSwimmers={props.favoriteSwimmers}
                />
              </div>
            </>
          </CardContent>
        </CardActionArea>
      </Card>
      <Divider />
    </div>
  );
};

export default SwimmerProgramItem;
