import Tab from "@mui/material/Tab";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UinnitAppBar from "../../UinnitAppBar";
import UinnitAppTabs from "../../UinnitAppTabs";
import { SwimmersClubs } from "./SwimmersClubs";
import { SwimmersAll } from "./SwimmersAll";
import { SwimmersFavorites } from "./SwimmersFavorites";

interface CompetitionSwimmersProps {
  userSettings: UserSettings | undefined;
  setAccountDialog: (accountDialogOpen: boolean) => void;
  swimmers: Swimmers | undefined;
  clubs: Clubs | undefined;
  clubPairs: ClubNamePairs | undefined;
  competitionData: CompetitionData | undefined;
  cid: string;
  currentHeats: CurrentHeat[];
  navigationCID: string;
}

const CompetitionSwimmers = (props: CompetitionSwimmersProps) => {
  const navigate = useNavigate();


  const tabs = [
    { key: ".", label: "Omat" },
    { key: "all", label: "Kaikki" },
    { key: "clubs", label: "Seuroittain" },
  ];

  const handleChange = (event: any, newValue: any) => {
    navigate(newValue);
    window.scrollTo(0, 0);
  };

  const location = useLocation();
  const currentPath = location.pathname;
  let tab = undefined;
  if (currentPath.endsWith("/all")) {
    tab = "all";
  } else if (currentPath.endsWith("/clubs")) {
    tab = "clubs";
  } else {
    tab = ".";
  }

  return (
    <div>
      <UinnitAppBar
        title={props.competitionData ? props.competitionData.competitionName : ""}
        tabs={
          <UinnitAppTabs value={tab} variant="fullWidth" onChange={handleChange}>
            {tabs.map((tab) => (
              <Tab key={tab.key} value={tab.key} label={tab.label} />
            ))}
          </UinnitAppTabs>
        }
        denseTabs={true}
        currentHeats={props.currentHeats} 
        navigationCID={props.navigationCID}
      ></UinnitAppBar>

      <div>
        <Routes>
          <Route path="all" element={<SwimmersAll cid={props.cid} swimmers={props.swimmers} userSettings={props.userSettings} setAccountDialog={props.setAccountDialog} />} />

          <Route
            path="clubs"
            element={
              <SwimmersClubs
                clubs={props.clubs}
                cid={props.cid}
                userSettings={props.userSettings}
                clubNamePairs={props.clubPairs}
                setAccountDialog={props.setAccountDialog}
              />
            }
          />

          <Route path="/" element={<SwimmersFavorites cid={props.cid} swimmers={props.swimmers} userSettings={props.userSettings} setAccountDialog={props.setAccountDialog} />} />
        </Routes>
      </div>
    </div>
  );
};

export default CompetitionSwimmers;




