import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate, useParams } from "react-router-dom";
import UinnitAppBar from "../../../UinnitAppBar";
import { EventInfo } from "../../Event/EventInfo";
import { FlagIcon } from "../../../Helpers";
import { Accordion, AccordionDetails, AccordionSummary, Divider, ListItemButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SwimmerProgramItem from "../SwimmerProgramItem";
import { FavoriteSwimmerControl } from "../../../User/FavoriteHelpers";
import { enqueueSnackbar } from "notistack";
import { getSwimifySwimmer } from "../../GetSwimmerData";
import { SessionCard } from "../../SessionCard";
import CompetitionSwimmerEventHistory from "./SwimmerEventHistory";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function getFirstProgramItem(competitionData: CompetitionData, event: any): ProgramItem | undefined {
  if (competitionData.sessions === undefined) {
    return undefined;
  }
  try {
    for (var i = 0; i < Object.keys(competitionData.sessions).length; i++) {
      var session = competitionData.sessions[Object.keys(competitionData.sessions)[i]];
      for (var j = 0; session.programItems !== undefined && j < Object.keys(session.programItems).length; j++) {
        var programItem = session.programItems[Object.keys(session.programItems)[j] as any];
        if (programItem.event === event) {
          return programItem;
        }
      }
    }
    console.warn("Event " + event + " not found in sessions and programs.");
    return undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

function getEventName(competitionData: CompetitionData | undefined, event: string): string {
  if (competitionData === undefined) {
    return "";
  }
  const firstProgramItem = getFirstProgramItem(competitionData, event);
  if (firstProgramItem !== undefined) {
    return firstProgramItem.eventName;
  } else {
    return "";
  }
}

function getSwimStyle(competitionData: CompetitionData, event: string): any {
  const firstProgramItem = getFirstProgramItem(competitionData, event);
  if (firstProgramItem !== undefined) {
    return firstProgramItem.swimStyle;
  } else {
    return undefined;
  }
}

interface CompetitionSwimmerEventProps {
  cid: string;
  loading: boolean;
  setAccountDialog: any;
  competitionData: CompetitionData | undefined;
  swimmers: Swimmers | undefined;
  userSettings: UserSettings | undefined;
  currentHeats: CurrentHeat[];
  navigationCID: string;
  liveHeatData: any;
}

const CompetitionSwimmerEvent = (props: CompetitionSwimmerEventProps) => {
  const { swimmerid } = useParams();
  const { event } = useParams();
  const id = decodeURIComponent(swimmerid === undefined ? "" : swimmerid);
  let navigate = useNavigate();
  const theme = useTheme();
  const competitionData = props.competitionData;

  const skipSwimmer = props.cid.length === 4 || id === undefined || id === null || competitionData === undefined;
  const {
    swimifySwimmer: swimifySwimmer,
    swimifySwimmerLoading: swimifySwimmerLoading,
    swimifySwimmerError: swimifySwimmerError,
  } = getSwimifySwimmer(props.liveHeatData, props.cid, id ? id : "neverhappens", competitionData, skipSwimmer, event);

  if (id === undefined || event === undefined) {
    return null;
  }
  const swimmer =
    props.cid.length === 4 ? (props.swimmers !== undefined ? props.swimmers[id] : undefined) : swimifySwimmer !== undefined ? swimifySwimmer : undefined;
  const swimStyle = competitionData !== undefined ? getSwimStyle(competitionData, event) : undefined;

  function swimmerSessionHasEvents(swimmerSession: SwimmerSession, event: string) {
    if (swimmerSession.swimmerProgramItems === undefined) {
      return false;
    }
    for (let swimmerProgramItem of Object.values(swimmerSession.swimmerProgramItems)) {
      const sessions = competitionData?.sessions;
      const session = sessions && sessions[swimmerProgramItem.sid];
      const programItem = session && session.programItems && session.programItems[swimmerProgramItem.programItemIndex];
      if (programItem?.event === event) {
        return true;
      }
    }
    return false;
  }

  const swimmerEvents =
    swimmer &&
    swimmer.swimmerSessions &&
    competitionData &&
    Object.entries(swimmer.swimmerSessions)
      .filter(([sid, swimmerSession]) => swimmerSessionHasEvents(swimmerSession, event))
      .slice(0)
      .reverse()
      .map(([sid, swimmerSession], index) => {
        return (
          <div key={"swimmerSession" + sid}>
            {swimmerSession.swimmerProgramItems !== undefined &&
              Object.entries(swimmerSession.swimmerProgramItems)
                .filter(([programItemIndex, swimmerProgramItem]) => {
                  const sessions = props.competitionData?.sessions;
                  const session = sessions && sessions[swimmerProgramItem.sid];
                  const programItem = session && session.programItems && session.programItems[swimmerProgramItem.programItemIndex];
                  return programItem?.event === event;
                })
                .map(([programItemIndex, swimmerProgramItem]) => {
                  const sessions = props.competitionData?.sessions;
                  const session = sessions && sessions[swimmerProgramItem.sid];
                  const programItem = session && session.programItems && session.programItems[swimmerProgramItem.programItemIndex];

                  if (!programItem) return null;

                  return (
                    <div key={"swimmerevent_" + swimmer.id + "_" + sid + "_" + programItemIndex}>
                      <Divider sx={{ borderBottomWidth: "0.5rem" }} />
                      <SessionCard session={session} />
                      <Divider />
                      <SwimmerProgramItem
                        cid={props.cid}
                        competitionData={props.competitionData}
                        swimmerProgramItem={swimmerProgramItem}
                        sid={sid}
                        swimmer={swimmer}
                        currentHeats={props.currentHeats}
                        dense={false}
                        detailed={true}
                        favoriteSwimmers={props.userSettings?.favoriteSwimmers}
                      />
                    </div>
                  );
                })}
          </div>
        );
      });

  return (
    <>
      <UinnitAppBar navigationCID={props.navigationCID} currentHeats={props.currentHeats} prominent={false} includeBackButton={true} title={"Uimarin laji"} />

      <div style={{ height: "4px", width: "100%", clear: "both", marginTop: 0, marginBottom: 0 }}>
        {props.loading || swimifySwimmerLoading || ((swimmer === undefined || props.competitionData === undefined) && <LinearProgress />)}
      </div>

      {swimmer && (
        <List disablePadding>
          <ListItem
            disablePadding
            disableGutters
            secondaryAction={
              <>
                {props.userSettings && (
                  <FavoriteSwimmerControl
                    swimmer={swimmer}
                    setAccountDialog={props.setAccountDialog}
                    favoriteSwimmers={props.userSettings.favoriteSwimmers}
                    anonGuestModeStarted={props.userSettings.anonGuestModeStarted}
                    enqueueSnackbar={enqueueSnackbar}
                  />
                )}
                <IconButton
                  sx={{ marginRight: theme.spacing(1) }}
                  color="inherit"
                  component={Link}
                  to={"../swimmer/" + encodeURIComponent(swimmer.id)}
                  tabIndex={-1}
                >
                  <ArrowRightIcon sx={{ color: theme.palette.text.secondary }} />
                </IconButton>
              </>
            }
          >
            <ListItemButton component={Link} to={"../swimmer/" + encodeURIComponent(swimmer.id)}>
              <ListItemText
                primary={<span>{id}</span>}
                secondary={
                  <span>
                    {<FlagIcon nationality={swimmer.nationality} />}
                    {swimmer.club ? swimmer.club : <span>&nbsp;</span>}
                  </span>
                }
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            disableGutters
            secondaryAction={
              <>
                <IconButton sx={{ marginRight: theme.spacing(1) }} color="inherit" component={Link} to={"../event/" + event} tabIndex={-1}>
                  <ArrowRightIcon sx={{ color: theme.palette.text.secondary }} />
                </IconButton>
              </>
            }
          >
            <ListItemButton component={Link} to={"../event/" + event}>
              <ListItemText primary={props.competitionData !== undefined && getEventName(props.competitionData, event)} secondary={"Laji " + event} />
            </ListItemButton>
          </ListItem>
        </List>
      )}

      {swimmer && <EventInfo swimStyle={swimStyle} defaultExpanded={false} pool={props.competitionData?.pool} year={swimmer.year} />}

      {swimmerEvents}

      <Divider sx={{ borderBottomWidth: "0.5rem" }} />

      <Accordion
        slotProps={{ transition: { unmountOnExit: true } }}
        disableGutters
        elevation={0}
        square
        defaultExpanded={false}
        sx={{
          "&:before": {
            display: "none",
          },
          margin: theme.spacing(1),
          "& .MuiAccordionSummary-root": {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(0.3),
          },
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        }}
      >
        <AccordionSummary
          sx={{
            marginLeft: 0,
            marginRight: 0, //THIS marginLeft marginRight (zeros) SEEM IMPORTANT for proper scrolling
            borderBottom: "2px solid " + (theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main),
            "& .MuiAccordionSummary-content": {
              margin: 0,
            },
          }}
          expandIcon={
            <IconButton tabIndex={-1} size="small">
              <ExpandMoreIcon
                sx={{
                  color: theme.palette.text.secondary,
                }}
              />
            </IconButton>
          }
        >
          <Typography variant="h6">Uimarin tulosvertailu</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            boxShadow: "none",
            "& .MuiCard-root": {
              border: 0,
            },
          }}
        >
          {swimmer !== undefined && props.competitionData !== undefined && <CompetitionSwimmerEventHistory 
          cid={props.cid} 
          competitionData={props.competitionData}
          navigationCID={props.navigationCID} 
          currentHeats={props.currentHeats}
          swimmer={swimmer}
          event={event}
          />}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CompetitionSwimmerEvent;
