import { isFavoriteSwimmer } from "../../User/FavoriteHelpers";
import { isFavoriteClub, getTimeDiff } from "../../Helpers";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { FlagIcon } from "../../Helpers";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-router-dom";

export interface ResultProps {
  result: ResultListItem;
  userSettings: UserSettings | undefined;
  event: string;
  programItem: ProgramItem;
}

export function Result(props: ResultProps) {
  const { result, userSettings } = props;
  const theme = useTheme();
  const entryTime = result.entryTime; // || (entry && entry.entryTime);
  const timeDiff = entryTime && getTimeDiff(result.time, entryTime);

  const goldPositions = ["1", "=1", "1."];
  const silverPositions = ["2", "=2", "2."];
  const bronzePositions = ["3", "=3", "3."];
  let color: any;
  const getPositionAvatar = () => {
    let backgroundColor = "";
    let fontSize = "1.2rem";
    if (goldPositions.includes(result.position)) {
      backgroundColor = "#d4af37";
    } else if (silverPositions.includes(result.position)) {
      backgroundColor = "#808080";
    } else if (bronzePositions.includes(result.position)) {
      backgroundColor = "#967444";
    } else {
      backgroundColor = "transparent";
      color = theme.palette.primary.main;
    }
    return (
      <Avatar
        sx={{
          bgcolor: backgroundColor,
          maxHeight: "2.25rem",
          maxWidth: "2.25rem",
          fontSize: fontSize,
        }}
      >
        {color !== undefined ? <span style={{ color: color }}>{result.position}</span> : result.position}
      </Avatar>
    );
  };

  const renderPrimaryText = () => {
    const isFavoriteSwimmerResult = userSettings && isFavoriteSwimmer(result, userSettings.favoriteSwimmers);

    return <span>{isFavoriteSwimmerResult ? <span style={{ color: theme.palette.secondary.main }}>{result.id}</span> : <span>{result.id}</span>}</span>;
  };

  const renderSecondaryText = () => {
    const nationality = result.nationality;
    const isFavoriteClubResult = userSettings && userSettings.favoriteClubs && isFavoriteClub(result.club, userSettings.favoriteClubs);

    return (
      <span>
        <FlagIcon nationality={nationality} />
        {isFavoriteClubResult ? <span style={{ color: theme.palette.secondary.main }}>{result.club}</span> : <span>{result.club}</span>}
      </span>
    );
  };

  const renderTimeText = () => {
    const isTimeDiffPositive = timeDiff && timeDiff.length > 0 && timeDiff.charAt(0) === "+";
    const isQualificationValid = result.qualification && result.qualification.length > 0 && result.qualification !== "-";

    return (
      <span>
        <Typography align="right" sx={{ fontSize: "0.8rem" }}>
          {result.time && result.time.length > 0 ? "" + result.time : ""}
          {result.additionalInfo && result.additionalInfo.length > 0 ? " " + result.additionalInfo : ""}
        </Typography>
        <Typography align="right" sx={{ fontSize: "0.8rem" }}>
          {isTimeDiffPositive ? (
            <span style={{ fontSize: "0.8rem", color: theme.palette.error.main }}> {timeDiff}</span>
          ) : (
            <span style={{ fontSize: "0.8rem", color: theme.palette.success.main }}> {timeDiff}</span>
          )}
          {isQualificationValid && ` ${result.qualification}`}
        </Typography>
      </span>
    );
  };

  return (
    <ListItem disablePadding disableGutters dense 
      secondaryAction={
        <>
          <IconButton tabIndex={-1} size="medium" component={Link} to={"../../swimmer/" + result.id + "/event/" + props.event}>
          <ArrowRightIcon />
        </IconButton>
        </>
      }
    >
      <ListItemButton
        component={Link}
        to={"../../swimmer/" + result.id + "/event/" + props.event}
        sx={{
          paddingLeft: theme.spacing(1),
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: theme.spacing(1.5),
        }}
      >
        <ListItemAvatar sx={{ 
          minWidth: "2.75rem",
         }}>
            {getPositionAvatar()}
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ style: { fontSize: "0.9rem" } }}
          secondaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
          primary={renderPrimaryText()}
          secondary={renderSecondaryText()}
        />
        <span>{renderTimeText()}</span>
      </ListItemButton>
    </ListItem>
  );
}
