import { useState } from "react";
import { logInWithEmailAndPassword } from "../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { sendPasswordReset } from "../Firebase";
import { EmailAuthProvider, linkWithCredential } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getEnvPrefix } from "../Helpers";
import CardActions from "@mui/material/CardActions";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

function LoginRegisterForm(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({});
  const [user] = useAuthState(getAuth());
  const [loading, setLoading] = useState(false);
  const [phase, setPhase] = useState("SIGNIN");
  const auth = getAuth();
  const enqueueSnackbar= props.enqueueSnackbar;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const login = async (e: any) => {
    console.debug("Logging in...");
    e.preventDefault();
    setErrors({});
    setLoading(true);
    const error = await logInWithEmailAndPassword(email, password);
    if (error) {
      if (error.code === "auth/invalid-email" || error.code === "auth/user-not-found" || error.code === "auth/email-already-in-use") {
        setErrors((prevState) => ({ ...prevState, email: error.code }));
      } else if (error.code === "auth/wrong-password" || error.code === "auth/internal-error") {
        setErrors((prevState) => ({ ...prevState, password: error.code }));
      } else {
        console.error(error);
        enqueueSnackbar(error);
      }
    } else {
      enqueueSnackbar("Kirjautuminen onnistui.");
      props.setAccountDialog(false);
    }
    setLoading(false);
  };

  function getTranslation(errorCode: string | undefined) {
    if (errorCode === undefined) return "";
    const errorCodeTranslations: { [key: string]: string } = {
      "auth/wrong-password": "Salasana on väärin",
      "auth/invalid-email": "Virheellinen sähköpostiosoite",
      "auth/email-already-in-use": "Tällä osoitteella on jo käyttäjätunnus",
      "auth/user-not-found": "Käyttäjätiliä ei löydy.",
      "auth/internal-error": "Kirjautuminen ei onnistunut",
      "auth/weak-password": "Salasanan tulisi olla ainakin 6 merkkiä pitkä"
    };
    if (errorCodeTranslations[errorCode] !== undefined) {
      return errorCodeTranslations[errorCode];
    } else {
      return errorCode;
    }
  }

  const registerEmail = async (e: any) => {
    e.preventDefault();
    setErrors({});
    const credential = EmailAuthProvider.credential(email, password);
    console.log("Luo uutta käyttäjää username ", email, " password ", password);
    if (auth && auth.currentUser && user) {
      setLoading(true);
      await linkWithCredential(auth.currentUser, credential)
        //await createUserWithEmailAndPassword(auth, email, password)
        .then((usercred) => {
          const user = usercred.user;
          console.log("Account linking success", user);
        })
        .then(() => {
          const userId = user.uid;
          const userDoc = doc(getFirestore(), getEnvPrefix() + "users", userId);
          setDoc(userDoc, { email: email }, { merge: true });
          setLoading(false);
          enqueueSnackbar("Käyttäjätilin luonti onnistui");
          props.setAccountDialog(false);
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use" || error.code === "auth/invalid-email") {
            setErrors((prevState) => ({ ...prevState, email: error.code }));
          }
          if (error.code === "auth/weak-password") {
            setErrors((prevState) => ({ ...prevState, password: error.code }));
          }
          console.debug(error);
        });
    }
    setLoading(false);
  };

  const signUpPhaseClicked = async (e: any) => {
    e.preventDefault();
    setErrors({});
    setPassword("");
    setPhase("SIGNUP");
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setErrors((prevState) => ({ ...prevState, email: undefined }));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setErrors((prevState) => ({ ...prevState, password: undefined }));
  };

  const resetEmailPhaseClicked = async (e: any) => {
    e.preventDefault();
    setErrors({});
    setPassword("");
    setPhase("RESETEMAIL");
  };

  const backToSignInPhaseClicked = async (e: any) => {
    e.preventDefault();
    setErrors({});
    setPassword("");
    setPhase("SIGNIN");
  };

  const resetEmailClicked = async (e: any) => {
    console.debug("Sending password reset email.");
    setLoading(true);
    await sendPasswordReset(email)
      .then(() => {
        enqueueSnackbar("Viesti lähetetty. Tarkista sähköpostisi.", {
          autoHideDuration: 5000,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.debug(error);
        enqueueSnackbar(error);
        setLoading(false);
        setPhase("SIGNIN");
      });
  };

  if (phase === "SIGNIN") {
    return (
      <div>
        <Box sx={{ align: "center", display: "flex", flexDirection: "column" }}>
        <div style={{ height: "4px", width: "100%", clear: "both", marginBottom: "0px" }}>{loading && <LinearProgress />}</div>
          <Typography variant="body2" style={{ marginTop: "10px", marginBottom: "10px" }}>
            Voit halutessasi luoda käyttäjätunnuksen Uinnit.app -sovellukseen. Kirjautumalla sovellukseen omat uimarit pysyvät tallessa ja ovat aina
            käytettävissäsi, halutessasi myös useilla eri laitteilla.
          </Typography>

          <Typography variant="h6" style={{ marginTop: "10px", marginBottom: "10px" }}>
            Kirjaudu sovellukseen
          </Typography>
          <form autoComplete="on">
            <FormControl style={{ marginTop: "12px" }} fullWidth={true} error={errors.email !== undefined} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-email">Sähköpostiosoite</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type={"email"}
                value={email}
                autoFocus
                onChange={handleEmailChange}
                label={"Sähköpostiosoite"}
                autoComplete="username"
              />
              <FormHelperText id="component-error-text">{getTranslation(errors.email)}</FormHelperText>
            </FormControl>
            <FormControl style={{ marginTop: "12px" }} fullWidth={true} error={errors.password !== undefined} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Salasana</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={"Salasana"}
                autoComplete="current-password"
              />
              <FormHelperText id="component-error-text">{getTranslation(errors.password)}</FormHelperText>
            </FormControl>
            <Typography align="right" variant="body2" style={{ marginTop: "12px" }}>
              <Link onClick={resetEmailPhaseClicked}>Unohtuiko salasana?</Link>
            </Typography>
            <Typography align="right" style={{ marginTop: "12px" }}>
              <Button onClick={signUpPhaseClicked} variant="outlined">
                Luo tunnus
              </Button>
              <Button style={{ marginLeft: "10px" }} onClick={(e) => login(e)} variant="contained">
                Kirjaudu
              </Button>
            </Typography>
          </form>
        </Box>
      </div>
    );
  } else if (phase === "SIGNUP") {
    return (
      <div>
        <div style={{ height: "4px", width: "100%", clear: "both", marginBottom: 0 }}>{loading && <LinearProgress />}</div>
        <Box sx={{ align: "center", display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" style={{ marginTop: "10px", marginBottom: "10px" }}>
            Luo tunnus
          </Typography>
          <Typography variant="body2">Luo uusi käyttäjätunnus antamalla sähköpostiosoitteesi ja vähintään 6 merkkiä pitkä salasana.</Typography>
          <form autoComplete="on">
          <FormControl style={{ marginTop: "12px" }} fullWidth={true} error={errors.email !== undefined} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-email">Sähköpostiosoite</InputLabel>
              <OutlinedInput
                autoFocus
                id="outlined-adornment-email"
                type={"email"}
                value={email}
                onChange={handleEmailChange}
                label={"Sähköpostiosoite"}
                autoComplete="username"
              />
              <FormHelperText id="component-error-text">{getTranslation(errors.email)}</FormHelperText>
            </FormControl>
            <FormControl style={{ marginTop: "12px" }} fullWidth={true} error={errors.password !== undefined} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Salasana</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={"Salasana"}
                autoComplete="new-password"
              />
              <FormHelperText id="component-error-text">{getTranslation(errors.password)}</FormHelperText>
            </FormControl>
            <Typography align="right" style={{ marginTop: "12px" }}>
            <Button style={{ marginLeft: "10px"}} variant="contained" onClick={(e) => registerEmail(e)}>
              Luo tunnus
            </Button>
            </Typography>
            <Typography align="right" variant="body2" style={{ marginTop: "12px" }}>
              <Link onClick={backToSignInPhaseClicked}>Minulla on jo tunnus</Link>
            </Typography>
          </form>
        </Box>
      </div>
    );
  } else if (phase === "RESETEMAIL") {
    return (
      <div>
        <div style={{ height: "4px", width: "100%", clear: "both", marginBottom: 0 }}>{loading && <LinearProgress />}</div>
        <Box sx={{ align: "center", display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" style={{ marginTop: "10px", marginBottom: "10px" }}>
            Vaihda salasana
          </Typography>
          <Typography variant="body2">Tilaa sähköpostiisi linkki uuden salasanan asettamista varten.</Typography>
          <form>
          <FormControl style={{ marginTop: "12px" }} fullWidth={true} error={errors.email !== undefined} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-email">Sähköpostiosoite</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type={"email"}
                value={email}
                autoFocus
                onChange={handleEmailChange}
                label={"Sähköpostiosoite"}
              />
              <FormHelperText id="component-error-text">{getTranslation(errors.email)}</FormHelperText>
            </FormControl>
            <div style={{ marginTop: "10px" }}>
              <CardActions sx={{ justifyContent: "center", margin: "0px", padding: "0px" }}>
                <Button fullWidth variant="contained" onClick={resetEmailClicked}>
                  Lähetä viesti
                </Button>
              </CardActions>
            </div>
            <CardActions sx={{ justifyContent: "center", margin: "0px", padding: "0px" }}>
              <Typography align="right" variant="body2" style={{ marginTop: "18px" }}>
                <Link onClick={backToSignInPhaseClicked}>Palaa kirjautumiseen</Link>
              </Typography>
            </CardActions>
          </form>
        </Box>
      </div>
    );
  } else {
    return null;
  }
}
export default LoginRegisterForm;
