interface LimitDataObject {
  [key: string]: any;
}

export function getLimits(swimStyle: SwimStyle, pool: string | undefined, swimmerYear: string | undefined): Limit[] {
  if (pool === undefined) return [];
  let array = [];

  let age = swimmerYear ? getAgeFromYear(parseInt(swimmerYear)) : undefined;
  if (age) {
    var rollo = getRolloEventLimit(swimStyle, age);
    if (rollo !== undefined) {
      var value;
      if (pool === "25m") value = rollo[0];
      if (pool === "50m") value = rollo[1];
      if (value !== undefined) {
        array.push({
          limitType: "ROLLO",
          limitName: "ROLLO (" + pool + " " + (swimStyle.sex === 'MALE' ? "P" : "T") + "-" + age + ")",
          limitNameShort: "ROLLO " + pool,
          limitPool: pool,
          limitValue: value,
        });
      }
    }

    var ikm = getIKMEventLimit(swimStyle, age);
    if (ikm !== undefined) {
      var value;
      if (pool === "25m") value = ikm[0];
      if (pool === "50m") value = ikm[1];
      if (value !== undefined) {
        array.push({
          limitType: "IKM",
          limitName: "IKM (" + pool + " " + (swimStyle.sex === 'MALE' ? "P" : "T") + "-" + age + ")",
          limitNameShort: "IKM " + pool,
          limitPool: pool,
          limitValue: value,
        });
      }
    }
  }

  var sm = getSMEventLimit(swimStyle);
  if (sm !== undefined) {
    var value;
    if (pool === "25m") value = sm[0];
    if (pool === "50m") value = sm[1];
    if (value !== undefined) {
      array.push({
        limitType: "SM",
        limitName: "SM (" + pool + " " + (swimStyle.sex === 'MALE' ? "Miehet" : "Naiset") + ")",
        limitNameShort: "SM " + pool,
        limitPool: pool,
        limitValue: value,
      });
    }
  }
  var nsm = getNSMEventLimit(swimStyle);
  if (nsm !== undefined) {
    var value;
    if (pool === "25m") value = nsm[0];
    if (pool === "50m") value = nsm[1];
    if (value !== undefined) {
      array.push({
        limitType: "NSM",
        limitName: "NSM (" + pool + " " + (swimStyle.sex === 'MALE' ? "Miehet" : "Naiset") + ")",
        limitNameShort: "NSM " + pool,
        limitPool: pool,
        limitValue: value,
      });
    }
  }
  var gp = getGPEventLimit(swimStyle);
  if (gp !== undefined) {
    var value;
    if (pool === "25m") value = gp[0];
    if (pool === "50m") value = gp[1];
    if (value !== undefined) {
      array.push({
        limitType: "GP",
        limitName: "GP (" + pool + " " + (swimStyle.sex === 'MALE' ? "Miehet" : "Naiset") + ")",
        limitNameShort: "GP " + pool,
        limitPool: pool,
        limitValue: value,
      });
    }
  }
  return array;
}

function getAgeFromYear(year: number): number {
  let curYear = new Date().getFullYear();
  return curYear - year; // + 1; // @// TODO: age group changes in the summer, not new year
}

export function getLimitText(rajat: any) {
  if (rajat === undefined || rajat === null || rajat.limit === undefined || rajat.limits === undefined) return "";
  if (rajat.limit !== "IKM" && rajat.limit !== "ROLLO") return "";
  var limitprefix = rajat.limit === "IKM" ? "IKM" : rajat.limit === "ROLLO" ? "Rollo" : "";
  return [limitprefix + " lyhyt: " + rajat.limits[0], limitprefix + " pitkä: " + rajat.limits[1]];
}

export function getIKMEventLimit(swimStyle: SwimStyle, age: number) {
  const eventIdentifier = swimStyle.stroke + "_" + swimStyle.distance + "_" + swimStyle.sex + age;

  const IKM2024: LimitDataObject = {
    // Tytöt
    FLY_100m_FEMALE13: ["1.18,00", "1.20,00"],
    FLY_200m_FEMALE13: ["2.53,00", "2.57,00"],
    BACK_100m_FEMALE13: ["1.15,50", "1.17,50"],
    BACK_200m_FEMALE13: ["2.42,00", "2.46,00"],
    BREAST_100m_FEMALE13: ["1.23,00", "1.26,00"],
    BREAST_200m_FEMALE13: ["2.57,00", "3.03,00"],
    FREE_100m_FEMALE13: ["1.04,50", "1.06,50"],
    FREE_200m_FEMALE13: ["2.20,00", "2.24,00"],
    FREE_400m_FEMALE13: ["5.06,50", "5.14,50"],
    FREE_800m_FEMALE13: ["10.10,00", "10.26,00"],
    MEDLEY_200m_FEMALE13: ["2.49,00", "2.54,00"],
    MEDLEY_400m_FEMALE13: ["5.48,00", "5.58,00"],

    FLY_100m_FEMALE14: ["1.18,00", "1.20,00"],
    FLY_200m_FEMALE14: ["2.53,00", "2.57,00"],
    BACK_100m_FEMALE14: ["1.15,50", "1.17,50"],
    BACK_200m_FEMALE14: ["2.42,00", "2.46,00"],
    BREAST_100m_FEMALE14: ["1.23,00", "1.26,00"],
    BREAST_200m_FEMALE14: ["2.57,00", "3.03,00"],
    FREE_100m_FEMALE14: ["1.04,50", "1.06,50"],
    FREE_200m_FEMALE14: ["2.20,00", "2.24,00"],
    FREE_400m_FEMALE14: ["5.06,50", "5.14,50"],
    FREE_800m_FEMALE14: ["10.10,00", "10.26,00"],
    MEDLEY_200m_FEMALE14: ["2.49,00", "2.54,00"],
    MEDLEY_400m_FEMALE14: ["5.48,00", "5.58,00"],

    FLY_100m_FEMALE15: ["1.13,00", "1.15,00"],
    FLY_200m_FEMALE15: ["2.48,00", "2.52,00"],
    BACK_100m_FEMALE15: ["1.13,50", "1.15,50"],
    BACK_200m_FEMALE15: ["2.39,00", "2.43,00"],
    BREAST_100m_FEMALE15: ["1.21,00", "1.24,00"],
    BREAST_200m_FEMALE15: ["2.55,00", "3.01,00"],
    FREE_100m_FEMALE15: ["1.03,00", "1.05,00"],
    FREE_200m_FEMALE15: ["2.17,00", "2.21,00"],
    FREE_400m_FEMALE15: ["4.56,00", "5.04,00"],
    FREE_800m_FEMALE15: ["10.01,00", "10.17,00"],
    MEDLEY_200m_FEMALE15: ["2.37,00", "2.42,00"],
    MEDLEY_400m_FEMALE15: ["5.38,00", "5.48,00"],

    FLY_100m_FEMALE16: ["1.11,00", "1.13,00"],
    FLY_200m_FEMALE16: ["2.43,00", "2.47,00"],
    BACK_100m_FEMALE16: ["1.12,00", "1.14,00"],
    BACK_200m_FEMALE16: ["2.37,50", "2.41,50"],
    BREAST_100m_FEMALE16: ["1.19,50", "1.22,50"],
    BREAST_200m_FEMALE16: ["2.53,00", "2.59,00"],
    FREE_100m_FEMALE16: ["1.01,50", "1.03,50"],
    FREE_200m_FEMALE16: ["2.14,50", "2.18,50"],
    FREE_400m_FEMALE16: ["4.48,00", "4.56,00"],
    FREE_800m_FEMALE16: ["9.53,00", "10.09,00"],
    MEDLEY_200m_FEMALE16: ["2.35,00", "2.40,00"],
    MEDLEY_400m_FEMALE16: ["5.32,00", "5.42,00"],

    // Pojat
    FLY_100m_MALE13: ["1.14,00", "1.16,00"],
    FLY_200m_MALE13: ["2.48,00", "2.52,00"],
    BACK_100m_MALE13: ["1.14,00", "1.16,00"],
    BACK_200m_MALE13: ["2.37,00", "2.41,00"],
    BREAST_100m_MALE13: ["1.21,00", "1.23,00"],
    BREAST_200m_MALE13: ["2.54,00", "2.58,00"],
    FREE_100m_MALE13: ["1.02,00", "1.04,00"],
    FREE_200m_MALE13: ["2.13,00", "2.17,00"],
    FREE_400m_MALE13: ["4.50,00", "4.58,00"],
    FREE_800m_MALE13: ["9.54,00", "10.10,00"],
    MEDLEY_200m_MALE13: ["2.44,00", "2.49,00"],
    MEDLEY_400m_MALE13: ["5.37,00", "5.47,00"],

    FLY_100m_MALE14: ["1.14,00", "1.16,00"],
    FLY_200m_MALE14: ["2.48,00", "2.52,00"],
    BACK_100m_MALE14: ["1.14,00", "1.16,00"],
    BACK_200m_MALE14: ["2.37,00", "2.41,00"],
    BREAST_100m_MALE14: ["1.21,00", "1.23,00"],
    BREAST_200m_MALE14: ["2.54,00", "2.58,00"],
    FREE_100m_MALE14: ["1.02,00", "1.04,00"],
    FREE_200m_MALE14: ["2.13,00", "2.17,00"],
    FREE_400m_MALE14: ["4.50,00", "4.58,00"],
    FREE_800m_MALE14: ["9.54,00", "10.10,00"],
    MEDLEY_200m_MALE14: ["2.44,00", "2.49,00"],
    MEDLEY_400m_MALE14: ["5.37,00", "5.47,00"],

    FLY_100m_MALE15: ["1.08,00", "1.10,00"],
    FLY_200m_MALE15: ["2.40,00", "2.44,00"],
    BACK_100m_MALE15: ["1.10,00", "1.12,00"],
    BACK_200m_MALE15: ["2.30,00", "2.34,00"],
    BREAST_100m_MALE15: ["1.16,00", "1.19,00"],
    BREAST_200m_MALE15: ["2.47,50", "2.53,50"],
    FREE_100m_MALE15: ["0.59,00", "1.01,00"],
    FREE_200m_MALE15: ["2.09,50", "2.13,50"],
    FREE_400m_MALE15: ["4.42,00", "4.48,00"],
    FREE_800m_MALE15: ["9.37,00", "9.53,00"],
    MEDLEY_200m_MALE15: ["2.27,00", "2.32,00"],
    MEDLEY_400m_MALE15: ["5.19,00", "5.29,00"],

    FLY_100m_MALE16: ["1.04,00", "1.06,00"],
    FLY_200m_MALE16: ["2.35,00", "2.39,00"],
    BACK_100m_MALE16: ["1.07,00", "1.09,00"],
    BACK_200m_MALE16: ["2.26,00", "2.30,00"],
    BREAST_100m_MALE16: ["1.14,00", "1.17,00"],
    BREAST_200m_MALE16: ["2.42,00", "2.48,00"],
    FREE_100m_MALE16: ["0.57,00", "0.59,00"],
    FREE_200m_MALE16: ["2.06,00", "2.10,00"],
    FREE_400m_MALE16: ["4.29,50", "4.37,50"],
    FREE_800m_MALE16: ["9.22,00", "9.38,00"],
    MEDLEY_200m_MALE16: ["2.23,00", "2.28,00"],
    MEDLEY_400m_MALE16: ["5.13,00", "5.23,00"],

    // 50m
    FLY_50m_FEMALE13: ["0.30,60", "0.31,60"],
    FLY_50m_FEMALE14: ["0.30,60", "0.31,60"],
    FLY_50m_FEMALE15: ["0.30,60", "0.31,60"],
    FLY_50m_FEMALE16: ["0.30,60", "0.31,60"],
    FLY_50m_MALE13: ["0.28,00", "0.29,00"],
    FLY_50m_MALE14: ["0.28,00", "0.29,00"],
    FLY_50m_MALE15: ["0.28,00", "0.29,00"],
    FLY_50m_MALE16: ["0.28,00", "0.29,00"],

    BACK_50m_FEMALE13: ["0.34,00", "0.35,00"],
    BACK_50m_FEMALE14: ["0.34,00", "0.35,00"],
    BACK_50m_FEMALE15: ["0.34,00", "0.35,00"],
    BACK_50m_FEMALE16: ["0.34,00", "0.35,00"],
    BACK_50m_MALE13: ["0.31,00", "0.32,00"],
    BACK_50m_MALE14: ["0.31,00", "0.32,00"],
    BACK_50m_MALE15: ["0.31,00", "0.32,00"],
    BACK_50m_MALE16: ["0.31,00", "0.32,00"],

    BREAST_50m_FEMALE13: ["0.36,00", "0.37,50"],
    BREAST_50m_FEMALE14: ["0.36,00", "0.37,50"],
    BREAST_50m_FEMALE15: ["0.36,00", "0.37,50"],
    BREAST_50m_FEMALE16: ["0.36,00", "0.37,50"],
    BREAST_50m_MALE13: ["0.33,00", "0.34,50"],
    BREAST_50m_MALE14: ["0.33,00", "0.34,50"],
    BREAST_50m_MALE15: ["0.33,00", "0.34,50"],
    BREAST_50m_MALE16: ["0.33,00", "0.34,50"],

    FREE_50m_FEMALE13: ["0.28,70", "0.29,70"],
    FREE_50m_FEMALE14: ["0.28,70", "0.29,70"],
    FREE_50m_FEMALE15: ["0.28,70", "0.29,70"],
    FREE_50m_FEMALE16: ["0.28,70", "0.29,70"],
    FREE_50m_MALE13: ["0.26,20", "0.27,20"],
    FREE_50m_MALE14: ["0.26,20", "0.27,20"],
    FREE_50m_MALE15: ["0.26,20", "0.27,20"],
    FREE_50m_MALE16: ["0.26,20", "0.27,20"],
  };

  if (IKM2024[eventIdentifier] !== undefined) {
    return IKM2024[eventIdentifier];
  } else {
    return;
  }
}

export function getRolloEventLimit(swimStyle: SwimStyle, age: number) {
  const eventIdentifier = swimStyle.stroke + "_" + swimStyle.distance + "_" + swimStyle.sex + age;

  const ROLLO2024: LimitDataObject = {
    FLY_50m_FEMALE10: ["0.48,36", "0.49,36"],
    BACK_50m_FEMALE10: ["0.48,57", "0.49,57"],
    BREAST_50m_FEMALE10: ["0.54,01", "0.55,51"],
    FREE_100m_FEMALE10: ["1.36,00", "1.38,00"],
    FREE_200m_FEMALE10: ["3.12,50", "3.16,50"],
    //MEDLEY_200m_FEMALE10: ["3.47,54", "3.52,54"],

    FLY_50m_FEMALE11: ["0.43,00", "0.44,040"],
    BACK_100m_FEMALE11: ["1.34,00", "1.36,00"],
    BREAST_100m_FEMALE11: ["1.47,00", "1.50,00"],
    FREE_100m_FEMALE11: ["1.23,00", "1.25,00"],
    FREE_400m_FEMALE11: ["6.18,00", "6.22,00"],
    //MEDLEY_200m_FEMALE11: ["3.19,76", "3.24,76"],

    FLY_100m_FEMALE12: ["1.38,00", "1.40,00"],
    BACK_100m_FEMALE12: ["1.29,00", "1.31,00"],
    BREAST_100m_FEMALE12: ["1.39,00", "1.42,00"],
    FREE_100m_FEMALE12: ["1.16,00", "1.18,00"],
    FREE_400m_FEMALE12: ["5.43,50", "5.51,50"],
    MEDLEY_200m_FEMALE12: ["3.10,00", "3.15,00"],

    FLY_100m_FEMALE13: ["1.28,00", "1.30,00"],
    BACK_100m_FEMALE13: ["1.21,00", "1.23,00"],
    BREAST_100m_FEMALE13: ["1.30,00", "1.33,00"],
    FREE_100m_FEMALE13: ["1.11,00", "1.13,00"],
    FREE_400m_FEMALE13: ["5.18,00", "5.26,00"],
    MEDLEY_200m_FEMALE13: ["2.55,00", "3.00,00"],

    FLY_50m_MALE10: ["0.49,00", "0.50,00"],
    BACK_50m_MALE10: ["0.52,00", "0.53,00"],
    BREAST_50m_MALE10: ["0.57,50", "0.59,00"],
    FREE_100m_MALE10: ["1.38,00", "1.40,00"],
    FREE_200m_MALE10: ["3.21,00", "3.25,00"],
    //MEDLEY_200m_MALE10: ["3.38,51", "3.43,51"],

    FLY_50m_MALE11: ["0.46,00", "0.47,00"],
    BACK_100m_MALE11: ["1.38,00", "1.40,00"],
    BREAST_100m_MALE11: ["1.51,00", "1.54,00"],
    FREE_100m_MALE11: ["1.26,50", "1.28,50"],
    FREE_400m_MALE11: ["6.26,00", "6.30,00"],
    //MEDLEY_200m_MALE11: ["3.38,51", "3.43,51"],

    FLY_100m_MALE12: ["1.42,00", "1.44,00"],
    BACK_100m_MALE12: ["1.33,50", "1.34,50"],
    BREAST_100m_MALE12: ["1.42,50", "1.45,50"],
    FREE_100m_MALE12: ["1.18,00", "1.20,00"],
    FREE_400m_MALE12: ["5.48,00", "5.56,00"],
    MEDLEY_200m_MALE12: ["3.15,00", "3.20,00"],

    FLY_100m_MALE13: ["1.28,00", "1.30,00"],
    BACK_100m_MALE13: ["1.22,00", "1.24,00"],
    BREAST_100m_MALE13: ["1.32,00", "1.35,00"],
    FREE_100m_MALE13: ["1.10,00", "1.12,00"],
    FREE_400m_MALE13: ["5.25,00", "5.33,00"],
    MEDLEY_200m_MALE13: ["3.05,00", "3.10,00"],
  };

  if (ROLLO2024[eventIdentifier] !== undefined) {
    return ROLLO2024[eventIdentifier];
  } else {
    return;
  }
}

export function getSMEventLimit(swimStyle: SwimStyle, age?: number) {
  const eventIdentifier = swimStyle.stroke + "_" + swimStyle.distance + "_" + swimStyle.sex;

  const SM2024: LimitDataObject = {
    FLY_50m_FEMALE: ["0.29,96", "0.30,96"],
    FLY_100m_FEMALE: ["1.08,04", "1.10,04"],
    FLY_200m_FEMALE: ["2.32,75", "2.36,75"],
    BACK_50m_FEMALE: ["0.32,38", "0.33,38"],
    BACK_100m_FEMALE: ["1.09.88", "1.11,88"],
    BACK_200m_FEMALE: ["2.33,00", "2.37,00"],
    BREAST_50m_FEMALE: ["0.35,42", "0.36,92"],
    BREAST_100m_FEMALE: ["1.17,36", "1.20,36"],
    BREAST_200m_FEMALE: ["2.48,48", "2.54,48"],
    FREE_50m_FEMALE: ["0.27,54", "0.28,54"],
    FREE_100m_FEMALE: ["0.59,90", "1.01,90"],
    FREE_200m_FEMALE: ["2.12,00", "2.16,00"],
    FREE_400m_FEMALE: ["4.40,27", "4.48,27"],
    FREE_800m_FEMALE: ["9.41,14", "9.57,14"],
    FREE_1500m_FEMALE: ["19.18,90", "19.48,90"],
    MEDLEY_100m_FEMALE: ["1.08,14", undefined],
    MEDLEY_200m_FEMALE: ["2.30,30", "2.35,30"],
    MEDLEY_400m_FEMALE: ["5.26,90", "5.36,90"],

    FLY_50m_MALE: ["0.26,99", "0.27,99"],
    FLY_100m_MALE: ["1.00,60", "1.02,60"],
    FLY_200m_MALE: ["2.18,33", "2.22,33"],
    BACK_50m_MALE: ["0.29,56", "0.30,56"],
    BACK_100m_MALE: ["1.04,10", "1.06,10"],
    BACK_200m_MALE: ["2.21,00", "2.25,00"],
    BREAST_50m_MALE: ["0.31,45", "0.32,95"],
    BREAST_100m_MALE: ["1.09,13", "1.12,13"],
    BREAST_200m_MALE: ["2.33,85", "2.39,85"],
    FREE_50m_MALE: ["0.24,49", "0.25,49"],
    FREE_100m_MALE: ["0.53,60", "0.55,60"],
    FREE_200m_MALE: ["2.01,10", "2.05,10"],
    FREE_400m_MALE: ["4.17,78", "4.25,78"],
    FREE_800m_MALE: ["8.56,67", "9.12,67"],
    FREE_1500m_MALE: ["17.42,14", "18.12,14"],
    MEDLEY_100m_MALE: ["1.00,87", undefined],
    MEDLEY_200m_MALE: ["2.20,80", "2.25,80"],
    MEDLEY_400m_MALE: ["5.01,20", "5.11,20"],
  };

  if (SM2024[eventIdentifier] !== undefined) {
    return SM2024[eventIdentifier];
  } else {
    return;
  }
}

export function getGPEventLimit(swimStyle: SwimStyle) {
  const eventIdentifier = swimStyle.stroke + "_" + swimStyle.distance + "_" + swimStyle.sex;

  const GP2024: LimitDataObject = {
    FLY_50m_FEMALE: ["0.29,96", "0.30,96"],
    FLY_100m_FEMALE: ["1.09,30", "1.11,30"],
    FLY_200m_FEMALE: ["2.38,41", "2.42,41"],
    BACK_50m_FEMALE: ["0.32,60", "0.33,60"],
    BACK_100m_FEMALE: ["1.10,50", "1.12,50"],
    BACK_200m_FEMALE: ["2.36,00", "2.40,00"],
    BREAST_50m_FEMALE: ["0.35,70", "0.37,20"],
    BREAST_100m_FEMALE: ["1.18,00", "1.21,00"],
    BREAST_200m_FEMALE: ["2.51,00", "2.57,00"],
    FREE_50m_FEMALE: ["0.27,90", "0.28,90"],
    FREE_100m_FEMALE: ["1.00,00", "1.02,00"],
    FREE_200m_FEMALE: ["2.12,80", "2.16,80"],
    FREE_400m_FEMALE: ["4.43,35", "4.51,35"],
    FREE_800m_FEMALE: ["9.43,50", "9.59,50"],
    FREE_1500m_FEMALE: ["19.21,27", "19.51,27"],
    MEDLEY_100m_FEMALE: ["1.10,50", undefined],
    MEDLEY_200m_FEMALE: ["2.33,00", "2.38,00"],
    MEDLEY_400m_FEMALE: ["5.27,36", "5.37,36"],

    FLY_50m_MALE: ["0.26,99", "0.27,99"],
    FLY_100m_MALE: ["1.00,60", "1.02,60"],
    FLY_200m_MALE: ["2.24,41", "2.28,41"],
    BACK_50m_MALE: ["0.29,63", "0.30,63"],
    BACK_100m_MALE: ["1.05,10", "1.07,10"],
    BACK_200m_MALE: ["2.24,50", "2.28,50"],
    BREAST_50m_MALE: ["0.32,30", "0.33,80"],
    BREAST_100m_MALE: ["1.12,10", "1.15,10"],
    BREAST_200m_MALE: ["2.38,37", "2.44,37"],
    FREE_50m_MALE: ["0.25,34", "0.26,34"],
    FREE_100m_MALE: ["0.54,20", "0.56,20"],
    FREE_200m_MALE: ["2.03,83", "2.07,83"],
    FREE_400m_MALE: ["4.21,61", "4.29,61"],
    FREE_800m_MALE: ["9.04,04", "09.16,04"],
    FREE_1500m_MALE: ["18.06,65", "18.36,65"],
    MEDLEY_100m_MALE: ["1.03,10", undefined],
    MEDLEY_200m_MALE: ["2.20,80", "2.25,80"],
    MEDLEY_400m_MALE: ["5.07,68", "5.17,68"],
  };

  if (GP2024[eventIdentifier] !== undefined) {
    return GP2024[eventIdentifier];
  } else {
    return;
  }
}

export function getNSMEventLimit(swimStyle: SwimStyle) {
  const eventIdentifier = swimStyle.stroke + "_" + swimStyle.distance + "_" + swimStyle.sex;

  const NSM2024: LimitDataObject = {
    FLY_50m_FEMALE: ["0.29,96", "0.30,96"],
    FLY_100m_FEMALE: ["1.09,30", "1.11,30"],
    FLY_200m_FEMALE: ["2.38,41", "2.42,41"],
    BACK_50m_FEMALE: ["0.32,60", "0.33,60"],
    BACK_100m_FEMALE: ["1.10.50", "1.12,50"],
    BACK_200m_FEMALE: ["2.36,00", "2.40,00"],
    BREAST_50m_FEMALE: ["0.35,70", "0.37,20"],
    BREAST_100m_FEMALE: ["1.18,00", "1.21,00"],
    BREAST_200m_FEMALE: ["2.51,00", "2.57,00"],
    FREE_50m_FEMALE: ["0.27,90", "0.28,90"],
    FREE_100m_FEMALE: ["1.00,00", "1.02,00"],
    FREE_200m_FEMALE: ["2.12,80", "2.16,80"],
    FREE_400m_FEMALE: ["4.43,35", "4.51,35"],
    FREE_800m_FEMALE: ["9.43,50", "9.59,50"],
    FREE_1500m_FEMALE: ["19.21,27", "19.51,27"],
    MEDLEY_100m_FEMALE: ["1.10,50", undefined],
    MEDLEY_200m_FEMALE: ["2.33,00", "2.38,00"],
    MEDLEY_400m_FEMALE: ["5.27,36", "5.37,36"],

    FLY_50m_MALE: ["0.26,99", "0.27,99"],
    FLY_100m_MALE: ["1.00,60", "1.02,60"],
    FLY_200m_MALE: ["2.24,41", "2.28,41"],
    BACK_50m_MALE: ["0.29,63", "0.30,63"],
    BACK_100m_MALE: ["1.05,10", "1.07,10"],
    BACK_200m_MALE: ["2.24,50", "2.28,50"],
    BREAST_50m_MALE: ["0.32,30", "0.33,80"],
    BREAST_100m_MALE: ["1.12,10", "1.15,10"],
    BREAST_200m_MALE: ["2.38,37", "2.44,37"],
    FREE_50m_MALE: ["0.25,34", "0.26,34"],
    FREE_100m_MALE: ["0.54,20", "0.56,20"],
    FREE_200m_MALE: ["2.03,83", "2.07,83"],
    FREE_400m_MALE: ["4.21,61", "4.29,61"],
    FREE_800m_MALE: ["9.00,04", "9.16,04"],
    FREE_1500m_MALE: ["18.06,65", "18.36,65"],
    MEDLEY_100m_MALE: ["1.03,10", undefined],
    MEDLEY_200m_MALE: ["2.20,80", "2.25,80"],
    MEDLEY_400m_MALE: ["5.07,68", "5.17,68"],
  };

  if (NSM2024[eventIdentifier] !== undefined) {
    return NSM2024[eventIdentifier];
  } else {
    return;
  }
}
