// FUNCTIONS FOR CONVERTING COMPETITION DATA FROM SWIMIFY

import moment from "moment";
import { formatSessionDate } from "../Helpers";


function getSwimStyle(eventObject : any) : SwimStyle | undefined {

  interface styleArray {
    [key: number]: Strokes
  }

  const styles : styleArray = {
    1001: "FREE",
    1002: "BREAST",
    1003: "BACK",
    1004: "FLY",
    1005: "MEDLEY",
    1006: "FREE", // relay
    1007: "BREAST", // relay
    1008: "BACK", // relay
    1009: "FLY", // relay
    1010: "MEDLEY", // relay
    1011: "OTHER", // TODO 4x100m IM Medley Herrer",
    1012: "OTHER",
    1013: "OTHER",
  }

  interface sexStringsArray {
    [key: number]: Sexes
  }

  const sexStrings : sexStringsArray = {
    1: "MALE",
    2: "FEMALE",
    3: "MIXED",
  }

  const swimStyle = {
    distance: (eventObject.distance ? eventObject.distance + "m" : undefined),
    relayCount: (eventObject.athlete_count && eventObject.athlete_count > 1 ? eventObject.athlete_count : undefined),
    sex: (sexStrings[eventObject.gender_number] ? sexStrings[eventObject.gender_number] : undefined),
    stroke: (styles[eventObject.event_type] ? styles[eventObject.event_type] : undefined),
    isPara: eventObject.is_para_event,
    isMasters: eventObject.is_masters_event,
  }
  return swimStyle;
}

/*
function getSwimStyle(programItemName : string) : SwimStyle | undefined {
  let swimStyle : any = {};
  const distanceArray = /[0-9]+ ?m/.exec(programItemName);
  if (distanceArray !== null) {
    swimStyle.distance = distanceArray[0];
  }

  var relayCountArray = /[0-9]x[0-9]+/.exec(programItemName.toLowerCase());
  if (relayCountArray !== null) {
    swimStyle.relayCount = relayCountArray[0].charAt(0);
  }
  if(swimStyle.relayCount && swimStyle.relayCount > 0) {
    swimStyle.distance = swimStyle.relayCount * parseInt(swimStyle.distance.slice(0, -1)) + "m";
  }

  var stroke;
  if (programItemName.toUpperCase().indexOf("VAPAA") >= 0) {
    stroke = "FREE";
  } else if (programItemName.toUpperCase().indexOf("FREE") >= 0) {
    stroke = "FREE";
  } else if (programItemName.toUpperCase().indexOf("FRISIM") >= 0) {
    stroke = "FREE";
  } else if (programItemName.toUpperCase().indexOf("PERHOS") >= 0) {
    stroke = "FLY";
  } else if (programItemName.toUpperCase().indexOf("BUTTER") >= 0) {
    stroke = "FLY";
  } else if (programItemName.toUpperCase().indexOf("FJÄRIL") >= 0) {
    stroke = "FLY";
  } else if (programItemName.toUpperCase().indexOf("SKU") >= 0) {
    stroke = "MEDLEY";
  } else if (programItemName.toUpperCase().indexOf("MEDLEY") >= 0) {
    stroke = "MEDLEY";
  } else if (programItemName.toUpperCase().indexOf("SEKA") >= 0) {
    stroke = "MEDLEY";
  } else if (programItemName.toUpperCase().indexOf("RINTA") >= 0) {
    stroke = "BREAST";
  } else if (programItemName.toUpperCase().indexOf("BREAST") >= 0) {
    stroke = "BREAST";
  } else if (programItemName.toUpperCase().indexOf("BRÖST") >= 0) {
    stroke = "BREAST";
  } else if (programItemName.toUpperCase().indexOf("SELKÄ") >= 0) {
    stroke = "BACK";
  } else if (programItemName.toUpperCase().indexOf("RYGG") >= 0) {
    stroke = "BACK";
  } else if (programItemName.toUpperCase().indexOf("BACK") >= 0) {
    stroke = "BACK";
  } else if (programItemName.toUpperCase().indexOf(" RU") >= 0) {
    stroke = "BREAST";
  } else if (programItemName.toUpperCase().indexOf(" PU") >= 0) {
    stroke = "FLY";
  } else if (programItemName.toUpperCase().indexOf(" SU") >= 0) {
    stroke = "BACK";
  } else if (programItemName.toUpperCase().indexOf(" VU") >= 0) {
    stroke = "FREE";
  } else if (programItemName.toUpperCase().indexOf(" VUV") >= 0) {
    stroke = "FREE";
  }
  if (stroke !== undefined) {
    swimStyle.stroke = stroke;
  }

  var sex;
  if (programItemName.toUpperCase().indexOf("NAISET") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("WOMEN") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("DAMER") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("TYTÖT") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("MIEHET") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf("POJAT") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf("HERRAR") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf("MEN") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf("T1") >= 0 && programItemName.toUpperCase().indexOf("P1") >= 0) {
    sex = "MIXED";
  } else if (programItemName.toUpperCase().indexOf("T1") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("T9") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("T8") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("T-1") >= 0 && programItemName.toUpperCase().indexOf("P-1") >= 0) {
    sex = "MIXED";
  } else if (programItemName.toUpperCase().indexOf("T-1") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("T-9") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("T-8") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf("P1") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf("P9") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf("P8") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf("P-1") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf("P-9") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf("P-8") >= 0) {
    sex = "MALE";
  } else if (programItemName.toUpperCase().indexOf(" N ") >= 0) {
    sex = "FEMALE";
  } else if (programItemName.toUpperCase().indexOf(" M ") >= 0) {
    sex = "MALE";
  } else if (
    relayCountArray !== null &&
    programItemName.toUpperCase().indexOf("2N") >= 0 &&
    programItemName.toUpperCase().indexOf("2M") >= 0
  ) {
    sex = "MIXED";
  } else if (
    relayCountArray !== null &&
    programItemName.toUpperCase().indexOf("2T") >= 0 &&
    programItemName.toUpperCase().indexOf("2P") >= 0
  ) {
    sex = "MIXED";
  } else if (
    relayCountArray !== null &&
    programItemName.toUpperCase().indexOf("MIX") >= 0
  ) {
    sex = "MIXED";
  } else if (
    relayCountArray !== null &&
    programItemName.toUpperCase().indexOf("SEKA") >= 0
  ) {
    sex = "MIXED";
  }
  if (sex !== undefined) {
    swimStyle.sex = sex;
  }
  if (Object.keys(swimStyle).length > 0) {
    let ss : SwimStyle = swimStyle;
    return ss;
  } else {
    return undefined;
  }
} */

function getEvent(events: any, event_oid: number): any {
  // loop through events and find the one with the correct oid
  for (let i = 0; i < events?.length; i++) {
    if (events[i]?.oid === event_oid) {
      return events[i];
    }
  }
}

function convertProgramEntries(swimifyCompetitionData: any, sid : string, data: any, lists: Lists, liveSessionStatuses : any): ProgramItem[] {
  let items: ProgramItem[] = [];
  let arrayForSort = [...data];
  for (const [key, value] of Object.entries(arrayForSort)) {
    items.push(value);
  }
  return items.map((item: any, index) => {
    let eventObject = getEvent(swimifyCompetitionData?.events, item.round?.event_oid);
    let eventNumber = eventObject?.number;
    if (eventNumber === undefined || eventNumber === null) {
      eventNumber = "0";
    }

    let swimStyle = (eventObject ? getSwimStyle(eventObject) : undefined);
    let liveStatus = liveSessionStatuses?.time_program_entries?.find((liveItem : { id: string }) => liveItem.id === item.id);
    let status = (liveStatus ? liveStatus.round?.status : item.round?.status);

    if (status < 5) {
      // 5 means finished // TODO this is a bit wild guess and should be studied more from the data
    }

    //console.log("CONVERTING ITEM", item);

    return {
      cid: item.competition_id,
      sid: "" + sid,
      event: "" + eventNumber,
      eventName: eventObject?.name,
      programItemIndex: index, //TODO TÄSSÄ OLI IKÄVÄ BUGI CETUS GAMES JAKSO 2 (oli sort_order)
      programItemName: item.name,
      programItemId: item.id,
      roundOid: item.round_oid,
      round_id: item.round?.id,
      tpid: item.oid, // TODO using oid instead of id but why?
      status: status,
      pool: (swimifyCompetitionData?.pool_type === 1 ? "25m" : "50m"),
      swimStyle: swimStyle,
      //entryListId: hasEntries ? entryListId : undefined, TODO livetiming continues to use these, but they are not used when converting swimify data
      //startListId: hasHeats ? startListId : undefined,
      //resultListId: hasResults ? resultListId : undefined,
      startTime: item.start_time && item.start_time.startsWith("202") ? item.start_time : undefined,
      startTimeText: item.start_time.startsWith("202") ? moment(item.start_time).format("H.mm") : undefined,
      stopTime: item.stop_time && item.stop_time.startsWith("202") ? item.stop_time : undefined,
      stopTimeText: item.stop_time.startsWith("202") ? moment(item.stop_time).format("H.mm") : undefined,
    };
  });
}

function convertSession(swimifyCompetitionData: any, data: any, lists: Lists, liveSessionStatuses : any): Session {
  let sessionName = data.name;
  if (sessionName === "Kisajakso " + data.number) {
    sessionName = undefined;
  }
  if(sessionName === "Session " + data.number) {
    sessionName = undefined;
  }
  if(sessionName === "Jakso " + data.number) {
    sessionName = undefined;
  }
  return {
    sid: "" + data.number,
    sessionNumberText: "Jakso " + data.number,
    sessionName: (sessionName && " " + sessionName),
    sessionDate: data.start_time,
    sessionDateText: formatSessionDate(data.start_time),
    superlive_seo_link: data.superlive_seo_link,
    programItems: convertProgramEntries(swimifyCompetitionData, data.number, data.time_program_entries || {}, lists, liveSessionStatuses),
  };
}

function convertSessions(swimifyCompetitionData: any, data: any, lists: Lists, competitionStatuses : any): Sessions {
  let sessions: Sessions = {};
  let arrayForSort = [...data];
  let sortedData = arrayForSort.sort(function (a: any, b: any) {
    return a.number - b.number;
  });
  for (const [key, value] of Object.entries(sortedData)) {
    let liveSessionStatuses = competitionStatuses?.competition_sessions?.find((session : { id: string }) => session.id === value.id);
    sessions[value.number] = convertSession(swimifyCompetitionData, value, lists, liveSessionStatuses);
  }
  return sessions;
}

export function convertSwimifyCompetitionData(data: any, lists: Lists, competitionStatuses : any): CompetitionData {
  return {
    cid: data.id,
    navigationCID: data.seo_text,
    competitionName: data.name,
    competitionLocation: data.city,
    pool_name: data.pool_name,
    organizer: data.organizer,
    startDate: data.startDate,
    endDate: data.endDate,
    file_archives: data.file_archives,
    superlive: data.superlive,
    pool: (data.pool_type === 1 ? "25m" : "50m"),
    sessions: convertSessions(data, data.competition_sessions || [], lists, competitionStatuses),
  };
}
