import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoginIcon from "@mui/icons-material/Login";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CardMedia from "@mui/material/CardMedia";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { getAuth } from "firebase/auth";
import React from "react";
import { Link } from "react-router-dom";
import FeedbackIcon from '@mui/icons-material/Feedback';

interface CompetitionMoreProps {
  competitionData?: CompetitionData;
  setInfoDialog: (infoDialogOpen: boolean) => void;
  setAccountDialog: (accountDialogOpen: boolean) => void;
}

const CompetitionMore = (props: CompetitionMoreProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const auth = getAuth();
  const theme = useTheme();

  function openLive(cid: string) {
    window.open("http://livetiming.fi/live.php?cid=" + cid);
  }

  function openLiveTiming(cid: string) {
    window.open("http://livetiming.fi/program.php?cid=" + cid);
  }

  function openFeedback() {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSddYMvm_2saOWYWuUT11-Q91V58QmENl-NYZuvCD7OxWwL1mg/viewform?embedded=true");
  }

  function openSuperLive(link: string | undefined) {
    if (link) {
      window.open(link);
    }
  }

  if (props.competitionData === undefined) return null;
  const cid = props.competitionData.cid;
  if (cid === undefined) return null;

  const infos = [
    [
      "Ajankohta",
      props.competitionData.startDate !== props.competitionData.endDate
        ? props.competitionData.startDate + " - " + props.competitionData.endDate
        : props.competitionData.startDate,
    ],
    ["Järjestäjä", props.competitionData.organizer],
    ["Sijainti", props.competitionData.competitionLocation],
    ["Halli", props.competitionData.pool_name],
    ["Allas", props.competitionData.pool],
  ];

  function filterEmpty(info: any) {
    return info[1] !== undefined && info[1].length > 0;
  }

  return (
    <div>
      <div style={{ margin: theme.spacing(2) }}>
        {infos.filter(filterEmpty).map((info, index) => (
          <Typography key={"info_" + index} variant="body2">
            <span style={{ color: theme.palette.text.secondary }}>{info[0] + ": "}</span>
            <span style={{ color: theme.palette.text.primary }}>{info[1]}</span>
          </Typography>
        ))}
      </div>

      {cid.length === 4 && (
        <List>
          <ListItem button component={Link} to={"/competition/" + cid + "/info"}>
            <ListItemText>Kilpailun tiedostot</ListItemText>
            <IconButton edge="end">
              <ArrowRightIcon />
            </IconButton>
          </ListItem>

          <ListItem button onClick={() => openLiveTiming(cid)}>
            <ListItemText>LiveTiming</ListItemText>
            <IconButton edge="end">
              <ArrowRightIcon />
            </IconButton>
          </ListItem>

          <ListItem button onClick={() => openLive(cid)}>
            <ListItemText>Live</ListItemText>
            <IconButton edge="end">
              <ArrowRightIcon />
            </IconButton>
          </ListItem>

          <ListItem button onClick={() => openSuperLive(cid)}>
            <ListItemText>SuperLive</ListItemText>
            <IconButton edge="end">
              <ArrowRightIcon />
            </IconButton>
          </ListItem>
        </List>
      )}

      <Divider />

      {props.competitionData && props.competitionData.superlive && props.competitionData.sessions && (
        <>
          <Typography variant="h6" sx={{ marginLeft: theme.spacing(2), marginBottom: theme.spacing(1), marginTop: theme.spacing(2) }}>
            SuperLive:
          </Typography>
          {Object.entries(props.competitionData.sessions).map((session, index) => (
            <Accordion
              key={"superlive_sessions_" + index}
              elevation={0}
              square
              disableGutters
              sx={{
                margin: theme.spacing(1),
                marginTop: theme.spacing(0),
                "&:before": {
                  display: "none",
                },
                "& .MuiCardContent-root:last-child": {
                  paddingBottom: 0,
                },
              }}
              expanded={expanded === "Accordion Jakso " + session[1].sid}
              onChange={handleChange("Accordion Jakso " + session[1].sid)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.primary.contrastText }} />}
                sx={{
                  backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                  },
                  padding: theme.spacing(1),
                  paddingRight: "0.7rem",
                }}
              >
                Jakso {session[1].sid}
              </AccordionSummary>
              <AccordionDetails>
                {expanded === "Accordion Jakso " + session[1].sid && (
                  <CardMedia component="iframe" src={session[1].superlive_seo_link} allowFullScreen={true} height={(window.innerWidth * 9) / 16}></CardMedia>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
          <Divider sx={{ marginTop: theme.spacing(2) }} />
        </>
      )}

      {props.competitionData.file_archives && props.competitionData.file_archives.length > 0 && (
        <>
          <Typography variant="h6" sx={{ marginLeft: theme.spacing(2), marginBottom: theme.spacing(0), marginTop: theme.spacing(2) }}>
            Tiedostot:
          </Typography>
          <List dense>
            {props.competitionData.file_archives.map((file_archive: any, index: number) => (
              <ListItem sx={{ padding: 0 }} dense key={"file_" + index}>
                <ListItemButton component={Link} to={file_archive.link}>
                  <ListItemText
                    primary={file_archive.description ? file_archive.description : file_archive.name}
                    secondary={file_archive.description ? file_archive.name : undefined}
                  ></ListItemText>
                  <IconButton edge="end" tabIndex={-1}>
                    <ArrowRightIcon />
                  </IconButton>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </>
      )}

      <List>
        <ListItem button key="Käyttäjätili" onClick={() => props.setAccountDialog(true)}>
          <ListItemIcon>{auth && auth.currentUser && auth.currentUser.email ? <AccountCircleIcon /> : <LoginIcon />}</ListItemIcon>
          <ListItemText primary={auth && auth.currentUser && auth.currentUser.email ? "Käyttäjätili" : "Kirjaudu"} />
        </ListItem>

        <ListItem button key="Tietoja sovelluksesta" onClick={() => props.setInfoDialog(true)}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Tietoja sovelluksesta" />
        </ListItem>

        <ListItem button key="Anna palautetta" onClick={() => openFeedback()}>
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary="Anna palautetta" />
        </ListItem>

      </List>
    </div>
    
  );
};

export default CompetitionMore;
