import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { EVENT_QUERY, getEntryList, getResultList, getStartList } from "../GetCompetitionEvent";
import { EventList } from "./EventList";
import EventProgramItems from "./EventProgramItems";

export interface CompetitionEventProps {
  cid: string;
  competitionData: CompetitionData | undefined;
  loading: boolean;
  lists: Lists | undefined;
  userSettings: UserSettings | undefined;
  currentHeats: CurrentHeat[];
  navigationCID: string;
  liveHeatData: any;
}

function convertSwimifyListsToEventLists(cid: string, competitionData: CompetitionData | undefined, data: any, eventParam: string) {
  let listsObject: Lists = {};
  if (competitionData === undefined) return listsObject;

  let swimifyEvent = data?.events[0];

  if (competitionData?.sessions && Object.keys(competitionData.sessions).length > 0) {
    const sessions: Sessions = competitionData.sessions;
    // loop through sessions and programItems to find the event
    Object.entries(sessions).forEach(([sid, session]) => {
      session.programItems &&
        session.programItems.forEach((programItem: ProgramItem) => {
          if (programItem.event === "" + eventParam) {
            // entries for this event
            let entryList = getEntryList(swimifyEvent, programItem);
            listsObject[entryList.listId] = entryList;
            // starts for this event
            let startList = getStartList(swimifyEvent, programItem);
            listsObject[startList.listId] = startList;

            // results for this event
            let resultList = getResultList(swimifyEvent, programItem);
            listsObject[resultList.listId] = resultList;
          }
        });
    });
  }
  return listsObject;
}

export const CompetitionEvent = (props: CompetitionEventProps) => {
  const [swimifyLists, setSwimifyLists] = useState<Lists | undefined>(undefined);

  const { eventParam } = useParams();
  if (eventParam === undefined) return null;
  const event = parseInt(eventParam);
  const cid = props.cid;

  const skipEvent = (eventParam === undefined || props.cid.length === 4 ? true : false);
  const { data, loading, error, refetch } = useQuery(EVENT_QUERY, {
    variables: { cid, event },
    skip: skipEvent,
    pollInterval: 1 * 60 * 1000, // every 1 minute
  });

  useEffect(() => {
    if (data) {
      setSwimifyLists(convertSwimifyListsToEventLists(cid, props.competitionData, data, eventParam));
    }
  }, [data, props.competitionData]);

  useEffect(() => {
    if (eventParam && refetch) {
      refetch();
    }
  }, [props.currentHeats]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <EventProgramItems
              currentHeats={props.currentHeats}
              cid={props.cid}
              competitionData={props.competitionData}
              loading={props.loading || loading}
              lists={props.cid.length === 4 ? props.lists : swimifyLists}
              userSettings={props.userSettings}
              navigationCID={props.navigationCID}
            />
          }
        />
        <Route
          path=":selectedListType/:selectedSid/:selectedProgramItemIndexString"
          element={
            <>
              <EventProgramItems
                currentHeats={props.currentHeats}
                cid={props.cid}
                competitionData={props.competitionData}
                loading={props.loading || loading}
                lists={props.cid.length === 4 ? props.lists : swimifyLists}
                userSettings={props.userSettings}
                navigationCID={props.navigationCID}
              />
              <EventList
                currentHeats={props.currentHeats}
                cid={props.cid}
                userSettings={props.userSettings}
                loading={props.loading || loading}
                competitionData={props.competitionData}
                lists={props.cid.length === 4 ? props.lists : swimifyLists}
                liveHeatData={props.liveHeatData}
              />
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default CompetitionEvent;