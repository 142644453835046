import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { FlagIcon, isFavoriteClub } from "../../Helpers";
import { isFavoriteSwimmer } from "../../User/FavoriteHelpers";

export interface EntryProps {
  entry: EntryListItem;
  programItem: ProgramItem;
  event: string;
  userSettings?: UserSettings;
}

export function Entry(props: EntryProps) {
  const theme = useTheme();
  const { entry, programItem, userSettings } = props;

  if (!entry || !programItem) return null;

  const isFavoriteSwimmerEntry = userSettings && isFavoriteSwimmer(entry, userSettings.favoriteSwimmers);
  const isFavoriteClubEntry = userSettings && userSettings.favoriteClubs && isFavoriteClub(entry.club, userSettings.favoriteClubs);

  const avatar = (
    <ListItemAvatar sx={{ minWidth: "2.75rem" }}>
      <Avatar sx={{ bgcolor: "transparent", color: theme.palette.primary.main, maxHeight: "2.25rem", maxWidth: "2.25rem", fontSize: "1.2rem" }}>
        {entry.ranking || entry.number}
      </Avatar>
    </ListItemAvatar>
  );

  return (
    <ListItem
      disablePadding
      disableGutters
      dense
      secondaryAction={
        <>
          <IconButton size="medium" tabIndex={-1} component={Link} to={"../../swimmer/" + entry.id + "/event/" + props.event}>
            <ArrowRightIcon />
          </IconButton>
        </>
      }
    >
      <ListItemButton
        component={Link}
        to={"../../swimmer/" + entry.id + "/event/" + props.event}
        sx={{
          paddingLeft: theme.spacing(1),
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: theme.spacing(1),
        }}
      >
        {avatar}
        <ListItemText
          primaryTypographyProps={{ style: { fontSize: "0.9rem" } }}
          secondaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
          primary={<span>{isFavoriteSwimmerEntry ? <span style={{ color: theme.palette.secondary.main }}>{entry.id}</span> : <span>{entry.id}</span>}</span>}
          secondary={
            <span>
              {isFavoriteClubEntry ? (
                <span>
                  <span>{entry.nationality && <FlagIcon nationality={entry.nationality} />}</span>
                  <span style={{ color: theme.palette.secondary.main }}>{entry.club}</span>
                </span>
              ) : (
                <span>
                  {entry.nationality && <FlagIcon nationality={entry.nationality} />}
                  {entry.club}
                </span>
              )}
            </span>
          }
        />
        <div>
          <Typography align="right" color={theme.palette.text.secondary} style={{ fontSize: "0.8rem" }}>
            Ilm. aika:
          </Typography>
          <Typography align="right" color={theme.palette.text.primary} style={{ fontSize: "0.8rem" }}>
            {entry.entryTime !== undefined && entry.entryTime !== "" ? entry.entryTime : "-"}
          </Typography>
        </div>
      </ListItemButton>
    </ListItem>
  );
}
