import { EventResultList } from "./EventResultList";
import { EventStartList } from "./EventStartList";
import { EventEntryList } from "./EventEntryList";
import { useNavigate, useParams } from "react-router-dom";
import { getListIds } from "../../Helpers";
import { EventInfo } from "./EventInfo";

interface EventListProps {
  cid: string;
  userSettings: UserSettings | undefined;
  currentHeats: CurrentHeat[];
  competitionData: CompetitionData | undefined;
  liveHeatData: any;
  lists: Lists | undefined;
  loading: boolean | undefined;
}

function openLiveTiming(link: string | undefined) {
  if (link === undefined) return;
  if (link.startsWith("http")) {
    window.open(link);
  } else {
    window.open("https://www.livetiming.fi/" + link);
  }
}

export function EventList(props: EventListProps) {
  let navigate = useNavigate();

  const { selectedSid } = useParams();
  const { selectedProgramItemIndexString } = useParams();
  const { selectedListType } = useParams();

  const selectedProgramItemIndex = parseInt(selectedProgramItemIndexString || "-1"); //TODO
  const selectedSession = (props.competitionData && selectedSid && props.competitionData.sessions && props.competitionData.sessions[selectedSid]) || undefined;
  const selectedListProgramItem = selectedSession?.programItems ? selectedSession.programItems[selectedProgramItemIndex] : undefined;

  let selectedListId = undefined;
  if (selectedListProgramItem === undefined) return null;

  if (selectedListType === "results") {
    selectedListId = getListIds(selectedListProgramItem).resultListId;
  } else if (selectedListType === "starts") {
    selectedListId = getListIds(selectedListProgramItem).startListId;
  } else if (selectedListType === "entries") {
    selectedListId = getListIds(selectedListProgramItem).entryListId;
  }

  if (selectedListId === undefined) return null;
  if (props.lists === undefined) return null;
  const selectedList = props.lists && props.lists[selectedListId];

  function itemSelected(cid: string, swimmerid: string, event: string) {
    navigate("../../swimmer/" + encodeURIComponent(swimmerid) + "/event/" + event);
  }

  return (
    <>
      {selectedListId && selectedListProgramItem && selectedListProgramItem.swimStyle && props.competitionData?.pool && (
        <EventInfo swimStyle={selectedListProgramItem.swimStyle} defaultExpanded={false} pool={props.competitionData?.pool} />
      )}

      {selectedListType === "results" && (
        <EventResultList
          selectedList={selectedList as ResultList}
          cid={props.cid}
          userSettings={props.userSettings}
          programItem={selectedListProgramItem}
          selectedListProgramItem={selectedListProgramItem}
          openLiveTiming={openLiveTiming}
          itemSelected={itemSelected}
          loading={props.loading}
        />
      )}

      {selectedListType === "starts" && (
        <EventStartList
          selectedList={selectedList as StartList}
          cid={props.cid}
          userSettings={props.userSettings}
          itemSelected={itemSelected}
          programItem={selectedListProgramItem}
          currentHeats={props.currentHeats}
          selectedListProgramItem={selectedListProgramItem}
          liveHeatData={props.liveHeatData}
          openLiveTiming={openLiveTiming}
          loading={props.loading}
        />
      )}

      {selectedListType === "entries" && (
        <EventEntryList
          selectedList={selectedList as EntryList}
          cid={props.cid}
          userSettings={props.userSettings}
          itemSelected={itemSelected}
          programItem={selectedListProgramItem}
          selectedListProgramItem={selectedListProgramItem}
          openLiveTiming={openLiveTiming}
          loading={props.loading}
        />
      )}

      <br />
    </>
  );
}
