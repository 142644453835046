// This file is used to resolve the list items from the list object.


export function resolveListItems(list: List) {
  if(!list) return {};
  let listItems: ListItems = {};
  if ((list.listType === "resultList" || list.listType === "resultListJunior") && (list as ResultList).results !== undefined) {
    let results = (list as ResultList).results;
    if(results === undefined) return {};
    for (const listItem of results) {
      let resultListItem = {
        id: listItem.id,
        event: list.event,
        listId: list.listId,
        listType: list.listType,
        listItemType: "result",
        sid: list.sid,
        programItemIndex: list.programItemIndex,
        lastName: listItem.lastName,
        firstNames: listItem.firstNames,
        name: listItem.name,
        club: listItem.club,
        year: listItem.year,
        result: { ...listItem, ...{ sid: list.sid, programItemIndex: list.programItemIndex } },
      } as ListItem;
      if (listItem.nationality) {
        resultListItem["nationality"] = listItem.nationality;
      }
      listItems[list.listId + "_" + listItem.id] = resultListItem;
    }
  } else if (list.listType === "startList" && (list as StartList).starts !== undefined) {
    let starts = (list as StartList).starts;
    if(starts === undefined) return {};
    for (const listItem of starts) {
      let startListItem = {
        id: listItem.id,
        event: list.event,
        listId: list.listId,
        listType: list.listType,
        listItemType: "start",
        sid: list.sid,
        programItemIndex: list.programItemIndex,
        lastName: listItem.lastName,
        firstNames: listItem.firstNames,
        name: listItem.name,
        club: listItem.club,
        year: listItem.year,
        heat_rank: listItem.heat_rank,
        heatId: listItem.heatId,
        heatTimeText: listItem.heatTimeText,
        resultTime: listItem.resultTime,
        start: { ...listItem, ...{ sid: list.sid, programItemIndex: list.programItemIndex } },
      } as ListItem;
      if (listItem.nationality) {
        startListItem["nationality"] = listItem.nationality;
      }
      listItems[list.listId + "_" + listItem.id] = startListItem;
    }
  } else if ((list.listType === "entryList" || list.listType === "entryListJunior") && (list as EntryList).entries !== undefined) {
    let entries = (list as EntryList).entries;
    if(entries === undefined) return {};
    for (const listItem of entries) {
      let entryListItem = {
        id: listItem.id,
        event: list.event,
        listId: list.listId,
        listType: list.listType,
        listItemType: "entry",
        sid: list.sid,
        programItemIndex: list.programItemIndex,
        lastName: listItem.lastName,
        firstNames: listItem.firstNames,
        name: listItem.name,
        club: listItem.club,
        year: listItem.year,
        entry: { ...listItem, ...{ sid: list.sid, programItemIndex: list.programItemIndex } },
      } as ListItem;
      if (listItem.nationality) {
        entryListItem["nationality"] = listItem.nationality;
      }
      listItems[list.listId + "_" + listItem.id] = entryListItem;
    }
  }
  return listItems;
}
