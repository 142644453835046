import { gql, useQuery } from "@apollo/client";
import Competition from "./Competition";
import { User } from "firebase/auth";
import { useParams } from "react-router-dom";

interface CompetitionSEOProps {
    recentCompetitions: any;
    futureCompetitions: any;
    userSettings: UserSettings | undefined;
    authUser: User | undefined;
    setInfoDialog: (infoDialogOpen: boolean) => void;
    setAccountDialog: (accountDialogOpen: boolean) => void;
    addRecentCompetition: (competitionData: CompetitionData) => void;
  }

function CompetitionSEO(props : CompetitionSEOProps) : JSX.Element {
    const { navigationCID } = useParams();

    const { data } = useQuery(COMPETITIONID_QUERY, {
      variables: { navigationCID: navigationCID },
      skip: navigationCID === undefined || navigationCID.length === 4,
    });

    let cid;
    if(navigationCID && navigationCID.length === 4) {
      cid = navigationCID;
    } else if(navigationCID && navigationCID.length > 4) {
      cid = data?.competitions[0]?.id;
    }

    if(cid !== undefined && navigationCID !== undefined) {
        return <Competition cid={cid} navigationCID={navigationCID} {...props}/>
    } else {
        return <div/>;
    }
}

export default CompetitionSEO;


const COMPETITIONID_QUERY = gql`
  query competitionIdQuery_WL($navigationCID: String) {
    competitions(where: { seo_text: { _eq: $navigationCID } }) {
      id
      __typename
    }
  }
`;