import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Result } from "./EventResult";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { CardActionArea, CardHeader, Collapse, IconButton, IconButtonProps, styled } from "@mui/material";
import { useState } from "react";


interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: { theme: any, expand: boolean }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


interface EventResultListProps {
  selectedList: ResultList | undefined;
  itemSelected: (cid: string, swimmerid: string, event: string) => void;
  cid: string;
  userSettings: UserSettings | undefined;
  programItem: ProgramItem;
  selectedListProgramItem: ProgramItem;
  openLiveTiming: (link: string | undefined) => void;
  loading: boolean | undefined;
}

export function EventResultList(props: EventResultListProps) {
  const [collapsed, setCollapsed] = useState<{ [key: number]: boolean }>({});
  const selectedList = props.selectedList;
  const programItem = props.programItem;
  const theme = useTheme();

  if (selectedList?.summaries === undefined) {
    return (
      <Typography variant="subtitle2" style={{ padding: theme.spacing(2) }}>
        {props.loading ? "Ladataan tulostietoja..." : "Ei tulostietoja."}
      </Typography>
    );
  }
  if (Object.keys(selectedList.summaries).length === 0) {
    return (
      <Typography variant="subtitle2" style={{ padding: theme.spacing(2) }}>
        Ei tulostietoja.
      </Typography>
    );
  }
  if (Object.keys(selectedList.summaries).length === 1) {
    let onlyList = selectedList.summaries[Object.keys(selectedList.summaries)[0]];
    if (onlyList === undefined) {
      return (
        <Typography variant="subtitle2" style={{ padding: theme.spacing(2) }}>
          Ei tulostietoja.
        </Typography>
      );
    }
  }

  function isExpanded(index: number) {
    return collapsed[index] === undefined || !collapsed[index];
  }

  function setExpanded(index: number, expanded: boolean) {
    if(expanded) {
      setCollapsed({ ...collapsed, [index]: true })
    } else {
      setCollapsed({ ...collapsed, [index]: false })
    }
  }

  return (
    <div style={{ marginTop: theme.spacing(1) }}>
      {Object.keys(selectedList.summaries).map((summaryName, index) => (
        <Card
          key={"accordion" + summaryName}
          elevation={0}
          square
          sx={{
            margin: theme.spacing(1),
            "& .MuiCardContent-root:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <CardActionArea
            onClick={() => setExpanded(index, isExpanded(index))}
          >
          <CardHeader
            sx={{
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              padding: theme.spacing(0.5),
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            }}
          
            title={
            <Typography variant="subtitle2" style={{ fontSize: "0.9rem" }}>
              {summaryName === "Total" ? "Total" : summaryName}
            </Typography>
            }

            action={
              <span>
              {selectedList?.summaries &&
              <Typography variant="subtitle2" display="inline">
                {selectedList.summaries[summaryName] && " (" + selectedList.summaries[summaryName].length + ")"}
                </Typography>
              }
                <ExpandMore tabIndex={-1} expand={!collapsed[index]} 
                  component="span"
                  >
                  <ExpandMoreIcon sx={{
                  color: theme.palette.primary.contrastText
                }}/></ExpandMore>
              </span>
            }
            />
            </CardActionArea>
          <Collapse in={isExpanded(index)}>
            <Card variant={"outlined"} square>
              <CardContent sx={{ padding: theme.spacing(0) }}>
                <List dense disablePadding>
                  {selectedList.summaries &&
                    selectedList.summaries[summaryName] &&
                    selectedList.summaries[summaryName].map((result, index, array) => (
                      <div key={"summary" + summaryName + index}>
                        <Result
                          event={programItem.event}
                          programItem={props.selectedListProgramItem}
                          result={result}
                          userSettings={props.userSettings}

                          //entry={getEntry(props.lists, programItem.event, result.id)}
                        />
                        {index !== array.length - 1 && <Divider />}
                      </div>
                    ))}
                </List>
              </CardContent>
            </Card>
          </Collapse>
        </Card>
      ))}

      {selectedList?.liveTimingUrl && selectedList.liveTimingUrl.length > 0 && (
        <Button style={{ margin: theme.spacing(2) }} variant="outlined" color="primary" onClick={() => props.openLiveTiming(selectedList.liveTimingUrl)}>
          Avaa tuloslista LiveTimingissa
        </Button>
      )}
    </div>
  );
}

/*

    <div>
      {selectedList?.summaries === undefined || Object.keys(selectedList.summaries).length === 0 &&
        <Typography variant="body2" style={{ padding: theme.spacing(2) }}>
          Ei tulostietoja.
        </Typography>
      }
      {selectedList?.results !== undefined &&
        selectedList.results.map((result, index, array) => (
          <div key={"resultloop_" + index + "_" + result.id}>
            {(index === 0 || (array[index - 1] !== undefined && array[index].series !== array[index - 1].series)) && (
              <ListItem
              disableGutters
              disablePadding
              divider={true}
            >
                <Grid container alignContent={"center"} alignItems={"center"} justifyContent={"space-between"}>
                  <Grid item>
                  <Typography variant="subtitle2" style={{ fontSize: "0.9rem", paddingTop: theme.spacing(2), paddingLeft: theme.spacing(2) }}>
                      {getSeriesName(array[index].series, programItem.eventName)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography align="right" variant="subtitle2" style={{ fontSize: "0.9rem", paddingTop: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                      Tulokset
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            )}
            <div onClick={() => props.itemSelected(props.cid, result.id, programItem.event)}>
              <Result
                event={programItem.event}
                programItem={props.selectedListProgramItem}
                result={result}
                userSettings={props.userSettings}
                
                //entry={getEntry(props.lists, programItem.event, result.id)} 
                />
            </div>
          </div>
        ))}

        */
