import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, CardActionArea, CardContent, CardHeader, Collapse, Divider, IconButton, IconButtonProps, List } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import { useState } from "react";
import { Entry } from "./EventEntry";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: { theme: any; expand: boolean }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface EventEntryListProps {
  selectedList: EntryList | undefined;
  itemSelected: (cid: string, swimmerid: string, event: string) => void;
  cid: string;
  userSettings: UserSettings | undefined;
  programItem: ProgramItem;
  selectedListProgramItem: ProgramItem;
  openLiveTiming: (link: string | undefined) => void;
  loading: boolean | undefined;
}

export function EventEntryList(props: EventEntryListProps) {
  const [collapsed, setCollapsed] = useState<{ [key: number]: boolean }>({});
  const selectedList = props.selectedList;
  const programItem = props.programItem;
  const theme = useTheme();
  const openLiveTiming = props.openLiveTiming;

  if (selectedList?.entryRanks === undefined) {
    return (
      <Typography variant="subtitle2" style={{ padding: theme.spacing(2) }}>
        {props.loading ? "Ladataan osallistujatietoja..." : "Ei osallistujatietoja."}
      </Typography>
    );
  }
  if (Object.keys(selectedList.entryRanks).length === 0) {
    return (
      <Typography variant="subtitle2" style={{ padding: theme.spacing(2) }}>
        Ei osallistujatietoja.
      </Typography>
    );
  }

  function isExpanded(index: number) {
    return collapsed[index] === undefined || !collapsed[index];
  }

  function setExpanded(index: number, expanded: boolean) {
    if (expanded) {
      setCollapsed({ ...collapsed, [index]: true });
    } else {
      setCollapsed({ ...collapsed, [index]: false });
    }
  }

  return (
    <div style={{ marginTop: theme.spacing(1) }}>
      {Object.keys(selectedList?.entryRanks).map((entryRankName, index) => (
        <Card
          key={"accordion" + entryRankName}
          square
          elevation={0}
          sx={{
            margin: theme.spacing(1),
            "& .MuiCardContent-root:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <CardActionArea
            onClick={() => setExpanded(index, isExpanded(index))}
          >
          <CardHeader
            sx={{
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              padding: theme.spacing(1),
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            }}
            title={
              <Typography variant="subtitle2" style={{ fontSize: "0.9rem" }}>
                {entryRankName === "Total" ? "Total" : entryRankName}
              </Typography>
            }
            action={
              <span>
                {selectedList?.entryRanks && (
                  <Typography variant="subtitle2" display="inline">
                    {selectedList.entryRanks[entryRankName] && " (" + selectedList.entryRanks[entryRankName].length + ")"}
                  </Typography>
                )}
                <ExpandMore expand={!collapsed[index]} tabIndex={-1} component="span">
                  <ExpandMoreIcon
                    sx={{
                      color: theme.palette.primary.contrastText,
                    }}
                  />
                </ExpandMore>
              </span>
            }
          />
          </CardActionArea>
          <Collapse in={isExpanded(index)}>
            <Card variant={"outlined"} square>
              <CardContent sx={{ padding: theme.spacing(0) }}>
                <List dense disablePadding>
                  {selectedList.entryRanks &&
                    selectedList.entryRanks[entryRankName] &&
                    selectedList.entryRanks[entryRankName].map((entry, index, array) => (
                      <div key={"summary" + entryRankName + index}>
                        <Entry
                          event={programItem.event}
                          programItem={props.selectedListProgramItem}
                          entry={entry}
                          userSettings={props.userSettings}

                          //entry={getEntry(props.lists, programItem.event, result.id)}
                        />
                        {index !== array.length - 1 && <Divider />}
                      </div>
                    ))}
                  {selectedList?.entryRanks && selectedList?.entryRanks[entryRankName]?.length === 0 && (
                    <Typography variant="body2" style={{ padding: theme.spacing(1) }}>
                      Ei osallistujia.
                    </Typography>
                  )}
                </List>
              </CardContent>
            </Card>
          </Collapse>
        </Card>
      ))}

      {selectedList?.liveTimingUrl && selectedList.liveTimingUrl.length > 0 && (
        <Button style={{ margin: theme.spacing(2) }} variant="outlined" color="primary" onClick={() => openLiveTiming(selectedList.liveTimingUrl)}>
          Avaa osallistujalista LiveTimingissa
        </Button>
      )}
    </div>
  );
}
