import { initializeApp } from "firebase/app";
import { getId, getInstallations } from "firebase/installations";
import { getFirestore, connectFirestoreEmulator, doc, setDoc } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { enableIndexedDbPersistence } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { getEnvPrefix } from "./Helpers";
import { EmailAuthProvider, linkWithCredential } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDsEwc5cMmc-UEAA_aOa0-Ew2JM0UuGXGo",
  //authDomain: "uinnit-app.firebaseapp.com",
  authDomain: "uinnit.app",
  databaseURL: "https://uinnit-app.firebaseio.com",
  projectId: "uinnit-app",
  storageBucket: "uinnit-app.appspot.com",
  messagingSenderId: "70547254717",
  appId: "1:70547254717:web:4e8669287b62c346e2f6d4",
  measurementId: "G-S8YX6ZM6NQ",
};

const vapid = "BGkBRhsAPGplVJvEK9g3S18M_WZw5IoSvhvXeFD9Hju3YSiQOUOlvaCneRj-oxFw83K-UyiaFrbaHPIAbrcH07E";

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore();
const messaging = getMessaging();

if (process.env.NODE_ENV === "development") {
  //db.settings({ host: 'localhost:8080',  ssl: false });
  connectFirestoreEmulator(firestore, "localhost", 8080);
}

enableIndexedDbPersistence(firestore).catch((err) => {
  console.log(err);
});

export const auth = getAuth();
if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
}

export function getFirebaseApp() {
  return firebaseApp;
}

export function getInstallationId() {
  let installationId = getId(getInstallations());
  return installationId;  
}

// ------- Notifications / messaging ----

export const getNotificationToken = async () => {
  return getToken(messaging, { vapidKey: vapid })
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });


// ----- login & register ----

export const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    return err;
  }
};

export const sendPasswordReset = async (email: string) => {
  try {
    const res = await sendPasswordResetEmail(auth, email);
    console.debug("Response:");
    console.debug(res);
  } catch (err: any) {
    console.error(err);
    return err;
  }
};

export const logoutUser = async (auth : any) => {
  console.debug("Logging out user...");
  try { 
    await signOut(auth);
  } catch(err: any) {
    console.error(err);
    return err;
  }
};


export const linkEmailAndPassword = async (user: any, email: string, password: string) => {
  try {
    const credential = EmailAuthProvider.credential(email, password);
    const auth = getAuth();
    if (auth && auth.currentUser) {
      const res = await linkWithCredential(auth.currentUser, credential);
      const user = res.user;
      console.log("Account linking success", user);
      const userId = user.uid;
      const userDoc = doc(getFirestore(), getEnvPrefix() + "users", userId);
      await setDoc(userDoc, { email: email }, { merge: true });
    }
  } catch (err: any) {
    return err;
  }
};
