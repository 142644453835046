import { Card, IconButton, useTheme } from "@mui/material";
import { CardContent } from "@mui/material";
import { CardActions } from "@mui/material";
import { getStrokeName, openTempusEvent } from "../../Helpers";
import { getLimits } from "../../LimitFunctions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import Grid from "@mui/material/Grid";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface EventInfoProps {
  swimStyle: SwimStyle;
  defaultExpanded: boolean;
  pool?: string;
  year?: string;
}

export function EventInfo(props: EventInfoProps) {
  const theme = useTheme();
  var swimClass;
  if (!props.swimStyle) return null;
  if (props.swimStyle.sex === "MALE") {
    swimClass = "Miehet";
  } else if (props.swimStyle.sex === "FEMALE") {
    swimClass = "Naiset";
  } else if (props.swimStyle.sex === "MIXED") {
    swimClass = "Seka";
  }
  var generalLimits = getLimits(props.swimStyle, props.pool, props.year);

  let isPara = props.swimStyle.isPara;
  let isMasters = props.swimStyle.isMasters;

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      defaultExpanded={props.defaultExpanded}
      sx={{
        "&:before": {
          display: "none",
        },
        margin: theme.spacing(1),
        "& .MuiAccordionSummary-root": {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(0.3),
        },
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      }}
    >
      <AccordionSummary
        sx={{ marginLeft: 0, marginRight: 0, //THIS marginLeft marginRight (zeros) SEEM IMPORTANT for proper scrolling
          borderBottom: "2px solid " + (theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main),
          "& .MuiAccordionSummary-content": {
            margin: 0, 
          },
        }} 
        expandIcon={
        <IconButton tabIndex={-1} size="small"><ExpandMoreIcon sx={{
          color: theme.palette.text.secondary,

        }} /></IconButton>}
      >
        <Typography variant="h6">Lajin tiedot</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ 
        boxShadow: "none",
        "& .MuiCard-root": {
          border: 0,
        },
         }} >
        <Card variant={"outlined"}>
          <CardContent sx={{ paddingTop: theme.spacing(1), paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1)}}>
            <Grid container>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "0.7rem", color: theme.palette.text.secondary }} variant="body2">
                  matka
                </Typography>
                <Typography style={{ fontSize: "0.8rem" }}>{props.swimStyle.distance}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "0.7rem", color: theme.palette.text.secondary }} variant="body2">
                  tyyli
                </Typography>
                <Typography style={{ fontSize: "0.8rem" }}>{getStrokeName(props.swimStyle?.stroke)}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "0.7rem", color: theme.palette.text.secondary }} variant="body2">
                  luokka
                </Typography>
                <Typography style={{ fontSize: "0.8rem" }}>{swimClass} {isPara ? " Para" : ""} {isMasters ? " Masters" : ""}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: "0.7rem", color: theme.palette.text.secondary }} variant="body2">
                  allas
                </Typography>
                <Typography style={{ fontSize: "0.8rem", color: theme.palette.text.primary }}>{props.pool ? props.pool : "?"}</Typography>
              </Grid>
            </Grid>

            {props.swimStyle.relayCount && props.swimStyle.relayCount > 0 && (
              <Typography style={{ fontSize: "0.8rem" }}>{"Viesti (" + props.swimStyle.relayCount + " uimaria)"}</Typography>
            )}

            {!props.swimStyle.isPara && !props.swimStyle.isMasters && !props.swimStyle.relayCount && generalLimits && generalLimits.length > 0 && (
              <div>
                <Typography variant="subtitle2">
                  <br />
                  Aikarajat 2024
                </Typography>
                {generalLimits &&
                  generalLimits.map((limit, index) => (
                    <Typography key={"limit " + index} style={{ fontSize: "0.8rem" }}>
                      <span style={{ color: theme.palette.text.secondary }}>{limit.limitName}:</span> {limit.limitValue}
                    </Typography>
                  ))}
                  
              </div>
            )}
          </CardContent>
          <CardActions>
            <Button
              variant="outlined"
              sx={{ marginLeft: 0, marginTop: theme.spacing(1) }}
              color="primary"
              onClick={() => openTempusEvent(props.swimStyle, undefined, undefined)}
            >
              Avaa laji Tempus Openissa
            </Button>
          </CardActions>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
}
