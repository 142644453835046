import Divider from "@mui/material/Divider";
import ProgramItem from "./ProgramItem";
import Sparkles from "@chad.b.morrow/sparkles";

interface ProgramProps {
  sid: string;
  cid: string;
  competitionData: CompetitionData;
  userSettings: UserSettings | undefined;
  clubPairs: ClubNamePairs | undefined;
  lists: Lists | undefined;
  swimmers: Swimmers | undefined;
  currentHeats: CurrentHeat[];
}

const Program = (props: ProgramProps) => {
  if (props.competitionData === undefined) return null;
  if (props.competitionData.sessions === undefined) return null;
  const session = props.competitionData.sessions[props.sid];
  if (session === undefined) return null;
  if (session.programItems === undefined) return null;

  function filterProgramItemLists(lists: Lists | undefined, programItemIndex: number) {
    let listsForProgramItem: Lists = {};
    for (var listId in lists) {
      if (lists[listId].programItemIndex + "" === programItemIndex + "") {
        listsForProgramItem[listId] = lists[listId];
      }
    }
    return listsForProgramItem;
  }

  return (
    <div>
      {Object.values(session.programItems).map((item: ProgramItem) => (
        <div key={"programitem" + "_" + item.sid + "_" + item.event + "_" + item.programItemIndex}>
          {item.programItemName?.toUpperCase().indexOf("MYSTE") > -1 ? (
            <Sparkles colors="rainbow" style={{ width: "100%" }}>
              <ProgramItem
                competitionData={props.competitionData}
                userSettings={props.userSettings}
                clubPairs={props.clubPairs}
                programItem={item}
                lists={props.lists && filterProgramItemLists(props.lists, item.programItemIndex)}
                //lists={props.lists}
                cid={props.cid}
                sid={props.sid}
                swimmers={props.swimmers}
                currentHeats={props.currentHeats}
              />
            </Sparkles>
          ) : (
            <ProgramItem
              competitionData={props.competitionData}
              userSettings={props.userSettings}
              clubPairs={props.clubPairs}
              programItem={item}
              lists={props.lists && filterProgramItemLists(props.lists, item.programItemIndex)}
              //lists={props.lists}
              cid={props.cid}
              sid={props.sid}
              swimmers={props.swimmers}
              currentHeats={props.currentHeats}
            />
          )}
          <Divider sx={{ borderBottomWidth: "0.5rem"}} />
        </div>
      ))}
    </div>
  );
};

export default Program;
