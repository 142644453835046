import { Theme, useMediaQuery } from "@mui/material";

export const NAV_RAIL_WIDTH = 6;
export const NAV_DRAWER_WIDTH = 12;
export const BOTTOMNAV_HEIGHT = 4;

export function getNavigationVariant(theme: Theme): "bottom" | "rail" | "drawer" {
  const navigationQueries = {
    isBottom: useMediaQuery(theme.breakpoints.down("md")),
    isRail: useMediaQuery(theme.breakpoints.between("md", "lg")),
    isDrawer: useMediaQuery(theme.breakpoints.up("lg")),
  };

  const navigationVariant = (navigationQueries.isBottom ? "bottom" : navigationQueries.isRail ? "rail" : "drawer") as "bottom" | "rail" | "drawer";

  return navigationVariant;
}
