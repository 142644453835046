import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Program from "./Program";
import "moment/locale/fi";
import { getAPIURL } from "../../Helpers";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Link, useParams } from "react-router-dom";
import UinnitAppTabs from "../../UinnitAppTabs";
import UinnitAppBar from "../../UinnitAppBar";
import Tab from "@mui/material/Tab";
import LinearProgress from "@mui/material/LinearProgress";
import { SessionCard } from "../SessionCard";
import { Box, Divider } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
    {value === index && <div>{children}</div>}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

interface SessionsProps {
  cid: string;
  competitionData?: CompetitionData;
  lists?: Lists;
  clubPairs?: ClubNamePairs;
  swimmers?: Swimmers;
  userSettings?: UserSettings;
  loading: boolean;
  currentHeats: CurrentHeat[];
  navigationCID: string;
}

const Sessions: React.FC<SessionsProps> = ({ cid, competitionData, lists, clubPairs, swimmers, userSettings, loading, currentHeats, navigationCID }) => {
  const { sid } = useParams();
  const theme = useTheme();

  useEffect(() => {
    if (cid && sid) {
      refreshProgram(competitionData, sid);
    }
  }, [sid]);

  const refreshProgram = (competitionData: CompetitionData | undefined, sid: string) => {
    if (competitionData && competitionData.sessions && competitionData.cid.length === 4) {
      console.debug("Refreshing program for cid " + cid + " sid " + sid);
      fetch(getAPIURL() + "/refreshProgram?cid=" + cid + "&sid=" + sid).catch((error) => console.error(error));
    }
  };

  let tabs = sid && (
    <UinnitAppTabs value={sid} variant="scrollable">
      {competitionData &&
        competitionData.sessions &&
        Object.values(competitionData.sessions).map(
          (session) =>
            competitionData &&
            session.sid && (
              <Tab
                key={competitionData.cid + "_tab_" + session.sid}
                value={session.sid}
                component={Link}
                to={"../session/" + session.sid}
                label={"Jakso " + session.sid}
              />
            )
        )}
    </UinnitAppTabs>
  );

  function filterSessionLists(lists: Lists | undefined, sid: string | number) {
    if (lists === undefined) return {};
    let filteredLists: Lists = {};
    for (var listId in lists) {
      if (lists[listId].sid + "" === sid + "") {
        filteredLists[listId] = lists[listId];
      }
    }
    return filteredLists;
  }

  return (
    <>
      <UinnitAppBar
        navigationCID={navigationCID}
        title={competitionData ? competitionData.competitionName : ""}
        tabs={tabs}
        denseTabs={true}
        currentHeats={currentHeats}
      />
      <div style={{ height: "4px", width: "100%", clear: "both", marginTop: 0, marginBottom: 0 }}>{loading && <LinearProgress />}</div>
      <div>
        {competitionData === undefined || competitionData.sessions === undefined || Object.keys(competitionData.sessions).length === 0 ? (
          <div>{loading ? "" : 
            <Typography variant="subtitle2" sx={{ margin: theme.spacing(2) }}>
            Ei jaksotietoja.
          </Typography>}           
          </div>
        ) : (
          <div>
            {Object.values(competitionData.sessions).map((session, index) => (
              <TabPanel value={index} index={index} key={"session_" + cid + "_events_" + session.sid}>
                {session.sid === sid && (
                  <div>
                    <SessionCard session={session} />
                    <Divider sx={{ borderBottomWidth: "0.5rem" }} />
                    {session.programItems === undefined ? (
                      <Box>
                      <Typography variant="subtitle2" sx={{ margin: theme.spacing(1) }}>
                        Ei ohjelmatietoja.
                      </Typography>
                      </Box>
                    ) : (
                      <Program
                        competitionData={competitionData}
                        userSettings={userSettings}
                        clubPairs={clubPairs}
                        lists={filterSessionLists(lists, session.sid)}
                        cid={cid}
                        sid={session.sid}
                        swimmers={swimmers}
                        currentHeats={currentHeats}
                      />
                    )}
                  </div>
                )}
              </TabPanel>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Sessions;
