import { CircleFlag } from "react-circle-flags";
import moment from "moment";

export function convertSplits(sub_results: any): Splits {
  let splits: Splits = {};
  if(sub_results === undefined) return splits;
  for (let i = 0; i < sub_results.length; i++) {
    if(sub_results[i].result_value_text !== undefined && sub_results[i].result_value_text.length > 2) {
      splits[sub_results[i].done_at + "m"] = sub_results[i].result_value_text;
    }
  }
  return splits;
}

function getLiveLane(liveHeatData: any, heatId: number | undefined, laneNumber: number | undefined) {
  //console.log("getLiveLane", heatId, laneNumber, liveHeatData);
  if(heatId && laneNumber && liveHeatData && liveHeatData.id === heatId) {
    if (liveHeatData.lanes) {
      const liveLaneData = liveHeatData.lanes.find((lane: any) => lane.number === laneNumber);
      return liveLaneData;
    }
  }
}

export function getStartOrLiveStart(start: StartListItem | undefined, liveHeatData: any) : StartListItem | undefined {
  //console.log("getStartOrLiveStart", start, liveHeatData);
  if(start === undefined) return undefined;
  if(start && start.heatId && liveHeatData && start.heatId === liveHeatData.id) {
    const liveLaneData = getLiveLane(liveHeatData, start.heatId, parseInt(start.track));
    if(liveLaneData) {
      const liveStart = {
        ...start,
        resultTime: liveLaneData.result_text,
        dsq: liveLaneData.dsq,
        dns: liveLaneData.dns,
        dnf: liveLaneData.dnf,
        splits: convertSplits(liveLaneData.sub_results),
        reactionTime: liveLaneData.reaction_time,
        swimmed_distance: liveLaneData.swimmed_distance,
        fina_points: liveLaneData.fina_points,
        wps_points: liveLaneData.wps_points,
        heat_rank: liveLaneData.heat_rank,
        note: liveLaneData.note,
        qualification: liveLaneData.qualification,
        isLive: true,
      };
      return liveStart;
    }
  }
  return start;
}

interface ListIds {
  entryListId: string;
  entryListJuniorId: string;
  startListId: string;
  resultListId: string;
  resultListJuniorId: string;
}

export function getListIds(programItem: ProgramItem) : ListIds {
  let cid = programItem.cid;
  let sid = programItem.sid;
  let event = programItem.event;

  // magic calculations, which listids are used and might be found also in firestore lists
  let LT_startlist_round = getUrlParameter("round", programItem.liveTimingStartList);
  let LT_startlist_tpid = getUrlParameter("tpid", programItem.liveTimingStartList);
  let LT_resultlist_round = getUrlParameter("round", programItem.liveTimingResultList);
  let SW_round = programItem.roundOid;

  // livetiming entrylist: var listId = req.query.cid + "_" + req.query.sid + "_" + req.query.event + "_" + req.query.type + "_" + listType;
  const entryListId = cid + "_" + sid + "_" + event + "_" + "STS" + "_" + "entryList";
  const entryListJuniorId = cid + "_" + sid + "_" + event + "_" + "STJ" + "_" + "entryListJunior";

  const startRound = SW_round ? SW_round : LT_startlist_round ? LT_startlist_round : LT_startlist_tpid ? LT_startlist_tpid : undefined;
  const resultRound = SW_round ? SW_round : LT_resultlist_round ? LT_resultlist_round : undefined;

  // livetiming startlist: var listId = cid + "_" + sid + "_" + event + "_" + r + "_" + "startList"; missä r on joko round tai sitten tpid
  const startListId = cid + "_" + sid + "_" + event + "_" + startRound + "_" + "startList";

  // livetiming resultlist: var listId = req.query.cid + "_" + req.query.sid + "_" + req.query.event + "_" + req.query.round + "_" + listType;
  const resultListId = cid + "_" + sid + "_" + event + "_" + resultRound + "_" + "resultList";
  const resultListJuniorId = cid + "_" + sid + "_" + event + "_" + resultRound + "_" + "resultListJunior";

  return { entryListId, entryListJuniorId, startListId, resultListId, resultListJuniorId };
}

export function getUrlParameter(name: string, url: string | undefined): string | undefined {
  if (url === undefined) return undefined;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(url);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function getAPIURL() {
  if (process.env.NODE_ENV === "development") {
    //return "https://europe-west2-uinnit-app.cloudfunctions.net";
    return "http://localhost:5001/uinnit-app/europe-west2";
  } else {
    return "https://europe-west2-uinnit-app.cloudfunctions.net";
  }
}

export function getEnvPrefix() {
  if (process.env.NODE_ENV === "development") {
    return "DEV-";
  } else {
    return "PROD-";
  }
}

let countries = [
  {
    "Country Name": "Taiwan",
    IOC: "TPE",
    "ISO3166-1-Alpha-2": "TW",
    "ISO3166-1-Alpha-3": "TWN",
  },
  {
    "Country Name": "Afghanistan",
    IOC: "AFG",
    "ISO3166-1-Alpha-2": "AF",
    "ISO3166-1-Alpha-3": "AFG",
  },
  {
    "Country Name": "Albania",
    IOC: "ALB",
    "ISO3166-1-Alpha-2": "AL",
    "ISO3166-1-Alpha-3": "ALB",
  },
  {
    "Country Name": "Algeria",
    IOC: "ALG",
    "ISO3166-1-Alpha-2": "DZ",
    "ISO3166-1-Alpha-3": "DZA",
  },
  {
    "Country Name": "American Samoa",
    IOC: "ASA",
    "ISO3166-1-Alpha-2": "AS",
    "ISO3166-1-Alpha-3": "ASM",
  },
  {
    "Country Name": "Andorra",
    IOC: "AND",
    "ISO3166-1-Alpha-2": "AD",
    "ISO3166-1-Alpha-3": "AND",
  },
  {
    "Country Name": "Angola",
    IOC: "ANG",
    "ISO3166-1-Alpha-2": "AO",
    "ISO3166-1-Alpha-3": "AGO",
  },
  {
    "Country Name": "Anguilla",
    IOC: "AIA",
    "ISO3166-1-Alpha-2": "AI",
    "ISO3166-1-Alpha-3": "AIA",
  },
  {
    "Country Name": "Antarctica",
    IOC: "",
    "ISO3166-1-Alpha-2": "AQ",
    "ISO3166-1-Alpha-3": "ATA",
  },
  {
    "Country Name": "Antigua & Barbuda",
    IOC: "ANT",
    "ISO3166-1-Alpha-2": "AG",
    "ISO3166-1-Alpha-3": "ATG",
  },
  {
    "Country Name": "Argentina",
    IOC: "ARG",
    "ISO3166-1-Alpha-2": "AR",
    "ISO3166-1-Alpha-3": "ARG",
  },
  {
    "Country Name": "Armenia",
    IOC: "ARM",
    "ISO3166-1-Alpha-2": "AM",
    "ISO3166-1-Alpha-3": "ARM",
  },
  {
    "Country Name": "Aruba",
    IOC: "ARU",
    "ISO3166-1-Alpha-2": "AW",
    "ISO3166-1-Alpha-3": "ABW",
  },
  {
    "Country Name": "Australia",
    IOC: "AUS",
    "ISO3166-1-Alpha-2": "AU",
    "ISO3166-1-Alpha-3": "AUS",
  },
  {
    "Country Name": "Austria",
    IOC: "AUT",
    "ISO3166-1-Alpha-2": "AT",
    "ISO3166-1-Alpha-3": "AUT",
  },
  {
    "Country Name": "Azerbaijan",
    IOC: "AZE",
    "ISO3166-1-Alpha-2": "AZ",
    "ISO3166-1-Alpha-3": "AZE",
  },
  {
    "Country Name": "Bahamas",
    IOC: "BAH",
    "ISO3166-1-Alpha-2": "BS",
    "ISO3166-1-Alpha-3": "BHS",
  },
  {
    "Country Name": "Bahrain",
    IOC: "BRN",
    "ISO3166-1-Alpha-2": "BH",
    "ISO3166-1-Alpha-3": "BHR",
  },
  {
    "Country Name": "Bangladesh",
    IOC: "BAN",
    "ISO3166-1-Alpha-2": "BD",
    "ISO3166-1-Alpha-3": "BGD",
  },
  {
    "Country Name": "Barbados",
    IOC: "BAR",
    "ISO3166-1-Alpha-2": "BB",
    "ISO3166-1-Alpha-3": "BRB",
  },
  {
    "Country Name": "Belarus",
    IOC: "BLR",
    "ISO3166-1-Alpha-2": "BY",
    "ISO3166-1-Alpha-3": "BLR",
  },
  {
    "Country Name": "Belgium",
    IOC: "BEL",
    "ISO3166-1-Alpha-2": "BE",
    "ISO3166-1-Alpha-3": "BEL",
  },
  {
    "Country Name": "Belize",
    IOC: "BIZ",
    "ISO3166-1-Alpha-2": "BZ",
    "ISO3166-1-Alpha-3": "BLZ",
  },
  {
    "Country Name": "Benin",
    IOC: "BEN",
    "ISO3166-1-Alpha-2": "BJ",
    "ISO3166-1-Alpha-3": "BEN",
  },
  {
    "Country Name": "Bermuda",
    IOC: "BER",
    "ISO3166-1-Alpha-2": "BM",
    "ISO3166-1-Alpha-3": "BMU",
  },
  {
    "Country Name": "Bhutan",
    IOC: "BHU",
    "ISO3166-1-Alpha-2": "BT",
    "ISO3166-1-Alpha-3": "BTN",
  },
  {
    "Country Name": "Bolivia",
    IOC: "BOL",
    "ISO3166-1-Alpha-2": "BO",
    "ISO3166-1-Alpha-3": "BOL",
  },
  {
    "Country Name": "Caribbean Netherlands",
    IOC: "AHO",
    "ISO3166-1-Alpha-2": "BQ",
    "ISO3166-1-Alpha-3": "BES",
  },
  {
    "Country Name": "Bosnia",
    IOC: "BIH",
    "ISO3166-1-Alpha-2": "BA",
    "ISO3166-1-Alpha-3": "BIH",
  },
  {
    "Country Name": "Botswana",
    IOC: "BOT",
    "ISO3166-1-Alpha-2": "BW",
    "ISO3166-1-Alpha-3": "BWA",
  },
  {
    "Country Name": "Bouvet Island",
    IOC: "",
    "ISO3166-1-Alpha-2": "BV",
    "ISO3166-1-Alpha-3": "BVT",
  },
  {
    "Country Name": "Brazil",
    IOC: "BRA",
    "ISO3166-1-Alpha-2": "BR",
    "ISO3166-1-Alpha-3": "BRA",
  },
  {
    "Country Name": "British Indian Ocean Territory",
    IOC: "",
    "ISO3166-1-Alpha-2": "IO",
    "ISO3166-1-Alpha-3": "IOT",
  },
  {
    "Country Name": "British Virgin Islands",
    IOC: "IVB",
    "ISO3166-1-Alpha-2": "VG",
    "ISO3166-1-Alpha-3": "VGB",
  },
  {
    "Country Name": "Brunei",
    IOC: "BRU",
    "ISO3166-1-Alpha-2": "BN",
    "ISO3166-1-Alpha-3": "BRN",
  },
  {
    "Country Name": "Bulgaria",
    IOC: "BUL",
    "ISO3166-1-Alpha-2": "BG",
    "ISO3166-1-Alpha-3": "BGR",
  },
  {
    "Country Name": "Burkina Faso",
    IOC: "BUR",
    "ISO3166-1-Alpha-2": "BF",
    "ISO3166-1-Alpha-3": "BFA",
  },
  {
    "Country Name": "Burundi",
    IOC: "BDI",
    "ISO3166-1-Alpha-2": "BI",
    "ISO3166-1-Alpha-3": "BDI",
  },
  {
    "Country Name": "Cape Verde",
    IOC: "CPV",
    "ISO3166-1-Alpha-2": "CV",
    "ISO3166-1-Alpha-3": "CPV",
  },
  {
    "Country Name": "Cambodia",
    IOC: "CAM",
    "ISO3166-1-Alpha-2": "KH",
    "ISO3166-1-Alpha-3": "KHM",
  },
  {
    "Country Name": "Cameroon",
    IOC: "CMR",
    "ISO3166-1-Alpha-2": "CM",
    "ISO3166-1-Alpha-3": "CMR",
  },
  {
    "Country Name": "Canada",
    IOC: "CAN",
    "ISO3166-1-Alpha-2": "CA",
    "ISO3166-1-Alpha-3": "CAN",
  },
  {
    "Country Name": "Cayman Islands",
    IOC: "CAY",
    "ISO3166-1-Alpha-2": "KY",
    "ISO3166-1-Alpha-3": "CYM",
  },
  {
    "Country Name": "Central African Republic",
    IOC: "CAF",
    "ISO3166-1-Alpha-2": "CF",
    "ISO3166-1-Alpha-3": "CAF",
  },
  {
    "Country Name": "Chad",
    IOC: "CHA",
    "ISO3166-1-Alpha-2": "TD",
    "ISO3166-1-Alpha-3": "TCD",
  },
  {
    "Country Name": "Chile",
    IOC: "CHI",
    "ISO3166-1-Alpha-2": "CL",
    "ISO3166-1-Alpha-3": "CHL",
  },
  {
    "Country Name": "China",
    IOC: "CHN",
    "ISO3166-1-Alpha-2": "CN",
    "ISO3166-1-Alpha-3": "CHN",
  },
  {
    "Country Name": "Hong Kong",
    IOC: "HKG",
    "ISO3166-1-Alpha-2": "HK",
    "ISO3166-1-Alpha-3": "HKG",
  },
  {
    "Country Name": "Macau",
    IOC: "MAC",
    "ISO3166-1-Alpha-2": "MO",
    "ISO3166-1-Alpha-3": "MAC",
  },
  {
    "Country Name": "Christmas Island",
    IOC: "",
    "ISO3166-1-Alpha-2": "CX",
    "ISO3166-1-Alpha-3": "CXR",
  },
  {
    "Country Name": "Cocos (Keeling) Islands",
    IOC: "",
    "ISO3166-1-Alpha-2": "CC",
    "ISO3166-1-Alpha-3": "CCK",
  },
  {
    "Country Name": "Colombia",
    IOC: "COL",
    "ISO3166-1-Alpha-2": "CO",
    "ISO3166-1-Alpha-3": "COL",
  },
  {
    "Country Name": "Comoros",
    IOC: "COM",
    "ISO3166-1-Alpha-2": "KM",
    "ISO3166-1-Alpha-3": "COM",
  },
  {
    "Country Name": "Congo - Brazzaville",
    IOC: "CGO",
    "ISO3166-1-Alpha-2": "CG",
    "ISO3166-1-Alpha-3": "COG",
  },
  {
    "Country Name": "Cook Islands",
    IOC: "COK",
    "ISO3166-1-Alpha-2": "CK",
    "ISO3166-1-Alpha-3": "COK",
  },
  {
    "Country Name": "Costa Rica",
    IOC: "CRC",
    "ISO3166-1-Alpha-2": "CR",
    "ISO3166-1-Alpha-3": "CRI",
  },
  {
    "Country Name": "Croatia",
    IOC: "CRO",
    "ISO3166-1-Alpha-2": "HR",
    "ISO3166-1-Alpha-3": "HRV",
  },
  {
    "Country Name": "Cuba",
    IOC: "CUB",
    "ISO3166-1-Alpha-2": "CU",
    "ISO3166-1-Alpha-3": "CUB",
  },
  {
    "Country Name": "Curaçao",
    IOC: "",
    "ISO3166-1-Alpha-2": "CW",
    "ISO3166-1-Alpha-3": "CUW",
  },
  {
    "Country Name": "Cyprus",
    IOC: "CYP",
    "ISO3166-1-Alpha-2": "CY",
    "ISO3166-1-Alpha-3": "CYP",
  },
  {
    "Country Name": "Czechia",
    IOC: "CZE",
    "ISO3166-1-Alpha-2": "CZ",
    "ISO3166-1-Alpha-3": "CZE",
  },
  {
    "Country Name": "Côte d’Ivoire",
    IOC: "CIV",
    "ISO3166-1-Alpha-2": "CI",
    "ISO3166-1-Alpha-3": "CIV",
  },
  {
    "Country Name": "North Korea",
    IOC: "PRK",
    "ISO3166-1-Alpha-2": "KP",
    "ISO3166-1-Alpha-3": "PRK",
  },
  {
    "Country Name": "Congo - Kinshasa",
    IOC: "COD",
    "ISO3166-1-Alpha-2": "CD",
    "ISO3166-1-Alpha-3": "COD",
  },
  {
    "Country Name": "Denmark",
    IOC: "DEN",
    "ISO3166-1-Alpha-2": "DK",
    "ISO3166-1-Alpha-3": "DNK",
  },
  {
    "Country Name": "Djibouti",
    IOC: "DJI",
    "ISO3166-1-Alpha-2": "DJ",
    "ISO3166-1-Alpha-3": "DJI",
  },
  {
    "Country Name": "Dominica",
    IOC: "DMA",
    "ISO3166-1-Alpha-2": "DM",
    "ISO3166-1-Alpha-3": "DMA",
  },
  {
    "Country Name": "Dominican Republic",
    IOC: "DOM",
    "ISO3166-1-Alpha-2": "DO",
    "ISO3166-1-Alpha-3": "DOM",
  },
  {
    "Country Name": "Ecuador",
    IOC: "ECU",
    "ISO3166-1-Alpha-2": "EC",
    "ISO3166-1-Alpha-3": "ECU",
  },
  {
    "Country Name": "Egypt",
    IOC: "EGY",
    "ISO3166-1-Alpha-2": "EG",
    "ISO3166-1-Alpha-3": "EGY",
  },
  {
    "Country Name": "El Salvador",
    IOC: "ESA",
    "ISO3166-1-Alpha-2": "SV",
    "ISO3166-1-Alpha-3": "SLV",
  },
  {
    "Country Name": "Equatorial Guinea",
    IOC: "GEQ",
    "ISO3166-1-Alpha-2": "GQ",
    "ISO3166-1-Alpha-3": "GNQ",
  },
  {
    "Country Name": "Eritrea",
    IOC: "ERI",
    "ISO3166-1-Alpha-2": "ER",
    "ISO3166-1-Alpha-3": "ERI",
  },
  {
    "Country Name": "Estonia",
    IOC: "EST",
    "ISO3166-1-Alpha-2": "EE",
    "ISO3166-1-Alpha-3": "EST",
  },
  {
    "Country Name": "Eswatini",
    IOC: "SWZ",
    "ISO3166-1-Alpha-2": "SZ",
    "ISO3166-1-Alpha-3": "SWZ",
  },
  {
    "Country Name": "Ethiopia",
    IOC: "ETH",
    "ISO3166-1-Alpha-2": "ET",
    "ISO3166-1-Alpha-3": "ETH",
  },
  {
    "Country Name": "Falkland Islands",
    IOC: "FLK",
    "ISO3166-1-Alpha-2": "FK",
    "ISO3166-1-Alpha-3": "FLK",
  },
  {
    "Country Name": "Faroe Islands",
    IOC: "FAR",
    "ISO3166-1-Alpha-2": "FO",
    "ISO3166-1-Alpha-3": "FRO",
  },
  {
    "Country Name": "Fiji",
    IOC: "FIJ",
    "ISO3166-1-Alpha-2": "FJ",
    "ISO3166-1-Alpha-3": "FJI",
  },
  {
    "Country Name": "Finland",
    IOC: "FIN",
    "ISO3166-1-Alpha-2": "FI",
    "ISO3166-1-Alpha-3": "FIN",
  },
  {
    "Country Name": "France",
    IOC: "FRA",
    "ISO3166-1-Alpha-2": "FR",
    "ISO3166-1-Alpha-3": "FRA",
  },
  {
    "Country Name": "French Guiana",
    IOC: "FGU",
    "ISO3166-1-Alpha-2": "GF",
    "ISO3166-1-Alpha-3": "GUF",
  },
  {
    "Country Name": "French Polynesia",
    IOC: "FPO",
    "ISO3166-1-Alpha-2": "PF",
    "ISO3166-1-Alpha-3": "PYF",
  },
  {
    "Country Name": "French Southern Territories",
    IOC: "",
    "ISO3166-1-Alpha-2": "TF",
    "ISO3166-1-Alpha-3": "ATF",
  },
  {
    "Country Name": "Gabon",
    IOC: "GAB",
    "ISO3166-1-Alpha-2": "GA",
    "ISO3166-1-Alpha-3": "GAB",
  },
  {
    "Country Name": "Gambia",
    IOC: "GAM",
    "ISO3166-1-Alpha-2": "GM",
    "ISO3166-1-Alpha-3": "GMB",
  },
  {
    "Country Name": "Georgia",
    IOC: "GEO",
    "ISO3166-1-Alpha-2": "GE",
    "ISO3166-1-Alpha-3": "GEO",
  },
  {
    "Country Name": "Germany",
    IOC: "GER",
    "ISO3166-1-Alpha-2": "DE",
    "ISO3166-1-Alpha-3": "DEU",
  },
  {
    "Country Name": "Ghana",
    IOC: "GHA",
    "ISO3166-1-Alpha-2": "GH",
    "ISO3166-1-Alpha-3": "GHA",
  },
  {
    "Country Name": "Gibraltar",
    IOC: "GIB",
    "ISO3166-1-Alpha-2": "GI",
    "ISO3166-1-Alpha-3": "GIB",
  },
  {
    "Country Name": "Greece",
    IOC: "GRE",
    "ISO3166-1-Alpha-2": "GR",
    "ISO3166-1-Alpha-3": "GRC",
  },
  {
    "Country Name": "Greenland",
    IOC: "GRL",
    "ISO3166-1-Alpha-2": "GL",
    "ISO3166-1-Alpha-3": "GRL",
  },
  {
    "Country Name": "Grenada",
    IOC: "GRN",
    "ISO3166-1-Alpha-2": "GD",
    "ISO3166-1-Alpha-3": "GRD",
  },
  {
    "Country Name": "Guadeloupe",
    IOC: "GUD",
    "ISO3166-1-Alpha-2": "GP",
    "ISO3166-1-Alpha-3": "GLP",
  },
  {
    "Country Name": "Guam",
    IOC: "GUM",
    "ISO3166-1-Alpha-2": "GU",
    "ISO3166-1-Alpha-3": "GUM",
  },
  {
    "Country Name": "Guatemala",
    IOC: "GUA",
    "ISO3166-1-Alpha-2": "GT",
    "ISO3166-1-Alpha-3": "GTM",
  },
  {
    "Country Name": "Guernsey",
    IOC: "",
    "ISO3166-1-Alpha-2": "GG",
    "ISO3166-1-Alpha-3": "GGY",
  },
  {
    "Country Name": "Guinea",
    IOC: "GUI",
    "ISO3166-1-Alpha-2": "GN",
    "ISO3166-1-Alpha-3": "GIN",
  },
  {
    "Country Name": "Guinea-Bissau",
    IOC: "GBS",
    "ISO3166-1-Alpha-2": "GW",
    "ISO3166-1-Alpha-3": "GNB",
  },
  {
    "Country Name": "Guyana",
    IOC: "GUY",
    "ISO3166-1-Alpha-2": "GY",
    "ISO3166-1-Alpha-3": "GUY",
  },
  {
    "Country Name": "Haiti",
    IOC: "HAI",
    "ISO3166-1-Alpha-2": "HT",
    "ISO3166-1-Alpha-3": "HTI",
  },
  {
    "Country Name": "Heard & McDonald Islands",
    IOC: "",
    "ISO3166-1-Alpha-2": "HM",
    "ISO3166-1-Alpha-3": "HMD",
  },
  {
    "Country Name": "Vatican City",
    IOC: "",
    "ISO3166-1-Alpha-2": "VA",
    "ISO3166-1-Alpha-3": "VAT",
  },
  {
    "Country Name": "Honduras",
    IOC: "HON",
    "ISO3166-1-Alpha-2": "HN",
    "ISO3166-1-Alpha-3": "HND",
  },
  {
    "Country Name": "Hungary",
    IOC: "HUN",
    "ISO3166-1-Alpha-2": "HU",
    "ISO3166-1-Alpha-3": "HUN",
  },
  {
    "Country Name": "Iceland",
    IOC: "ISL",
    "ISO3166-1-Alpha-2": "IS",
    "ISO3166-1-Alpha-3": "ISL",
  },
  {
    "Country Name": "India",
    IOC: "IND",
    "ISO3166-1-Alpha-2": "IN",
    "ISO3166-1-Alpha-3": "IND",
  },
  {
    "Country Name": "Indonesia",
    IOC: "INA",
    "ISO3166-1-Alpha-2": "ID",
    "ISO3166-1-Alpha-3": "IDN",
  },
  {
    "Country Name": "Iran",
    IOC: "IRI",
    "ISO3166-1-Alpha-2": "IR",
    "ISO3166-1-Alpha-3": "IRN",
  },
  {
    "Country Name": "Iraq",
    IOC: "IRQ",
    "ISO3166-1-Alpha-2": "IQ",
    "ISO3166-1-Alpha-3": "IRQ",
  },
  {
    "Country Name": "Ireland",
    IOC: "IRL",
    "ISO3166-1-Alpha-2": "IE",
    "ISO3166-1-Alpha-3": "IRL",
  },
  {
    "Country Name": "Isle of Man",
    IOC: "",
    "ISO3166-1-Alpha-2": "IM",
    "ISO3166-1-Alpha-3": "IMN",
  },
  {
    "Country Name": "Israel",
    IOC: "ISR",
    "ISO3166-1-Alpha-2": "IL",
    "ISO3166-1-Alpha-3": "ISR",
  },
  {
    "Country Name": "Italy",
    IOC: "ITA",
    "ISO3166-1-Alpha-2": "IT",
    "ISO3166-1-Alpha-3": "ITA",
  },
  {
    "Country Name": "Jamaica",
    IOC: "JAM",
    "ISO3166-1-Alpha-2": "JM",
    "ISO3166-1-Alpha-3": "JAM",
  },
  {
    "Country Name": "Japan",
    IOC: "JPN",
    "ISO3166-1-Alpha-2": "JP",
    "ISO3166-1-Alpha-3": "JPN",
  },
  {
    "Country Name": "Jersey",
    IOC: "",
    "ISO3166-1-Alpha-2": "JE",
    "ISO3166-1-Alpha-3": "JEY",
  },
  {
    "Country Name": "Jordan",
    IOC: "JOR",
    "ISO3166-1-Alpha-2": "JO",
    "ISO3166-1-Alpha-3": "JOR",
  },
  {
    "Country Name": "Kazakhstan",
    IOC: "KAZ",
    "ISO3166-1-Alpha-2": "KZ",
    "ISO3166-1-Alpha-3": "KAZ",
  },
  {
    "Country Name": "Kenya",
    IOC: "KEN",
    "ISO3166-1-Alpha-2": "KE",
    "ISO3166-1-Alpha-3": "KEN",
  },
  {
    "Country Name": "Kiribati",
    IOC: "KIR",
    "ISO3166-1-Alpha-2": "KI",
    "ISO3166-1-Alpha-3": "KIR",
  },
  {
    "Country Name": "Kuwait",
    IOC: "KUW",
    "ISO3166-1-Alpha-2": "KW",
    "ISO3166-1-Alpha-3": "KWT",
  },
  {
    "Country Name": "Kyrgyzstan",
    IOC: "KGZ",
    "ISO3166-1-Alpha-2": "KG",
    "ISO3166-1-Alpha-3": "KGZ",
  },
  {
    "Country Name": "Laos",
    IOC: "LAO",
    "ISO3166-1-Alpha-2": "LA",
    "ISO3166-1-Alpha-3": "LAO",
  },
  {
    "Country Name": "Latvia",
    IOC: "LAT",
    "ISO3166-1-Alpha-2": "LV",
    "ISO3166-1-Alpha-3": "LVA",
  },
  {
    "Country Name": "Lebanon",
    IOC: "LIB",
    "ISO3166-1-Alpha-2": "LB",
    "ISO3166-1-Alpha-3": "LBN",
  },
  {
    "Country Name": "Lesotho",
    IOC: "LES",
    "ISO3166-1-Alpha-2": "LS",
    "ISO3166-1-Alpha-3": "LSO",
  },
  {
    "Country Name": "Liberia",
    IOC: "LBR",
    "ISO3166-1-Alpha-2": "LR",
    "ISO3166-1-Alpha-3": "LBR",
  },
  {
    "Country Name": "Libya",
    IOC: "LBA",
    "ISO3166-1-Alpha-2": "LY",
    "ISO3166-1-Alpha-3": "LBY",
  },
  {
    "Country Name": "Liechtenstein",
    IOC: "LIE",
    "ISO3166-1-Alpha-2": "LI",
    "ISO3166-1-Alpha-3": "LIE",
  },
  {
    "Country Name": "Lithuania",
    IOC: "LTU",
    "ISO3166-1-Alpha-2": "LT",
    "ISO3166-1-Alpha-3": "LTU",
  },
  {
    "Country Name": "Luxembourg",
    IOC: "LUX",
    "ISO3166-1-Alpha-2": "LU",
    "ISO3166-1-Alpha-3": "LUX",
  },
  {
    "Country Name": "Madagascar",
    IOC: "MAD",
    "ISO3166-1-Alpha-2": "MG",
    "ISO3166-1-Alpha-3": "MDG",
  },
  {
    "Country Name": "Malawi",
    IOC: "MAW",
    "ISO3166-1-Alpha-2": "MW",
    "ISO3166-1-Alpha-3": "MWI",
  },
  {
    "Country Name": "Malaysia",
    IOC: "MAS",
    "ISO3166-1-Alpha-2": "MY",
    "ISO3166-1-Alpha-3": "MYS",
  },
  {
    "Country Name": "Maldives",
    IOC: "MDV",
    "ISO3166-1-Alpha-2": "MV",
    "ISO3166-1-Alpha-3": "MDV",
  },
  {
    "Country Name": "Mali",
    IOC: "MLI",
    "ISO3166-1-Alpha-2": "ML",
    "ISO3166-1-Alpha-3": "MLI",
  },
  {
    "Country Name": "Malta",
    IOC: "MLT",
    "ISO3166-1-Alpha-2": "MT",
    "ISO3166-1-Alpha-3": "MLT",
  },
  {
    "Country Name": "Marshall Islands",
    IOC: "MSH",
    "ISO3166-1-Alpha-2": "MH",
    "ISO3166-1-Alpha-3": "MHL",
  },
  {
    "Country Name": "Martinique",
    IOC: "MRT",
    "ISO3166-1-Alpha-2": "MQ",
    "ISO3166-1-Alpha-3": "MTQ",
  },
  {
    "Country Name": "Mauritania",
    IOC: "MTN",
    "ISO3166-1-Alpha-2": "MR",
    "ISO3166-1-Alpha-3": "MRT",
  },
  {
    "Country Name": "Mauritius",
    IOC: "MRI",
    "ISO3166-1-Alpha-2": "MU",
    "ISO3166-1-Alpha-3": "MUS",
  },
  {
    "Country Name": "Mayotte",
    IOC: "MAY",
    "ISO3166-1-Alpha-2": "YT",
    "ISO3166-1-Alpha-3": "MYT",
  },
  {
    "Country Name": "Mexico",
    IOC: "MEX",
    "ISO3166-1-Alpha-2": "MX",
    "ISO3166-1-Alpha-3": "MEX",
  },
  {
    "Country Name": "Micronesia",
    IOC: "FSM",
    "ISO3166-1-Alpha-2": "FM",
    "ISO3166-1-Alpha-3": "FSM",
  },
  {
    "Country Name": "Monaco",
    IOC: "MON",
    "ISO3166-1-Alpha-2": "MC",
    "ISO3166-1-Alpha-3": "MCO",
  },
  {
    "Country Name": "Mongolia",
    IOC: "MGL",
    "ISO3166-1-Alpha-2": "MN",
    "ISO3166-1-Alpha-3": "MNG",
  },
  {
    "Country Name": "Montenegro",
    IOC: "MGO",
    "ISO3166-1-Alpha-2": "ME",
    "ISO3166-1-Alpha-3": "MNE",
  },
  {
    "Country Name": "Montserrat",
    IOC: "MNT",
    "ISO3166-1-Alpha-2": "MS",
    "ISO3166-1-Alpha-3": "MSR",
  },
  {
    "Country Name": "Morocco",
    IOC: "MAR",
    "ISO3166-1-Alpha-2": "MA",
    "ISO3166-1-Alpha-3": "MAR",
  },
  {
    "Country Name": "Mozambique",
    IOC: "MOZ",
    "ISO3166-1-Alpha-2": "MZ",
    "ISO3166-1-Alpha-3": "MOZ",
  },
  {
    "Country Name": "Myanmar",
    IOC: "MYA",
    "ISO3166-1-Alpha-2": "MM",
    "ISO3166-1-Alpha-3": "MMR",
  },
  {
    "Country Name": "Namibia",
    IOC: "NAM",
    "ISO3166-1-Alpha-2": "NA",
    "ISO3166-1-Alpha-3": "NAM",
  },
  {
    "Country Name": "Nauru",
    IOC: "NRU",
    "ISO3166-1-Alpha-2": "NR",
    "ISO3166-1-Alpha-3": "NRU",
  },
  {
    "Country Name": "Nepal",
    IOC: "NEP",
    "ISO3166-1-Alpha-2": "NP",
    "ISO3166-1-Alpha-3": "NPL",
  },
  {
    "Country Name": "Netherlands",
    IOC: "NED",
    "ISO3166-1-Alpha-2": "NL",
    "ISO3166-1-Alpha-3": "NLD",
  },
  {
    "Country Name": "New Caledonia",
    IOC: "NCD",
    "ISO3166-1-Alpha-2": "NC",
    "ISO3166-1-Alpha-3": "NCL",
  },
  {
    "Country Name": "New Zealand",
    IOC: "NZL",
    "ISO3166-1-Alpha-2": "NZ",
    "ISO3166-1-Alpha-3": "NZL",
  },
  {
    "Country Name": "Nicaragua",
    IOC: "NCA",
    "ISO3166-1-Alpha-2": "NI",
    "ISO3166-1-Alpha-3": "NIC",
  },
  {
    "Country Name": "Niger",
    IOC: "NIG",
    "ISO3166-1-Alpha-2": "NE",
    "ISO3166-1-Alpha-3": "NER",
  },
  {
    "Country Name": "Nigeria",
    IOC: "NGR",
    "ISO3166-1-Alpha-2": "NG",
    "ISO3166-1-Alpha-3": "NGA",
  },
  {
    "Country Name": "Niue",
    IOC: "NIU",
    "ISO3166-1-Alpha-2": "NU",
    "ISO3166-1-Alpha-3": "NIU",
  },
  {
    "Country Name": "Norfolk Island",
    IOC: "NFI",
    "ISO3166-1-Alpha-2": "NF",
    "ISO3166-1-Alpha-3": "NFK",
  },
  {
    "Country Name": "Northern Mariana Islands",
    IOC: "NMA",
    "ISO3166-1-Alpha-2": "MP",
    "ISO3166-1-Alpha-3": "MNP",
  },
  {
    "Country Name": "Norway",
    IOC: "NOR",
    "ISO3166-1-Alpha-2": "NO",
    "ISO3166-1-Alpha-3": "NOR",
  },
  {
    "Country Name": "Oman",
    IOC: "OMA",
    "ISO3166-1-Alpha-2": "OM",
    "ISO3166-1-Alpha-3": "OMN",
  },
  {
    "Country Name": "Pakistan",
    IOC: "PAK",
    "ISO3166-1-Alpha-2": "PK",
    "ISO3166-1-Alpha-3": "PAK",
  },
  {
    "Country Name": "Palau",
    IOC: "PLW",
    "ISO3166-1-Alpha-2": "PW",
    "ISO3166-1-Alpha-3": "PLW",
  },
  {
    "Country Name": "Panama",
    IOC: "PAN",
    "ISO3166-1-Alpha-2": "PA",
    "ISO3166-1-Alpha-3": "PAN",
  },
  {
    "Country Name": "Papua New Guinea",
    IOC: "PNG",
    "ISO3166-1-Alpha-2": "PG",
    "ISO3166-1-Alpha-3": "PNG",
  },
  {
    "Country Name": "Paraguay",
    IOC: "PAR",
    "ISO3166-1-Alpha-2": "PY",
    "ISO3166-1-Alpha-3": "PRY",
  },
  {
    "Country Name": "Peru",
    IOC: "PER",
    "ISO3166-1-Alpha-2": "PE",
    "ISO3166-1-Alpha-3": "PER",
  },
  {
    "Country Name": "Philippines",
    IOC: "PHI",
    "ISO3166-1-Alpha-2": "PH",
    "ISO3166-1-Alpha-3": "PHL",
  },
  {
    "Country Name": "Pitcairn Islands",
    IOC: "",
    "ISO3166-1-Alpha-2": "PN",
    "ISO3166-1-Alpha-3": "PCN",
  },
  {
    "Country Name": "Poland",
    IOC: "POL",
    "ISO3166-1-Alpha-2": "PL",
    "ISO3166-1-Alpha-3": "POL",
  },
  {
    "Country Name": "Portugal",
    IOC: "POR",
    "ISO3166-1-Alpha-2": "PT",
    "ISO3166-1-Alpha-3": "PRT",
  },
  {
    "Country Name": "Puerto Rico",
    IOC: "PUR",
    "ISO3166-1-Alpha-2": "PR",
    "ISO3166-1-Alpha-3": "PRI",
  },
  {
    "Country Name": "Qatar",
    IOC: "QAT",
    "ISO3166-1-Alpha-2": "QA",
    "ISO3166-1-Alpha-3": "QAT",
  },
  {
    "Country Name": "South Korea",
    IOC: "KOR",
    "ISO3166-1-Alpha-2": "KR",
    "ISO3166-1-Alpha-3": "KOR",
  },
  {
    "Country Name": "Moldova",
    IOC: "MDA",
    "ISO3166-1-Alpha-2": "MD",
    "ISO3166-1-Alpha-3": "MDA",
  },
  {
    "Country Name": "Romania",
    IOC: "ROU",
    "ISO3166-1-Alpha-2": "RO",
    "ISO3166-1-Alpha-3": "ROU",
  },
  {
    "Country Name": "Russia",
    IOC: "RUS",
    "ISO3166-1-Alpha-2": "RU",
    "ISO3166-1-Alpha-3": "RUS",
  },
  {
    "Country Name": "Rwanda",
    IOC: "RWA",
    "ISO3166-1-Alpha-2": "RW",
    "ISO3166-1-Alpha-3": "RWA",
  },
  {
    "Country Name": "Réunion",
    IOC: "REU",
    "ISO3166-1-Alpha-2": "RE",
    "ISO3166-1-Alpha-3": "REU",
  },
  {
    "Country Name": "St. Barthélemy",
    IOC: "",
    "ISO3166-1-Alpha-2": "BL",
    "ISO3166-1-Alpha-3": "BLM",
  },
  {
    "Country Name": "St. Helena",
    IOC: "HEL",
    "ISO3166-1-Alpha-2": "SH",
    "ISO3166-1-Alpha-3": "SHN",
  },
  {
    "Country Name": "St. Kitts & Nevis",
    IOC: "SKN",
    "ISO3166-1-Alpha-2": "KN",
    "ISO3166-1-Alpha-3": "KNA",
  },
  {
    "Country Name": "St. Lucia",
    IOC: "LCA",
    "ISO3166-1-Alpha-2": "LC",
    "ISO3166-1-Alpha-3": "LCA",
  },
  {
    "Country Name": "St. Martin",
    IOC: "",
    "ISO3166-1-Alpha-2": "MF",
    "ISO3166-1-Alpha-3": "MAF",
  },
  {
    "Country Name": "St. Pierre & Miquelon",
    IOC: "SPM",
    "ISO3166-1-Alpha-2": "PM",
    "ISO3166-1-Alpha-3": "SPM",
  },
  {
    "Country Name": "St. Vincent & Grenadines",
    IOC: "VIN",
    "ISO3166-1-Alpha-2": "VC",
    "ISO3166-1-Alpha-3": "VCT",
  },
  {
    "Country Name": "Samoa",
    IOC: "SAM",
    "ISO3166-1-Alpha-2": "WS",
    "ISO3166-1-Alpha-3": "WSM",
  },
  {
    "Country Name": "San Marino",
    IOC: "SMR",
    "ISO3166-1-Alpha-2": "SM",
    "ISO3166-1-Alpha-3": "SMR",
  },
  {
    "Country Name": "São Tomé & Príncipe",
    IOC: "STP",
    "ISO3166-1-Alpha-2": "ST",
    "ISO3166-1-Alpha-3": "STP",
  },
  {
    "Country Name": "",
    IOC: "",
    "ISO3166-1-Alpha-2": "",
    "ISO3166-1-Alpha-3": "",
  },
  {
    "Country Name": "Saudi Arabia",
    IOC: "KSA",
    "ISO3166-1-Alpha-2": "SA",
    "ISO3166-1-Alpha-3": "SAU",
  },
  {
    "Country Name": "Senegal",
    IOC: "SEN",
    "ISO3166-1-Alpha-2": "SN",
    "ISO3166-1-Alpha-3": "SEN",
  },
  {
    "Country Name": "Serbia",
    IOC: "SRB",
    "ISO3166-1-Alpha-2": "RS",
    "ISO3166-1-Alpha-3": "SRB",
  },
  {
    "Country Name": "Seychelles",
    IOC: "SEY",
    "ISO3166-1-Alpha-2": "SC",
    "ISO3166-1-Alpha-3": "SYC",
  },
  {
    "Country Name": "Sierra Leone",
    IOC: "SLE",
    "ISO3166-1-Alpha-2": "SL",
    "ISO3166-1-Alpha-3": "SLE",
  },
  {
    "Country Name": "Singapore",
    IOC: "SIN",
    "ISO3166-1-Alpha-2": "SG",
    "ISO3166-1-Alpha-3": "SGP",
  },
  {
    "Country Name": "Sint Maarten",
    IOC: "",
    "ISO3166-1-Alpha-2": "SX",
    "ISO3166-1-Alpha-3": "SXM",
  },
  {
    "Country Name": "Slovakia",
    IOC: "SVK",
    "ISO3166-1-Alpha-2": "SK",
    "ISO3166-1-Alpha-3": "SVK",
  },
  {
    "Country Name": "Slovenia",
    IOC: "SLO",
    "ISO3166-1-Alpha-2": "SI",
    "ISO3166-1-Alpha-3": "SVN",
  },
  {
    "Country Name": "Solomon Islands",
    IOC: "SOL",
    "ISO3166-1-Alpha-2": "SB",
    "ISO3166-1-Alpha-3": "SLB",
  },
  {
    "Country Name": "Somalia",
    IOC: "SOM",
    "ISO3166-1-Alpha-2": "SO",
    "ISO3166-1-Alpha-3": "SOM",
  },
  {
    "Country Name": "South Africa",
    IOC: "RSA",
    "ISO3166-1-Alpha-2": "ZA",
    "ISO3166-1-Alpha-3": "ZAF",
  },
  {
    "Country Name": "South Georgia & South Sandwich Islands",
    IOC: "",
    "ISO3166-1-Alpha-2": "GS",
    "ISO3166-1-Alpha-3": "SGS",
  },
  {
    "Country Name": "South Sudan",
    IOC: "",
    "ISO3166-1-Alpha-2": "SS",
    "ISO3166-1-Alpha-3": "SSD",
  },
  {
    "Country Name": "Spain",
    IOC: "ESP",
    "ISO3166-1-Alpha-2": "ES",
    "ISO3166-1-Alpha-3": "ESP",
  },
  {
    "Country Name": "Sri Lanka",
    IOC: "SRI",
    "ISO3166-1-Alpha-2": "LK",
    "ISO3166-1-Alpha-3": "LKA",
  },
  {
    "Country Name": "Palestine",
    IOC: "PLE",
    "ISO3166-1-Alpha-2": "PS",
    "ISO3166-1-Alpha-3": "PSE",
  },
  {
    "Country Name": "Sudan",
    IOC: "SUD",
    "ISO3166-1-Alpha-2": "SD",
    "ISO3166-1-Alpha-3": "SDN",
  },
  {
    "Country Name": "Suriname",
    IOC: "SUR",
    "ISO3166-1-Alpha-2": "SR",
    "ISO3166-1-Alpha-3": "SUR",
  },
  {
    "Country Name": "Svalbard & Jan Mayen",
    IOC: "",
    "ISO3166-1-Alpha-2": "SJ",
    "ISO3166-1-Alpha-3": "SJM",
  },
  {
    "Country Name": "Sweden",
    IOC: "SWE",
    "ISO3166-1-Alpha-2": "SE",
    "ISO3166-1-Alpha-3": "SWE",
  },
  {
    "Country Name": "Switzerland",
    IOC: "SUI",
    "ISO3166-1-Alpha-2": "CH",
    "ISO3166-1-Alpha-3": "CHE",
  },
  {
    "Country Name": "Syria",
    IOC: "SYR",
    "ISO3166-1-Alpha-2": "SY",
    "ISO3166-1-Alpha-3": "SYR",
  },
  {
    "Country Name": "Tajikistan",
    IOC: "TJK",
    "ISO3166-1-Alpha-2": "TJ",
    "ISO3166-1-Alpha-3": "TJK",
  },
  {
    "Country Name": "Thailand",
    IOC: "THA",
    "ISO3166-1-Alpha-2": "TH",
    "ISO3166-1-Alpha-3": "THA",
  },
  {
    "Country Name": "North Macedonia",
    IOC: "MKD",
    "ISO3166-1-Alpha-2": "MK",
    "ISO3166-1-Alpha-3": "MKD",
  },
  {
    "Country Name": "Timor-Leste",
    IOC: "TLS",
    "ISO3166-1-Alpha-2": "TL",
    "ISO3166-1-Alpha-3": "TLS",
  },
  {
    "Country Name": "Togo",
    IOC: "TOG",
    "ISO3166-1-Alpha-2": "TG",
    "ISO3166-1-Alpha-3": "TGO",
  },
  {
    "Country Name": "Tokelau",
    IOC: "",
    "ISO3166-1-Alpha-2": "TK",
    "ISO3166-1-Alpha-3": "TKL",
  },
  {
    "Country Name": "Tonga",
    IOC: "TGA",
    "ISO3166-1-Alpha-2": "TO",
    "ISO3166-1-Alpha-3": "TON",
  },
  {
    "Country Name": "Trinidad & Tobago",
    IOC: "TTO",
    "ISO3166-1-Alpha-2": "TT",
    "ISO3166-1-Alpha-3": "TTO",
  },
  {
    "Country Name": "Tunisia",
    IOC: "TUN",
    "ISO3166-1-Alpha-2": "TN",
    "ISO3166-1-Alpha-3": "TUN",
  },
  {
    "Country Name": "Turkey",
    IOC: "TUR",
    "ISO3166-1-Alpha-2": "TR",
    "ISO3166-1-Alpha-3": "TUR",
  },
  {
    "Country Name": "Turkmenistan",
    IOC: "TKM",
    "ISO3166-1-Alpha-2": "TM",
    "ISO3166-1-Alpha-3": "TKM",
  },
  {
    "Country Name": "Turks & Caicos Islands",
    IOC: "TKS",
    "ISO3166-1-Alpha-2": "TC",
    "ISO3166-1-Alpha-3": "TCA",
  },
  {
    "Country Name": "Tuvalu",
    IOC: "TUV",
    "ISO3166-1-Alpha-2": "TV",
    "ISO3166-1-Alpha-3": "TUV",
  },
  {
    "Country Name": "Uganda",
    IOC: "UGA",
    "ISO3166-1-Alpha-2": "UG",
    "ISO3166-1-Alpha-3": "UGA",
  },
  {
    "Country Name": "Ukraine",
    IOC: "UKR",
    "ISO3166-1-Alpha-2": "UA",
    "ISO3166-1-Alpha-3": "UKR",
  },
  {
    "Country Name": "United Arab Emirates",
    IOC: "UAE",
    "ISO3166-1-Alpha-2": "AE",
    "ISO3166-1-Alpha-3": "ARE",
  },
  {
    "Country Name": "UK",
    IOC: "GBR",
    "ISO3166-1-Alpha-2": "GB",
    "ISO3166-1-Alpha-3": "GBR",
  },
  {
    "Country Name": "Tanzania",
    IOC: "TAN",
    "ISO3166-1-Alpha-2": "TZ",
    "ISO3166-1-Alpha-3": "TZA",
  },
  {
    "Country Name": "U.S. Outlying Islands",
    IOC: "",
    "ISO3166-1-Alpha-2": "UM",
    "ISO3166-1-Alpha-3": "UMI",
  },
  {
    "Country Name": "U.S. Virgin Islands",
    IOC: "ISV",
    "ISO3166-1-Alpha-2": "VI",
    "ISO3166-1-Alpha-3": "VIR",
  },
  {
    "Country Name": "US",
    IOC: "USA",
    "ISO3166-1-Alpha-2": "US",
    "ISO3166-1-Alpha-3": "USA",
  },
  {
    "Country Name": "Uruguay",
    IOC: "URU",
    "ISO3166-1-Alpha-2": "UY",
    "ISO3166-1-Alpha-3": "URY",
  },
  {
    "Country Name": "Uzbekistan",
    IOC: "UZB",
    "ISO3166-1-Alpha-2": "UZ",
    "ISO3166-1-Alpha-3": "UZB",
  },
  {
    "Country Name": "Vanuatu",
    IOC: "VAN",
    "ISO3166-1-Alpha-2": "VU",
    "ISO3166-1-Alpha-3": "VUT",
  },
  {
    "Country Name": "Venezuela",
    IOC: "VEN",
    "ISO3166-1-Alpha-2": "VE",
    "ISO3166-1-Alpha-3": "VEN",
  },
  {
    "Country Name": "Vietnam",
    IOC: "VIE",
    "ISO3166-1-Alpha-2": "VN",
    "ISO3166-1-Alpha-3": "VNM",
  },
  {
    "Country Name": "Wallis & Futuna",
    IOC: "WAF",
    "ISO3166-1-Alpha-2": "WF",
    "ISO3166-1-Alpha-3": "WLF",
  },
  {
    "Country Name": "Western Sahara",
    IOC: "",
    "ISO3166-1-Alpha-2": "EH",
    "ISO3166-1-Alpha-3": "ESH",
  },
  {
    "Country Name": "Yemen",
    IOC: "YEM",
    "ISO3166-1-Alpha-2": "YE",
    "ISO3166-1-Alpha-3": "YEM",
  },
  {
    "Country Name": "Zambia",
    IOC: "ZAM",
    "ISO3166-1-Alpha-2": "ZM",
    "ISO3166-1-Alpha-3": "ZMB",
  },
  {
    "Country Name": "Zimbabwe",
    IOC: "ZIM",
    "ISO3166-1-Alpha-2": "ZW",
    "ISO3166-1-Alpha-3": "ZWE",
  },
  {
    "Country Name": "Åland Islands",
    IOC: "",
    "ISO3166-1-Alpha-2": "AX",
    "ISO3166-1-Alpha-3": "ALA",
  },
];

interface FlagIconProps {
  nationality: string | undefined;
  size?: "small" | "large"
}

export function FlagIcon(props: FlagIconProps) {
  if (props.nationality === undefined || props.nationality.trim().length === 0) return <></>;
  let countryCode = getCountryCode(props.nationality);

  let size = (props.size === "large" ? 14 : 10);

  if (countryCode && countryCode !== "") {
    return <CircleFlag height={size} countryCode={countryCode.toLowerCase()} style={{ marginRight: "4px" }} />;
  } else {
    return <span>{props.nationality && props.nationality.length === 3 && props.nationality} </span>; //<span>{"" + props.nationality + " "}</span>;
  }
}

function getCountryCode(threeLetterCode: string) {
  // convert three letter code to two letter code
  // https://www.iban.com/country-codes
  // loop through countries and find the country with the matching three letter code
  // return the two letter code
  let twoLetterCode = "";
  countries.forEach((country) => {
    //console.log(country);
    if (country["IOC"] === threeLetterCode) {
      twoLetterCode = country["ISO3166-1-Alpha-2"];
    } else if(country["ISO3166-1-Alpha-3"] === threeLetterCode) {
      twoLetterCode = country["ISO3166-1-Alpha-2"];
    }
  });
  return twoLetterCode;
}

export function getSessionPrettyDate(competitionData: CompetitionData, sid: string) {
  var sessionDay = undefined;
  try {
    if (competitionData.competitionDates && competitionData.competitionDates.length === 1) {
      sessionDay = competitionData.competitionDates[0];
    } else if (competitionData.schedule[sid].sessionDate !== undefined) {
      sessionDay = competitionData.schedule[sid].sessionDate;
    } else if (competitionData.sessions !== undefined && competitionData.sessions[sid].sessionDate !== undefined) {
      sessionDay = competitionData.sessions[sid].sessionDate;
    } else {
      return null;
    }
    return formatSessionDate(sessionDay);
  } catch (error) {
    return null;
  }
}

export function formatSessionDate(sessionDate: string): string {
  const calendarStrings = {
    lastDay: "[Eilen]",
    sameDay: "[Tänään]",
    nextDay: "[Huomenna]",
    lastWeek: "[Viime] dddd[na] D.M.YYYY",
    nextWeek: "[Ensi] dddd[na] D.M.YYYY",
    sameElse: "dddd[na] D.M.YYYY",
  };
  // convert sessionDate to Moment object and format it
  let formattedDate = moment(new Date(sessionDate)).calendar(null, calendarStrings);
  return formattedDate.toString();
}

export function getSeriesName(series: string | undefined, eventName: string | undefined) {
  if (!series) return "";
  if (!eventName) return series;

  let s = series;
  if (s.startsWith(eventName)) {
    s = s.substring(eventName.length).trim();
  }
  if (s.startsWith("-")) {
    s = s.substring(1).trim();
  }
  return s.trim();
}

export function getTimeDiff(time: string | undefined, entryTime: string | undefined): string {
  if (time === undefined || entryTime === undefined) return "";
  if (time === "DNS" || time === "DSQ" || time === "DNF") return "";
  if (time === "" || entryTime === "") return "";
  var timeSec = 0;
  var entryTimeSec = 0;
  if (time.split(":").length === 2) {
    timeSec += 60 * parseFloat(time.split(":")[0]);
    timeSec += parseFloat(time.split(":")[1]);
  } else {
    timeSec += parseFloat(time.split(":")[0]);
  }
  if (entryTime.split(":").length === 2) {
    entryTimeSec += 60 * parseFloat(entryTime.split(":")[0]);
    entryTimeSec += parseFloat(entryTime.split(":")[1]);
  } else {
    entryTimeSec += parseFloat(entryTime.split(":")[0]);
  }
  var diff = "" + (timeSec - entryTimeSec).toFixed(2);
  if (diff.charAt(0) !== "-") diff = "+" + diff;
  //console.log(time + " vs " + entryTime + " split " + time.split(":").length + " timeSec " + timeSec + " entryTimeSec " + entryTimeSec + " diff " + diff);
  return diff;
}

export function getTimeDiffWithParenthesis(time: string, entryTime: string): string {
  var diff = getTimeDiff(time, entryTime);
  if (diff === "") return "";
  return "(" + diff + ")";
}



export function getSplitsArray(splits: Splits) : Split[] {
  // convert splits object to SplitArray

  var array = Object.keys(splits).map(function (key) {
    let time;
    try { 
      time = parseTime(splits[key]);
    } catch(error) {
      console.error("Error parsing time", error, splits[key])
      time = 0;
    }

    return { name: parseInt(key.substring(0, key.length - 1)), split_float: time, split_text: splits[key] };
  });
  // remove all elements where time is zero
  array = array.filter(function (el) {
    return el.split_float > 0;
  });

  // sort the array by name
  var sorted = array.sort(function (a, b) {
    return a.name - b.name;
  });
  return sorted;
}

function parseTime(time: string): number {
  // parse time string (in format m:s.nn to seconds
  
  let timeSec = 0;
  let minutes = 0;
  let seconds = 0;
  let subseconds = 0;

  if (time.split(":").length === 2) {
    minutes = parseFloat(time.split(":")[0]);
    seconds = parseFloat(time.split(":")[1].split(".")[0]);
    subseconds = parseFloat(time.split(":")[1].split(".")[1]) / 100;
  } else {
    seconds = parseFloat(time.split(".")[0]);
    subseconds += parseFloat(time.split(".")[1]) / 100;
  }
  timeSec = 60*minutes + seconds + subseconds;

  return timeSec;
}

export function getPaceObject(splitArray : Split[] | undefined) : PaceObject | undefined {
  if(splitArray === undefined) return undefined;
  let paceArray : Pace[] = [];

  // for each split, calculate the difference to the previous split, and store it in pace_float and pace_text
  for (var i = 0; i < splitArray.length; i++) {
    let pace = 0;
    if (i === 0) {
      pace = splitArray[i].split_float;
    } else {
      pace = splitArray[i].split_float - splitArray[i - 1].split_float;
    }

    let pacediff = 0;
    if(i > 1) {
      pacediff = pace - (splitArray[i-1].split_float - splitArray[i-2].split_float);
    } else if(i === 1) {
      pacediff = pace - splitArray[i-1].split_float;
    }

    let pacediff_text = pacediff.toFixed(2);
    if(pacediff_text.charAt(0) !== "-") pacediff_text = "+" + pacediff_text;
    if(pacediff_text === "+0.00") pacediff_text = "";

    paceArray.push({ 
      name: splitArray[i].name, 
      pace_float: pace, 
      pace_text: pace.toFixed(2),
      split_text: splitArray[i].split_text,
      split_float: splitArray[i].split_float,
      pacediff_float: pacediff,
      pacediff_text: pacediff_text,
      diffToAverage_float: -1000, // will be never used
    });
  }

  // calculate average pace
  let average = 0;
  for (var i = 0; i < paceArray.length; i++) {
    average = average + paceArray[i].pace_float;
  }
  average = average / paceArray.length;

  // for each pace, calculate the difference to the average pace, and store it in diffToAverage_float
  for (var i = 0; i < paceArray.length; i++) {
    paceArray[i].diffToAverage_float = paceArray[i].pace_float - average;
  }

  // find the maximum and minimum difference to the average pace
  let max = 0;
  let min = 0;
  if(paceArray.length > 0) {
  max = paceArray[0].diffToAverage_float;
  min = paceArray[0].diffToAverage_float;
  for (var i = 0; i < paceArray.length; i++) {
    if (paceArray[i].diffToAverage_float > max) max = paceArray[i].diffToAverage_float;
    if (paceArray[i].diffToAverage_float < min) min = paceArray[i].diffToAverage_float;
  }
  }

  let paces = { paces: paceArray, average_float: average, pacediff_max: max, pacediff_min: min };

  return paces;
}


export function getAveragePace(pacedata: any): string | undefined {
  if(pacedata === undefined) return undefined;
  var average = parseFloat("0");
  for (var i = 0; i < pacedata.length; i++) {
    average = average + parseFloat(pacedata[i].pace);
  }
  return (average / parseFloat(pacedata.length)).toFixed(2);
}


export function getAdditionalInfoText(additionalInfo: string): string {
  var codes = [
    "SW 4.4 Lähteminen ennen lähtömerkkiä",
    "SW 5.1 Väärä uintityyli sekauinnin vapaauintiosuudella",
    "SW 5.2 Ei kosketusta seinään käännöksessä",
    "SW 5.3 Sukellus yli 15 m lähdön / käännöksen jälkeen",
    "SW 5.3 Sukellus uidessa (pl. edellinen kohta)",
    "SW 6.1 Kummankin jalan tulee koskea seinää/paneelia",
    "SW 6.2 Uintia vatsallaan",
    "SW 6.3 Sukellus yli 15 m lähdön / käännöksen jälkeen",
    "SW 6.3 Sukellus uidessa / maaliin tullessa",
    "SW 6.4 Käännös ei alkanut heti vatsalleen kääntymisen jälkeen",
    "SW 6.4 Käännös ei alkanut käsivedon päätyttyä",
    "SW 6.4 Ei kosketusta seinään käännöksessä",
    "SW 6.4 Vatsallaan jalkojen irrotessa seinästä",
    "SW 6.5 Maaliintulo vatsallaan",
    "SW 7.1 Pää ei rikkonut vedenpintaa ennen toisen käsivedon leveintä kohtaa lähdön / käännöksen jälkeen",
    "SW 7.1 Enemmän kuin yksi perhosuinnin potku ennen 1. rintauinnin potkua",
    "SW 7.2 Uintia selällään",
    "SW 7.2 Eriaikaisia käsiliikkeitä tai liikkeet eri horisontaalisessa tasossa",
    "SW 7.2 Käsiliikkeet eivät samassa vaakasuorassa tasossa",
    "SW 7.2 Väärä uintisykli (=1 käsiveto, 1 potku) paitsi käännöksessä / maaliin tullessa",
    "SW 7.3 Eriaikainen käsien palautus",
    "SW 7.3 Kyynärpäät vedenpinnan yläpuolella uidessa",
    "SW 7.3 Käsiveto ohi lantiolinjan (pl. lähdön/käännöksen jälk.)",
    "SW 7.4 Eriaikaisia jalkaliikkeitä tai liikkeet eri horisontaalisesssa tasossa",
    "SW 7.4 Pää ei rikkonut vedenpintaa uintisyklin (käsiveto ja potku) aikana",
    "SW 7.5 Jalkaterä sisäänpäin potkussa",
    "SW 7.5 Perhosuinnin potku (pl. lähdön/käännöksen jälk.)",
    "SW 7.6 Yhden käden tai kädet päällekkäin / eriaikainen kosketus seinään käännöksessä / maaliin tultaessa",
    "SW 8.1 Uintia selällään (paitsi käännöksen aikana)",
    "SW 8.2 Käsien palautus ei tapahtunut veden pinnan yläpuolella",
    "SW 8.2 Eriaikainen käsien palautus",
    "SW 8.2 Eriaikainen käsiveto vedenpinnan alapuolella",
    "SW 8.3 Eriaikaisia jalkaliikkeitä",
    "SW 8.3 Rintauinnin potku",
    "SW 8.4 Yhden käden tai kädet päällekkäin / eriaikainen kosketus seinään käännöksessä / maaliin tultaessa",
    "SW 8.5 Sukellus yli 15 m lähdön / käännöksen jälkeen",
    "SW 8.5 Sukellus uidessa",
    "SW 8.5 Ylimääräinen käsiveto sukelluksissa lähdön / käännöksen jälkeen",
    "SW 9.1 Väärä lajijärjestys (PU, SU, RU, VU ¼ kutakin)",
    "SW 9.2 Uintia selällään VU-osuudella 1. potkun tai vedon jälkeen",
    "SW 9.3 Viestijoukkueen väärä uintijärjestys (SU, RU, PU, VU ¼ kutakin)",
    "SW 9.4 Uintiosuus ei päättynyt ao. lajin mukaisesti PU / SU / RU / VU",
    "SW 10.2 Ei suorittanut koko matkaa yksin uidessaan",
    "SW 10.3 Ei pysynyt koko matkaa omalla radallaan",
    "SW 10.4 Käännöksessä ei kosketusta seinään",
    "SW 10.4 Kävely/ponnistaminen altaan pohjassa",
    "SW 10.5 Seisominen altaan pohjassa (paitsi VU tai SKU:n VU-osuus)",
    "SW 10.6 Köydestä vetäminen",
    "SW 10.7 Toisen kilpailijan estäminen / häiritseminen",
    "SW 10.8 Kielletty varuste tai vartalon teippaus",
    "SW 10.9 Altaaseen meneminen erässä, johon ei kuulu",
    "SW 10.11 Vaihtorikko",
    "SW 10.12 Altaaseen uudelleen meno ennen kuin kaikki joukkueet ovat maalissa",
    "SW 10.13 Viestijoukkueen väärä uintijärjestys",
    "SW 10.14 Toisen kilpailijan estäminen/häiritseminen viestissä tai viivyttely",
    "SW 10.16 Kirittäminen",
  ];

  var a = additionalInfo.toUpperCase().trim();
  var b = [];
  try {
    if (a.startsWith("SW") || a.match(/^\d(\d)?\.\d/)) {
      if (a.startsWith("SW")) {
        a = a.substring(2);
      }
      a = a.trim();
      if (a.split(" ").length > 1) {
        a = a.split(" ")[0];
      }
      for (var i = 0; i < codes.length; i++) {
        if (codes[i].substring(3).startsWith(a)) {
          b.push(codes[i]);
        }
      }
      if (b.length > 0) {
        var s = b[0];
        for (var j = 1; j < b.length; j++) {
          s = s + ", " + b[j];
        }
        return s;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (error) {
    console.log(error);
    return "";
  }
}

export function getStrokeName(stroke: string | undefined): string | undefined {
  if (stroke === "FREE") {
    return "Vapaauinti";
  } else if (stroke === "BREAST") {
    return "Rintauinti";
  } else if (stroke === "BACK") {
    return "Selkäuinti";
  } else if (stroke === "FLY") {
    return "Perhosuinti";
  } else if (stroke === "MEDLEY") {
    return "Sekauinti";
  }
  return undefined;
}

export function getTempusEventCode(swimStyle: SwimStyle): number | undefined {
  if (swimStyle === undefined) return undefined;
  if (swimStyle.distance === undefined) return undefined;
  if (swimStyle.stroke === undefined) return undefined;

  //console.debug(swimStyle);
  const tempusEventCodes: EventCodeArray = {
    "25m Vapaauinti": 4,
    "50m Vapaauinti": 11,
    "100m Vapaauinti": 13,
    "200m Vapaauinti": 15,
    "400m Vapaauinti": 17,
    "800m Vapaauinti": 19,
    "1500m Vapaauinti": 21,
    "25m Rintauinti": 5,
    "50m Rintauinti": 31,
    "100m Rintauinti": 33,
    "200m Rintauinti": 35,
    "25m Selkäuinti": 6,
    "50m Selkäuinti": 41,
    "100m Selkäuinti": 43,
    "200m Selkäuinti": 45,
    "25m Perhosuinti": 7,
    "50m Perhosuinti": 51,
    "100m Perhosuinti": 53,
    "200m Perhosuinti": 55,
    "100m Sekauinti": 61,
    "200m Sekauinti": 63,
    "400m Sekauinti": 65,
    Kaikki: 1000,
  };

  const tempusStrokeNames: StrokeNameArray = {
    FREE: "Vapaauinti",
    FLY: "Perhosuinti",
    BACK: "Selkäuinti",
    MEDLEY: "Sekauinti",
    BREAST: "Rintauinti",
  };
  return tempusEventCodes[swimStyle.distance + " " + tempusStrokeNames[swimStyle.stroke]];
}

export function openTempusEvent(swimStyle: any, pool: string | undefined, year: string | undefined) {
  //console.log(swimStyle);
  //console.log(entry);
  var datefirst;
  var datelast;
  if (year !== undefined) {
    try {
      datefirst = new Date().getFullYear() - parseInt(year);
      datelast = datefirst;
    } catch (error) {
      return;
    }
  }

  // https://www.tempusopen.fi/index.php?r=result%2Findex&Result%5Bresult_date%5D=2020&Result%5Bclass%5D=2&Result%5Bevent_code%5D=17&Result%5Bpooltype%5D=1&Result%5Bcompetition_group%5D=&Result%5Bdate_first%5D=14&Result%5Bdate_last%5D=14&Result%5Bclub_group%5D=&Result%5Bswim_club%5D=&Result%5Bbesttimes%5D=1&ajax=result-grid

  window.open(
    "https://www.tempusopen.fi/index.php?r=result%2Findex&Result%5Bresult_date%5D=" +
      new Date().getFullYear() +
      "&Result%5Bclass%5D=" +
      (swimStyle.sex === "MALE" ? "1" : "2") +
      "&Result%5Bevent_code%5D=" +
      getTempusEventCode(swimStyle) +
      (pool !== undefined ? "&Result%5Bpooltype%5D=" + (pool === "50m" ? 2 : 1) : "") +
      //+ "&Result%5Bcompetition_group%5D="
      (datefirst !== undefined ? "&Result%5Bdate_first%5D=" + datefirst : "") +
      (datelast !== undefined ? "&Result%5Bdate_last%5D=" + datelast : "") +
      //+ "&Result%5Bclub_group%5D="
      //+ "&Result%5Bswim_club%5D="
      "&Result%5Bbesttimes%5D=1" +
      "&ajax=result-grid"
  );
}

export function openTempusSwimmer(firstNames: string, lastName: string) {
  window.open(
    "https://www.tempusopen.fi/index.php?r=swimmer%2Findex&Swimmer%5Bfirst_name%5D=" +
      firstNames +
      "&Swimmer%5Blast_name%5D=" +
      lastName +
      "&Swimmer%5Bswimmer_club%5D=&Swimmer%5BsearchChoice%5D=&ajax=swimmer-grid"
  );
}

export function isInFavoriteClub(swimmer: Swimmer, favoriteClubs: FavoriteClubs | undefined) {
  if (swimmer === undefined || favoriteClubs === undefined) {
    return false;
  }
  if(swimmer.club && favoriteClubs.hasOwnProperty(swimmer.club)) {
    return true;
  }
  if(swimmer.club2 && favoriteClubs.hasOwnProperty(swimmer.club2)) {
    return true;
  }
}

export function isFavoriteClub(clubName: string, favoriteClubs: FavoriteClubs) {
  if (favoriteClubs.hasOwnProperty(clubName)) {
    return true;
  }
  return false;
}

export function getEventTime(competitionData: any, sid: string, event: string): string {
  if (competitionData.schedule === undefined) return "";
  if (competitionData.schedule[sid] === undefined) return "";
  if (competitionData.schedule[sid].scheduleItems === undefined) return "";
  for (var s = 0; s < competitionData.schedule[sid].scheduleItems.length; s++) {
    if (competitionData.schedule[sid].scheduleItems[s].event === event) return competitionData.schedule[sid].scheduleItems[s].time;
  }
  return "";
}

function hasHeatOrResultLists(session: Session, lists : Lists) {
  if (session === undefined) return false;
  if (session.programItems === undefined) return false;
  for (var i = 0; i < Object.keys(session.programItems).length; i++) {
    let listIds = getListIds(session.programItems[i]);
    if (
      lists[listIds.startListId] !== undefined ||
      lists[listIds.resultListId] !== undefined ||
      lists[listIds.resultListJuniorId] !== undefined
    ) {
      return true;
    }
  }
  return false;
}

export function getDefaultSid(sessions: Sessions | undefined) : sid {
  if (sessions === undefined) return "1";

  // find all sessions for today
  var today = new Date();
  var todayString = today.toISOString().split("T")[0];
  // make todaystring to be today minus two days
  var today = new Date();
  today.setDate(today.getDate() - 0);
  var todayString = today.toISOString().split("T")[0];

  // if the last session date is already in the past, return the last session sid
  var lastSession = Object.entries(sessions)[Object.entries(sessions).length-1];
  var lastSessionDate = (lastSession && lastSession.length > 0 ? lastSession[1].sessionDate : undefined);
  if(lastSessionDate) {
    var lastSessionDateObj = new Date(lastSessionDate);
    var lastSessionDateString = lastSessionDateObj.toISOString().split("T")[0];
    if(lastSessionDateString < todayString) {
      return lastSession[0];
      //console.debug("Last session date is in the past, defaulting to sid " + defaultsid);
    }
  }

  // if the first session day is in the future, return the first session sid
  var firstSession = Object.entries(sessions)[0];
  var firstSessionDate = (firstSession && firstSession.length > 0 ? firstSession[1].sessionDate : undefined);
  if(firstSessionDate) {
    var firstSessionDateObj = new Date(firstSessionDate);
    var firstSessionDateString = firstSessionDateObj.toISOString().split("T")[0];
    if(firstSessionDateString > todayString) {
      return firstSession[0];
      //console.debug("First session date is in the future, defaulting to sid " + defaultsid);
    }
  }

  // find the sessions for today (if any)
  var todaySIDs = Object.keys(sessions).filter((sid) => {
    var sessionDateString = sessions[sid].sessionDate?.split("T")[0];
    if(sessionDateString === undefined) return false;
    if(sessionDateString !== undefined && sessionDateString.length > 0 && sessionDateString === todayString) {
      return true;
    } else {
      return false;
    }
  });


  // if there is only one session for today, use that
  if (todaySIDs.length === 1) {
    //console.debug("Only one session for today, defaulting to sid " + defaultsid);
    return todaySIDs[0];
  }

  // if there are several sessions for today, find the last session that matches the following condition: "(start time minus one hour) is in the future" 
  if(todaySIDs.length > 1) {
    var now = new Date();
    // iterate through sessions in reverse order
    for (var i = todaySIDs.length - 1; i >= 0; i--) {
      var session = sessions[todaySIDs[i]];
      if(session === undefined) continue;
      var firstProgramItem = (session?.programItems ? session.programItems[0] : undefined);
      var lastProgramItem = (session?.programItems ? session.programItems[session.programItems.length-1] : undefined);
      var firstProgramItemStartTime = (firstProgramItem ? firstProgramItem.startTime : undefined);
      // if the date of firstProgramItemStartTime is not today, set the date to today but keep the time
      if(firstProgramItemStartTime !== undefined) {
        var firstProgramItemStartTimeObj = new Date(firstProgramItemStartTime);
        if(firstProgramItemStartTimeObj.toISOString().split("T")[0] !== todayString) {
          firstProgramItemStartTime = todayString + "T" + firstProgramItemStartTimeObj.toISOString().split("T")[1];
        }
      }
      var lastProgramItemStartTime = (lastProgramItem ? lastProgramItem.startTime : undefined);
      // if the date of lastProgramItemStartTime is not today, set the date to today but keep the time
      if(lastProgramItemStartTime !== undefined) {
        var lastProgramItemStartTimeObj = new Date(lastProgramItemStartTime);
        if(lastProgramItemStartTimeObj.toISOString().split("T")[0] !== todayString) {
          lastProgramItemStartTime = todayString + "T" + lastProgramItemStartTimeObj.toISOString().split("T")[1];
        }
      }
      if(firstProgramItemStartTime === undefined || lastProgramItemStartTime === undefined) continue;
      // if the first program item start time minus one hour is in the past, use that session
      var firstProgramItemStartTimeObj = new Date(firstProgramItemStartTime);
      var lastProgramItemStartTimeObj = new Date(lastProgramItemStartTime);
      var firstProgramItemStartTimeMinusOneHour = new Date(firstProgramItemStartTimeObj.getTime() - 60*60*1000);
      if(firstProgramItemStartTimeMinusOneHour < now) {
        return todaySIDs[i];
        //console.log("Comparing ", firstProgramItemStartTimeMinusOneHour, " < ", now, " => true, session starts in less than one hour, returning", defaultsid);
      }
    }
    // return the first sid of today
    return todaySIDs[0];
  }

  //console.debug("Defaulting to sid " + defaultsid);
  return "1";
}