import { useMediaQuery } from "@mui/material";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";

interface UinnitAppTabsProps {
  value: string | undefined;
  variant: "fullWidth" | "scrollable";
  onChange?: (event: React.SyntheticEvent, newValue: string) => void;
  children?: any;
  eventTabArray?: any;
}

function UinnitAppTabs(props: UinnitAppTabsProps) {
  const theme = useTheme();
  let isSmall = useMediaQuery(theme.breakpoints.down("md"));
  let bgcolor = isSmall ? (theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.primary.dark) : theme.palette.background.default;

  let tabCount = (props.eventTabArray ? props.eventTabArray.length : props.children?.length);
  let scrollButtons : boolean | "auto" | undefined = undefined;
  if(tabCount && tabCount > 3) {
    scrollButtons = true;
  } else {
    scrollButtons = false;
  }
 
  return (
    <Tabs
      value={props.value}
      variant={props.variant}
      scrollButtons={scrollButtons}
      allowScrollButtonsMobile
      textColor="inherit"
      indicatorColor={isSmall ? "secondary" : "primary"}
      onChange={props.onChange}
      role="navigation"
      sx={{
        //paddingLeft: { xs: "0rem", md: (navigationVariant === "rail" ? NAV_RAIL_WIDTH : NAV_DRAWER_WIDTH) + "rem" },
        backgroundColor: bgcolor,
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: "0 1px 0 0",
      }}
    >
      {props.eventTabArray &&
        props.eventTabArray.map((tab: any) => (
          <Tab key={tab.key} value={tab.key} 
            label={
              <>
                <div>
                  <Typography variant="inherit">{"Jakso " + tab.tabProgramItem.sid}</Typography>
                </div>
                <div>
                  <Typography variant="inherit">{tab.tabTitle}</Typography>
                </div>
              </>
          } />
        ))}
      {props.children}
    </Tabs>
  );
}

export default UinnitAppTabs;
