import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import { hasFavoriteSwimmers, isFavoriteSwimmer, toggleFavoriteSwimmer } from "../../User/FavoriteHelpers";
import { FlagIcon } from "../../Helpers";
import getSwimifyCompetitors from "../GetCompetitors";
import { LinearProgress, useTheme } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";

interface SwimmersFavoritesProps {
  setAccountDialog: (accountDialogOpen: boolean) => void;
  swimmers: Swimmers | undefined;
  userSettings: UserSettings | undefined;
  cid: string;
}

export function SwimmersFavorites(props: SwimmersFavoritesProps) {
  const navigate = useNavigate();
  const favoriteSwimmers = props.userSettings && props.userSettings.favoriteSwimmers;
  const { enqueueSnackbar } = useSnackbar();
  const anonGuestModeStarted = props.userSettings && props.userSettings.anonGuestModeStarted;
  const theme = useTheme();

  const skipCompetitors = props.cid.length === 4 || props.userSettings === undefined;
  const {
    swimifyCompetitors: swimifyCompetitors,
    swimifyCompetitorsLoading: swimifyCompetitorsLoading,
    swimifyCompetitorsLoadingError: swimifyCompetitorsLoadingError,
  } = getSwimifyCompetitors(props.cid, skipCompetitors);
  const swimmers = props.cid.length === 4 ? props.swimmers : swimifyCompetitors;

  let filteredFavoriteSwimmers = swimmers ? Object.values(swimmers).filter(filterFavoriteSwimmers).sort(sortAlpha) : [];

  function filterFavoriteSwimmers(swimmer: any, index: number, swimmers: any) {
    return favoriteSwimmers && favoriteSwimmers.hasOwnProperty(swimmer.id);
  }

  function sortAlpha(swimmerA: any, swimmerB: any) {
    return swimmerB.id.trim().localeCompare(swimmerA.id.trim()) > 0 ? -1 : 1;
  }

  return (
    <div>
        <div style={{ height: "4px", width: "100%", clear: "both", marginBottom: 0 }}>
          {(swimmers === undefined || swimifyCompetitorsLoading === undefined) && <LinearProgress />}
        </div>

        {swimmers !== undefined && Object.keys(swimmers).length === 0 && (
          <Typography variant="body2" style={{ margin: "12px" }}>
            Kilpailun listoissa ei ole vielä uimareita.
          </Typography>
        )}


      {swimmers !== undefined && Object.values(swimmers).length > 0 && (
        <div>
          {!hasFavoriteSwimmers(favoriteSwimmers) && (
            <div>
              <Typography variant="body2" style={{ margin: "12px" }}>
                Et ole valinnut omia uimareita.
              </Typography>
              <Typography variant="body2" style={{ margin: "12px" }}>
                Oletko jo rekisteröitynyt? Kirjaudu sovellukseen jotta näet aiemmin valitsemasi omat uimarit.
              </Typography>
              <Button style={{ margin: "12px" }} variant="outlined" onClick={(e) => props.setAccountDialog(true)}>
                Kirjaudu
              </Button>
            </div>
          )}

          {hasFavoriteSwimmers(favoriteSwimmers) && filteredFavoriteSwimmers.length === 0 && (
            <div>
              <Typography variant="body2" style={{ margin: "12px" }}>
                Valittuja omia uimareita ei löydy tämän kilpailun listoista.
              </Typography>
              <Button variant="outlined" style={{ margin: "12px" }} onClick={(e) => navigate("all")}>
                Valitse omat uimarit
              </Button>
            </div>
          )}

          {hasFavoriteSwimmers(favoriteSwimmers) && filteredFavoriteSwimmers.length > 0 && (
            <div>
              <List>
                {filteredFavoriteSwimmers.map((swimmer: Swimmer) => (
                  <ListItem key={"swimmer_" + swimmer.id} disablePadding
                    secondaryAction={
                      <>
                        <IconButton
                          edge="end"
                          onClick={(e) => toggleFavoriteSwimmer(swimmer.id, favoriteSwimmers, props.setAccountDialog, enqueueSnackbar, anonGuestModeStarted)}
                        >
                          {isFavoriteSwimmer(swimmer, favoriteSwimmers) ? <FavoriteIcon style={{ color: theme.palette.secondary.main }} /> : <FavoriteBorderIcon />}
                        </IconButton>
                        <IconButton edge="end" onClick={() => navigate("../../swimmer/" + swimmer.id)} tabIndex={-1}>
                          <ArrowRightIcon />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemButton
                      component={Link}
                      to={"./../swimmer/" + swimmer.id}
                    >
                    <ListItemText
                      primary={<span>{swimmer.id}</span>}
                      secondary={<span>
                        {<FlagIcon nationality={swimmer.nationality} />} {swimmer.club}
                      </span>}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
