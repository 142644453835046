import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getAuth } from "firebase/auth";
import { TransitionProps } from "@mui/material/transitions";
import LoginRegisterForm from "./LoginRegisterForm";
import { useSnackbar } from "notistack";
import { signOut } from "firebase/auth";
import { sendPasswordReset } from "../Firebase";
import { useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Container, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { enableAnonGuestMode } from "./AnonGuestHelpers";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AccountDialogProps {
  accountDialogOpen: boolean;
  setAccountDialog: (accountDialogOpen: boolean) => void;
  authUser: any;
}

export default function FullScreenDialog(props: AccountDialogProps) {
  const auth = getAuth();
  const authUser = auth ? auth.currentUser : undefined;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const signOutClicked = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await signOut(auth)
      .then(() => {
        enqueueSnackbar("Käyttäjä kirjattu ulos.");
        setLoading(false);
      })
      .catch((error) => {
        console.debug(error);
        enqueueSnackbar(error.message);
        setLoading(false);
      });
  };

  function accountInfoForm(authUser: any) {
    return (
      <>
        <div style={{ marginTop: "24px", marginBottom: "24px" }}>
          <Typography variant="h6">Kirjautunut käyttäjä:</Typography>
          <Typography variant="body1">{authUser.email}</Typography>
        </div>
        <Button fullWidth style={{ marginTop: "18px" }} variant="outlined" onClick={resetEmailClicked}>
          Vaihda salasana
        </Button>
        <Button fullWidth style={{ marginTop: "18px" }} variant="outlined" onClick={signOutClicked}>
          Kirjaudu ulos
        </Button>
      </>
    );
  }

  const resetEmailClicked = async (e: any) => {
    if (authUser && authUser.email) {
      setLoading(true);
      await sendPasswordReset(authUser.email)
        .then(() => {
          enqueueSnackbar("Linkki salasanan vaihtamiseen lähetetty osoitteeseen " + authUser.email, {
            autoHideDuration: 3000,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.debug(error);
          enqueueSnackbar(error);
          setLoading(false);
        });
    }
  };

  const anonGuestModeClicked = async (e: any) => {
    e.preventDefault();
    props.setAccountDialog(false);
    return enableAnonGuestMode();
  };

  return (
    <Dialog fullScreen open={props.accountDialogOpen} onClose={() => props.setAccountDialog(false)} TransitionComponent={Transition}>
      
      <AppBar color={"secondary"} style={{ zIndex: 900, backgroundColor: theme.palette.background.paper }} elevation={0}>
      <Container maxWidth="lg" disableGutters>

        <Toolbar sx={{
                backgroundColor: (theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.dark),
        }}>
          <div style={{ width: "100%" }}>
            <Grid container justifyContent="left" alignItems="center">
              <IconButton style={{ marginRight: "0.5rem" }} color="inherit" onClick={() => props.setAccountDialog(false)} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography>Käyttäjätilin tiedot</Typography>
            </Grid>
          </div>
        </Toolbar>
        </Container>
      </AppBar>
      <p>&nbsp;</p>
      
      <Container disableGutters maxWidth={"lg"}>
      <div style={{ height: "4px", width: "100%", clear: "both", marginBottom: 0 }}>{loading && <LinearProgress />}</div>
        <Box sx={{ align: "center", marginTop: "0px", marginLeft: "0.5rem", marginRight: "0.5rem", display: "flex", flexDirection: "column" }}>
          {authUser && authUser.email && accountInfoForm(authUser)}
          {authUser && !authUser.email && <LoginRegisterForm enqueueSnackbar={enqueueSnackbar} setAccountDialog={props.setAccountDialog} />}

          <Divider style={{ marginTop: "0.5rem" }} />

          {authUser && authUser.email ? (
            <Button style={{ marginTop: "0.5rem" }} variant="outlined" onClick={() => props.setAccountDialog(false)}>
              Sulje
            </Button>
          ) : (
            <Button style={{ marginTop: "0.5rem" }} variant="outlined" onClick={(e) => anonGuestModeClicked(e)}>
              Jatka ilman kirjautumista
            </Button>
          )}
        </Box>
      </Container>
    </Dialog>
  );
}
