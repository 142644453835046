import MoreIcon from "@mui/icons-material/MoreHoriz";
import PoolIcon from "@mui/icons-material/Pool";
import ScheduleIcon from "@mui/icons-material/Schedule";
import HomeIcon from "@mui/icons-material/Home";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { BOTTOMNAV_HEIGHT, NAV_RAIL_WIDTH, NAV_DRAWER_WIDTH } from "../Layout";
import { getDefaultSid } from "../Helpers";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

interface CompetitionNavigationProps {
  navigationCID: string;
  variant: "drawer" | "bottom" | "rail";
  currentCompetitionData: CompetitionData | undefined;
}

export function CompetitionNavigation(props: CompetitionNavigationProps) {
  const theme = useTheme();

  const actionSX_rail = {
    maxWidth: NAV_RAIL_WIDTH + "rem",
    minWidth: NAV_RAIL_WIDTH + "rem",
    minHeight: 72,
    maxHeight: 72,
    padding: "14px 8px",
    color: theme.palette.mode === "light" ? theme.palette.primary.contrastText : theme.palette.primary.main,
  };
  const actionSX_drawer = {
    maxWidth: NAV_DRAWER_WIDTH + "rem",
    minWidth: NAV_DRAWER_WIDTH + "rem",
    minHeight: (props.variant === "drawer" ? 48 : 72),
    maxHeight: (props.variant === "drawer" ? 48 : 72),
    padding: "14px 8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    '& .MuiBottomNavigationAction-label': { fontSize: '0.9rem' },
    color: theme.palette.mode === "light" ? theme.palette.primary.contrastText : theme.palette.primary.main,
  };
  const actionSX_bottom = { marginBottom: "10px", marginTop: "5px", color: theme.palette.primary.contrastText };

  const boxSX_rail = { 
    backgroundColor: theme.palette.primary.main, 
    position: "fixed", height: "100%", zIndex: 1200 
  };
  const boxSX_drawer = { 
    backgroundColor: theme.palette.primary.main, 
    position: "fixed", height: "100%", zIndex: 1200 
  };
  const boxSX_bottom = { position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 3 };

  const bottomNavSX_rail = {
    width: NAV_RAIL_WIDTH + "rem",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "0.2rem",
    height: "100%",
    backgroundColor: theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.background.default,
  };
  const bottomNavSX_drawer = {
    width: NAV_DRAWER_WIDTH + "rem",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "0.2rem",
    height: "100%",
    backgroundColor: theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.background.default,
  };
  const bottomNavSX_bottom = {
    height: BOTTOMNAV_HEIGHT + "rem",
    backgroundColor: theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.primary.dark,
  };
  const iconSX = (props.variant === "drawer") ? {
    marginRight: "0.9rem",
    marginLeft: "0.9rem",
  } : {};

  const defaultSID = getDefaultSid(props.currentCompetitionData?.sessions);

  return (
    <>
    <Paper sx={props.variant === "rail" ? boxSX_rail : props.variant === "drawer" ? boxSX_drawer : boxSX_bottom} elevation={props.variant === "bottom" ? 3 : 0}>
      <ScrollToTop />
      <BottomNavigation id={props.variant + "Nav"} showLabels sx={props.variant === "rail" ? bottomNavSX_rail : props.variant === "drawer" ? bottomNavSX_drawer : bottomNavSX_bottom}>
        <BottomNavigationAction label="Etusivu" component={Link} to={"/"} 
          sx={props.variant === "rail" ? actionSX_rail : props.variant === "drawer" ? actionSX_drawer : actionSX_bottom}
          icon={<HomeIcon style={iconSX}/>} />
        {props.navigationCID && <BottomNavigationAction
          label="Ohjelma"
          component={Link}
          to={"/competition/" + props.navigationCID + "/session/" + defaultSID}
          sx={props.variant === "rail" ? actionSX_rail : props.variant === "drawer" ? actionSX_drawer : actionSX_bottom}
          icon={<ScheduleIcon style={iconSX}/>}
       />}
        {props.navigationCID && <BottomNavigationAction
          label="Uimarit"
          component={Link}
          to={"/competition/" + props.navigationCID + "/swimmers"}
          sx={props.variant === "rail" ? actionSX_rail : props.variant === "drawer" ? actionSX_drawer : actionSX_bottom}
          icon={<PoolIcon style={iconSX}/>}
        />}
        {props.navigationCID && <BottomNavigationAction
          label="Lisää"
          component={Link}
          to={"/competition/" + props.navigationCID + "/more"}
          sx={props.variant === "rail" ? actionSX_rail : props.variant === "drawer" ? actionSX_drawer : actionSX_bottom}
          icon={<MoreIcon style={iconSX}/>}
        />}
      </BottomNavigation>
    </Paper>
    </>
  );
}

export default CompetitionNavigation;
