import SwimmerProgramItemContent from "../Swimmer/SwimmerProgramItemContent";

interface SwimmerCardProps {
  swimmer: Swimmer;
  competitionData: CompetitionData;
  programItem: ProgramItem;
  favoriteSwimmers: FavoriteSwimmers | undefined;
}

const SwimmerCard: React.FC<SwimmerCardProps> = ({ swimmer: swimmer, competitionData, programItem, favoriteSwimmers }) => {
  const swimmerProgramItem = swimmer.swimmerSessions?.[programItem.sid]?.swimmerProgramItems?.[programItem.programItemIndex];

  return (
    <>
      {swimmerProgramItem && (
        <SwimmerProgramItemContent
          cid={competitionData.cid}
          competitionData={competitionData}
          swimmerProgramItem={swimmerProgramItem}
          sid={programItem.sid}
          swimmer={swimmer}
          includeName={true}
          showSplits={false}
          showAthletes={true}
          dense={true}
          favoriteSwimmers={favoriteSwimmers}
        />
      )}
    </>
  );
};

export default SwimmerCard;
