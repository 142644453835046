import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Container, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { HideOnScroll } from "./HideOnScroll";
import { NAV_DRAWER_WIDTH, NAV_RAIL_WIDTH, getNavigationVariant } from "./Layout";

interface UinnitAppBarProps {
  title: string;
  titleElement?: any;
  includeBackButton?: boolean;
  prominent?: boolean;
  disableNav?: boolean;
  tabs?: any;
  denseTabs?: boolean;
  children?: any;
  actions?: any;
  currentHeats: CurrentHeat[] | undefined;
  navigationCID: string | undefined;
}

function UinnitAppBar(props: UinnitAppBarProps) {
  const theme = useTheme();

  const navigationVariant = getNavigationVariant(theme);

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  let navWidth = navigationVariant === "bottom" ? 0 : navigationVariant === "rail" ? NAV_RAIL_WIDTH : NAV_DRAWER_WIDTH;

  const bgcolor = isSmall
    ? theme.palette.mode === "light"
      ? theme.palette.primary.main
      : theme.palette.primary.dark // small background is blue
    : theme.palette.background.default; // wide background is white or black

  const textColor = isSmall
    ? theme.palette.mode === "light"
      ? theme.palette.primary.contrastText
      : theme.palette.primary.contrastText // small text is white or black
    : theme.palette.mode === "light"
    ? theme.palette.text.primary
    : theme.palette.primary.main; // wide text is black or blue

  const navigate = useNavigate();

  const toolbarSx = props.prominent
    ? {
        backgroundColor: bgcolor,
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: "0 1px 0 0",
        "@media all": {
          minHeight: props.prominent ? "5rem" : "",
        },
      }
    : {
        backgroundColor: bgcolor,
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderWidth: "0 1px 0 0",
      };

  const title = "Uinnit.app" + (props.title !== "Uinnit.app" ? " - " + props.title : "");

  const currentHeatActions = props.currentHeats !== undefined ? 
  <span>
    {props.currentHeats.map((currentHeat, index) => (
      <Link key={"currentHeat_" + index} to={"/competition/" + props.navigationCID + "/event/" + currentHeat.event + "/" + "starts" + "/" + currentHeat.sid + "/" + currentHeat.programItemIndex}>
        <Chip color="error" label="LIVE" icon={
          <Avatar sx={{ width: 24, height: 24, fontSize: "small", bgcolor: theme.palette.background.default }}><span style={{color: theme.palette.text.primary}}>{currentHeat.event}</span>
          </Avatar>}
        />
      </Link>  
    ))}
  </span> 
  : undefined;

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="Description" content={title} />
      </Helmet>
      <HideOnScroll {...props}>
        <AppBar
          color={navigationVariant === "bottom" ? "primary" : "transparent"}
          style={{ zIndex: 1100 }}
          elevation={navigationVariant === "bottom" || navigationVariant === "rail" ? 3 : 0}
        > 
        <Container maxWidth="lg" disableGutters>
          <Box sx={{ marginLeft: navWidth+"rem" }} >
          <Toolbar sx={toolbarSx}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Grid container alignItems="center" justifyContent="space-between" >
                {props.includeBackButton && (
                  <div>
                  <IconButton color="inherit" onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                  </IconButton>
                  </div>
                )}
                <Typography style={{ marginLeft: theme.spacing(1), color: textColor, fontWeight: 500, fontSize: "1rem", flex: 1, display: "inline-block" }}>
                  {props.titleElement ? <span>{props.titleElement}</span> : props.title}
                </Typography>
                {currentHeatActions}
                {props.actions}
                {props.children}
              </Grid>
            </Box>
          </Toolbar>

          {props.tabs}

          {!isSmall && (
            <Divider
              sx={{
                width: "100%",
                margin: "auto",
                //maxWidth: PAGE_WIDTH + "rem",
              }}
            />
          )}
          </Box>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar sx={{ backgroundColor: theme.palette.background.default }} />
      {props.prominent && <Toolbar variant="dense" sx={{ backgroundColor: theme.palette.background.default }} />}
      {props.tabs && <Toolbar variant={props.denseTabs === true ? "dense" : "regular"} sx={{ backgroundColor: theme.palette.background.default }} />}
    </div>
  );
}

export default UinnitAppBar;
