import { getAuth } from "firebase/auth";
import { getEnvPrefix } from "../Helpers";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";

export const enableAnonGuestMode = async () => {
  const auth = getAuth();
  if (auth === undefined || auth === null || auth.currentUser === undefined || auth.currentUser === null) {
    console.error("User not authenticated. Unable to store anon guest mode.");
  } else {
    console.log("Setting anonymous guest mode.");
    const userId = auth.currentUser.uid;
    var userDocRef = doc(getFirestore(), getEnvPrefix() + "users", userId);
    return updateDoc(userDocRef, { anonGuestModeStarted: serverTimestamp() })
      .then((response: any) => {
        return response;
      })
      .catch(function (error: any) {
        console.error(error);
      });
  }
};
