import { useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";

interface HeatAvatarProps {
  heatNumber: string;
  isCurrentHeat: boolean;
  heatStatus: number | undefined;
}

export function HeatAvatar(props: HeatAvatarProps) {
  const theme = useTheme();
  const heatNumber = props.heatNumber;
  const isCurrentHeat = props.isCurrentHeat;
  const heatStatus = props.heatStatus;

  const heatAvatarColor =  isCurrentHeat ? theme.palette.error.main : heatStatus === 3 ? "#53aa8a" : heatStatus === 2 ? "#53aa8a" : theme.palette.grey[500];

  const heatAvatar = (
    <Avatar variant="rounded" sx={{ backgroundColor: heatAvatarColor }}>
      <div style={{ display: "table", marginTop: "auto", marginBottom: "auto" }}>
        <div style={{ textAlign: "center", fontSize: "0.55rem", marginLeft: "auto", marginRight: "auto", marginTop: "0.1rem", marginBottom: "0.15rem" }}>
          ERÄ
        </div>
        <div style={{ display: "table", marginLeft: "auto", marginRight: "auto" }}>{heatNumber}</div>
      </div>
    </Avatar>
  );

  return heatAvatar;
}
