import Typography from "@mui/material/Typography";
import { Start } from "./EventStart";
import { useTheme } from "@mui/material/styles";
import { Avatar, Button, Card, CardActionArea, CardContent, CardHeader, Chip, Collapse, Divider, IconButton, IconButtonProps, List, styled } from "@mui/material";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fab from "@mui/material/Fab";
import { useEffect, useRef, useState } from "react";
import { getStartOrLiveStart } from "../../Helpers";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: { theme: any; expand: boolean }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface EventStartListProps {
  selectedList: StartList | undefined;
  cid: string;
  userSettings: UserSettings | undefined;
  itemSelected: (cid: string, swimmerid: string, event: string) => void;
  programItem: ProgramItem;
  currentHeats: CurrentHeat[];
  selectedListProgramItem: ProgramItem;
  liveHeatData: any;
  openLiveTiming: (link: string | undefined) => void;
  loading: boolean | undefined;
}

export function EventStartList(props: EventStartListProps) {
  const theme = useTheme();
  const selectedList = props.selectedList;
  const programItem = props.programItem;
  const selectedListProgramItem = props.selectedListProgramItem;
  const currentHeats = props.currentHeats;

  return (
    <>
      <div>
        {selectedList === undefined && (
          <Typography variant="subtitle2" style={{ padding: theme.spacing(2) }}>
            {props.loading ? "Ladataan erätietoja..." : "Ei erätietoja."}
          </Typography>
        )}
        {selectedList !== undefined && (selectedList.heatArray === undefined || selectedList.heatArray.length === 0) && (
          <Typography variant="subtitle2" style={{ padding: theme.spacing(2) }}>
            Ei erätietoja.
          </Typography>
        )}
        {selectedList?.heatArray !== undefined &&
          selectedList.heatArray.map((heat, index, array) => (
            <div key={"heatloop_" + index + "_" + heat.heatNumber} id={"heatId_" + heat.heatId}>
              <Heat cid={props.cid} heat={heat} liveHeatData={props.liveHeatData} programItem={programItem} userSettings={props.userSettings} />
            </div>
          ))}

        {selectedList?.liveTimingUrl && selectedList.liveTimingUrl.length > 0 && (
          <Button style={{ margin: theme.spacing(2) }} variant="outlined" color="primary" onClick={() => props.openLiveTiming(selectedList.liveTimingUrl)}>
            Avaa erälista LiveTimingissa
          </Button>
        )}
      </div>
    </>
  );

  interface HeatProps {
    cid: string;
    heat: Heat;
    programItem: ProgramItem;
    userSettings: UserSettings | undefined;
    liveHeatData: any;
  }

  function getHeatOrLiveHeat(heat: Heat, liveHeatData: any): Heat {
    if (liveHeatData && liveHeatData.id === heat.heatId) {
      return {
        ...heat,
        status: liveHeatData.status,
        estimatedStartTime: liveHeatData.estimated_start_time,
        startTime: liveHeatData.start_time,
        isLive: true,
      };
    }
    return heat;
  }

  function Heat(props: HeatProps) {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const heat = getHeatOrLiveHeat(props.heat, props.liveHeatData);
    //console.log("props.heat", props.heat, "liveHeatData", props.liveHeatData, "heat", heat);
    if (heat === undefined) return null;

    const isCurrentHeat = currentHeats.find((currentHeat) => currentHeat.heatId + "" === heat.heatId + "") ? true : false;
    const heatAvatarColor = isCurrentHeat ? theme.palette.error.main : heat.status === 3 ? "#53aa8a" : heat.status === 2 ? "#53aa8a" : theme.palette.grey[500];

    const heatAvatar = (
      <Avatar variant="rounded" style={{ backgroundColor: heatAvatarColor }}>
        <div style={{ display: "table", marginTop: "auto", marginBottom: "auto" }}>
          <div style={{ textAlign: "center", fontSize: "0.55rem", marginLeft: "auto", marginRight: "auto", marginTop: "0.1rem", marginBottom: "0.15rem" }}>
            ERÄ
          </div>
          <div style={{ display: "table", marginLeft: "auto", marginRight: "auto" }}>{heat.heatNumber}</div>
        </div>
      </Avatar>
    );

    const heatElementRef = useRef(null);

    const currentHeatFAB = isCurrentHeat && <ScrollToHeatButton heatElementRef={heatElementRef} />;

    return (
      <div style={{ marginTop: theme.spacing(1) }} ref={heatElementRef}>
        {currentHeatFAB}
        <Card
          key={"heatcard" + heat.heatNumber}
          elevation={0}
          square
          sx={{
            margin: theme.spacing(1),
            marginTop: theme.spacing(0),
            "& .MuiCardContent-root:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <CardActionArea
            onClick={() => setCollapsed(!collapsed)}          
          >
          <CardHeader
            sx={{
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              padding: theme.spacing(1),
              marginRight: 0,
              marginTop: 0,
              marginBottom: 0,
            }}
            avatar={heatAvatar}
            title={<HeatTitle heat={heat} programItem={props.programItem} />}
            action={
              <span>
                {heat.isLive === true && (
                  <span style={{ color: theme.palette.error.main }}>
                    <Chip
                      color="error"
                      sx={{ marginBottom: theme.spacing(0.65), marginTop: theme.spacing(0.65), marginRight: theme.spacing(0.5) }}
                      size="small"
                      label="LIVE"
                    />
                  </span>
                )}
                <ExpandMore expand={!collapsed}
                  component="span"
                  tabIndex={-1}
                  sx={{
                    marginTop: theme.spacing(0.65),
                    marginBottom: theme.spacing(0.65),
                  }}
                  >
                  <ExpandMoreIcon
                    sx={{
                      color: theme.palette.primary.contrastText,
                    }}
                  />
                </ExpandMore>
              </span>
            }
          />
          </CardActionArea>
          <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            <Card variant={"outlined"} square>
              <CardContent sx={{ padding: theme.spacing(0) }}>
                <List dense disablePadding>
                  {heat.starts !== undefined &&
                    heat.starts.map((start, index, array) => (
                      <div key={"startloop_" + index + "_" + start.id}>
                        <Start
                          start={getStartOrLiveStart(start, props.liveHeatData)}
                          event={programItem.event}
                          programItem={selectedListProgramItem}
                          userSettings={props.userSettings}
                          heatStatus={props.heat.status}
                        />
                        {index !== array.length - 1 && <Divider />}
                      </div>
                    ))}
                </List>
              </CardContent>
            </Card>
          </Collapse>
        </Card>
      </div>
    );
  }

  interface HeatTitleProps {
    heat: Heat;
    programItem: ProgramItem;
  }

  function HeatTitle(props: HeatTitleProps) {
    const heat = props.heat;
    // convert start_time to H.mm
    const startTimeTemp = heat.startTime && moment(heat.startTime).format("H.mm");
    const startTime = startTimeTemp?.startsWith("1.") || startTimeTemp?.startsWith("01.") ? undefined : startTimeTemp;
    // convert estimated_start_time to H.mm
    const estimatedStartTime = heat.estimatedStartTime ? moment(heat.estimatedStartTime).format("H.mm") : undefined;
    // timeDiff is difference between start_time and estimated_start_time in minutes
    const timeDiff = moment(heat.startTime).diff(moment(heat.estimatedStartTime), "minutes");
    // timeDiff is legit if it's between -1000 and +1000 minutes
    const timeDiffIsLegit = timeDiff > -1000 && timeDiff < 1000 ? true : false;
    // timeDiffPrefix is "+" if timeDiff is positive, "-" if negative
    const timeDiffPrefix = timeDiff >= 0 ? "+" : "-";
    // timeDiffString is timeDiff with prefix and " min" suffix
    const timeDiffString = " (" + timeDiffPrefix + Math.abs(timeDiff) + " min)";

    return (
      <>
        <Typography variant="subtitle2">{heat.heatName}</Typography>
        <Typography variant="body2">
          {startTime ? (
            <>
              <span style={{ color: theme.palette.primary.contrastText }}>Alkanut: </span>
              <span style={{ color: theme.palette.primary.contrastText }}>klo {startTime + (timeDiffIsLegit ? timeDiffString : "")}</span>
            </>
          ) : (
            <></>
          )}
        </Typography>
        <Typography variant="body2">
          {estimatedStartTime && (startTime === undefined || timeDiffIsLegit === false) ? (
            <>
              <span style={{ color: theme.palette.primary.contrastText }}>Arvioitu: </span>
              <span style={{ color: theme.palette.primary.contrastText }}>klo {estimatedStartTime}</span>
            </>
          ) : (
            <></>
          )}
        </Typography>
      </>
    );
  }
}

interface ScrollToHeatButtonProps {
  heatElementRef: React.MutableRefObject<null>;
}

function ScrollToHeatButton(props: ScrollToHeatButtonProps) {
  const theme = useTheme();
  const elementRef = props.heatElementRef;

  const [showScrollDown, setShowScrollDown] = useState(false);
  const [showScrollUp, setShowScrollUp] = useState(false);

  let bottomSpacing = Number(theme.spacing(2).slice(0, -2));
  bottomSpacing += document.getElementById("bottomNav")?.clientHeight || 0;

  const fabStyle = {
    position: "fixed",
    bottom: bottomSpacing,
    right: theme.spacing(2),
  };

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const element = elementRef.current as HTMLElement;
        const rect = element.getBoundingClientRect();
        const isVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth);
        const isAbove = rect.top < 0;
        const isBelow =
          rect.top >
          window.innerHeight -
            // height of the botton navigation bar, if it is visible
            (document.getElementById("bottomNav")?.clientHeight || 0) -
            // approximate height of the heat header
            50;
        const showScrollDown = isBelow && !isVisible;
        const showScrollUp = isAbove && !isVisible;
        setShowScrollUp(showScrollUp);
        setShowScrollDown(showScrollDown);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Initial check on component mount
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToHeat = () => {
    const element = elementRef.current as unknown as HTMLElement;
    element.scrollIntoView({ behavior: "smooth" });
  };

  if (showScrollDown) {
    return (
      <Fab color="error" sx={fabStyle} size="small" variant="circular" onClick={scrollToHeat}>
        <ExpandMoreIcon />
      </Fab>
    );
  } else if (showScrollUp) {
    return (
      <Fab color="error" sx={fabStyle} size="small" variant="circular" onClick={scrollToHeat}>
        <ExpandMoreIcon sx={{ transform: "rotate(180deg)" }} />
      </Fab>
    );
  } else {
    return null;
  }
}
