import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { FlagIcon, getSplitsArray, getTimeDiff, getPaceObject } from "../../Helpers";
import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, ReferenceLine } from "recharts";
import { LaneAvatar } from "../LaneAvatar";
import { PositionAvatar } from "../PositionAvatar";
import { HeatAvatar } from "../HeatAvatar";
import { isFavoriteSwimmer } from "../../User/FavoriteHelpers";

interface SwimmerProgramItemContentProps {
  cid: string;
  competitionData: CompetitionData | undefined;
  swimmerProgramItem: SwimmerProgramItem;
  sid: string;
  swimmer: Swimmer;
  includeName: boolean;
  showSplits: boolean;
  showAthletes: boolean;
  dense: boolean;
  favoriteSwimmers: FavoriteSwimmers | undefined;
}

const SwimmerProgramItemContent = (props: SwimmerProgramItemContentProps) => {
  const theme = useTheme();
  const competitionData = props.competitionData;
  const swimmerProgramItem = props.swimmerProgramItem;
  const sid = props.sid;
  const swimmer = props.swimmer;

  if (swimmer === undefined) return null;
  if (competitionData === undefined) return null;
  const session = competitionData.sessions && competitionData.sessions[sid];
  if (session === undefined) return <></>;
  const programItem = session.programItems && session.programItems[swimmerProgramItem.programItemIndex];
  if (programItem === undefined) return <></>;

  const start = swimmerProgramItem.start;
  const result = swimmerProgramItem.result;
  const entry = swimmerProgramItem.entry;

  let entryTime = entry?.entryTime ? entry.entryTime : start?.entryTime ? start.entryTime : result?.entryTime ? result.entryTime : undefined;

  let athletes = result && result.athletes;
  if (athletes === undefined) {
    athletes = entry?.athletes;
  }

  const splits: Splits | undefined = result?.splits && start ? result.splits : start?.splits ? start.splits : undefined;

  const resultTime = start?.resultTime ? start?.resultTime : result?.time;
  const raceDistance = programItem.swimStyle?.distance;
  const swimmed_distance = start?.swimmed_distance;
  const raceOngoing: boolean | undefined = swimmed_distance === undefined ? undefined : !(swimmed_distance + "m" === raceDistance);
  const latestSplitDistance = swimmed_distance ? swimmed_distance + "m" : undefined;
  const latestSplitTime = splits && latestSplitDistance && splits[latestSplitDistance];

  const additionalInfo = start?.additionalInfo ? start.additionalInfo : result?.additionalInfo ? result.additionalInfo : undefined;
  const dq_code = start?.dq_code ? start.dq_code : result?.dq_code ? result.dq_code : undefined;
  const dq_description = start?.dq_description ? start.dq_description : result?.dq_description ? result.dq_description : undefined;

  const dsq = start?.dsq ? true : false;
  const dnf = start?.dnf ? true : false;
  const dns = start?.dns ? true : false;

  const heatStatus = start?.heatStatus ? start.heatStatus : 0;

  const favoriteStyle = {
    color: theme.palette.secondary.main,
  };
  const nonFavoriteStyle = {
    color: theme.palette.text.primary,
  };

  return (
    <>
      {props.includeName && <NameAndResult swimmer={swimmer} resultTime={resultTime} entryTime={entryTime} />}

      {start && (
        <HeatElement
          cid={props.cid}
          start={start}
          entry={entry}
          result={result}
          dense={props.dense}
          includeTime={!props.includeName}
          heatStatus={heatStatus}
          raceOngoing={raceOngoing}
          latestSplitDistance={latestSplitDistance}
          latestSplitTime={latestSplitTime}
          dns={dns}
          dsq={dsq}
          dnf={dnf}
        />
      )}

      {additionalInfo && additionalInfo.length > 0 && (
        <Grid container sx={{ marginTop: theme.spacing(1) }}>
          <Grid item xs={12}>
            <Typography variant="body2">{additionalInfo}</Typography>
          </Grid>
        </Grid>
      )}

      {dq_code && dq_code.length > 0 && (
        <Grid container sx={{ marginTop: theme.spacing(1) }}>
          <Grid item xs={12}>
            <Typography variant="body2">{dq_code}</Typography>
          </Grid>
        </Grid>
      )}

      {dq_description && dq_description.length > 0 && (
        <Grid container sx={{ marginTop: theme.spacing(1) }}>
          <Grid item xs={12}>
            <Typography variant="body2">{dq_description}</Typography>
          </Grid>
        </Grid>
      )}

      {result !== undefined && <ResultRankings result={result} raceOngoing={raceOngoing} />}

      {props.dense && resultTime ? <></> : entry !== undefined && <Entry entry={entry} />}

      {props.showAthletes && athletes && athletes.length > 1 && (
        <Grid container sx={{ marginTop: theme.spacing(1) }}>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }}>
            Uimarit:
          </Typography>
          {athletes &&
            athletes.map(function (athlete: any, i: any) {
              return (
                <Grid key={"relay_competitor_" + athlete.id} item xs={12}>
                  <Typography variant="body2">
                    <span>{i + 1}: </span>
                    <span style={isFavoriteSwimmer(athlete, props.favoriteSwimmers) ? favoriteStyle : nonFavoriteStyle}>{athlete.id}</span>
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      )}

      {props.showSplits && splits && <SplitsElement splits={splits} distance={raceDistance + "m"} />}

      {props.showSplits && start?.reactionTime && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2">
              <span style={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }}>Reaktioaika: </span>
            </Typography>
            <Typography variant="body2">
              <span>{start.reactionTime}</span>
            </Typography>
          </Grid>
        </Grid>
      )}

      {props.showSplits && start && start.fina_points && start.fina_points > 0 ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2">
              <span style={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }}>FINA-pisteet: </span>
            </Typography>
            <Typography variant="body2">
              <span>{start.fina_points}</span>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      {props.showSplits && start && start.wps_points && start.wps_points > 0 ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2">
              <span style={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }}>WPS-pisteet: </span>
            </Typography>
            <Typography variant="body2">
              <span>{start.wps_points}</span>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default SwimmerProgramItemContent;

interface HeatElementProps {
  cid: string;
  entry: EntryListItem | undefined;
  start: StartListItem;
  result: ResultListItem | undefined;
  dense: boolean;
  includeTime: boolean;
  heatStatus: number;
  raceOngoing: boolean | undefined;
  latestSplitDistance: string | undefined;
  latestSplitTime: string | undefined;
  dnf: boolean | undefined;
  dsq: boolean | undefined;
  dns: boolean | undefined;
}

function HeatElement(props: HeatElementProps) {
  const start = props.start;
  const result = props.result;
  const entry = props.entry;

  const dns = props.dns;
  const dnf = props.dnf;
  const dsq = props.dsq;

  let entryTime = entry?.entryTime ? entry.entryTime : start?.entryTime ? start.entryTime : result?.entryTime ? result.entryTime : undefined;
  const resultTime = start?.resultTime ? start?.resultTime : result?.time;
  const timeDiff = getTimeDiff(resultTime, entryTime);

  const latestSplitDistance = props.latestSplitDistance;
  const latestSplitTime = props.latestSplitTime;
  const raceOngoing = props.raceOngoing;

  const theme = useTheme();

  return (
    <List disablePadding={true}>
      <ListItem disablePadding>
        <ListItemAvatar sx={{ minWidth: 0 }}>
          <HeatAvatar heatNumber={start.heat} isCurrentHeat={false} heatStatus={props.heatStatus} />
        </ListItemAvatar>
        <ListItemAvatar sx={{ minWidth: 0, marginLeft: theme.spacing(1) }}>
          <LaneAvatar track={start.track} />
        </ListItemAvatar>
        <ListItemText
          sx={{ marginTop: 0, marginBottom: 0 }}
          primary={props.start.heatName}
          primaryTypographyProps={{ variant: "body2" }}
          secondaryTypographyProps={{ variant: "body2" }}
          secondary={props.start.heatTimeText && "klo " + props.start.heatTimeText}
        />

        {props.includeTime && props.raceOngoing !== true && (
          <div>
            <Typography align="right" color={theme.palette.text.primary} sx={{ fontSize: "0.85rem", marginRight: theme.spacing(1) }}>
              {resultTime}
            </Typography>
            <Typography align="right" color={theme.palette.text.primary} sx={{ fontSize: "0.85rem", marginRight: theme.spacing(1) }}>
                {timeDiff && timeDiff.length > 0 && timeDiff.charAt(0) === "+" ? (
                  <span style={{ fontSize: "0.85rem", color: theme.palette.error.main }}> {timeDiff}</span>
                ) : (
                  <span style={{ fontSize: "0.85rem", color: theme.palette.success.main }}> {timeDiff}</span>
                )}
            </Typography>
          </div>
        )}

        {(dsq || dnf || dns) && (
          <Typography variant="body2">
            {dsq ? "DSQ" : ""}
            {dnf ? "DNF" : ""}
            {dns ? "DNS" : ""}
          </Typography>
        )}

        {props.includeTime && raceOngoing && latestSplitDistance && (
          <>
            <Typography align="right" color={theme.palette.text.secondary} sx={{ fontSize: "0.8rem", marginRight: theme.spacing(1) }}>
              <div>
                <span>{latestSplitDistance}</span>
              </div>
              <div>
                <span style={{ color: theme.palette.text.primary }}>{latestSplitTime}</span>
              </div>
            </Typography>
          </>
        )}
        {start.heat_rank ? (
          <PositionAvatar rank={"" + start.heat_rank} dsq={start.dsq} dnf={start.dnf} dns={start.dns} raceOngoing={props.raceOngoing} />
        ) : (
          <></>
        )}
      </ListItem>
    </List>
  );
}

interface RankingElementProps {
  ranking: string;
  series: string;
  dsq: boolean;
  dnf: boolean;
  dns: boolean;
  raceOngoing: boolean | undefined;
}

function RankingElement(props: RankingElementProps) {
  const theme = useTheme();

  return (
    <>
      <ListItem disableGutters disablePadding sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(0) }}>
        <ListItemText
          sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(0) }}
          primary={
            <Typography variant="body2">
              <span style={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }}>Sarja:</span>
            </Typography>
          }
          secondary={
            <Typography variant="body2">
              <span>{props.series}</span>
            </Typography>
          }
        />
        <PositionAvatar rank={props.ranking} dsq={props.dsq} dnf={props.dnf} dns={props.dns} raceOngoing={props.raceOngoing} />
      </ListItem>
    </>
  );
}

interface EntryProps {
  entry: EntryListItem;
}

function Entry(props: EntryProps) {
  const theme = useTheme();
  const entry = props.entry;

  if (entry === undefined) return null;

  return (
    <div>
      {entry.rankings &&
        Object.entries(entry.rankings).map(([series, ranking]) => {
          return (
            <div key={"entry_" + entry.sid + "_" + entry.programItemIndex + "_" + entry.id + "_" + ranking + "_" + series}>
              <Grid sx={{ marginTop: theme.spacing(1) }} container>
                <Grid item xs={8} key={"entryposition_" + entry.sid + "_" + entry.programItemIndex + "_" + entry.id + "_" + ranking + "_" + series}>
                  <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                    <span style={{ color: theme.palette.text.secondary }}>Lähtönumero: </span>
                  </Typography>
                  <Typography variant="body2">
                    <span>
                      {ranking} {series ? "(" + series + ")" : ""}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={4} key={"entrytime" + entry.sid + "_" + entry.programItemIndex + "_" + entry.id + "_" + ranking + "_" + series}>
                  <Typography variant="body2" align="right">
                    <span style={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }}>Ilm. aika: </span>
                  </Typography>
                  <Typography variant="body2" align="right">
                    <span>{entry.entryTime ? entry.entryTime : "-"}</span>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          );
        })}
    </div>
  );
}

interface RankingsProps {
  result: ResultListItem;
  raceOngoing: boolean | undefined;
}

function ResultRankings(props: RankingsProps) {
  const result = props.result;
  const raceOngoing = props.raceOngoing;

  if (result === undefined) return null;

  return (
    <div>
      {result.rankings && raceOngoing !== true && (
        <div>
          {Object.entries(result.rankings).map(([series, ranking]) => {
            return (
              <div key={"resultrank" + result.id + series}>
                <RankingElement ranking={ranking} series={series} dsq={false} dns={false} dnf={false} raceOngoing={raceOngoing} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

interface NameAndResultProps {
  swimmer: Swimmer;
  resultTime: string | undefined;
  entryTime: string | undefined;
}

function NameAndResult(props: NameAndResultProps) {
  const theme = useTheme();
  const swimmer = props.swimmer;
  const resultTime = props.resultTime;
  const entryTime = props.entryTime;
  const timeDiff = getTimeDiff(resultTime, entryTime);

  const highlightStyle = {
    color: theme.palette.secondary.main,
  };
  const nonHighlightStyle = {};
  const swimmerNameStyle = swimmer.isFavorite ? highlightStyle : nonHighlightStyle;
  const swimmerClubStyle = swimmer.isInFavoriteClub ? highlightStyle : nonHighlightStyle;

  return (
    <Grid container sx={{ marginBottom: theme.spacing(1) }}>
      <Grid item xs={8}>
        <Typography variant="body2">
          <span style={swimmerNameStyle}>{swimmer.id}</span>
        </Typography>
        <Typography variant="body2">
          <FlagIcon nationality={swimmer.nationality} />
          <span style={swimmerClubStyle}>{swimmer.club}</span>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {resultTime && (
          <>
            <Typography variant="body2" align="right">
              <span>
                <span>{resultTime}</span>
              </span>
            </Typography>
            <Typography variant="body2" align="right">
              {timeDiff && timeDiff.length > 0 && timeDiff.charAt(0) === "+" ? (
                <span style={{ color: theme.palette.error.main }}> {timeDiff}</span>
              ) : (
                <span style={{ color: theme.palette.success.main }}> {timeDiff}</span>
              )}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}

interface SplitsElementProps {
  distance: string;
  splits: Splits;
}

function SplitsElement(props: SplitsElementProps) {
  const theme = useTheme();
  const distance = props.distance;
  const splits = props.splits;

  const splitsArray: Split[] | undefined = splits && getSplitsArray(splits);
  const pacesObject: PaceObject | undefined = splitsArray && getPaceObject(splitsArray);

  let graphAxisWidth = 10;
  if (pacesObject?.pacediff_max && pacesObject?.pacediff_min) {
    graphAxisWidth = Math.max(Math.abs(pacesObject.pacediff_max), Math.abs(pacesObject.pacediff_min));
  }
  graphAxisWidth = Math.ceil(graphAxisWidth) * 1; // enough room

  function isNegative(pace: string) {
    if (pace.startsWith("-")) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Grid container sx={{ marginTop: "12px" }}>
      {pacesObject && pacesObject.paces && pacesObject.paces.length > 0 && (
        <>
          <Grid item xs={2} sx={{ marginTop: "12px" }}>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }}>
              Matka
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ marginTop: "12px" }}>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }}>
              Väliaika
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ marginTop: "12px" }}>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }}>
              +/-
            </Typography>
          </Grid>

          <Grid item xs={4} sx={{ marginTop: "12px" }}>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }} align="center">
              ka
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ marginTop: "12px" }}>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: "0.8rem" }} align="right">
              Aika
            </Typography>
          </Grid>
        </>
      )}

      <Grid item xs={2}>
        <div style={{ marginTop: "6px" }}>
          {pacesObject &&
            pacesObject.paces &&
            pacesObject.paces.length > 0 &&
            pacesObject.paces.map((pace: Pace, index: number) => {
              let distance_at = pace.name;
              let fontWeight = pacesObject.paces.length - 1 === index && distance_at + "m" === distance ? "bold" : "normal";
              return (
                <div key={"split_" + index}>
                  <Typography variant="body2" style={{ height: "24px", fontWeight: fontWeight }}>
                    <span style={{ color: theme.palette.text.primary }}>{pace.name}m</span>
                  </Typography>
                </div>
              );
            })}
        </div>
      </Grid>

      <Grid item xs={2}>
        <div style={{ marginTop: "6px" }}>
          {pacesObject &&
            pacesObject.paces &&
            pacesObject.paces.length > 0 &&
            pacesObject.paces.map((split: any, index: number) => {
              return (
                <div key={"splitpace_" + index}>
                  <Typography variant="body2" style={{ height: "24px" }}>
                    <span style={{ color: theme.palette.text.primary }}>{split.pace_text}</span>
                  </Typography>
                </div>
              );
            })}
        </div>
      </Grid>

      <Grid item xs={2}>
        <div style={{ marginTop: "6px" }}>
          {pacesObject &&
            pacesObject.paces &&
            pacesObject.paces.length > 0 &&
            pacesObject.paces.map((pace: Pace, index: number) => {
              let fontColor = isNegative(pace.pacediff_text) ? theme.palette.success.main : theme.palette.error.main;
              return (
                <div key={"splitpace_" + index}>
                  <Typography variant="body2" style={{ height: "24px" }}>
                    <span style={{ color: fontColor }}>{pace.pacediff_text}</span>
                  </Typography>
                </div>
              );
            })}
        </div>
      </Grid>

      <Grid item xs={4}>
        {pacesObject && pacesObject.paces && (
          <ResponsiveContainer width={"99%"} height={Object.keys(pacesObject.paces).length * 24 + 10}>
            <ScatterChart data={pacesObject.paces}>
              <Scatter type="monotone" fill={theme.palette.secondary.main} line></Scatter>
              <XAxis orientation="top" type={"number"} hide={true} dataKey={"diffToAverage_float"} domain={[-graphAxisWidth, graphAxisWidth]} />
              <YAxis reversed={true} type={"category"} dataKey="name" unit={"m"} axisLine={false} tick={false} hide={true} tickLine={false} />
              <ReferenceLine x={0} stroke="gray" strokeDasharray="3 3" />
            </ScatterChart>
          </ResponsiveContainer>
        )}
      </Grid>

      <Grid item xs={2}>
        <div style={{ marginTop: "6px" }}>
          {pacesObject &&
            pacesObject.paces &&
            pacesObject.paces.length > 0 &&
            pacesObject.paces.map((pace: Pace, index: number) => {
              let distance_at = pace.name;
              let fontWeight = pacesObject.paces.length - 1 === index && distance_at + "m" === distance ? "bold" : "normal";
              let fontColor =
                pacesObject.paces.length - 1 === index && distance_at + "m" === distance ? theme.palette.text.primary : theme.palette.text.primary;
              return (
                <div key={"splitstring_" + index}>
                  <Typography variant="body2" style={{ height: "24px" }} align="right">
                    <span style={{ color: fontColor, fontWeight: fontWeight }}>{pace.split_text}</span>
                  </Typography>
                </div>
              );
            })}
        </div>
      </Grid>
    </Grid>
  );
}
