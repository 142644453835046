import Avatar from "@mui/material/Avatar";
import useTheme from "@mui/material/styles/useTheme";

interface PositionAvatarProps {
    dsq: boolean | undefined;
    dnf: boolean | undefined;
    dns: boolean | undefined;
    rank: string | undefined;
    raceOngoing: boolean | undefined;
}

export function PositionAvatar(props: PositionAvatarProps) {
  
    const theme = useTheme();
  
    const goldPositions = ["1", "=1", "1."];
    const silverPositions = ["2", "=2", "2."];
    const bronzePositions = ["3", "=3", "3."];

    const rank = props.rank;
    const dsq = props.dsq;
    const dns = props.dns;
    const dnf = props.dnf;
    const raceOngoing = props.raceOngoing;
  
    let positionColor = theme.palette.primary.main;
    let bgcolor;
    let avatarBorder;
    let textColor;
    let fontSize = dsq || dnf || dns ? "0.8rem" : "1.2rem";
    if (rank && goldPositions.includes("" + rank)) {
      positionColor = "#d4af37";
      bgcolor = raceOngoing ? "transparent" : positionColor;
      textColor = raceOngoing ? positionColor : "";
    } else if (rank && silverPositions.includes("" + rank)) {
      positionColor = "#808080";
      bgcolor = raceOngoing ? "transparent" : positionColor;
      textColor = raceOngoing ? positionColor : "";
    } else if (rank && bronzePositions.includes("" + rank)) {
      positionColor = "#967444";
      bgcolor = raceOngoing ? "transparent": positionColor;
      textColor = raceOngoing ? positionColor : "";
    } else {
      positionColor = theme.palette.primary.main;
      bgcolor = raceOngoing ? "transparent" : positionColor;
      textColor = raceOngoing ? positionColor : "";
    }
    if (dsq || dnf || dns) {
      textColor = theme.palette.text.primary;
      bgcolor = "transparent";
    }
  
    avatarBorder =
      dsq !== true && dns !== true && dnf !== true && rank !== "0" && rank !== "undefined"
        ? "1px solid " + positionColor
        : "transparent";
  
    return (
      <Avatar
        sx={{
          bgcolor: bgcolor,
          border: avatarBorder,
          maxHeight: "2.25rem",
          maxWidth: "2.25rem",
          fontSize: fontSize,
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(0),
        }}
      >
        <span style={{ color: textColor }}>
          {rank !== undefined && !dns && !dnf && !dsq && rank !== "0" && rank !== "undefined" ? ""+rank : ""}
          {dns ? "DNS" : ""}
          {dsq ? "DSQ" : ""}
          {dnf ? "DNF" : ""}
        </span>
      </Avatar>
    );
  }