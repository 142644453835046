import { useQuery } from "@apollo/client";
import { getListIds, isInFavoriteClub } from "../Helpers";
import moment from "moment";
import { isFavoriteSwimmer } from "../User/FavoriteHelpers";
import { gql } from "../../src/__generated__/gql";
import { CompetitorDataFragment } from "./GetCompetitors";

export const ProgramItemFragment = gql(/* GraphQL */ `
  fragment ProgramItemData on time_program_entry {
    id
    oid
    round_oid
    sort_order
    start_time
    stop_time
    type
    name
  }
`);

export const RoundFragment = gql(/* GraphQL */ `
  fragment RoundData on round {
    id
    oid
    event_oid
    name
    official_at
    round_type
    sort_order
    status
  }
`);

export const SummaryTypeFragment = gql(/* GraphQL */ `
  fragment SummaryTypeData on summary_type {
    id
    oid
    order
    rank_type
    ranks_count
    round_oid
    sort_by_name
    name
  }
`);

export const RankFragment = gql(/* GraphQL */ `
  fragment RankData on rank {
    id
    rank
    type
  }
`);

export const LaneFragment = gql(/* GraphQL */ `
  fragment LaneData on lane {
    id
    competitor_oid
    dnf
    dns
    dq_code
    dq_description
    dq_relay_swimmer
    dsq
    entry_time_text
    fina_points
    heat_rank
    not_in_competition
    note
    number
    oid
    qualification
    reaction_time
    result_text
    swimmed_distance
    total_rank
  }
`);

export const HeatFragment = gql(/* GraphQL */ `
  fragment HeatData on heat {
    id
    oid
    name
    number
    start_time
    estimated_start_time
    status
  }
`);

/*
const RelayCompetitorsFragment = gql(/* GraphQL */ /*`
    fragment RelayCompetitorData on relay_competitor {
        id
        oid
        order
    }
`);*/
/*
                relay_competitors(order_by: { order: asc }) {
                    competition_id
                    competitor_oid
                    created_at
                    entry_oid
                    id
                    lane_oid
                    oid
                    order
                    updated_at
                }
                */

export const EventFragment = gql(/* GraphQL */ `
  fragment EventData on events {
    id
    oid
    athlete_count
    distance
    event_type
    gender_number
    hidden
    is_masters_event
    is_para_event
    is_relay
    name
    number
  }
`);

export const EntryFragment = gql(/* GraphQL */ `
  fragment EntryData on entry {
    id
    auto_withdrawn
    entry_time_text
    late_entry
    not_in_competition
    ranking
    withdrawn
    reserve
  }
`);

export const EntryRankFragment = gql(/* GraphQL */ `
  fragment EntryRankData on entry_rank {
    id
    oid
    rank
    entry_rank_type
  }
`);

export const EntryListTypeFragment = gql(/* GraphQL */ `
  fragment EntryListTypeData on entry_list_type {
    id
    oid
    name
    sort_by_name
    order
    entries_count
  }
`);

const PROGRAMITEM_QUERY = gql(/* GraphQL */ `
  query TimeProgramEntryData($programItemId: Int!) @cached(ttl: 30) {
    time_program_entry_by_pk(id: $programItemId) {
      ...ProgramItemData
      round {
        ...RoundData
        status
        summary_types(order_by: { order: asc }) {
          ...SummaryTypeData
          ranks {
            ...RankData
            lane {
              ...LaneData
              heat {
                ...HeatData
              }
              competitor {
                ...CompetitorData
                club {
                  ...ClubNames
                }
              }
            }
          }
        }
        event {
          ...EventData
          entry_list_types {
            ...EntryListTypeData
            entry_ranks {
              ...EntryRankData
              entry {
                ...EntryData
                competitor {
                  ...CompetitorData
                  club {
                    ...ClubNames
                  }
                  relay_competitors(order_by: { order: asc }) {
                    competition_id
                    competitor_oid
                    created_at
                    entry_oid
                    id
                    lane_oid
                    oid
                    order
                    updated_at
                  }
                }
              }
            }
          }
        }
      }
      heats {
        ...HeatData
        lanes {
          ...LaneData
          competitor {
            ...CompetitorData
            club {
              ...ClubNames
            }
            relay_competitors(order_by: { order: asc }) {
              competition_id
              competitor_oid
              created_at
              entry_oid
              id
              lane_oid
              oid
              order
              updated_at
            }
          }
        }
      }
    }
  }
`);

/*function getAthletes(relay_competitors: any): Athlete[] | undefined {
  if (relay_competitors === undefined) return undefined;
  let athleteArray: Athlete[] = [];
  for (let i = 0; i < relay_competitors.length; i++) {
    let c = relay_competitors[i].competitor;
    if (c && c !== null) {
      let athlete: Athlete = {
        id: c.full_name + " " + c.birthday.substring(0, 4),
        name: c.full_name,
        lastName: c.last_name,
        firstNames: c.first_name,
        year: c.birthday.substring(0, 4),
      };
      athleteArray.push(athlete);
    }
  }
  return athleteArray;
}*/

export function getSwimifyProgramItemLists(programItem: ProgramItem, userSettings: UserSettings | undefined, skip: boolean) {
  const programItemId = programItem.programItemId ? programItem.programItemId : -1; // never happens
  const {
    data: swimifyProgramItemLists,
    loading: swimifyProgramItemListsLoading,
    error: swimifyProgramItemListsError,
  } = useQuery(PROGRAMITEM_QUERY, {
    variables: { programItemId },
    pollInterval: 1 * 60 * 1000, // every 1 minute
    skip: skip || programItemId === -1,
  });

  const lists: Lists = {};

  if (swimifyProgramItemLists?.time_program_entry_by_pk?.round?.event && swimifyProgramItemLists?.time_program_entry_by_pk.round.event.entry_list_types) {
    let listId = getListIds(programItem).entryListId;
    let entryRanks: EntryRanksObject = {};
    swimifyProgramItemLists.time_program_entry_by_pk.round.event.entry_list_types.forEach((entry_list_type: any) => {
      let entries: EntryListItem[] = [];
      entry_list_type.entry_ranks.forEach((entry_rank: any) => {
        if (entry_rank.entry) {
          const entry: EntryListItem = {
            name: entry_rank.entry.competitor?.full_name,
            lastName: entry_rank.entry.competitor?.last_name,
            firstNames: entry_rank.entry.competitor?.first_name,
            club: entry_rank.entry.competitor?.club?.name,
            year: entry_rank.entry.competitor?.birthday?.substring(0, 4),
            id: entry_rank.entry.competitor?.full_name + " " + entry_rank.entry.competitor?.birthday?.substring(0, 4),
            listId: listId,
            listType: "entryList",
            listItemType: "entry",
            programItemIndex: programItem.programItemIndex,
            sid: programItem.sid,
            event: programItem.event,
            entryTime: entry_rank.entry.entry_time_text,
            ranking: entry_rank.rank,
            series: entry_list_type.name,
            nationality: entry_rank.entry.competitor?.country_code,
            //athletes: getAthletes(entry_rank.entry.competitor?.relay_competitors),
          };
          if (entry.id) {
            entries.push(entry);
          }
        }
      });
      entryRanks[entry_list_type.name] = entries;
    });
    const list: EntryList = {
      listId: listId,
      listType: "entryList",
      event: programItem.event,
      programItemIndex: programItem.programItemIndex,
      sid: programItem.sid,
      entryRanks: entryRanks,
      type: "STS",
    };
    lists[list.listId] = list;
  }

  if (swimifyProgramItemLists?.time_program_entry_by_pk?.heats) {
    let starts: StartListItem[] = [];
    let listId = getListIds(programItem).startListId;
    let heatArray: Heat[] = [];
    swimifyProgramItemLists?.time_program_entry_by_pk?.heats.forEach((heat: any) => {
      let thisHeatStarts: StartListItem[] = [];
      heat.lanes.forEach((lane: any) => {
        if (lane) {
          const start: StartListItem = {
            name: lane.competitor?.full_name,
            resultTime: lane.result_text,
            lastName: lane.competitor?.last_name,
            firstNames: lane.competitor?.first_name,
            event: programItem.event,
            club: lane.competitor?.club?.name,
            year: lane.competitor?.birthday?.substring(0, 4),
            id: lane.competitor?.full_name + " " + lane.competitor?.birthday?.substring(0, 4),
            listId: listId,
            listType: "startList",
            listItemType: "start",
            programItemIndex: programItem.programItemIndex,
            sid: programItem.sid,
            heat: heat.number,
            heatName: heat.name,
            track: lane.number,
            heatTimeText: moment(heat.estimated_start_time).format("H.mm"),
            startlistindex: 1000 * heat.number + lane.number,
            heat_rank: lane.heat_rank === 0 ? undefined : lane.heat_rank,
            swimmed_distance: lane.swimmed_distance,
            heatStatus: heat.status,
            isLive: false,
            additionalInfo:
              "" +
              (lane.qualification ? lane.qualification : "") +
              (lane.dnf ? "DNF " : "") +
              (lane.dns ? "DNS " : "") +
              (lane.dsq ? "DSQ " : "") +
              (lane.dq_code !== null ? lane.dq_code : "") +
              " " +
              (lane.dq_description !== null ? lane.dq_description : ""),
          };
          if (start.name !== null && start.name !== undefined) {
            starts.push(start);
            thisHeatStarts.push(start);
          }
        }
      });
      heatArray.push({
        heatId: heat.id,
        heatName: heat.name,
        heatNumber: heat.number,
        starts: thisHeatStarts,
        status: heat.status,
        isLive: false,
      });
    });
    const list: StartList = {
      listId: listId,
      listType: "startList",
      event: programItem.event,
      programItemIndex: programItem.programItemIndex,
      sid: programItem.sid,
      starts: starts,
      heats: swimifyProgramItemLists.time_program_entry_by_pk?.heats?.length,
      heatArray: heatArray,
    };
    lists[list.listId] = list;
  }

  const round = swimifyProgramItemLists?.time_program_entry_by_pk?.round;
  if (round && round.status === 5 && round.summary_types) {
    let listId = getListIds(programItem).resultListId;
    let summaries: SummariesObject = {};
    round.summary_types.forEach((summary_type: any) => {
      let results: ResultListItem[] = [];
      let seriesName: string = summary_type.name;
      if (summary_type) {
        summary_type.ranks.forEach((rank: any) => {
          if (rank) {
            const result: ResultListItem = {
              position: "" + rank.rank,
              name: rank.lane.competitor?.full_name,
              time: rank.lane.result_text,
              lastName: rank.lane.competitor?.last_name,
              firstNames: rank.lane.competitor?.first_name,
              event: programItem.event,
              club: rank.lane.competitor?.club?.name,
              year: rank.lane.competitor?.birthday?.substring(0, 4),
              id: rank.lane.competitor?.full_name + " " + rank.lane.competitor?.birthday?.substring(0, 4),
              listId: listId,
              listType: "resultList",
              listItemType: "result",
              programItemIndex: programItem.programItemIndex,
              sid: programItem.sid,
              series: summary_type.name,
              additionalInfo:
                "" +
                (rank.lane.qualification ? rank.lane.qualification : "") +
                (rank.lane.dnf ? "DNF " : "") +
                (rank.lane.dns ? "DNS " : "") +
                (rank.lane.dsq ? "DSQ " : "") +
                (rank.lane.dq_code !== null ? rank.lane.dq_code : "") +
                " " +
                (rank.lane.dq_description !== null ? rank.lane.dq_description : ""),
            };
            if (result.position === "0") {
              result.position = "-";
            }
            if (result.name !== null && result.name !== undefined) {
              results.push(result);
            }
          }
        });
        if (seriesName === undefined || seriesName === null || seriesName === "") {
          seriesName = "Total";
        }
        summaries[seriesName] = results;
      }
    });
    // sort summaries to that the element "Total" is always last (if it exists)
    const total = summaries["Total"];
    if (total) {
      delete summaries["Total"];
      summaries["Total"] = total;
    }

    const list: ResultList = {
      listId: listId,
      listType: "resultList",
      event: programItem.event,
      programItemIndex: programItem.programItemIndex,
      sid: programItem.sid,
      round: "" + programItem.roundOid,
      summaries: summaries,
    };
    lists[list.listId] = list;
  }

  // create Swimmer object

  const swimmers: Swimmers = {};

  // get an array of all unique swimmerids from all entryRanks
  for (const [listId, list] of Object.entries(lists)) {
    if (list.listType === "entryList") {
      let entryList = list as EntryList;
      if (entryList.entryRanks) {
        for (const [entryRankName, entryRankArray] of Object.entries(entryList.entryRanks)) {
          for (let entry of entryRankArray) {
            const swimmerId = entry.id;
            if (swimmerId !== undefined) {
              let swimmer: Swimmer = {
                id: swimmerId,
                name: entry.name,
                lastName: entry.lastName,
                firstNames: entry.firstNames,
                club: entry.club,
                year: entry.year,
                nationality: entry.nationality,
                athletes: entry.athletes,
              };
              if (userSettings && isFavoriteSwimmer(swimmer, userSettings.favoriteSwimmers)) {
                swimmer.isFavorite = true;
              }
              if (userSettings && isInFavoriteClub(swimmer, userSettings.favoriteClubs)) {
                swimmer.isInFavoriteClub = true;
              }
              swimmers[swimmerId] = swimmer;
            }
          }
        }
      }
    }
  }

  // iterate through swimmers object and add entryRanks, starts and results to each swimmer for each swimmersession and swimmerprogramitem
  for (const [swimmerId, swimmer] of Object.entries(swimmers)) {
    // --- results
    let resultRankingsObject: RankingsObject = {};
    let summaries = (lists[getListIds(programItem).resultListId] as ResultList)?.summaries;
    let swimmerResult: ResultListItem | undefined = undefined;
    if (summaries) {
      for (const [series, results] of Object.entries(summaries)) {
        for (let result of results) {
          if (result.id === swimmerId) {
            swimmerResult = result;
            resultRankingsObject[series] = result.position;
          }
        }
      }
    }
    if (swimmerResult) swimmerResult.rankings = resultRankingsObject;

    // --- starts
    let startArray = (lists[getListIds(programItem).startListId] as StartList)?.starts?.filter((start: StartListItem) => start.id === swimmerId);
    let firstStart = startArray && startArray.length > 0 ? startArray[0] : undefined;

    // --- entries
    let entryRankingsObject: RankingsObject = {};
    let entryRanks = (lists[getListIds(programItem).entryListId] as EntryList)?.entryRanks;
    let swimmerEntry: EntryListItem | undefined = undefined;
    if (entryRanks) {
      for (const [series, entries] of Object.entries(entryRanks)) {
        for (let entry of entries) {
          if (entry.id === swimmerId) {
            swimmerEntry = entry;
            if (entry.ranking !== undefined) {
              entryRankingsObject[series] = entry.ranking;
            } else if (entry.number !== undefined) {
              entryRankingsObject[series] = entry.number;
            }
          }
        }
      }
    }
    if (swimmerEntry) swimmerEntry.rankings = entryRankingsObject;

    swimmer["swimmerSessions"] = {
      [programItem.sid]: {
        sid: programItem.sid,
        swimmerProgramItems: {
          [programItem.programItemIndex]: {
            sid: programItem.sid,
            programItemIndex: programItem.programItemIndex,
            competitorType: "competitor",
            result: swimmerResult,
            entry: swimmerEntry,
            start: firstStart,
          },
        },
      },
    };
  }

  return { data: { lists: lists, swimmers: swimmers }, loading: swimifyProgramItemListsLoading, error: swimifyProgramItemListsError };
}

export default getSwimifyProgramItemLists;
