import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material/styles";
import { getDefaultSid } from "../Helpers";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";

interface CompetitionsProps {
  recentCompetitions: CompetitionData[];
  futureCompetitions: CompetitionData[];
}

function Competitions(props: CompetitionsProps) {
  const theme = useTheme();
  const { recentCompetitions, futureCompetitions } = props;

  const hasRecentCompetitions = recentCompetitions && recentCompetitions.length > 0;
  const hasFutureCompetitions = futureCompetitions && futureCompetitions.length > 0;

  const dateText = (startDate: string | undefined, endDate: string | undefined) => {
    if (startDate === undefined || endDate === undefined) return "";
    return startDate === endDate ? startDate : startDate + " - " + endDate;
  };

  const locationText = (location: string | undefined) => {
    return location ? location : "";
  };

  const renderCompetitionList = (competitions: CompetitionData[], title: string) => {
    return (
      <>
        <Divider sx={{ borderBottomWidth: "0.5rem" }} />

        <Card
          //variant="outlined"
          square
          elevation={0}
        >
          <Typography variant="body2" style={{ fontSize: "0.875rem", margin: theme.spacing(2), marginBottom: theme.spacing(0) }}>
            {title}
          </Typography>
          <List dense>
            {competitions
              .filter((v, i, a) => a.findIndex((t) => t.cid === v.cid) === i)
              .map((competition, index) => (
                <ListItem disablePadding key={competition.cid + "_" + index}>
                  <ListItemButton
                    component={Link}
                    to={
                      "/competition/" +
                      (competition.navigationCID ? competition.navigationCID : competition.cid) +
                      "/session/" +
                      getDefaultSid(competition.sessions)
                    }
                    aria-label={competition.competitionName}
                  >
                    <ListItemText
                      primary={competition.competitionName}
                      secondary={dateText(competition.startDate, competition.endDate) + " " + locationText(competition.competitionLocation)}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label={competition.competitionName} tabIndex={-1} >
                        <ArrowForwardIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Card>
      </>
    );
  };

  return (
    <div>
      {hasRecentCompetitions && renderCompetitionList(recentCompetitions, "Viimeksi seuraamasi kilpailut:")}
      {hasFutureCompetitions && renderCompetitionList(futureCompetitions.slice(0, 20), "Nyt ja tulevat kilpailut:")}
    </div>
  );
}

export default Competitions;
