import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider } from "@mui/material/styles/";
import { User, getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { doc, getFirestore, onSnapshot, serverTimestamp, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
//import "typeface-roboto";
import CompetitionNavigation from "./Competition/CompetitionNavigation";
import CompetitionSelector from "./CompetitionSelector";
import { getFirebaseApp } from "./Firebase";
import FrontPage from "./Frontpage/FrontPage";
import { getEnvPrefix } from "./Helpers";
import { BOTTOMNAV_HEIGHT, NAV_RAIL_WIDTH, NAV_DRAWER_WIDTH, getNavigationVariant } from "./Layout";
import InfoDialog from "./InfoDialog";
import AccountDialog from "./User/AccountDialog";
import { ThemeProvider } from "@mui/material/styles";
import getTheme from "./Theme";
import { useQuery, gql } from "@apollo/client";
import CompetitionSEO from "./Competition/CompetitionSEO";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const COMPETITIONS_QUERY = gql`
  query CompetitionsSince2024($endDate: date!) {
    competitions(order_by: { startDate: asc }, where: { endDate: { _gte: $endDate }, nation_code: { _eq: "FIN" } }) {
      city
      created_at
      endDate
      id
      name
      nation_code
      organizer
      pool_name
      pool_type
      seo_text
      startDate
      superlive
      updated_at
    }
  }
`;

function convertCompetitions(competitions: any) {
  if (competitions === undefined) return;
  let convertedCompetitions: CompetitionData[] = [];
  competitions.forEach((competition: any) => {
    convertedCompetitions.push({
      cid: competition.id,
      navigationCID: competition.seo_text,
      competitionName: competition.name,
      startDate: competition.startDate,
      endDate: competition.endDate,
      competitionDates: competition.competitionDates,
      competitionLocation: competition.city,
      organizer: competition.organizer,
      showCompetition: true,
    });
  });
  // filter out endDates older than today minus one day
  const filteredCompetitions = convertedCompetitions.filter((competition) => {
    if (competition.startDate === undefined) return false;
    const endDate = new Date(competition.endDate);
    const today = new Date();
    today.setDate(today.getDate() - 1);
    if (endDate >= today) {
      return true;
    } else {
      return false;
    }
  });
  const sortedCompetitions = filteredCompetitions.sort((a, b) => {
    if (a.startDate === undefined || b.startDate === undefined) return 0;
    if (a.startDate < b.startDate) {
      return -1;
    } else {
      return 1;
    }
  });
  return sortedCompetitions;
}

const App = () => {
  const [infoDialogOpen, setInfoDialog] = useState<boolean>(false);
  const [accountDialogOpen, setAccountDialog] = useState<boolean>(false);
  const [userSettings, setUserSettings] = useState<UserSettings>();
  const [authUser, setAuthUser] = useState<User>();
  const [recentCompetitions, setRecentCompetitions] = useState<CompetitionData[]>([]);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = getTheme(prefersDarkMode ? "dark" : "light");
  const navigationVariant = getNavigationVariant(theme);

  const todayDateString = new Date().toISOString().split("T")[0];
  const todayDate = new Date(todayDateString);

  const {
    data: futureCompetitionsData,
    loading,
    error,
  } = useQuery(COMPETITIONS_QUERY, {
    pollInterval: 1 * 60 * 1000, // every 1 minute
    variables: { endDate: todayDate },
  });

  const futureCompetitions = convertCompetitions(futureCompetitionsData && futureCompetitionsData.competitions ? futureCompetitionsData.competitions : []);

  useEffect(() => {
    const firebase = getFirebaseApp();
    const auth = getAuth();
    if (firebase) {
      onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          // user is signed in already.
          console.debug("Authentication state changed. User is signed in.", authUser);
          setAuthUser(authUser);
          const userId = authUser.uid;
          const userDoc = doc(getFirestore(), getEnvPrefix() + "users", userId);
          return setDoc(userDoc, { uid: userId, authTimestamp: serverTimestamp() }, { merge: true });
        } else {
          console.debug("User has signed out.");
          const prevUser = auth.currentUser;
          if (prevUser && !prevUser.isAnonymous) {
            console.debug("User was previously signed in as non-anonymous. User will not be signed in as anonymous.");
          } else {
            console.debug("User was previously signed in as anonymous. User will be signed in as anonymous again.");
            signInAnonymously(auth).catch(function (error) {
              var errorCode = error.code;
              var errorMessage = error.message;
              console.error("Auth error " + errorCode + " " + errorMessage);
            });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const firebase = getFirebaseApp();
    const auth = getAuth();
    if (firebase && auth && auth.currentUser) {
      const userId = auth.currentUser.uid;
      const unsub = onSnapshot(doc(getFirestore(), getEnvPrefix() + "users", userId), (doc) => {
        const userDoc = doc.data() as UserSettings;
        if (userDoc !== undefined) {
          console.debug("User settings updated from firestore:", userDoc, authUser);
          setUserSettings(userDoc);
        }
      });
      return unsub;
    }
  }, [authUser]);

  useEffect(() => {
    const rc = localStorage.getItem("recentCompetitions");
    if (rc !== null) {
      setRecentCompetitions(JSON.parse(rc));
    }
  }, []);

  function addRecentCompetition(competitionData: CompetitionData) {
    if (competitionData === undefined) return;
    if (competitionData.cid === undefined) return;
    let rc = [
      {
        cid: competitionData.cid,
        navigationCID: competitionData.navigationCID,
        competitionName: competitionData.competitionName,
        startDate: competitionData.startDate,
        endDate: competitionData.endDate,
        competitionDates: competitionData.competitionDates,
        competitionLocation: competitionData.competitionLocation,
        sessions: competitionData.sessions,
      } as CompetitionData,
    ].concat(recentCompetitions);
    rc = rc.filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.cid === v.cid) === i);
    if (rc.length > 5) {
      rc = rc.slice(0, 5);
    }
    setRecentCompetitions(rc);
    localStorage.setItem("recentCompetitions", JSON.stringify(rc));
  }

  let navWidth = navigationVariant === "bottom" ? 0 : navigationVariant === "rail" ? NAV_RAIL_WIDTH : NAV_DRAWER_WIDTH;

  const navCID =
    recentCompetitions && recentCompetitions[0] && recentCompetitions[0].navigationCID
      ? recentCompetitions[0].navigationCID
      : recentCompetitions && recentCompetitions[0] && recentCompetitions[0].cid;

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <div className="App">
          <InfoDialog infoDialogOpen={infoDialogOpen} setInfoDialog={setInfoDialog} />
          <AccountDialog accountDialogOpen={accountDialogOpen} setAccountDialog={setAccountDialog} authUser={authUser} />
          <Container disableGutters maxWidth={"lg"}>
            <CompetitionNavigation
              navigationCID={navCID}
              currentCompetitionData={recentCompetitions && recentCompetitions.length > 0 ? recentCompetitions[0] : undefined}
              variant={navigationVariant}
            />
            <Box sx={{ marginLeft: navWidth + "rem" }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <FrontPage authUser={authUser} futureCompetitions={futureCompetitions ? futureCompetitions : []} recentCompetitions={recentCompetitions} />
                  }
                />
                <Route
                  path="competitions"
                  element={
                    <>
                      <CompetitionSelector futureCompetitions={futureCompetitions ? futureCompetitions : []} />
                    </>
                  }
                />
                <Route path="competition">
                  <Route
                    path=":navigationCID/*"
                    element={
                      <CompetitionSEO
                        setInfoDialog={setInfoDialog}
                        setAccountDialog={setAccountDialog}
                        addRecentCompetition={addRecentCompetition}
                        futureCompetitions={futureCompetitions ? futureCompetitions : []}
                        recentCompetitions={recentCompetitions}
                        authUser={authUser}
                        userSettings={userSettings}
                      />
                    }
                  />
                </Route>
              </Routes>
              {navigationVariant === "bottom" && (
                // add a bottom navigation placeholder
                <div style={{ height: BOTTOMNAV_HEIGHT + "rem" }}></div>
              )}
            </Box>
          </Container>
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
