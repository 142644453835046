import Competitions from "./FrontPageCompetitions";
import { FrontPageWelcome } from "./FrontPageWelcome";
import { useTheme } from "@mui/material/styles";
import UinnitAppBar from "../UinnitAppBar";

interface FrontPageProps {
  recentCompetitions: CompetitionData[];
  futureCompetitions: CompetitionData[];
  authUser: any;
}

function FrontPage(props: FrontPageProps) {
  const theme = useTheme();

  return (
    <>
    <UinnitAppBar title="Uinnit.app" currentHeats={undefined} navigationCID={undefined}></UinnitAppBar>
    <div style={{backgroundColor: theme.palette.background.default, height: "auto",  minHeight: '100vh' }}>
      <FrontPageWelcome />
      <Competitions futureCompetitions={props.futureCompetitions} recentCompetitions={props.recentCompetitions} />
    </div>
    </>
  );
}

export default FrontPage;
