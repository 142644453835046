import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { useParams } from "react-router-dom";
import { FavoriteSwimmerControl } from "../../User/FavoriteHelpers";
import { useTheme } from "@mui/material/styles";
import UinnitAppBar from "../../UinnitAppBar";
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { FlagIcon } from "../../Helpers";
import SwimmerProgramItem from "./SwimmerProgramItem";
import { enqueueSnackbar } from "notistack";
import { getSwimifySwimmer } from "../GetSwimmerData";
import { SessionCard } from "../SessionCard";

interface CompetitionSwimmerProps {
  cid: string;
  setAccountDialog: any;
  loading: boolean;
  competitionData: CompetitionData | undefined;
  swimmers: Swimmers | undefined;
  userSettings: UserSettings | undefined;
  currentHeats: CurrentHeat[];
  navigationCID: string;
  liveHeatData: any;
}

const CompetitionSwimmer = (props: CompetitionSwimmerProps) => {
  const theme = useTheme();
  const { swimmerid } = useParams();
  const id = swimmerid !== undefined ? decodeURIComponent(swimmerid) : undefined;
  const competitionData = props.competitionData;
  const skipSwimmer = props.cid.length === 4 || id === undefined || id === null || competitionData === undefined;

  const {
    swimifySwimmer: swimifySwimmer,
    swimifySwimmerLoading: swimifySwimmerLoading,
    swimifySwimmerError: swimifySwimmerError,
  } = getSwimifySwimmer(props.liveHeatData, props.cid, id ? id : "neverhappens", competitionData, skipSwimmer, undefined);

  if (id === undefined) return null;
  const swimmer: Swimmer | undefined = props.cid.length === 4 && props.swimmers && id ? props.swimmers[id] : swimifySwimmer;

  function openTempus(firstNames: string, lastName: string) {
    window.open(
      "https://www.tempusopen.fi/index.php?r=swimmer%2Findex&Swimmer%5Bfirst_name%5D=" +
        firstNames +
        "&Swimmer%5Blast_name%5D=" +
        lastName +
        "&Swimmer%5Bswimmer_club%5D=&Swimmer%5BsearchChoice%5D=&ajax=swimmer-grid"
    );
  }

  return (
    <div>
      <UinnitAppBar
        includeBackButton={true}
        title={id}
        titleElement={<span>{"Uimarin lajit"}</span>}
        //actions={<span style={{ marginRight: theme.spacing(1) }}>{favoriteControl()}</span>}
        currentHeats={props.currentHeats}
        navigationCID={props.navigationCID}
      />

      <div style={{ height: "4px", width: "100%", clear: "both", marginTop: 0, marginBottom: 0 }}>
        {props.loading || swimifySwimmerLoading || ((swimmer === undefined || competitionData === undefined) && <LinearProgress />)}
      </div>

      <List disablePadding>
        <ListItem>
          <ListItemText
            primary={<span>{id}</span>}
            secondary={
              <span>
                {<FlagIcon nationality={swimmer?.nationality} />}
                {swimmer?.club}
              </span>
            }
          />
          <ListItemSecondaryAction>
            {swimmer && props.userSettings && (
              <FavoriteSwimmerControl
                swimmer={swimmer}
                setAccountDialog={props.setAccountDialog}
                favoriteSwimmers={props.userSettings.favoriteSwimmers}
                anonGuestModeStarted={props.userSettings.anonGuestModeStarted}
                enqueueSnackbar={enqueueSnackbar}
              />
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {competitionData !== undefined &&
        swimmer !== undefined &&
        swimmer.swimmerSessions !== undefined &&
        Object.entries(swimmer.swimmerSessions).map(([sid, swimmerSession]) => (
          <div key={"session_" + sid}>
            <Divider sx={{ borderBottomWidth: "0.5rem" }} />

            <SessionCard session={props.competitionData?.sessions && props.competitionData?.sessions[sid]} />
            <Divider />

            {swimmerSession.swimmerProgramItems !== undefined &&
              Object.entries(swimmerSession.swimmerProgramItems).map(([programItemIndex, swimmerProgramItem]) => (
                <div key={"pi" + programItemIndex}>
                  <SwimmerProgramItem
                    cid={props.cid}
                    currentHeats={props.currentHeats}
                    competitionData={competitionData}
                    swimmerProgramItem={swimmerProgramItem}
                    sid={sid}
                    swimmer={swimmer}
                    dense={false}
                    detailed={false}
                    favoriteSwimmers={props.userSettings?.favoriteSwimmers}
                  />
                </div>
              ))}
          </div>
        ))}

      <Divider sx={{ borderBottomWidth: "0.5rem" }} />
      <Button
        style={{ margin: theme.spacing(1), padding: theme.spacing(1) }}
        variant="outlined"
        color="primary"
        onClick={() => openTempus(id.split(" ")[0], id.split(" ")[1])}
      >
        Avaa uimari Tempus Openissa
      </Button>
    </div>
  );
};

export default CompetitionSwimmer;
