import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CancelIcon from "@mui/icons-material/Cancel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isFavoriteSwimmer, toggleFavoriteSwimmer } from "../../User/FavoriteHelpers";
import { FlagIcon } from "../../Helpers";
import getSwimifyCompetitors from "../GetCompetitors";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemButton from "@mui/material/ListItemButton";

interface SwimmersAllProps {
  swimmers: Swimmers | undefined;
  userSettings: UserSettings | undefined;
  setAccountDialog: (accountDialogOpen: boolean) => void;
  cid: string;
}

export function SwimmersAll(props: SwimmersAllProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [allSwimmersPage, setAllSwimmersPage] = useState<number>(1);
  const favoriteSwimmers = props.userSettings && props.userSettings.favoriteSwimmers;
  const anonGuestModeStarted = props.userSettings && props.userSettings.anonGuestModeStarted;
  const theme = useTheme();

  const skipCompetitors = props.cid.length === 4 || props.userSettings === undefined;
  const {
    swimifyCompetitors: swimifyCompetitors,
    swimifyCompetitorsLoading: swimifyCompetitorsLoading,
    swimifyCompetitorsLoadingError: swimifyCompetitorsLoadingError,
  } = getSwimifyCompetitors(props.cid, skipCompetitors);
  const swimmers = props.cid.length === 4 ? props.swimmers : swimifyCompetitors;

  const { enqueueSnackbar } = useSnackbar();

  let filteredAllSwimmers = swimmers ? Object.values(swimmers).filter(filterSwimmers).sort(sortAlpha) : [];
  const PER_PAGE = 50;
  const start = allSwimmersPage * PER_PAGE - PER_PAGE;
  const end = start + PER_PAGE - 1;
  let filteredPagedSearchSwimmers = filteredAllSwimmers ? filteredAllSwimmers.slice(start, end) : [];

  const navigate = useNavigate();

  const handlePageChange = (e: any, p: number) => {
    setAllSwimmersPage(p);
  };

  const searchTermChanged = (event: any) => {
    setSearchTerm(event.target.value);
    setAllSwimmersPage(1);
  };

  const clearSearch = (event: any) => {
    setSearchTerm("");
    setAllSwimmersPage(1);
  };

  function sortAlpha(swimmerA: any, swimmerB: any) {
    return swimmerB.id.trim().localeCompare(swimmerA.id.trim()) > 0 ? -1 : 1;
  }

  function filterSwimmers(swimmer: any, index: number, swimmers: any) {
    if (searchTerm === undefined || searchTerm === null || searchTerm === "" || searchTerm.trim() === "") {
      return true;
    } else {
      return (
        swimmer?.id?.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0 || swimmer?.club?.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0
      );
    }
  }

  return (
    <div>
      <div style={{ height: "4px", width: "100%", clear: "both", marginBottom: 0 }}>
        {(swimmers === undefined || swimifyCompetitorsLoading === undefined) && <LinearProgress />}
      </div>

      {swimmers !== undefined && Object.values(swimmers).length === 0 && (
        <Typography variant="body2" style={{ margin: "12px" }}>
          Kilpailun listoissa ei ole vielä uimareita.
        </Typography>
      )}

      {swimmers !== undefined && Object.values(swimmers).length > 0 && (
        <div>
          <div style={{ margin: "12px" }}>
            <FormControl style={{ marginTop: "12px" }} fullWidth={true} variant="outlined">
              <InputLabel htmlFor="outlined-searchterm">Hakusana</InputLabel>
              <OutlinedInput
                id="outlined-searchterm"
                type={"text"}
                fullWidth
                value={searchTerm}
                onChange={searchTermChanged}
                label={"Hakusana"}
                endAdornment={
                  <InputAdornment position="end">
                    {searchTerm !== undefined && searchTerm.length > 0 ? (
                      <IconButton onClick={clearSearch}>
                        <CancelIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          {searchTerm !== undefined && searchTerm.length > 0 && filteredAllSwimmers.length === 0 && (
            <div>
              <Typography variant="body2" style={{ margin: "12px" }}>
                Tästä kilpailusta ei löydy hakuun sopivia uimareita.
              </Typography>
            </div>
          )}
          {filteredAllSwimmers.length > 0 && (searchTerm === undefined || searchTerm.trim().length === 0) && (
            <Typography variant="body2" style={{ margin: "12px" }}>
              Tämän kilpailun uimarit ({filteredAllSwimmers.length}) näkyvät alla. Sydämellä voit merkitä uimarin omaksi uimariksesi.
            </Typography>
          )}

          {props.userSettings && (
            <div>
              <List>
                {filteredPagedSearchSwimmers.map((swimmer: any) => (
                  <ListItem
                    disablePadding
                    key={"swimmer_" + swimmer.id}
                    secondaryAction={
                      <>
                        <IconButton
                          edge="end"
                          onClick={() => toggleFavoriteSwimmer(swimmer.id, favoriteSwimmers, props.setAccountDialog, enqueueSnackbar, anonGuestModeStarted)}
                        >
                          {isFavoriteSwimmer(swimmer, favoriteSwimmers) ? (
                            <FavoriteIcon style={{ color: theme.palette.secondary.main }} />
                          ) : (
                            <FavoriteBorderIcon />
                          )}
                        </IconButton>
                        <IconButton edge="end" tabIndex={-1} onClick={() => navigate("../../swimmer/" + swimmer.id)}>
                          <ArrowRightIcon />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemButton component={Link} to={"../../swimmer/" + swimmer.id} aria-label={swimmer.id}>
                      <ListItemText
                        primary={<span>{swimmer.id}</span>}
                        secondary={
                          <span>
                            {<FlagIcon nationality={swimmer.nationality} />} {swimmer.club}
                          </span>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              {filteredAllSwimmers.length > PER_PAGE && (
                <Pagination onChange={handlePageChange} page={allSwimmersPage} count={Math.ceil(filteredAllSwimmers.length / PER_PAGE)} color="primary" />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
