import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Card, CardActionArea, CardHeader, CardContent, CardActions, Collapse, Typography, Divider, IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { getListIds, isInFavoriteClub } from "../../Helpers";
import { isFavoriteSwimmer } from "../../User/FavoriteHelpers";
//import { isFavoriteSwimmerInRelayTeam } from "../../User/FavoriteHelpers";
import SwimmerCard from "./SwimmerCard";
import { useTheme } from "@mui/material/styles";
import getSwimifyProgramItemData from "../GetProgramItemData";
import { ProgramItemAvatar } from "../ProgramItemAvatar";

interface ProgramItemProps {
  cid: string;
  sid: string;
  programItem: ProgramItem;
  competitionData: CompetitionData;
  userSettings: UserSettings | undefined;
  clubPairs: ClubNamePairs | undefined;
  lists: Lists | undefined;
  swimmers: Swimmers | undefined;
  currentHeats: CurrentHeat[];
}

const ProgramItem = (props: ProgramItemProps) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const programItem = props.programItem;
  const cid = props.cid;
  const sid = props.sid;
  const competitionData = props.competitionData;

  const skipProgramItems = props.cid.length === 4 || props.userSettings === undefined;
  const {
    data: listsAndSwimmers,
    loading: swimifyProgramItemDataLoading,
    error: swimifyProgramItemDataError,
  } = getSwimifyProgramItemData(programItem, props.userSettings, skipProgramItems);

  const lists = props.cid.length === 4 ? props.lists : listsAndSwimmers.lists;
  const swimmers = props.cid.length === 4 ? props.swimmers : listsAndSwimmers.swimmers;

  function getEventLink(cid: string, event: string, programItemIndex: number, listType: "entries" | "starts" | "results" | undefined) {
    if (event !== "0") {
      if (listType !== undefined) {
        return "/competition/" + cid + "/event/" + event + "/" + listType + "/" + sid + "/" + programItemIndex;
      } else {
        return "/competition/" + cid + "/event/" + event;
      }
    } else {
      return "";
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function hideResults() {
    if (props.cid.length === 4) {
      return false;
    } else {
      if (programItem.status === 5) {
        return false;
      } else {
        return true;
      }
    }
  }

  const listIds = getListIds(props.programItem);
  let resultList = hideResults() === false ? lists && lists[listIds.resultListId] : undefined;
  let startList = ((lists && lists[listIds.startListId]) as StartList)?.heats > 0 ? lists && lists[listIds.startListId] : undefined;
  let entryList = lists && lists[listIds.entryListId];

  function filterMySwimmers(swimmers: Swimmers | undefined, userSettings: UserSettings | undefined) {
    if (swimmers === undefined || userSettings === undefined) return undefined;
    let mySwimmers: Swimmers | undefined = {};
    if (userSettings.favoriteSwimmers) {
      for (let swimmerId in swimmers) {
        if (isFavoriteSwimmer(swimmers[swimmerId], userSettings.favoriteSwimmers) || isInFavoriteClub(swimmers[swimmerId], userSettings.favoriteClubs)) {
          mySwimmers[swimmerId] = swimmers[swimmerId];
        }
        /*if(isFavoriteSwimmerInRelayTeam(swimmers[swimmerId], userSettings.favoriteSwimmers)) {
          mySwimmers[swimmerId] = swimmers[swimmerId];
        }*/
      }
    }
    return mySwimmers;
  }

  const filteredMySwimmers = filterMySwimmers(swimmers, props.userSettings);

  function filterProgramItemSwimmers(swimmers: Swimmers | undefined, sid: string, programItemIndex: number) {
    if (swimmers === undefined) return undefined;
    let swimmersForProgramItem: Swimmers = {};
    for (let swimmerId in swimmers) {
      let swimmer = swimmers[swimmerId];
      if (swimmer && swimmer.swimmerSessions && swimmer.swimmerSessions[sid] && swimmer.swimmerSessions[sid].swimmerProgramItems[programItemIndex]) {
        swimmersForProgramItem[swimmerId] = swimmers[swimmerId];
      }
    }
    return swimmersForProgramItem;
  }

  const mySwimmersForProgramItem =
    cid.length === 4 ? filterProgramItemSwimmers(filteredMySwimmers, props.sid, props.programItem.programItemIndex) : filteredMySwimmers;

  let programItemTime = props.programItem.startTimeText;
  const programItemIndex: number = props.programItem.programItemIndex;

  const notClickable = props.programItem.event !== "0";
  // || [entryListId, entryListJuniorId, startListId, resultListJuniorId, resultListId].some(id => id !== undefined);

  let cardInfo;
  if (swimmers === undefined) {
    cardInfo = "LOADING";
  } else {
    if (swimmers === undefined) {
      //} || resultList === undefined && resultListJunior === undefined && startList === undefined && entryList === undefined) {
      cardInfo = "NO_DATA";
    } else {
      if (mySwimmersForProgramItem && Object.keys(mySwimmersForProgramItem).length > 0) {
        cardInfo = "HAS_MY_SWIMMERS";
      } else {
        cardInfo = "NO_MY_SWIMMERS";
      }
    }
  }

  let isLive = false;
  for (let i = 0; i < props.currentHeats.length; i++) {
    if (props.currentHeats[i].programItemIndex === props.programItem.programItemIndex) {
      if (props.currentHeats[i].sid === props.programItem.sid) {
        isLive = true;
      }
    }
  }

  const programItemName = props.programItem.programItemName;
  const event = props.programItem.event;

  let entriesCount = 0;
  // count all unique swimmerids from all entryRanks in all entry lists, make sure to count only once per swimmer
  if (entryList !== undefined) {
    let entryRanks = (entryList as EntryList).entryRanks;
    if (entryRanks !== undefined) {
      let uniqueSwimmerIds = new Set();
      // loop through all elements in EntryRanks object and add swimmerId to uniqueSwimmerIds from all arrays in entryRanks
      Object.entries(entryRanks).forEach(([key, value]) => {
        for (let i = 0; i < value.length; i++) {
          uniqueSwimmerIds.add(value[i].id);
        }
      });
      entriesCount = uniqueSwimmerIds.size;
    }
  }

  let resultsCount = 0;
  // count all unique swimmerids from all summaries in all result lists, make sure to count only once per swimmer
  if (resultList !== undefined) {
    let summaries = (resultList as ResultList).summaries;
    if (summaries !== undefined) {
      let uniqueSwimmerIds = new Set();
      // loop through all elements in Summaries object and add swimmerId to uniqueSwimmerIds from all arrays in summaries
      Object.entries(summaries).forEach(([key, value]) => {
        for (let i = 0; i < value.length; i++) {
          uniqueSwimmerIds.add(value[i].id);
        }
      });
      resultsCount = uniqueSwimmerIds.size;
    }
  }

  let heatsCount = startList !== undefined && (startList as StartList).heatArray ? (startList as StartList).heatArray.length : undefined;

  let listInfoText: any = undefined;
  let listType: "results" | "starts" | "entries" | undefined = undefined;
  if (resultList !== undefined && resultsCount > 0) {
    listInfoText = "Tulokset: " + resultsCount;
    listType = "results";
  } else if (startList !== undefined && heatsCount !== undefined) {
    listInfoText = "Erät: " + heatsCount;
    listType = "starts";
  } else if (entryList !== undefined && entriesCount > 0) {
    listInfoText = "Osallistujat: " + entriesCount;
    listType = "entries";
  }

  return (
    <div>
      <Card
        //variant="outlined"
        square
        elevation={0}
        sx={{
          // @ts-ignore
          expand: {
            transform: "rotate(0deg)",
          },
          expandOpen: {
            transform: "rotate(180deg)",
          },
        }}
      >
        <CardActionArea
          component={Link}
          disabled={event === "0" ? true : false}
          to={getEventLink(competitionData.navigationCID ? competitionData.navigationCID : cid, event, programItemIndex, listType)}
        >
          <CardHeader
            sx={{
              width: "100%",
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
              //backgroundColor: theme.palette.primary.light,
            }}
            avatar={<ProgramItemAvatar programItem={props.programItem} isLive={isLive} />}
            title={
              <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
                {programItemName}
              </Typography>
            }
            action={
              event !== "0" &&
              notClickable && (
                <IconButton size="small" edge="end" aria-label={programItemName} tabIndex={-1}>
                  <ArrowRightIcon sx={{ margin: theme.spacing(0.5) }} />
                </IconButton>
              )
            }
            subheader={
              <>
                {programItemTime !== undefined && programItemTime.length > 0 ? <span>{"Klo " + programItemTime + ""}</span> : ""}
                {programItemTime !== undefined && programItemTime.length > 0 && event !== "0" && listInfoText ? <span>{" | "}</span> : ""}
                {event !== "0" ? <span>{listInfoText}</span> : ""}
              </>
            }
          />
        </CardActionArea>

        {event !== "0" && (
          <CardContent>
            <CardActions
              sx={{
                paddingLeft: theme.spacing(1),
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: theme.spacing(1),
                //backgroundColor: theme.palette.primary.light,
              }}
            >
              <ButtonGroup>
                {cardInfo === "HAS_MY_SWIMMERS" && (
                  <Button style={{ color: theme.palette.secondary.main }} variant="text" onClick={handleExpandClick}>
                    {expanded === true
                      ? "Piilota omat uimarit (" + (mySwimmersForProgramItem && Object.keys(mySwimmersForProgramItem).length) + ")"
                      : "Näytä omat uimarit (" + (mySwimmersForProgramItem && Object.keys(mySwimmersForProgramItem).length) + ")"}
                    {expanded === true ? <ExpandMore style={{ transform: "rotate(180deg)" }} /> : <ExpandMore style={{ transform: "rotate(0deg)" }} />}
                  </Button>
                )}
                {cardInfo === "NO_DATA" && (
                  <Button variant="text" disabled>
                    Ei osallistujatietoja
                  </Button>
                )}
                {cardInfo === "NO_MY_SWIMMERS" && (
                  <Button variant="text" disabled>
                    Ei omia uimareita
                  </Button>
                )}
                {cardInfo === "LOADING" && (
                  <Button variant="text" disabled>
                    Lataa...
                  </Button>
                )}
              </ButtonGroup>
            </CardActions>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {mySwimmersForProgramItem &&
                Object.keys(mySwimmersForProgramItem).map((swimmerId) => (
                  <div key={"programitem_" + sid + "_" + programItemIndex + "_" + swimmerId}>
                    <Divider sx={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }} />
                    <CardActionArea
                      sx={{ paddingTop: theme.spacing(1), paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(1) }}
                      component={Link}
                      to={"../swimmer/" + swimmerId + "/event/" + event}
                    >
                      <SwimmerCard
                        competitionData={competitionData}
                        swimmer={mySwimmersForProgramItem[swimmerId]}
                        programItem={props.programItem}
                        favoriteSwimmers={props.userSettings?.favoriteSwimmers}
                      />
                    </CardActionArea>
                  </div>
                ))}
            </Collapse>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default ProgramItem;
