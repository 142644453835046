import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { collection, doc, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEnvPrefix, getSplitsArray, openTempusSwimmer, getPaceObject } from "../../../Helpers";
import { Checkbox, Grid, IconButton, ListItemButton, Pagination, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { gql } from "../../../__generated__/gql";
import { useQuery, useApolloClient } from "@apollo/client";
import React from "react";
import { ArrowCircleRight } from "@mui/icons-material";

function getFirstProgramItem(competitionData: CompetitionData, event: any): ProgramItem | undefined {
  if (competitionData.sessions === undefined) {
    return undefined;
  }
  try {
    for (var i = 0; i < Object.keys(competitionData.sessions).length; i++) {
      var session = competitionData.sessions[Object.keys(competitionData.sessions)[i]];
      for (var j = 0; session.programItems !== undefined && j < Object.keys(session.programItems).length; j++) {
        var programItem = session.programItems[Object.keys(session.programItems)[j] as any];
        if (programItem.event === event) {
          return programItem;
        }
      }
    }
    console.warn("Event " + event + " not found in sessions and programs.");
    return undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

function getSwimStyle(competitionData: CompetitionData, event: string): any {
  const firstProgramItem = getFirstProgramItem(competitionData, event);
  if (firstProgramItem !== undefined) {
    return firstProgramItem.swimStyle;
  } else {
    return undefined;
  }
}

interface CompetitionSwimmerEventHistoryProps {
  cid: string;
  competitionData: CompetitionData;
  currentHeats: CurrentHeat[];
  navigationCID: string;
  swimmer: Swimmer;
  event: string;
}

interface strokesType {
  [key: string]: number;
}

const strokes: strokesType = {
  FREE: 1001,
  BREAST: 1002,
  BACK: 1003,
  FLY: 1004,
  MEDLEY: 1005,
};

const COMPETITORCOMPETITIONS_QUERY = gql(/* GraphQL */ `
  query CompetitorCompetitions($full_name: String!) @cached(ttl: 60) {
    competitor(where: { full_name: { _eq: $full_name }, competition: { startDate: { _gte: "2024-01-01" } } }) {
      age
      birthday
      competition_id
      id
      full_name
      country_code
      club {
        id
        name
      }
      competition {
        name
        startDate
        endDate
        seo_text
      }
    }
  }
`);

const COMPETITIONRESULT_QUERY = gql(/* GraphQL */ `
  query CompetitorResults($competitor_id: Int!, $competition_id: uuid!, $event_type: Int!, $distance: Int!) @cached(ttl: 60) {
    lane(
      where: {
        competition_id: { _eq: $competition_id }
        competitor: { id: { _eq: $competitor_id } }
        heat: { round: { event: { event_type: { _eq: $event_type }, distance: { _eq: $distance } } } }
      }
    ) {
      id
      competition_id
      result_text
      qualification
      dns
      dsq
      dnf
      dq_code
      dq_description
      heat_rank
      reaction_time
      total_rank
      entry_pool
      heat {
        round {
          event {
            number
          }
        }
      }
      sub_results(order_by: { order: asc }) {
        done_at
        order
        result_value_text
      }
    }
  }
`);

const CompetitionSwimmerEventHistory = (props: CompetitionSwimmerEventHistoryProps) => {
  const [liveTimingResults, setLiveTimingResults] = useState<any[] | undefined>(undefined);
  const [liveTimingCompetitionList, setLiveTimingCompetitionList] = useState<Array<CompetitionData> | undefined>(undefined);
  const [liveTimingResultsLoading, setLiveTimingResultsLoading] = useState<boolean>(false);
  const [liveTimingCompetitionListLoading, setLiveTimingCompetitionListLoading] = useState<boolean>(false);
  const theme = useTheme();

  //const id = decodeURIComponent(swimmerid === undefined ? "" : swimmerid);
  const id = props.swimmer.id;
  const event = props.event;
  const swimStyle = props.competitionData !== undefined ? getSwimStyle(props.competitionData, event) : undefined;

  let event_type: number = strokes[swimStyle?.stroke];
  let distance: number = parseInt(swimStyle?.distance.substring(0, swimStyle?.distance.length - 1));
  // set full name to be swimmerid but remove the last five characters
  let full_name = id.substring(0, id.length - 5);

  const { data, loading, error } = useQuery(COMPETITORCOMPETITIONS_QUERY, {
    variables: { full_name },
    skip: props.competitionData === undefined,
  });

  const swimifyCompetitions =
    data?.competitor !== undefined
      ? data?.competitor.map((competitor: any) => {
          if (competitor.competition_id === props.cid) {
          }
          return {
            competitor_swimify_id: competitor.id,
            competition_id: competitor.competition_id,
            competition_name: competitor.competition?.name,
            startDate: competitor.competition?.startDate,
            endDate: competitor.competition?.endDate,
            event_type: event_type,
            distance: distance,
            stroke: swimStyle?.stroke,
            seo_text: competitor.competition?.seo_text,
          };
        })
      : [];

  useEffect(() => {
    // TODO this is not optimal, we should only fetch the competition list once
    if (id !== undefined && swimStyle !== undefined) {
      return getLiveTimingCompetitionList();
    }
  }, [id, swimStyle]);

  useEffect(() => {
    if (id !== undefined && swimStyle !== undefined) {
      return getLiveTimingResultsData(id, swimStyle);
    }
  }, [id, swimStyle]);

  useEffect(() => {}, [swimifyCompetitions]);

  function getLiveTimingCompetitionList() {
    console.debug("Loading competitions...");
    const competitionsListDocRef = doc(getFirestore(), getEnvPrefix() + "LT-competitionsList", "competitionsListDoc");
    setLiveTimingCompetitionListLoading(true);
    const unsub = onSnapshot(competitionsListDocRef, (doc) => {
      var dd = doc.data();
      if (dd !== undefined) {
        console.debug("Competition list updated from firestore (" + dd.competitions.length + ")");
        setLiveTimingCompetitionList(dd.competitions);
        setLiveTimingCompetitionListLoading(false);
      }
    });
    return unsub;
  }

  function getLiveTimingResultsData(id: string, swimStyle: SwimStyle) {
    const q = query(
      collection(getFirestore(), getEnvPrefix() + "results"),
      where("swimmersResultsSearchTerm", "==", id + "_" + swimStyle.distance + "_" + swimStyle.stroke)
    );
    setLiveTimingResultsLoading(true);
    const unsub = onSnapshot(q, (snapshot) => {
      let liveTimingResults = snapshot.docs.map((doc) => doc.data());
      setLiveTimingResults(liveTimingResults);
      setLiveTimingResultsLoading(false);
    });
    return unsub;
  }

  return (
    <div>
      <div>
        <div style={{ height: "4px", width: "100%", clear: "both", marginTop: 0, marginBottom: 0 }}>
          {(liveTimingCompetitionListLoading === undefined || liveTimingResultsLoading || props.competitionData === undefined || loading === true) && (
            <LinearProgress />
          )}
        </div>

        <div style={{ margin: theme.spacing(1), fontSize: "0.8rem" }}>
          {liveTimingResults !== undefined && swimifyCompetitions !== undefined && liveTimingCompetitionList !== undefined && (
            <CompetitionList
              event_type={event_type}
              distance={distance}
              liveTimingCompetitionList={liveTimingCompetitionList}
              liveTimingResults={liveTimingResults}
              swimifyCompetitions={swimifyCompetitions}
            />
          )}

          <div>
            <Button
              style={{ margin: theme.spacing(1), marginTop: theme.spacing(2) }}
              variant="outlined"
              color="primary"
              onClick={() => openTempusSwimmer(decodeURIComponent(id).split(" ")[0], decodeURIComponent(id).split(" ")[1])}
            >
              Avaa uimari Tempus Openissa
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CompetitionListProps {
  event_type: number;
  distance: number;
  liveTimingCompetitionList: any;
  liveTimingResults: any;
  swimifyCompetitions: any;
}

interface checkboxStateType {
  [key: string]: boolean;
}

function CompetitionList(props: CompetitionListProps) {
  const [swimifyResults, setSwimifyResults] = useState<any[] | undefined>(undefined);
  const [combinedResults, setCombinedResults] = useState<any[] | undefined>(undefined);

  const [page, setPage] = useState<number>(1);

  const [checkboxStates, setCheckboxStates] = useState<checkboxStateType>({});

  const swimifyCompetitions = props.swimifyCompetitions;
  const client = useApolloClient();

  const theme = useTheme();

  useEffect(() => {
    const fetchSwimifyData = async () => {
      if (swimifyCompetitions) {
        const followUpPromises = swimifyCompetitions.map((competition: any) => {
          const competitor_id = competition.competitor_swimify_id;
          const competition_id = competition.competition_id;
          const event_type = competition.event_type;
          const distance = competition.distance;

          if (event_type) {
            return client.query({
              query: COMPETITIONRESULT_QUERY,
              variables: { competitor_id, competition_id, event_type, distance },
            });
          }
        });

        const dataArray = await Promise.all(followUpPromises);
        const dataFiltered = dataArray.filter((data: any) => data?.data?.lane.length > 0);
        setSwimifyResults(dataFiltered);
      }
    };
    fetchSwimifyData();
  }, [swimifyCompetitions]);

  useEffect(() => {
    //  combining results from swimifyResults and liveTimingResults
    if (swimifyResults === undefined) return;
    if (props.liveTimingResults === undefined) return;
    if (props.liveTimingCompetitionList === undefined) return;

    let results = [];

    for (let i = 0; i < swimifyResults.length; i++) {
      let swimifyResult = swimifyResults[i];
      let competition_id = swimifyResult.data.lane[0].competition_id;
      let result_text = swimifyResult.data.lane[0].result_text;
      let total_rank = swimifyResult.data.lane[0].total_rank;
      let heat_rank = swimifyResult.data.lane[0].heat_rank;
      let reaction_time = swimifyResult.data.lane[0].reaction_time;
      if (reaction_time?.startsWith("+")) {
        reaction_time = reaction_time.substring(1);
      }
      let startDate = getSwimifyCompetitionStartDate(swimifyCompetitions, competition_id);
      let endDate = getSwimifyCompetitionEndDate(swimifyCompetitions, competition_id);
      let additionalInfo =
        (swimifyResult.data.lane[0].dns ? "DNS" : "") +
        (swimifyResult.data.lane[0].dsq ? "DSQ" : "") +
        (swimifyResult.data.lane[0].dnf ? "DNF" : "") +
        (swimifyResult.data.lane[0].dq_code ? swimifyResult.data.lane[0].dq_code : "");
      let splits = convertToSplits(swimifyResult.data.lane[0].sub_results);
      let paces = getPaceObject(getSplitsArray(convertToSplits(swimifyResult.data.lane[0].sub_results)));
      let event = swimifyResult.data.lane[0].heat.round.event.number;
      let seo_text = getSwimifyCompetitionSEO(swimifyCompetitions, competition_id);
      let pool = swimifyResult.data.lane[0].entry_pool === 2 ? "50m" : swimifyResult.data.lane[0].entry_pool === 1 ? "25m" : undefined;

      // getPaceDiffData(getPaceData(getSplitData(getOrderedSplits(splits))))

      results.push({
        competition_id: competition_id,
        competition_name: getSwimifyCompetitionName(swimifyCompetitions, competition_id),
        startDate: startDate,
        endDate: endDate,
        result_text: result_text,
        total_rank: total_rank,
        heat_rank: heat_rank,
        reaction_time: reaction_time,
        additionalInfo: additionalInfo,
        splits: splits,
        paces: paces,
        id: competition_id + result_text + total_rank,
        source: "swimify",
        event: event,
        seo_text: seo_text,
        pool: pool,
      });
    }

    for (let i = 0; i < props.liveTimingResults.length; i++) {
      let liveTimingResult = props.liveTimingResults[i];
      let cid = liveTimingResult.cid;
      let competition_name = getLiveTimingCompetitionName(props.liveTimingCompetitionList, cid);
      let startDate = getLiveTimingCompetitionStartDate(props.liveTimingCompetitionList, cid);
      let endDate = getLiveTimingCompetitionEndDate(props.liveTimingCompetitionList, cid);

      let result_text = liveTimingResult.result?.time;
      let reaction_time = liveTimingResult.result?.reactionTime;
      let position = liveTimingResult.result?.position;
      let additionalInfo = liveTimingResult.result?.additionalInfo;

      let splits = liveTimingResult.result?.splits;
      let paces = getPaceObject(getSplitsArray(liveTimingResult.result?.splits));

      results.push({
        competition_id: cid,
        competition_name: competition_name,
        startDate: startDate,
        endDate: endDate,
        result_text: result_text,
        reaction_time: reaction_time,
        total_rank: position,
        splits: splits,
        paces: paces,
        additionalInfo: additionalInfo,
        id: cid + result_text + position,
        source: "livetiming",
      });
    }

    results.sort((a: any, b: any) => {
      if (a.startDate < b.startDate) {
        return 1;
      } else {
        return -1;
      }
    });

    results = results.filter((result: any, index: number) => {
      const a = new Date(result.startDate);
      const b = new Date("2024-01-01");
      if (result.source === "livetiming" && a > b) {
        return false;
      } else {
        return true;
      }
    });

    setCombinedResults(results);
  }, [swimifyResults, props.liveTimingResults, props.liveTimingCompetitionList]);

  // filteredResults is the results that are checked by the user
  const filteredResults = combinedResults?.filter((result: any, index: number) => {
    return checkboxStates["result" + result.id] === true;
  });

  interface filteredPacesObjectType {
    [key: string]: any;
  }

  interface filteredPaceObjectType {
    [key: string]: any;
  }

  let filteredPaces: filteredPacesObjectType = {};

  if (filteredResults) {
    for (let i = 0; i < filteredResults.length; i++) {
      let result = filteredResults[i];
      if (result.paces && result.paces.paces) {
        result.paces.paces.map((pace: any, index: number) => {
          let d = pace.name;
          if (filteredPaces[d] === undefined) {
            let p: filteredPaceObjectType = {};
            p[result.id] = pace;
            filteredPaces[d] = p;
          } else {
            // add pace to existing distance
            filteredPaces[d][result.id] = pace;
          }
        });
      }
    }
  }

  let additionalInfos: string[] = [];
  filteredResults?.map((result: any, index: number) => {
    additionalInfos.push(result.additionalInfo);
  });

  // function to handle listitembuttonclick and update the check box statuses accordingly
  const handleListItemButtonClick = (key: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [key]: !checkboxStates[key],
    }));
  };

  const PER_PAGE = 5;
  const start = page * PER_PAGE - PER_PAGE;
  const end = start + PER_PAGE;
  let filteredCombinedResults = combinedResults === undefined ? undefined : combinedResults.slice(start, end);

  const handlePageChange = (e: any, p: number) => {
    setPage(p);
  };

  const tvelve: number = 12.0;
  let columnWidth: number = tvelve;
  if (filteredResults && filteredResults.length) {
    columnWidth = tvelve / (filteredResults.length + 1);
  }

  const cellStyle = {
    borderBottom: 1,
    borderBottomColor: theme.palette.grey[300],
  };

  const paceCellStyle = {
    borderBottom: 1,
    borderBottomColor: theme.palette.grey[200],
  };

  return (
    <>
      <Typography variant="subtitle2">Valitse lajin aiemmat tulokset vertailuun:</Typography>
      {filteredCombinedResults && combinedResults && (
        <>
          <List dense disablePadding>
            {filteredCombinedResults.map((result: any, index: number) => (
              <ListItem disablePadding key={"resultmap" + index + result.id} disableGutters>
                <ListItemButton onClick={handleListItemButtonClick("result" + result.id)}>
                  <Checkbox edge="start" key={"result" + result.id} checked={checkboxStates["result" + result.id] === true} disableRipple />
                  <ListItemText primary={result.competition_name} secondary={result.startDate + " - " + result.endDate} />
                  <Typography variant="body2">{result.result_text}</Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Pagination onChange={handlePageChange} page={page} count={Math.ceil(combinedResults.length / PER_PAGE)} color="primary" />
        </>
      )}

      {filteredResults && filteredResults.length > 0 && (
        <div>
          <Typography sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1) }} variant="subtitle2">
            Valitut tulokset:
          </Typography>

          <div>
            <Grid container style={{ overflowX: "auto", margin: 0 }}>
              <Grid item xs={columnWidth} sx={cellStyle}>
                <Typography style={{ fontSize: "0.8rem", color: theme.palette.text.secondary }}>Päivä:</Typography>
              </Grid>
              {filteredResults.map((result: any, index: number) => (
                <Grid key={"day" + index + result.id} item xs={columnWidth} sx={cellStyle}>
                  <Typography style={{ fontSize: "0.8rem" }}>{result.startDate}</Typography>
                </Grid>
              ))}

              <Grid item xs={columnWidth} sx={cellStyle}>
                <Typography style={{ fontSize: "0.8rem", color: theme.palette.text.secondary }}>Kilpailu:</Typography>
              </Grid>
              {filteredResults.map((result: any, index: number) => (
                <Grid key={"name" + index + result.id} item xs={columnWidth} sx={cellStyle}>
                  <Typography noWrap style={{ fontSize: "0.8rem" }}>
                    {result.competition_name}
                  </Typography>
                </Grid>
              ))}

              <Grid item xs={columnWidth} sx={cellStyle}>
                <Typography style={{ fontSize: "0.8rem", color: theme.palette.text.secondary }}>Allas:</Typography>
              </Grid>
              {filteredResults.map((result: any, index: number) => (
                <Grid key={"name" + index + result.id} item xs={columnWidth} sx={cellStyle}>
                  <Typography style={{ fontSize: "0.8rem" }}>{result.pool}</Typography>
                </Grid>
              ))}

              <Grid item xs={columnWidth} sx={cellStyle}>
                <Typography style={{ fontSize: "0.8rem", color: theme.palette.text.secondary }}>Aika:</Typography>
              </Grid>
              {filteredResults.map((result: any, index: number) => (
                <Grid key={"time" + index + result.id} item xs={columnWidth} sx={cellStyle}>
                  <Typography style={{ fontSize: "0.8rem" }}>{result.result_text}</Typography>
                </Grid>
              ))}

              <Grid item xs={columnWidth} sx={cellStyle}>
                <Typography style={{ fontSize: "0.8rem", color: theme.palette.text.secondary }}>Sijoitus:</Typography>
              </Grid>
              {filteredResults.map((result: any, index: number) => (
                <Grid key={"ranksijoitus" + index + result.id} item xs={columnWidth} sx={cellStyle}>
                  <Typography style={{ fontSize: "0.8rem" }}>{result.total_rank}</Typography>
                </Grid>
              ))}

              <Grid item xs={columnWidth} sx={cellStyle}>
                <Typography style={{ fontSize: "0.8rem", color: theme.palette.text.secondary, borderTop: 1 }}>Lisätiedot:</Typography>
              </Grid>
              {filteredResults.map((result: any, index: number) => (
                <Grid key={"rankadditional" + index + result.id} item xs={columnWidth} sx={cellStyle}>
                  <Typography noWrap style={{ fontSize: "0.8rem" }}>
                    {result.additionalInfos}
                  </Typography>
                </Grid>
              ))}

              {filteredPaces &&
                <>
                <Grid item xs={tvelve}><Typography style={{ fontSize: "0.8rem", color: theme.palette.text.secondary}}>Väliajat:</Typography></Grid>

                {Object.keys(filteredPaces).map((distanceKey: any, paceIndex: number, arr: any) => (
                  <React.Fragment key={"pacefragment" + paceIndex + distanceKey}>
                    <Grid key={"pace" + paceIndex + distanceKey} item xs={columnWidth}>
                      <Typography style={{ fontSize: "0.8rem", color: theme.palette.text.secondary }}>{distanceKey}m:</Typography>
                    </Grid>

                    {filteredResults.map((result: any, index: number) => (
                      <Grid key={"pacer" + index + distanceKey + result.id} item xs={columnWidth} sx={paceCellStyle}>
                        <Typography style={{ fontSize: "0.8rem" }}>
                          <FormattedPace pace={filteredPaces[distanceKey][result.id] && filteredPaces[distanceKey][result.id]} />
                        </Typography>
                      </Grid>
                    ))}
                  </React.Fragment>
                ))}</>}

              <Grid item xs={columnWidth} sx={cellStyle}>
                <Typography style={{ fontSize: "0.8rem", color: theme.palette.text.secondary }}></Typography>
              </Grid>
              {filteredResults.map((result: any, index: number) => (
                <Grid key={"ranklink" + index + result.id} item xs={columnWidth} sx={cellStyle}>
                  {result.seo_text !== undefined && result.event !== undefined && (
                    <>
                      <IconButton
                        size="small"
                        sx={{ margin: theme.spacing(0) }}
                        component={Link}
                        to={"/competition/" + result.seo_text + "/event/" + result.event}
                      >
                        <ArrowCircleRight color="primary" />
                      </IconButton>
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}

function isNegative(pace: string) {
  if (pace.startsWith("-")) {
    return true;
  } else {
    return false;
  }
}

function FormattedPace(props: any) {
  const pace = props.pace;
  const theme = useTheme();

  if (pace === undefined) return null;
  return (
    <>
      <span>{pace.pace_text}</span>
      <span style={{ color: isNegative(pace.pacediff_text) ? theme.palette.success.main : theme.palette.error.main }}> {pace.pacediff_text}</span>
      <br />
      <span style={{ color: "grey" }}> {pace.split_text}</span>
    </>
  );
}

export default CompetitionSwimmerEventHistory;

function convertToSplits(sub_results: any) {
  let splits: Splits = {};
  for (let i = 0; i < sub_results.length; i++) {
    let d: string = sub_results[i].done_at + "m";
    let t: string = sub_results[i].result_value_text;
    splits[d] = t;
  }
  return splits;
}

function getSwimifyCompetitionName(swimifyCompetitions: any, competition_id: string) {
  if (swimifyCompetitions === undefined) return;
  for (var i = 0; i < swimifyCompetitions.length; i++) {
    if (swimifyCompetitions[i].competition_id === competition_id) {
      return swimifyCompetitions[i].competition_name;
    }
  }
}

function getSwimifyCompetitionStartDate(swimifyCompetitions: any, competition_id: string) {
  if (swimifyCompetitions === undefined) return;
  for (var i = 0; i < swimifyCompetitions.length; i++) {
    if (swimifyCompetitions[i].competition_id === competition_id) {
      return swimifyCompetitions[i].startDate;
    }
  }
}

function getSwimifyCompetitionEndDate(swimifyCompetitions: any, competition_id: string) {
  if (swimifyCompetitions === undefined) return;
  for (var i = 0; i < swimifyCompetitions.length; i++) {
    if (swimifyCompetitions[i].competition_id === competition_id) {
      return swimifyCompetitions[i].endDate;
    }
  }
}

function getSwimifyCompetitionSEO(swimifyCompetitions: any, competition_id: string) {
  if (swimifyCompetitions === undefined) return;
  for (var i = 0; i < swimifyCompetitions.length; i++) {
    if (swimifyCompetitions[i].competition_id === competition_id) {
      return swimifyCompetitions[i].seo_text;
    }
  }
}

function getLiveTimingCompetitionName(liveTimingCompetitionList: any, cid: string) {
  if (liveTimingCompetitionList === undefined) return;
  for (var i = 0; i < liveTimingCompetitionList.length; i++) {
    if (liveTimingCompetitionList[i].cid === cid) {
      return liveTimingCompetitionList[i].competitionName;
    }
  }
  return;
}

function getLiveTimingCompetitionStartDate(liveTimingCompetitionList: any, cid: string) {
  if (liveTimingCompetitionList === undefined) return;
  for (var i = 0; i < liveTimingCompetitionList.length; i++) {
    if (liveTimingCompetitionList[i].cid === cid) {
      return liveTimingCompetitionList[i].startDate;
    }
  }
  return;
}

function getLiveTimingCompetitionEndDate(liveTimingCompetitionList: any, cid: string) {
  if (liveTimingCompetitionList === undefined) return;
  for (var i = 0; i < liveTimingCompetitionList.length; i++) {
    if (liveTimingCompetitionList[i].cid === cid) {
      return liveTimingCompetitionList[i].endDate;
    }
  }
  return;
}
