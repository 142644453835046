import { Button, Grid, LinearProgress, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from "@mui/material";
import UinnitAppTabs from "../../UinnitAppTabs";
import UinnitAppBar from "../../UinnitAppBar";
import { useState, useEffect } from "react";
import { getAPIURL, getListIds, getUrlParameter } from "../../Helpers";
import { useNavigate, useParams } from "react-router-dom";
import { getSessionPrettyDate } from "../../Helpers";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Sparkles from "@chad.b.morrow/sparkles";
import { ProgramItemAvatar } from "../ProgramItemAvatar";

interface EventProgramItemsProps {
  cid: string;
  competitionData: CompetitionData | undefined;
  currentHeats: CurrentHeat[];
  lists: Lists | undefined;
  loading: boolean;
  userSettings: UserSettings | undefined;
  navigationCID: string;
}

export default function EventProgramItems(props: EventProgramItemsProps) {
  const [eventTabArray, setEventTabArray] = useState<EventTab[]>([]);
  const [eventName, setEventName] = useState<string>("");
  const theme = useTheme();
  let navigate = useNavigate();

  const { eventParam } = useParams();
  const { selectedSid } = useParams();
  const { selectedProgramItemIndexString } = useParams();
  const { selectedListType } = useParams();

  const selectedProgramItemIndex = parseInt(selectedProgramItemIndexString || "-1"); //TODO

  const noListsInURL = (selectedSid === undefined || selectedProgramItemIndexString === undefined || selectedListType === undefined ? true : false)

  const selectedSession = selectedSid && props.competitionData && props.competitionData.sessions && props.competitionData?.sessions[selectedSid]
  const selectedTabProgramItem = selectedSession && selectedSession.programItems ? selectedSession.programItems[selectedProgramItemIndex] : undefined;

  // if the page is reloaded with lists in the url, the selectedTabId is not set, and needs to be set based on the url
  const selectedTabId = selectedSid + "_" + selectedProgramItemIndexString + "_" + selectedListType;
  
  useEffect(() => {
    if(eventParam !== undefined && props.competitionData !== undefined) { 
      updateEventTabs(eventParam, props.competitionData);
    }
  }, [eventParam, props.competitionData]);

  function getDefaultTab(eventTabArray: EventTab[], lists : Lists | undefined) {
    let defaultTab = undefined;
    for (let i = 0; i < eventTabArray.length; i++) {
      let eventTab = eventTabArray[i];
      let tabListId = eventTab.tabListId;
      let list = tabListId && lists && lists[tabListId];
      let summaries = (list as ResultList)?.summaries;
      let hasResults = (eventTab.tabType === "results" && list && summaries && Object.keys(summaries).length > 0 ? true : false);
      let starts = (list as StartList)?.starts;
      let hasStarts = (eventTab.tabType === "starts" && list && starts && starts.length > 0 ?  true : false);
      let entryRanks = (list as EntryList)?.entryRanks;
      let hasEntries = (eventTab.tabType === "entries" && list && entryRanks && Object.keys(entryRanks).length > 0 ? true : false);

      // select the highest tab with items
      if (hasResults || hasStarts || hasEntries) {
        if(hasResults && eventTab.tabProgramItem.status !== undefined && eventTab.tabProgramItem.status !== 5) {
          // do not make result tab as default if the status exists but it is not 5
          
        } else {
          defaultTab = eventTab;
        }
      }
    }

    return defaultTab;
  }

  useEffect(() => {
    if(noListsInURL) {
      let defaultTab = getDefaultTab(eventTabArray, props.lists);
      if(defaultTab) {
        navigate("/competition/" + props.navigationCID + "/event/" + eventParam + "/" + defaultTab.tabType + "/" + defaultTab.tabProgramItem.sid + "/" + defaultTab.tabProgramItem.programItemIndex, { replace: true });
      }
    } 
  }, [eventTabArray, props.lists]);

  function updateEventTabs(event: string, competitionData : CompetitionData | undefined) {
    if (competitionData === undefined) return;
    if (competitionData.sessions === undefined) return;

    let eventTabArray: EventTab[] = [];
    for (let [sid, session] of Object.entries(competitionData.sessions)) {
      if (session.programItems !== undefined) {
        for (let [pi, programItem] of Object.entries(session.programItems)) {
          let listIds = getListIds(programItem);
          if (programItem.event === event) {
            refreshLists(programItem);
            setEventName(programItem.eventName);
             
              eventTabArray.push({
              key: programItem.sid + "_" + programItem.programItemIndex + "_entries",
              tabName: "Osallistujat: " + programItem.programItemName,
              tabTitle: "Osallistujat",
              tabProgramItem: programItem,
              tabListId: listIds.entryListId,
              tabType: "entries",
            });
             
            eventTabArray.push({
              key: programItem.sid + "_" + programItem.programItemIndex + "_starts",
              tabName: "Erät: " + programItem.programItemName,
              tabTitle: "Erät",
              tabProgramItem: programItem,
              tabListId: listIds.startListId,
              tabType: "starts",
            });
            eventTabArray.push({
              key: programItem.sid + "_" + programItem.programItemIndex + "_results",
              tabName: "Tulokset: " + programItem.programItemName,
              tabTitle: "Tulokset",
              tabProgramItem: programItem,
              tabListId: listIds.resultListId,
              tabType: "results",
            });
          }
        }
      }
    }
    setEventTabArray(eventTabArray);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    //setSelectedTabId(newValue);
    const sid = newValue.split("_")[0];
    const programItemIndex = newValue.split("_")[1];
    const type = newValue.split("_")[2];
    navigate("/competition/" + props.navigationCID + "/event/" + eventParam + "/" + type + "/" + sid + "/" + programItemIndex);
  };

  function openResultTotal(link?: string) {
    if (link === undefined) return;
    window.open("https://www.livetiming.fi/" + link);
  }


  const totalResultsButton = selectedTabProgramItem !== undefined && selectedTabProgramItem.liveTimingResultListTotal !== undefined && (
    <Button
      style={{ margin: theme.spacing(2) }}
      variant="outlined"
      color="primary"
      onClick={() => openResultTotal(selectedTabProgramItem.liveTimingResultListTotal)}
    >
      Näytä kokonaistilanne
    </Button>
  );

  function getSessionName(sid: string): string | undefined {
    /*
    if (props.competitionData !== undefined && props.competitionData.sessions !== undefined && props.competitionData.sessions[sid] !== undefined) {
      if (props.competitionData.sessions[sid].sessionName === "Jakso " + sid || props.competitionData.sessions[sid].sessionName === "Session " + sid) {
        return props.competitionData.sessions[sid].sessionName;
      } else {
        return "Jakso " + sid + ": " + props.competitionData.sessions[sid].sessionName;
      }
    }*/
    let session = props.competitionData?.sessions && props.competitionData?.sessions[sid];
    let sn = session?.sessionName;
    return sn;
  }

  const eventTime = selectedTabProgramItem?.startTimeText;
  const session = selectedTabProgramItem && props.competitionData?.sessions ? props.competitionData.sessions[selectedTabProgramItem?.sid] : undefined;
  const sessionDateText = session?.sessionDateText;

  const sessionName = selectedTabProgramItem && getSessionName(selectedTabProgramItem.sid);
  const sessionNumberAndName = selectedTabProgramItem && "Jakso " + selectedTabProgramItem.sid + 
  (sessionName && sessionName.trim() !== "Jakso " + selectedTabProgramItem.sid && sessionName.trim() !== "Session " + selectedTabProgramItem.sid ? ": " + sessionName.trim() : "");

  const isLive = (props.currentHeats && props.currentHeats.some((heat) => (heat.programItemIndex === selectedTabProgramItem?.programItemIndex && heat.sid === selectedTabProgramItem?.sid))) || false;
    
  const avatar = selectedTabProgramItem && <ProgramItemAvatar programItem={selectedTabProgramItem} isLive={isLive} />;

  const nextProgramItem = getNextProgramItem(selectedTabProgramItem);
  const previousProgramItem = getPreviousProgramItem(selectedTabProgramItem);

  function getPreviousProgramItem(programItem: ProgramItem | undefined) {
    const competitionData = props.competitionData;
    if (competitionData === undefined) return;
    if (competitionData.sessions === undefined) return;
    if (programItem === undefined) return;
    // finds the previous program item in the same session where the value of event parameter (as number) is greater than 0
    let previousProgramItem: ProgramItem | undefined = undefined;
    // find the previous program item in the same session where the value of event parameter (converted from string to number) is greater than 0
    for (let [sid, session] of Object.entries(competitionData.sessions)) {
      if (session.programItems !== undefined) {
        for (let [pi, piItem] of Object.entries(session.programItems)) {
          if (piItem.sid === programItem.sid && parseInt(piItem.event) > 0 && piItem.programItemIndex < programItem.programItemIndex) {
            previousProgramItem = piItem;
          }
        }
      }
    }
    return previousProgramItem;
  }

  function getNextProgramItem(programItem: ProgramItem | undefined) {
    const competitionData = props.competitionData;
    if (competitionData === undefined) return;
    if (competitionData.sessions === undefined) return;
    if (programItem === undefined) return;
    // finds the previous program item in the same session where the value of event parameter (as number) is greater than 0
    let previousProgramItem: ProgramItem | undefined = undefined;
    // find the previous program item in the same session where the value of event parameter (converted from string to number) is greater than 0
    for (let [sid, session] of Object.entries(competitionData.sessions)) {
      if (session.programItems !== undefined) {
        for (let [pi, piItem] of Object.entries(session.programItems).reverse()) {
          if (piItem.sid === programItem.sid && parseInt(piItem.event) > 0 && piItem.programItemIndex > programItem.programItemIndex) {
            previousProgramItem = piItem;
          }
        }
      }
    }
    return previousProgramItem;
  }

  const selectedProgramItemElement = 
    <ListItem>
    <ListItemAvatar>{avatar}</ListItemAvatar>
    <ListItemText>
      <Typography variant="h6">{selectedTabProgramItem?.programItemName}</Typography>
      {sessionNumberAndName && <Typography variant="body2">{sessionNumberAndName}</Typography>}
      <Typography variant="body2">
        {props.competitionData && <span>{getSessionPrettyDate(props.competitionData, (selectedTabProgramItem?.sid ? selectedTabProgramItem?.sid : ""))} </span>}
        {sessionDateText} {eventTime && <span>klo {eventTime}</span>}
      </Typography>
    </ListItemText>
  </ListItem>;

  return (
    <div>
      <UinnitAppBar
        includeBackButton={true}
        navigationCID={props.navigationCID}
        title={"" + (eventName !== undefined ? eventName : selectedTabProgramItem?.programItemName)}
        tabs={<EventTabs selectedTabId={selectedTabId} eventTabArray={eventTabArray} handleTabChange={handleTabChange}
        
        />}
        denseTabs={false}
        /*
        actions={
          <IconButton sx={{ marginRight: 0, display: "inline-block" }} color="inherit" onClick={(e) => handleClick(e)}>
            {yearFilter === "0" ? <FilterList /> : <FilterList style={{ color: "#f52aa4" }} />}
          </IconButton>
        } */
        currentHeats={props.currentHeats}
      >
      </UinnitAppBar>

      <div style={{ height: "4px", width: "100%", clear: "both", marginTop: 0, marginBottom: 0 }}>{props.loading && <LinearProgress />}</div>

      {selectedTabProgramItem && (

        selectedTabProgramItem?.programItemName?.toUpperCase().indexOf("MYSTE") > -1 ?
          <Sparkles colors="rainbow">
           {selectedProgramItemElement} 
          </Sparkles>
          :
          <>{selectedProgramItemElement}</>
      )}

      {selectedTabProgramItem ? (
        <Grid container>
          <Grid item xs={6}>
            {previousProgramItem !== undefined ? (
              <Button
              style={{ justifyContent: "flex-start" }}
              fullWidth
              variant="text"
              color="primary"
              startIcon={<KeyboardArrowLeftIcon sx={{ marginLeft: "0.5rem" }} />}
              onClick={() => navigate("../../event/" + previousProgramItem?.event + "/" + selectedListType + "/" + previousProgramItem?.sid + "/" + previousProgramItem?.programItemIndex)}
            >
              Edellinen laji {previousProgramItem?.event}
            </Button>
            ) : (
              <Button fullWidth disabled style={{ justifyContent: "flex-start" }} startIcon={<KeyboardArrowLeftIcon sx={{ marginLeft: "0.5rem" }} />}>
                Ei edellistä lajia
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            {nextProgramItem !== undefined ? (
              <Button
                style={{ justifyContent: "flex-end" }}
                fullWidth
                variant="text"
                color="primary"
                endIcon={<KeyboardArrowRightIcon sx={{ marginRight: "0.5rem" }} />}
                onClick={() => navigate("../../event/" + nextProgramItem?.event + "/" + selectedListType + "/" + nextProgramItem?.sid + "/" + nextProgramItem?.programItemIndex)}
                >
                Seuraava laji {nextProgramItem?.event}
              </Button>
            ) : (
              <Button fullWidth disabled style={{ justifyContent: "flex-end" }} endIcon={<KeyboardArrowRightIcon sx={{ marginRight: "0.5rem" }} />}>
                Ei seuraavaa lajia
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {totalResultsButton}

    </div>
  );
}


export async function refreshLists(programItem: ProgramItem) {
  if (programItem.cid === undefined || programItem.cid.length !== 4) return;

  //console.log("Refreshing lists for programItem", programItem);
  const cid = programItem.cid;
  const sid = programItem.sid;
  const event = programItem.event;

  let listTypes = ["entryList", "entryListJunior", "startList", "resultList", "resultListJunior"];

  for (let listType in listTypes) {
    let type;
    let tpid;
    let round;
    let listUrl;

    if (listTypes[listType] === "entryList") {
      listUrl = programItem.liveTimingEntryList;
    } else if (listTypes[listType] === "entryListJunior") {
      listUrl = programItem.liveTimingEntryListJunior;
    } else if (listTypes[listType] === "startList") {
      listUrl = programItem.liveTimingStartList;
    } else if (listTypes[listType] === "resultListJunior") {
      listUrl = programItem.liveTimingResultListJunior;
    } else if (listTypes[listType] === "resultList") {
      listUrl = programItem.liveTimingResultList;
    }
    let url =
      getAPIURL() +
      "/refreshList?cid=" +
      cid +
      "&sid=" +
      sid +
      "&event=" +
      event +
      "&programItemIndex=" +
      programItem.programItemIndex +
      "&listType=" +
      listTypes[listType];

    type = getUrlParameter("type", listUrl);
    tpid = getUrlParameter("tpid", listUrl);
    round = getUrlParameter("round", listUrl);

    if (type !== undefined && type.length > 0) url = url + "&type=" + type;
    if (tpid !== undefined && tpid.length > 0) url = url + "&tpid=" + tpid;
    if (round !== undefined && round.length > 0) url = url + "&round=" + round;
    console.log("Refreshing list url", url);

    try {
      await fetch(url);
    } catch (e) {
      console.error("Error refreshing list", e);
    }
  }
  return;
}

interface EventTabsProps {
  selectedTabId: string;
  eventTabArray: EventTab[];
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
}

function EventTabs(props: EventTabsProps) {
  const selectedTabId = props.selectedTabId;
  const eventTabArray = props.eventTabArray;
  const handleTabChange = props.handleTabChange;

  return (
    <UinnitAppTabs value={selectedTabId} variant="scrollable" onChange={handleTabChange} eventTabArray={eventTabArray}/>
  );
}
