import { useQuery } from "@apollo/client";
import { ClubNamesFragment } from "./GetClubCompetitors";
import { gql } from '../../src/__generated__/gql';

export const CompetitorDataFragment = gql(/* GraphQL */ `
    fragment CompetitorData on competitor {
        id
        oid
        full_name
        birthday
        first_name
        last_name
        gender_number
        country_code
    }
`);

const COMPETITORS_QUERY = gql(/* GraphQL */ `
query Competitors($cid: uuid!) @cached(ttl: 60) {
    competitor(
        where: { competition_id: { _eq: $cid } }
    ) {
        ...CompetitorData
        club {
            ...ClubNames
        }
    }
}
`);

/*
        relay_competitors(order_by: { order: asc }) {
            competition_id
            competitor_oid
            created_at
            entry_oid
            id
            lane_oid
            oid
            order
            updated_at
        }
*/

export default function getSwimifyCompetitors(cid: string | undefined, skip: boolean) {
    const { data, loading, error } = useQuery(COMPETITORS_QUERY, {
        variables: { cid },
        skip: skip,
        pollInterval: 1 * 60 * 1000, // every 1 minute
    });
    const competitorArray = data?.competitor;
    //console.log("Competitors", competitorArray, loading, error);

    const swimmersObject: Swimmers = competitorArray?.reduce(function (result: any, item: any, index: number, array: any) {
        const swimmer: Swimmer = {
          name: item.full_name,
          lastName: item.last_name,
          firstNames: item.first_name,
          id: item.full_name + " " + item.birthday.substring(0, 4),
          year: item.birthday.substring(0, 4),
          club: item.club?.name,
          nationality: item.country_code,
        };
        result[swimmer.id] = swimmer;
        return result;
      }, {});

    return { swimifyCompetitors: swimmersObject, swimifyCompetitorsLoading: loading, swimifyCompetitorsLoadingError: error };
}
