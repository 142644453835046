import { useQuery } from "@apollo/client";
import { gql } from '../../src/__generated__/gql';
import CompetitorDataFragment from './GetCompetitors';

export const ClubNamesFragment = gql(/* GraphQL */ `
    fragment ClubNames on clubs {
        name
        short_name
        country_code
    }
`);

const CLUBCOMPETITORS_QUERY = gql(/* GraphQL */`
  query ClubCompetitors($cid: uuid!, $club_name: String) @cached(ttl: 60) {
    clubs(where: { competition_id: { _eq: $cid }, name: { _eq: $club_name } }) {
      ...ClubNames
      competitors {
        ...CompetitorData
      }
    }
  }
`);

/*
        relay_competitors(order_by: { order: asc }) {
            competition_id
            competitor_oid
            created_at
            entry_oid
            id
            lane_oid
            oid
            order
            updated_at
        }
        */

export default function getSwimifyClubCompetitors(cid: string, club_name: string, skip: boolean) {
  //console.debug("Querying club competitors", cid, club_name, skip);
  const { data, loading, error } = useQuery(CLUBCOMPETITORS_QUERY, {
    variables: { cid, club_name },
    skip: skip,
    pollInterval: 1 * 60 * 1000, // every 1 minute
  });
  //console.debug("Query response (club competitors)", data, loading, error);
  const competitors: any = data?.clubs[0]?.competitors;
  const competitorsObject: Swimmers = competitors?.reduce(function (result: any, item: any, index: number, array: any) {
    const swimmer: Swimmer = {
      name: item.full_name,
      lastName: item.last_name,
      firstNames: item.first_name,
      id: item.full_name + " " + item.birthday.substring(0, 4),
      year: item.birthday.substring(0, 4),
      club: club_name,
      nationality: item.country_code,
    };
    result[item.full_name + " " + item.birthday.substring(0, 4)] = swimmer;
    return result;
  }, {});
  return { swimifyClubCompetitors: competitorsObject, swimifyClubCompetitorsLoading: loading, swimifyClubCompetitorsLoadingError: error };
}
