import { Avatar, ListItemAvatar, useTheme } from "@mui/material";

interface LaneAvatarProps {
    track: string;
}

export function LaneAvatar(props: LaneAvatarProps) {

    const theme = useTheme();
  
    return (
    <ListItemAvatar
      sx={{
        minWidth: "2.75rem",
      }}
    >
      <Avatar
        //variant="rounded"
        sx={{
          bgcolor: "transparent",
          //bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          maxHeight: "2.25rem",
          maxWidth: "2.25rem",
          fontSize: "1.2rem",
        }}
      >
        <div style={{ display: "table", marginTop: "auto", marginBottom: "auto" }}>
          <div style={{ textAlign: "center", fontSize: "0.6rem", marginLeft: "auto", marginRight: "auto", marginTop: "0.1rem", marginBottom: "0.15rem" }}>
            RATA
          </div>
          <div style={{ display: "table", marginLeft: "auto", marginRight: "auto" }}>{props.track}</div>
        </div>
      </Avatar>
    </ListItemAvatar>
    )
  }