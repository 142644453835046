import { collection, doc, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getAPIURL, getDefaultSid as getDefaultSID, getEnvPrefix } from "../Helpers";
import CompetitionFiles from "./Files";
import CompetitionMore from "./More";
import CompetitionSwimmers from "./Swimmers/CompetitionSwimmers";
import Sessions from "./Program/Sessions";
import CompetitionSwimmerEvent from "./Swimmer/Event/SwimmerEvent";
import CompetitionEvent from "./Event/CompetitionEvent";
import CompetitionSwimmer from "./Swimmer/Swimmer";
import Club from "./Swimmers/Club";
import { User } from "firebase/auth";
import UinnitAppBar from "../UinnitAppBar";
import { gql, useSubscription } from "@apollo/client";
import { resolveSwimmers } from "./resolveSwimmers";
import { resolveListItems } from "./resolveListItems";
import { convertSwimifyCompetitionData } from "./convertCompetitionData";
import { getSwimifyCompetitionData } from "./GetCompetitionData";
import { convertLiveTimingLists } from "./convertLiveTimingLists";
import CompetitionSwimmerEventHistory from "./Swimmer/Event/SwimmerEventHistory";

function refreshSessions(cid: string) {
  if (cid.length === 4) {
    console.debug("Refreshing sessions for cid " + cid);
    fetch(getAPIURL() + "/refreshSessions?cid=" + cid).catch((error) => console.error(error));
  }
}

function refreshPrograms(cid: string) {
  if (cid.length === 4) {
    console.debug("Refreshing programs for cid " + cid);
    fetch(getAPIURL() + "/refreshProgram?cid=" + cid).catch((error) => console.error(error));
  } else {
    console.debug("Will not refresh program. Sessions are missing.");
  }
}

function refreshSchedule(cid: string) {
  if (cid.length === 4) {
    console.debug("Refreshing schedule for cid " + cid);
    fetch(getAPIURL() + "/refreshSchedule?cid=" + cid).catch((error) => console.error(error));
  }
}

const CURRENTHEAT_SUBSCRIPTION = gql`
  subscription currentHeatSubscription_WL($cid: uuid = "") {
    current_heat(where: { competition_id: { _eq: $cid }, heat_type: { _eq: 1 } }) {
      id
      heat {
        id
        number
        round {
          id
          status
          event {
            id
            number
          }
        }
      }
      time_program_entry {
        id
        oid
        name
        competition_session {
          id
          oid
          name
          number
        }
      }
    }
  }
`;

const COMPETITIONSTATUS_SUBSCRIPTION = gql`
  subscription CompetitionStatus($cid: uuid!) {
    competitions_by_pk(id: $cid) {
      id
      competition_sessions {
        id
        oid
        time_program_entries(order_by: { sort_order: asc }) {
          id
          oid
          round {
            id
            oid
            status
          }
        }
      }
    }
  }
`;

export const CURRENTHEATDATA_SUBSCRIPTION = gql`
  subscription CurrentHeatDataSubscription($currentHeatId: Int!) {
      heat_by_pk(id: $currentHeatId) {
        estimated_start_time
        id
        name
        number
        oid
        priority
        round_oid
        start_time
        status
        lanes {
          checked
          competitor_oid
          dnf
          dns
          dq_code
          dq_description
          dq_relay_swimmer
          dsq
          entry_pool
          entry_time_text
          fina_points
          heat_rank
          id
          note
          number
          oid
          qualification
          reaction_time
          relay_age
          result_text
          swimmed_distance
          total_rank
          wps_points
          sub_results(order_by: { order: asc }) {
            done_at
            id
            lane_oid
            oid
            order
            relay_competitor_order
            result_value_text
            split_diff_text
            take_over
          }
        }
      }
  }
`;


interface CompetitionProps {
  cid: string;
  navigationCID: string;
  recentCompetitions: any;
  futureCompetitions: any;
  userSettings: UserSettings | undefined;
  authUser: User | undefined;
  setInfoDialog: (infoDialogOpen: boolean) => void;
  setAccountDialog: (accountDialogOpen: boolean) => void;
  addRecentCompetition: (competitionData: CompetitionData) => void;
}

const Competition = (props: CompetitionProps) => {
  const navigationCID = props.navigationCID;
  const cid = props.cid; 

  const [competitionData, setCompetitionData] = useState<CompetitionData>();
  const [listArray, setListArray] = useState<List[] | undefined>(undefined);
  const [lists, setLists] = useState<Lists | undefined>(undefined);
  const [convertedLists, setConvertedLists] = useState<Lists | undefined>(undefined);
  const [swimmers, setSwimmers] = useState<Swimmers | undefined>(undefined);
  const [clubs, setClubs] = useState<Clubs>({});
  const [clubNamePairs, setClubNamePairs] = useState<ClubNamePairs>({});
  const [defaultsid, setDefaultSID] = useState<any>();

  const [competitionStatuses, setCompetitionStatuses] = useState<any>(undefined);
  const [currentHeats, setCurrentHeats] = useState<CurrentHeat[]>([]);
  const [currentHeatsDetailed, setCurrentHeatsDetailed] = useState<CurrentHeat[]>([]);
  const [currentHeatData, setCurrentHeatData] = useState<any>(undefined);

  const { data: statusData, error: statusError, loading: statusLoading } = useSubscription(COMPETITIONSTATUS_SUBSCRIPTION, {
    variables: { cid },
    skip: cid === undefined || cid.length === 4,
    onData: (subscriptionData) => {
      if (subscriptionData?.data?.data?.competitions_by_pk) {
        //console.debug("Statuses updated via subscription:", subscriptionData?.data?.data?.competitions_by_pk);
        setCompetitionStatuses(subscriptionData?.data?.data?.competitions_by_pk);
      }
    },
    onError: (error) => {
      console.error("Error in subscription", error);
      setCompetitionStatuses(undefined);
    }
  });

  const currentHeatSub = getCurrentHeats();

  const currentHeatId = currentHeats && currentHeats.length > 0 ? currentHeats[0].heatId : undefined;

  const { data: heatData, error: heatDataError, loading: heatDataLoading } = useSubscription(CURRENTHEATDATA_SUBSCRIPTION, {
    variables: { currentHeatId },
    skip: cid === undefined || cid.length === 4 || currentHeatId === undefined,
    onData: (subscriptionData) => {
      if (subscriptionData?.data?.data?.heat_by_pk) {
        setCurrentHeatData(subscriptionData?.data?.data?.heat_by_pk);
      }
    },
    onError: (error) => {
      console.error("Error in subscription", error);
      setCurrentHeatData(undefined);
    }
  });

  function getCurrentHeats() {
    return useSubscription(CURRENTHEAT_SUBSCRIPTION, {
      variables: { cid },
      skip: cid === undefined || cid.length === 4,
      onData: (subscriptionData) => {
        if (subscriptionData.data?.data?.current_heat) {
          //console.debug("Current heat updated via subscription:", subscriptionData.data?.data?.current_heat);
          let current_heats = subscriptionData.data?.data?.current_heat;
          if (current_heats) {
            let currentHeats: CurrentHeat[] = [];
            for (let i = 0; i < current_heats.length; i++) {
              let currentHeat: CurrentHeat = {
                heatId: current_heats[i].heat.id,
                roundId: current_heats[i].heat.round.id,
                eventId: current_heats[i].heat.round.event.id,
                roundStatus: current_heats[i].heat.round.status,
                sid: ""+current_heats[i].time_program_entry?.competition_session?.number,
                programItemId: current_heats[i].time_program_entry?.id,
                event: ""+current_heats[i].heat?.round?.event?.number,
                programItemIndex: undefined,
                heatNumber: current_heats[i].heat?.number
              };
              // if the round is not finished, and if there is a matching programItemIndex, add it to the currentHeats
              if(currentHeat.roundStatus !== 5) {
                currentHeats.push(currentHeat);
              }
            }
            setCurrentHeats(currentHeats);
          }
        } else {
          console.error("Unable to get current heat information.");
          setCurrentHeats([]);
        }
      },
      onError: (error) => {
        console.error("Error getting current heat information", error);
        setCurrentHeats([]);
      }
    });
  } 


  const skipSwimifyCompetitionData: boolean = cid === undefined || cid.length === 4 ? true : false;
  const {
    data: swimifyCompetitionData,
    loading: competitionDataLoading,
    error: competitionDataError,
  } = getSwimifyCompetitionData(cid, skipSwimifyCompetitionData);

  /*
  if(competitionDataError) {
    const snackAction = (snackbarId : any) => (
      <>
        <Button variant="contained"  sx={{marginRight: theme.spacing(1)}} onClick={() => { closeSnackbar(snackbarId) }}>
          Sulje
        </Button>
        <Button variant="contained" onClick={() => { window.location.reload() }}>
          Lataa sivu uudelleen
        </Button>
      </>
    );
    enqueueSnackbar("Tietojen haku epäonnistui. '" + competitionDataError?.message + "' " +
    "Jos ongelma jatkuu, voit yrittää ladata sivun uudelleen, poistaa ja asentaa sovelluksen uudestaan tai yrittää myöhemmin.", 
    { action: snackAction, variant: "warning", preventDuplicate: true, persist: true });
  }*/

  // When we get new swimify competition data, we need to convert it to livetiming format
  useEffect(() => {
    if (cid !== undefined && cid.length > 0) {
      //console.debug("SWIMIFY COMPETITIONS", swimifyCompetitionData);
      return getCompetitionData(cid, lists, competitionStatuses);
    }
  }, [swimifyCompetitionData, competitionStatuses]);

  useEffect(() => {

    let currentHeatsDetailedData : CurrentHeat[] = [];
    
    if (competitionData !== undefined) {
      if (currentHeats !== undefined && currentHeats.length > 0) {
        for (let i = 0; i < currentHeats.length; i++) {
          let currentHeat = currentHeats[i];
          let programItemIndex = -1;

          // Find the programItemIndex from competitionData for the current heat based on sid and programItemIndex
          let session = competitionData.sessions && competitionData.sessions[currentHeat.sid];
          let programItem = session && session.programItems && session.programItems.find((programItem) => programItem.programItemId === currentHeat.programItemId);
          if (programItem) {
            programItemIndex = programItem.programItemIndex;
          }        
          
          if (programItemIndex !== undefined && programItemIndex >= 0) {
            currentHeat.programItemIndex = programItemIndex;
            currentHeatsDetailedData.push(currentHeat);
          }
        }
      }
    }

    setCurrentHeatsDetailed(currentHeatsDetailedData);
  }, [currentHeats, competitionData]);


  // When the CID is changed, we need to get competition data.
  // When the authUser is changed, we need to get competition data, but why? TODO test and find out
  useEffect(() => {
    if (cid !== undefined && cid.length > 0) {
      let competitionData;
      try {
        competitionData = JSON.parse(localStorage.getItem("competitionData") || "{}");
      } catch(error) {
        console.error("Error parsing competitionData from localStorage", error);
        competitionData = {};
      }
      if (localStorage.getItem("competitionData") !== null) {
        if(competitionData.cid === cid) {
          console.debug("Found competitionData in localStorage", competitionData);
          setCompetitionData(competitionData);
        }
      }
      let lists;
      try {
        lists = JSON.parse(localStorage.getItem("competitionLists") || "{}");
      } catch(error) {
        console.error("Error parsing lists from localstorage", error);
      }
      if (localStorage.getItem("competitionLists") !== null) {
        if(Object.keys(lists).length > 0 && lists[Object.keys(lists)[0]].cid === cid) {
          console.debug("Found lists in localStorage", lists);
          setLists(lists);
        }
      } else {
        console.debug("No lists in localStorage");
      }
      return getCompetitionData(cid, lists, competitionStatuses);
    }
  }, [cid, props.authUser]);
  //}, [cid]);

  useEffect(() => {
    // In livetiming, there might be a need to get new lists when the competitiondata changes (when the program has new lists)
    if (competitionData !== undefined && competitionData.cid !== undefined && competitionData.cid.length === 4) {
      return getListArray(competitionData.cid);
    }
  }, [competitionData]);

  function getCompetitionData(cid: string, lists: Lists | undefined, competitionStatuses: any | undefined) {
    if (cid.length === 4) {
      const unsub = onSnapshot(doc(getFirestore(), getEnvPrefix() + "uinnit-competitions", cid), (doc) => {
        console.debug("Competition " + cid + " updated from firestore.");
        localStorage.setItem("competitionData", JSON.stringify(doc.data()));
        setCompetitionData(doc.data() as CompetitionData);
      });
      return unsub;
    } else {
      let convertedCompetitionData = convertSwimifyCompetitionData(
        swimifyCompetitionData && swimifyCompetitionData.competitions_by_pk ? swimifyCompetitionData.competitions_by_pk : {},
        lists ? lists : {},
        competitionStatuses
      );
      if (convertedCompetitionData && convertedCompetitionData.cid) {
        localStorage.setItem("competitionData", JSON.stringify(convertedCompetitionData));
        setCompetitionData(convertedCompetitionData);
      }
    }
  }

  // When the cid is changed, we need to update the lists
  useEffect(() => {
    if (cid !== undefined && cid.length > 0) {
      return getListArray(cid);
    }
  }, [cid]);

  // When the list array changes, we need to update the lists
  useEffect(() => {
    if (listArray !== undefined) {
      console.debug("Lists changed for competition " + cid + ", amount: " + listArray.length);
      let updatedLists: any = {};
      for (var l = 0; l < listArray.length; l++) {
        updatedLists[listArray[l].listId] = listArray[l];
      }
      if (updatedLists) {
        localStorage.setItem("competitionLists", JSON.stringify(updatedLists));
        console.debug("Setting lists, length: " + Object.keys(updatedLists).length);
        setLists(updatedLists);
      }
    }
  }, [listArray]);

  function getListArray(cid: string) {
    if (cid.length === 4) {
      const q = query(collection(getFirestore(), getEnvPrefix() + "LT-lists"), where("cid", "==", cid));
      const unsub = onSnapshot(q, (snapshot) => {
        setListArray(snapshot.docs.map((doc) => doc.data() as List));
      });
      return unsub;
    }
  }

  // When the lists changes, we need to convert them to new format
  useEffect(() => {
    if (lists !== undefined) {
      setConvertedLists(convertLiveTimingLists(lists));
    }
  }, [lists]);

  // When we get new competition data, we need to update the recent competitions list to include it
  useEffect(() => {
    if (competitionData !== undefined) {
      props.addRecentCompetition(competitionData);
    }
  }, [competitionData]);

  // When we get new competition data, we want to update the default session data.
  // TODO this is not needed in Swimify, we can choose the best session based on timestamps
  useEffect(() => {
    if (competitionData !== undefined && competitionData.cid !== undefined) {
      setDefaultSID({
        cid: competitionData.cid,
        sid: getDefaultSID(competitionData.sessions),
      });
    }
  }, [competitionData]);

  // In livetiming, there is a need to refresh sessions, schedule and programs when the competitiondata changes
  useEffect(() => {
    if (competitionData !== undefined && competitionData.cid !== undefined) {
      if (competitionData.cid.length === 4) {
        refreshSessions(competitionData.cid);
        refreshSchedule(competitionData.cid);
        if (competitionData.sessions) {
          refreshPrograms(competitionData.cid);
        }
      }
    }
  }, [competitionData]);

  // When the lists or competitiondata changes, we need to update the swimmers and clubs
  useEffect(() => {
    if (convertedLists !== undefined) {
      let listItems = {};
      for (const [listId, list] of Object.entries(convertedLists)) {
        listItems = { ...listItems, ...resolveListItems(list) };
      }
      if (competitionData !== undefined && convertedLists !== undefined) {
        let swimmersData = resolveSwimmers(listItems, props.userSettings);
        setSwimmers(swimmersData["swimmers"]);
        setClubs(swimmersData["clubsData"]);
        setClubNamePairs(swimmersData["clubPairsData"]);
      }
    }
  }, [convertedLists, competitionData, props.userSettings]);

  // When the cid changes, in livetiming we need to refresh sessions, schedule and programs
  useEffect(() => {
    if (cid !== undefined && cid.length === 4) {
      refreshSessions(cid);
      const interval = setInterval(() => {
        refreshSessions(cid);
      }, 2 * 60 * 1000); // every 2 minutes
      return () => clearInterval(interval);
    }
  }, [cid]);

  return (
    true && (
      <div>
        <Routes>
          {cid && cid.length > 0 && competitionData !== undefined && competitionData.cid === cid && defaultsid && defaultsid.cid === cid && (
            <Route path="/" element={<Navigate replace to={"session/" + defaultsid.sid} />} />
          )}
          {cid && cid.length > 0 && competitionData !== undefined && competitionData.cid === cid && defaultsid && defaultsid.cid === cid && (
            <Route path="session" element={<Navigate replace to={defaultsid.sid} />} />
          )}
          {cid && cid.length > 0 && (
            <Route
              path="session/:sid"
              element={
                <Sessions
                  competitionData={competitionData}
                  loading={competitionDataLoading}
                  userSettings={props.userSettings}
                  clubPairs={clubNamePairs}
                  lists={convertedLists}
                  cid={cid}
                  swimmers={swimmers}
                  currentHeats={currentHeatsDetailed}
                  navigationCID={navigationCID}
                />
              }
            />
          )}
          {cid && cid.length > 0 && (
            <Route
              path="swimmers/*"
              element={
                <CompetitionSwimmers
                  setAccountDialog={props.setAccountDialog}
                  userSettings={props.userSettings}
                  competitionData={competitionData}
                  swimmers={swimmers}
                  clubs={clubs}
                  clubPairs={clubNamePairs}
                  cid={cid}
                  currentHeats={currentHeatsDetailed}
                  navigationCID={navigationCID}
                />
              }
            />
          )}
          {cid && cid.length > 0 && (
            <Route
              path="club/:clubName"
              element={
                <Club
                  cid={cid}
                  setAccountDialog={props.setAccountDialog}
                  userSettings={props.userSettings}
                  competitionData={competitionData}
                  swimmers={swimmers}
                  clubs={clubs}
                  clubPairs={clubNamePairs}
                  currentHeats={currentHeatsDetailed}
                  navigationCID={navigationCID}
                />
              }
            />
          )}
          {cid && cid.length > 0 && (
            <Route
              path="event/:eventParam/*"
              element={
                <CompetitionEvent
                  userSettings={props.userSettings}
                  competitionData={competitionData}
                  cid={cid}
                  lists={convertedLists}
                  loading={competitionDataLoading}
                  currentHeats={currentHeatsDetailed}
                  liveHeatData={currentHeatData}
                  navigationCID={navigationCID}
                />
              }
            />
          )}
          {cid && cid.length > 0 && (
            <Route
              path="swimmer/:swimmerid/event/:event"
              element={
                <CompetitionSwimmerEvent
                  userSettings={props.userSettings}
                  setAccountDialog={props.setAccountDialog}
                  competitionData={competitionData}
                  loading={competitionDataLoading}
                  cid={cid}
                  swimmers={swimmers}
                  currentHeats={currentHeatsDetailed}
                  navigationCID={navigationCID}
                  liveHeatData={currentHeatData}
                />
              }
            />
          )}

          {/*cid && (
            <Route
              path="swimmer/:swimmerid/notifications"
              element={
                <CompetitionSwimmerNotificationSettings
                  competitionData={competitionData}
                  cid={cid}
                  swimmers={swimmers}
                  notificationSettings={props.notificationSettings}
                  FCMToken={props.FCMToken}
                  updateNotificationSetting={props.updateNotificationSetting}
                />
              }
            />
            )*/}
          {cid && cid.length > 0 && (
            <Route
              path="swimmer/:swimmerid"
              element={
                <CompetitionSwimmer
                  setAccountDialog={props.setAccountDialog}
                  userSettings={props.userSettings}
                  competitionData={competitionData}
                  loading={competitionDataLoading}
                  cid={cid}
                  swimmers={swimmers}
                  currentHeats={currentHeatsDetailed}
                  navigationCID={navigationCID}
                  liveHeatData={currentHeatData}
                  //notificationSettings={props.notificationSettings}
                />
              }
            />
          )}
          {cid && cid.length === 4 && (
            <Route
              path="info"
              element={
                <>
                  <UinnitAppBar navigationCID={navigationCID} currentHeats={currentHeatsDetailed} title="Kilpailun tiedostot" includeBackButton={true}></UinnitAppBar>
                  <CompetitionFiles cid={cid} competitionData={competitionData} />
                  
                </>
              }
            />
          )}
          {cid && cid.length > 0 && (
            <Route
              path="more"
              element={
                <>
                  <UinnitAppBar navigationCID={navigationCID} currentHeats={currentHeatsDetailed} title={competitionData ? competitionData.competitionName : ""}></UinnitAppBar>
                  <CompetitionMore competitionData={competitionData} setInfoDialog={props.setInfoDialog} setAccountDialog={props.setAccountDialog} />
                </>
              }
            />
          )}
        </Routes>
      </div>
    )
  );
};

export default Competition;
