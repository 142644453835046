import { getAuth } from "firebase/auth";
import { getEnvPrefix } from "../Helpers";
import { Timestamp, doc, getFirestore, updateDoc } from "firebase/firestore";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useTheme } from "@mui/material";


interface FavoriteSwimmerControlProps {
  swimmer: Swimmer;
  favoriteSwimmers?: FavoriteSwimmers;
  setAccountDialog: (accountDialogOpen: boolean) => void;
  anonGuestModeStarted: Timestamp;
  enqueueSnackbar: any;
}

export function FavoriteSwimmerControl(props: FavoriteSwimmerControlProps) {
  let swimmer = props.swimmer;
  let favoriteSwimmers = props.favoriteSwimmers;
  let setAccountDialog = props.setAccountDialog;
  let anonGuestModeStarted = props.anonGuestModeStarted;
  let theme = useTheme();
  let enqueueSnackbar = props.enqueueSnackbar;

  return (
    <span style={{marginRight: theme.spacing(1)}}>
    <IconButton edge="end" onClick={() => toggleFavoriteSwimmer(swimmer.id, favoriteSwimmers, setAccountDialog, enqueueSnackbar, anonGuestModeStarted)}>
      {isFavoriteSwimmer(swimmer, favoriteSwimmers) ? <FavoriteIcon style={{ color: theme.palette.secondary.main }} /> : <FavoriteBorderIcon />}
    </IconButton>
    </span>
  );
}

export const toggleFavoriteSwimmer = async (
  swimmerid: string,
  favoriteSwimmers: FavoriteSwimmers | undefined,
  setAccountDialog: any,
  enqueueSnackbar: any,
  anonGuestMode: Timestamp
) => {
  var fs = favoriteSwimmers;
  var added: boolean = false;
  if (fs && fs.hasOwnProperty(swimmerid)) {
    delete fs[swimmerid];
  } else {
    if (!fs) {
      fs = {};
    }
    fs[swimmerid] = { id: swimmerid };
    added = true;
  }
  await setFavoriteSwimmersLocal(fs, setAccountDialog, anonGuestMode);
    if (added) {
      console.debug("Oma uimari lisätty:", swimmerid);
      enqueueSnackbar("Oma uimari lisätty: " + swimmerid);
    } else {
      console.debug("Oma uimari poistettu:", swimmerid);
      enqueueSnackbar("Oma uimari poistettu: " + swimmerid);
    }
};

function isAnonGuestMode(anonGuestModeStarted: Timestamp) {
  if (!anonGuestModeStarted) {
    console.debug("Not in anon guest mode");
    return false;
  }
  let then = anonGuestModeStarted.toDate().getTime();
  let now = new Date().getTime();
  let differenceInDays = (now - then) / 35e6;
  if (differenceInDays <= 2) {
    // 2 days
    console.debug("In anon guest mode.");
    return true;
  } else {
    console.debug("Not in anon guest mode.");
    return false;
  }
}

const setFavoriteSwimmersLocal = async (favoriteSwimmers: FavoriteSwimmers, setAccountDialog: any, anonGuestMode: Timestamp) => {
  const auth = getAuth();
  if (auth === undefined || auth === null || auth.currentUser === undefined || auth.currentUser === null) {
    console.error("User not authenticated. Unable to store favorite swimmers.");
    return null;
  } else {
    if (!auth.currentUser.email && !isAnonGuestMode(anonGuestMode)) {
      setAccountDialog(true);
    }
    const userId = auth.currentUser.uid;
    var userDocRef = doc(getFirestore(), getEnvPrefix() + "users", userId);
    console.debug("Updating favorite swimmers:", favoriteSwimmers);
    return updateDoc(userDocRef, { favoriteSwimmers: favoriteSwimmers });
  }
};

export const addFavoriteClub = async (
  clubName: string | undefined,
  shortName: string | undefined,
  favoriteClubs: FavoriteClubs | undefined,
  setAccountDialog: (accountDialogOpen: boolean) => void,
  anonGuestMode: Timestamp,
  enqueueSnackbar: any
) => {
  var fc = favoriteClubs;
  if (!fc) {
    fc = {};
  }
  if (clubName) fc[clubName] = { name: clubName };
  if (shortName) fc[shortName] = { name: shortName };
  return setFavoriteClubsLocal(fc, setAccountDialog, anonGuestMode).then((response: any) => {
    let msg = clubName;
    if (shortName) {
      msg = msg + " (" + shortName + ")";
    }
    enqueueSnackbar("Oma seura lisätty: " + msg, enqueueSnackbar);
  });
};

export const removeFavoriteClub = async (
  clubName: string | undefined,
  shortName: string | undefined,
  favoriteClubs: FavoriteClubs | undefined,
  setAccountDialog: any,
  anonGuestMode: Timestamp,
  enqueueSnackbar: any,
) => {
  var fc = favoriteClubs;
  if (!fc) {
    fc = {};
  }
  if (clubName && fc.hasOwnProperty(clubName)) {
    delete fc[clubName];
  }
  if (shortName && fc.hasOwnProperty(shortName)) {
    delete fc[shortName];
  }
  return setFavoriteClubsLocal(fc, setAccountDialog, anonGuestMode).then((response: any) => {
    let msg = clubName;
    if (shortName) {
      msg = msg + " (" + shortName + ")";
    }
    enqueueSnackbar("Oma seura poistettu: " + msg, enqueueSnackbar);
  });
};

const setFavoriteClubsLocal = async (favoriteClubs: FavoriteClubs, setAccountDialog: any, anonGuestMode: Timestamp) => {
  const auth = getAuth();
  if (auth === undefined || auth === null || auth.currentUser === undefined || auth.currentUser === null) {
    console.error("User not authenticated. Unable to update favorite clubs.");
    return;
  } else {
    if (!auth.currentUser.email && !isAnonGuestMode(anonGuestMode)) {
      setAccountDialog(true);
    }
    const userId = auth.currentUser.uid;
    var userDocRef = doc(getFirestore(), getEnvPrefix() + "users", userId);
    return updateDoc(userDocRef, { favoriteClubs: favoriteClubs }).catch(function (error: any) {
      console.error(error);
    });
  }
};

export function hasFavoriteSwimmers(favoriteSwimmers: FavoriteSwimmers | undefined) {
  if (favoriteSwimmers === undefined || Object.values(favoriteSwimmers).length === 0) {
    return false;
  } else {
    return true;
  }
}

export function isFavoriteSwimmer(swimmer: Swimmer, favoriteSwimmers: FavoriteSwimmers | undefined) : boolean {
  if (swimmer.id && favoriteSwimmers) {
    if (favoriteSwimmers.hasOwnProperty(swimmer.id)) {
      return true;
    }
    const athletes = swimmer.athletes;
    if (athletes) {
      for (let i = 0; i < athletes.length; i++) {
        const athlete = athletes[i];
        if (athlete && favoriteSwimmers.hasOwnProperty(athlete.id)) {
          return true;
        }
      }
    }
  }
  return false;
}

export function isFavoriteClub(name: (string | undefined), shortName: (string | undefined), favoriteClubs: (FavoriteClubs | undefined)) : boolean {
  if (name && favoriteClubs && favoriteClubs.hasOwnProperty(name)) {
    return true;
  } else if (shortName && favoriteClubs && favoriteClubs.hasOwnProperty(shortName)) {
      return true;
  } else {
    return false;
  }
}

/*
export function isFavoriteSwimmerInRelayTeam(swimmer: Swimmer, favoriteSwimmers: FavoriteSwimmers | undefined) : boolean {
  if(swimmer.id) {
    return false;
  } else {
    return false;
  }
} */
