import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import { getAPIURL } from "../Helpers";
import Box from "@mui/material/Box";

interface CompetitionFilesProps {
  cid: string;
  competitionData?: CompetitionData;
}

const CompetitionFiles = (props: CompetitionFilesProps) => {
  const [attachments, setAttachments] = useState<undefined | any[]>(undefined);
  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(true);

  useEffect(() => {
    return getData(props.cid);
  }, [props.cid]);

  useEffect(() => {
    setLoadingInProgress(false);
  }, [attachments]);

  function getData(cid: string) {
    if (cid !== undefined) {
      fetch(getAPIURL() + "/attachments?cid=" + cid)
        .then((res) => res.json())
        .then((json) => setAttachments(json))
        .catch((error) => console.log(error));
    }
  }

  let noAttachments = (
    <ListItem>
      <ListItemText primary="Ei julkaistuja tiedostoja." />
    </ListItem>
  );

  let noData = (
    <ListItem>
      <ListItemText primary="" />
    </ListItem>
  );

  let attachmentList =
    props.cid !== undefined &&
    attachments !== undefined &&
    attachments.map((item, i, arr) => (
      <ListItem button key={"attachment_" + props.cid + "_" + i}>
        <Link target="livetiming" href={"http://livetiming.fi" + item.filelink} key={"attachment_" + props.cid + "_" + i} underline="hover">
          <ListItemText primary={item.description} secondary={item.filename} />
        </Link>
      </ListItem>
    ));

  return (
    <div>
      <Box>
      <div>
        {loadingInProgress ? <LinearProgress /> : <div style={{ height: "4px", width: "100%", clear: "both" }}></div>}
        <List>
          <span>{attachments === undefined && noData}</span>
          <span>
          {(attachments && attachments.length > 0 ? attachmentList : noAttachments)}
          </span>
        </List>
      </div>
      </Box>
    </div>
  );
};

export default CompetitionFiles;
