import { useQuery } from "@apollo/client";

import { gql } from '../../src/__generated__/gql';

const CLUBNAMES_QUERY = gql(/* GraphQL */ `
query GetClubNames($cid: uuid!) @cached(ttl: 60) {
        clubs(
            where: { competition_id: { _eq: $cid } }
        ) {
            ...ClubNames
            competitors_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`);

function getSwimifyClubs(cid: string | undefined, skip: boolean) {
    const { data, loading, error } = useQuery(CLUBNAMES_QUERY, {
        variables: { cid },
        skip: skip,
        pollInterval: 1 * 60 * 1000, // every 1 minute
    });
    const clubs : any = data?.clubs;

    const clubsObject: Clubs = clubs?.reduce(function (result: any, item: any, index: number, array: any) {
        let club: Club = {
          name: item.name,
          shortName: item.short_name,
        };
        if(item.competitors_aggregate?.aggregate?.count) {
            club.competitor_count = item.competitors_aggregate.aggregate.count
        }
        if(item.country_code) {
            club.country_code = item.country_code;
        }
        result[club.name] = club;
        return result;
      }, {});
    return { swimifyClubs: clubsObject, swimifyClubsLoading: loading, swimifyClubsLoadingError: error };
}

export default getSwimifyClubs;
