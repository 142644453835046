import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
//import { PAGE_WIDTH } from "./Layout";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";

// original primary: "#0082bc"
// original secondary: "#f52aa4"

const PRIMARY_COLOR = "#0077AC";
const SECONDARY_COLOR = "#f52aa4";

const template = {
  typography: {
    fontSize: 14,
    h6: {
      fontSize: 16,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 600,
      lg: 832,
      xl: 1536,
    },
  },

  palette: {
    primary: {
      main: PRIMARY_COLOR,
      //light: "#0082bc10",
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
};

function getTheme(mode: "light" | "dark") {
  let templateTheme = { ...template, palette: { ...template.palette, mode: mode } };
  const themeWithMode = createTheme(templateTheme);

  let isBottom = useMediaQuery(themeWithMode.breakpoints.down("md"));

  useEffect(() => {

    let contentColor = (mode === "light" ? themeWithMode.palette.primary.main : themeWithMode.palette.primary.dark);

    /* meta name="msapplication-TileColor" content="#da532c">
    <meta name="theme-color" content="#0082bc">
    <meta name="apple-mobile-web-app-status-bar-style" content="#0082bc"> */

    let themeMeta2 = document.querySelector("meta[name='theme-color']");
    themeMeta2 !== null && themeMeta2.setAttribute("content", isBottom ? contentColor : themeWithMode.palette.background.default);
    let themeMeta3 = document.querySelector("meta[name='apple-mobile-web-app-status-bar-style']");
    themeMeta3 !== null && themeMeta3.setAttribute("content", isBottom ? contentColor : themeWithMode.palette.background.default);
  }, []);

  const themeWithOverrides = createTheme(
    deepmerge(themeWithMode, {
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: themeWithMode.palette.background.default,
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              width: "100%",
              margin: "auto",
              justifyContent: "space-between",
              //backgroundColor: (mode === 'light' ? themeWithMode.palette.primary.main : themeWithMode.palette.primary.dark),
              //maxWidth: PAGE_WIDTH + "rem",
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              //backgroundColor: (mode === 'light' ? themeWithMode.palette.primary.main : themeWithMode.palette.primary.dark),
              width: "100%",
              margin: "auto",
              //maxWidth: PAGE_WIDTH + "rem",
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              padding: 0,
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              padding: 0,
              "&:last-child": {
                paddingBottom: 0,
             },
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            primary: {
              fontWeight: 440,
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            content: {
              marginBottom: 0,
            },
            root: {
              margin: 0,
              minHeight: "2rem",
            },
          },
        },
      },
    })
  );

  document.body.style.backgroundColor = themeWithOverrides.palette.background.default;

  return themeWithOverrides;
}

export default getTheme;
