import { isFavoriteSwimmer } from "../../User/FavoriteHelpers";
import { isFavoriteClub } from "../../Helpers";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";
import { FlagIcon } from "../../Helpers";
import { getTimeDiff } from "../../Helpers";
import ListItemButton from "@mui/material/ListItemButton";
import { LaneAvatar } from "../LaneAvatar";
import { PositionAvatar } from "../PositionAvatar";
import { Link } from "react-router-dom";

export interface StartProps {
  start: StartListItem | undefined;
  event: string;
  programItem: ProgramItem;
  userSettings: UserSettings | undefined;
  heatStatus: number | undefined;
}

export function Start(props: StartProps) {
  const { start, programItem, userSettings } = props;
  const theme = useTheme();
  if (start === undefined) return null;

  const entryTime = start.entryTime;
  const resultTime = start.resultTime;
  const timeDiff = entryTime && resultTime && getTimeDiff(resultTime, entryTime);
  
  const splits = start.splits;
  const swimmed_distance = start.swimmed_distance;
  const latestSplitDistance = swimmed_distance ? swimmed_distance + "m" : undefined;
  const latestSplitTime = splits && latestSplitDistance && splits[latestSplitDistance];
  const raceDistance = programItem.swimStyle?.distance;
  const raceOngoing = !(swimmed_distance + "m" === raceDistance);

  const reactionTime = start.reactionTime;

  return (
    <ListItem disablePadding disableGutters dense
       sx={{
        '& .MuiListItemButton-root': { paddingRight: theme.spacing(4) } 
       }}
      secondaryAction={
        <>
        <IconButton size="medium" tabIndex={-1}
          component={Link}
          to={"../../swimmer/" + start.id + "/event/" + start.event}
        >
          <ArrowRightIcon />
        </IconButton>
        </>
      }
    >
      <ListItemButton
        disableGutters
        dense
        sx={{
          paddingLeft: theme.spacing(1),
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
        }}
        component={Link}
        to={"../../swimmer/" + start.id + "/event/" + start.event}
      >
        <LaneAvatar track={start.track}/>

        <ListItemText
          primaryTypographyProps={{ style: { fontSize: "0.9rem" } }}
          secondaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
          primary={
            <span>
              {userSettings && isFavoriteSwimmer(start, userSettings.favoriteSwimmers) ? (
                <span style={{ color: theme.palette.secondary.main }}>{start.id}</span>
              ) : (
                <span>{start.id}</span>
              )}
            </span>
          }
          secondary={
            <span>
              <span>
                <FlagIcon nationality={start.nationality} />
                {userSettings && userSettings.favoriteClubs && isFavoriteClub(start.club, userSettings.favoriteClubs) ? (
                  <span style={{ color: theme.palette.secondary.main }}>{start.club}</span>
                ) : (
                  <span>{start.club}</span>
                )}
              </span>
            </span>
          }
        />

        <span>
          <Typography align="right" color={theme.palette.text.primary} sx={{ fontSize: "0.8rem" }}>
            {!raceOngoing && resultTime}
          </Typography>
          {!raceOngoing && (
            <Typography align="right" color={theme.palette.text.primary} sx={{ fontSize: "0.8rem" }}>
              {timeDiff && timeDiff.length > 0 && timeDiff.charAt(0) === "+" ? (
                <span style={{ fontSize: "0.8rem", color: theme.palette.error.main }}> {timeDiff}</span>
              ) : (
                <span style={{ fontSize: "0.8rem", color: theme.palette.success.main }}> {timeDiff}</span>
              )}
            </Typography>
          )}
          {!reactionTime && (resultTime === undefined || resultTime.length === 0) && (
            <span>
              {entryTime !== undefined && entryTime.length > 0 && (
                <span>
                  <Typography align="right" color={theme.palette.text.secondary} sx={{ fontSize: "0.8rem" }}>
                    Ilm. aika:
                  </Typography>
                  <Typography align="right" color={theme.palette.text.primary} sx={{ fontSize: "0.8rem" }}>
                    {entryTime}
                  </Typography>
                </span>
              )}
            </span>
          )}

          {raceOngoing && latestSplitDistance && (
            <>
              <Typography align="right" color={theme.palette.text.secondary} sx={{ fontSize: "0.8rem" }}>
                {latestSplitDistance}
              </Typography>
              <Typography align="right" color={theme.palette.text.primary} sx={{ fontSize: "0.8rem" }}>
                {latestSplitTime}
              </Typography>
            </>
          )}
          {reactionTime && !latestSplitDistance && raceOngoing && (
            <span>
              <Typography align="right" color={theme.palette.text.secondary} sx={{ fontSize: "0.8rem" }}>
                Reaktio:
              </Typography>
              <Typography align="right" color={theme.palette.text.primary} sx={{ fontSize: "0.8rem" }}>
                {reactionTime}
              </Typography>
            </span>
          )}
        </span>
        {props.heatStatus && props.heatStatus > 1 ?
          <PositionAvatar rank={""+start.heat_rank} dsq={start.dsq} dnf={start.dnf} dns={start.dns} raceOngoing={raceOngoing} />
        : ""}
      </ListItemButton>
    </ListItem>
  );
}


