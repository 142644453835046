import { isInFavoriteClub } from "../Helpers";
import { isFavoriteSwimmer } from "../User/FavoriteHelpers";


export function resolveSwimmers(listItems: ListItems, userSettings: UserSettings | undefined) {
  let swimmers: Swimmers = {};
  let clubsObject: any = {};
  let clubPairsObject: any = {};

  for (const [listItemId, listItem] of Object.entries(listItems)) {
    clubsObject[listItem.club] = listItem.club;
    const listItemTypeKey = listItem.listItemType as keyof typeof listItem;

    let swimmer = swimmers[listItem.id];
    let athletes = listItem.athletes

    if (swimmer === undefined) {
      // create swimmer if it does not exist (this is the first occurrence)
      swimmer = {
        club: listItem.club,
        firstNames: listItem.firstNames,
        lastName: listItem.lastName,
        name: listItem.name,
        id: listItem.id,
        year: listItem.year,
        nationality: listItem.nationality,
        athletes: athletes,
        swimmerSessions: {},
      };
    }

    swimmer.swimmerSessions = {
      ...swimmer.swimmerSessions,
      ...parseSwimmer(swimmer, listItem, "competitor").swimmerSessions
    }

    // if swimmer exists, add club2 if different from club
    if (swimmer.club && swimmer.club !== listItem.club) {
      swimmer.club2 = listItem.club; // jos uimarille löytyy toinen seura, lisää se uimarille ja lisäksi clubpairs -listaan
      clubPairsObject[swimmer.club] = swimmer.club2;
      clubPairsObject[swimmer.club2] = swimmer.club;
    }

    // finally, add swimmer to swimmersData
    swimmers[listItem.id] = swimmer;

    if(athletes) {
      for (const athlete of athletes) {

        let swimmer = swimmers[athlete.id];
        if (swimmer === undefined) {
          // create swimmer if it does not exist (this is the first occurrence)
          swimmer = {
            club: listItem.club, // copy from relay team
            firstNames: athlete.firstNames,
            lastName: athlete.lastName,
            name: athlete.name,
            id: athlete.id,
            year: athlete.year,
            nationality: listItem.nationality, // copy from relay team
            swimmerSessions: {},
          }
        }
        swimmer.swimmerSessions = {
          ...swimmer.swimmerSessions,
          ...parseSwimmer(swimmer, listItem, "relayCompetitor").swimmerSessions
        }
        swimmers[athlete.id] = swimmer;
      }
    }

    if(userSettings && isFavoriteSwimmer(swimmer, userSettings.favoriteSwimmers)) { 
      swimmer.isFavorite = true;
    }
    if(userSettings && isInFavoriteClub(swimmer, userSettings.favoriteClubs)) {
      swimmer.isInFavoriteClub = true;
    }

  }

  return { swimmers: swimmers, clubsData: clubsObject, clubPairsData: clubPairsObject };
}

function parseSwimmer(swimmer : Swimmer, listItem: ListItem, competitorType : ("competitor" | "relayCompetitor")) : Swimmer {
  const listItemTypeKey = listItem.listItemType as keyof typeof listItem;

  // add session to swimmerSessions
  if (swimmer["swimmerSessions"] && swimmer["swimmerSessions"][listItem.sid] === undefined) {
    swimmer["swimmerSessions"][listItem.sid] = {
      swimmerProgramItems: {},
      sid: listItem.sid,
    };
  }

  // add programItem to swimmerProgramItems
  if (swimmer["swimmerSessions"] && swimmer["swimmerSessions"][listItem.sid]["swimmerProgramItems"][listItem.programItemIndex] === undefined) {
    let pi = undefined;
    try {
      // @ts-ignore
      pi = competitionData.sessions[listItem.sid].programItems[listItem.programItemIndex];
    } catch (error) {
      // nothing to do, but data will be incomplete and ugly
    }
    swimmer["swimmerSessions"][listItem.sid]["swimmerProgramItems"][listItem.programItemIndex] = {
      programItemIndex: listItem.programItemIndex,
      sid: listItem.sid,
      competitorType: competitorType,
      programItemId: pi?.programItemId,
    };
  }

  // add item (result, start or entry) to swimmerProgramItem
  if (swimmer["swimmerSessions"]) {
    if (listItem.listType === "resultList") {
      let result : any = (listItem as ResultListItem).result;
      if(result.rankings === undefined) {
        result.rankings = { "Total": result.position };
      }
      if(result.ranking === undefined) {
        result.ranking = result.position;
      }
      swimmer["swimmerSessions"][listItem.sid]["swimmerProgramItems"][listItem.programItemIndex] = {
        ...swimmer["swimmerSessions"][listItem.sid]["swimmerProgramItems"][listItem.programItemIndex],
        ...{ [listItem.listType.replace("List", "")]: listItem[listItemTypeKey] },
      };
    } else if(listItem.listType === "startList") {
      swimmer["swimmerSessions"][listItem.sid]["swimmerProgramItems"][listItem.programItemIndex] = {
        ...swimmer["swimmerSessions"][listItem.sid]["swimmerProgramItems"][listItem.programItemIndex],
        ...{ [listItem.listType.replace("List", "")]: listItem[listItemTypeKey] },
      };
    } else if(listItem.listType === "entryList") {
      let entry : any = (listItem as EntryListItem).entry;
      if(entry.rankings === undefined) {
        entry.rankings = { "Total": entry.number };
      }
      if(entry.ranking === undefined) {
        entry.ranking = entry.number;
      }
      swimmer["swimmerSessions"][listItem.sid]["swimmerProgramItems"][listItem.programItemIndex] = {
        ...swimmer["swimmerSessions"][listItem.sid]["swimmerProgramItems"][listItem.programItemIndex],
        ...{ [listItem.listType.replace("List", "")]: listItem[listItemTypeKey] },
      };
    }
  }
  return swimmer;
}