import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import preval from "preval.macro";
import { TransitionProps } from "@mui/material/transitions";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface InfoDialogProps {
  infoDialogOpen: boolean;
  setInfoDialog: (infoDialogOpen: boolean) => void;
}

export default function FullScreenDialog(props: InfoDialogProps) {
  const email = "juha.larjomaa@gmail.com";
  const subject = encodeURIComponent("Palautetta/kysymys Uinnit.app -sovelluksesta");
  const theme = useTheme();
  const handleEmailClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.location.href = `mailto:${email}?subject=${subject}`;
  };

  return (
    <Dialog
      fullScreen
      open={props.infoDialogOpen}
      onClose={() => props.setInfoDialog(false)}
      TransitionComponent={Transition}
    >
      <AppBar color="primary" style={{ zIndex: 900, backgroundColor: theme.palette.background.default }} elevation={0}>
      <Container maxWidth="lg" disableGutters>

        <Toolbar
          sx={{
            backgroundColor: theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.primary.dark,
          }}
        >
          <div style={{ width: "100%" }}>
            <Grid container justifyContent="left" alignItems="center">
              <IconButton style={{ marginRight: "0.5rem" }} color="inherit" onClick={() => props.setInfoDialog(false)} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography>Tietoja</Typography>
            </Grid>
          </div>
        </Toolbar>
        </Container>
      </AppBar>
      <p>&nbsp;</p>

      <Container disableGutters maxWidth={"lg"}>
      <Box padding={theme.spacing(2)}>
        <p>Tämä palvelu on koekäytössä. Kaikki palaute on tervetullutta sähköpostiosoitteeseen:</p>
        <p>
          <a href={`mailto:${email}?subject=${subject}`} onClick={handleEmailClick}>
            {email}
          </a>
        </p>
        <p>
          Palvelu hakee näytettävät tiedot Swimify ja LiveTiming -palveluista julkisesti saatavilla olevista tulosteista. Tiedot poistuvat palvelusta kun ne poistetaan
          LiveTiming ja Swimify -palveluista.
        </p>
        <p>
          LiveTimingin ja Swimifyn tiedot (aikataulut, osallistujaluettelot, erälistat ja tulokset) vaihtelevat kilpailukohtaisesti eivätkä kaikki kilpailut aina toimi
          palvelussa oikein.
        </p>
        <p>
          Palveluun kirjautuneista käyttäjistä tallennetaan seuraavat tiedot: sähköpostiosoite ja salasana. Tietoja käytetään käyttäjän tunnistamiseen, jotta
          käyttäjän palveluun tekemät asetukset (omat uimarit) voidaan tallentaa ja ne ovat käytettävissä eri käyttökertojen välillä. Tietoja ei käytetä
          markkinointitarkoituksiin eikä niitä välitetä kolmansille osapuolille.
        </p>
        <p>Sovellus päivitetty: {preval`module.exports = new Date().toLocaleString('fi-FI');`}</p>
        <Button variant="contained" color="primary" onClick={() => props.setInfoDialog(false)}>
          Sulje
        </Button>
      </Box>
      </Container>
    </Dialog>
  );
}
