import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import List from "@mui/material/List";
import { useParams } from "react-router-dom";
import { FavoriteSwimmerControl } from "../../User/FavoriteHelpers";
import { useSnackbar } from "notistack";
import LinearProgress from "@mui/material/LinearProgress";
import UinnitAppBar from "../../UinnitAppBar";
import { FlagIcon } from "../../Helpers";
import { useTheme } from "@mui/material/styles";
import getSwimifyClubCompetitors from "../GetClubCompetitors";
import ListItemButton from "@mui/material/ListItemButton";

interface ClubProps {
  userSettings: UserSettings | undefined;
  swimmers: Swimmers | undefined;
  clubs: Clubs | undefined;
  clubPairs: ClubNamePairs;
  cid: string;
  competitionData: CompetitionData | undefined;
  setAccountDialog: (accountDialogOpen: boolean) => void;
  currentHeats: CurrentHeat[];
  navigationCID: string;
}

export default function Club(props: ClubProps) {
  const { clubName } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const clubPairs: ClubNamePairs = {};

  const skip = props.cid.length === 4 || clubName === undefined || props.userSettings === undefined;
  const {
    swimifyClubCompetitors: swimifyClubCompetitors,
    swimifyClubCompetitorsLoading: swimifyClubCompetitorsLoading,
    swimifyClubCompetitorsLoadingError: swimifyClubCompetitorsLoadingError,
  } = getSwimifyClubCompetitors(props.cid, decodeURIComponent(clubName ? clubName : ""), skip);
  const swimmers = props.cid.length === 4 ? props.swimmers : swimifyClubCompetitors;

  const favoriteSwimmers = props.userSettings?.favoriteSwimmers;
  const anonGuestModeStarted = props.userSettings?.anonGuestModeStarted;

  function filterClubSwimmers(swimmer: any, index: number, swimmers: any) {
    if (clubName) {
      if (swimmer.club && swimmer.club === clubName) {
        return true;
      }
      if (swimmer.club2 && swimmer.club2 === clubName) {
        return true;
      }
    }
    // etsitään myös pariklubien uimarit
    if (clubName && clubPairs && clubPairs.hasOwnProperty(clubName)) {
      let otherClub = clubPairs[clubName];
      if (swimmer.club && swimmer.club === otherClub) {
        return true;
      }
      if (swimmer.club2 && swimmer.club2 === otherClub) {
        return true;
      }
    }
    return false;
  }

  function sortAlpha(swimmerA: any, swimmerB: any) {
    return swimmerB.id.trim().localeCompare(swimmerA.id.trim()) > 0 ? -1 : 1;
  }

  return (
    <div>
      <UinnitAppBar currentHeats={props.currentHeats} navigationCID={props.navigationCID} includeBackButton={true} title={clubName ? clubName : ""} />

      <div style={{ height: "4px", width: "100%", clear: "both", marginBottom: 0 }}>
        {(swimmers === undefined || swimifyClubCompetitorsLoading) && <LinearProgress />}
      </div>

      {swimmers !== undefined && (
        <div>
          <List style={{ maxHeight: "100%", overflow: "auto" }}>
            {Object.values(swimmers)
              .filter(filterClubSwimmers)
              .sort(sortAlpha)
              .map((swimmer: Swimmer) => (
                <ListItem
                  key={"swimmers_" + swimmer.id}
                  disablePadding
                  secondaryAction={
                    <>
                      <FavoriteSwimmerControl
                        enqueueSnackbar={enqueueSnackbar}
                        swimmer={swimmer}
                        favoriteSwimmers={favoriteSwimmers}
                        setAccountDialog={props.setAccountDialog}
                        anonGuestModeStarted={anonGuestModeStarted}
                      />
                      <IconButton edge="end" tabIndex={-1} onClick={() => navigate("../swimmer/" + swimmer.id)}>
                        <ArrowRightIcon />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemButton component={Link} to={"../swimmer/" + swimmer.id}>
                    <ListItemText
                      primary={<span>{swimmer.id}</span>}
                      secondary={
                        <span>
                          {swimmer && <FlagIcon nationality={swimmer.nationality} />}
                          {swimmer.club}
                        </span>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </div>
      )}
    </div>
  );
}
