import { Card, CardHeader, Avatar, useTheme, Divider } from "@mui/material";

interface SessionCardProps {
  session: Session | undefined;
}

export const SessionCard: React.FC<SessionCardProps> = (props: SessionCardProps): JSX.Element => {
  const theme = useTheme();
  const session = props.session;

  if (session === undefined) return <></>;

  const dateText = session.sessionDateText;

  const sessionNameTrimmed = (session.sessionName ? session.sessionName.trim() : undefined);

  const noSessionName = (sessionNameTrimmed === undefined || sessionNameTrimmed !== "" ? true : false);

  const hasTrivialName = (sessionNameTrimmed === "Jakso " + session.sid || sessionNameTrimmed === "Session " + session.sid ? true : false);

  const sessionText = (
    noSessionName ? "Jakso " + session.sid : 
    hasTrivialName ? sessionNameTrimmed : 
    "Jakso " + session.sid + ": " + sessionNameTrimmed
  );

  return (
    <>
    <Card
      elevation={0}
      sx={{
        padding: theme.spacing(0),
        margin: theme.spacing(1),
        //backgroundColor: theme.palette.primary.light,
      }}
    >
      <CardHeader
        sx={{
          padding: theme.spacing(1),
        }}
        title={sessionText}
        titleTypographyProps={{ variant: "h6", color: theme.palette.primary.main }}
        subheaderTypographyProps={{ variant: "body2" }}
        subheader={dateText}
      />
    </Card>

    </>
  );
};
